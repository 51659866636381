<md-list>
    <md-list-item ng-repeat="item in $ctrl.list" data="item" list-controller="$ctrl">
        <cmp-user-token-item data="item" list-controller="$ctrl" flex></cmp-user-token-item>
        <md-divider></md-divider>
    </md-list-item>

    <md-list-item><div flex></div><md-button ng-click="$ctrl.add()" class="md-raised  md-primary">
        <md-icon md-svg-icon="core:plus" class="ng-scope" ></md-icon>
        add</md-button></md-list-item>
</md-list>
