<md-card ng-if="$ctrl.asForm" id="org_{{$ctrl.id}}" >
    <form name="orgForm" ng-submit="$ctrl.save(orgForm)">
        <!--<md-card-title></md-card-title>-->
        <md-card-content layout="column">
            <div flex layout="row">
                <md-input-container flex="50">
                    <label>Organization's title</label>
                    <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" required minlength="2" ng-change="$ctrl.pickupName()">
                    <help-hint hint="org.displayname"></help-hint>
                    <div ng-messages="orgForm.DisplayName.$error">
                        <div ng-message="required">Organization title should be filled</div>
                        <div ng-message="minlength">Organization title is too short</div>
                    </div>
                </md-input-container>
                <div flex="50" class="layout-row layout-align-start-center">
                    <md-input-container flex>
                        <label translate>COMMON_IDENT</label>
                        <input
                            type="text"
                            name="Name"
                            ng-model="$ctrl.data.Name"
                            ng-change="$ctrl.checkName(orgForm)"
                            ng-model-options='{ debounce: 1000 }'
                            ng-disabled="!$ctrl.nameEditing && $ctrl.data.ID"
                            ng-maxlength="$ctrl.validate.maxName"
                            ng-minlength="$ctrl.validate.minName"
                            ng-pattern="$ctrl.validate.namePattern"
                            required
                        >
                        <help-hint hint="org.name"></help-hint>
                        <div ng-messages="orgForm.Name.$error">
                            <div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.nameErrorMessage" error="$ctrl.nameErrorMessage"></cmp-error-toast></div>
                            <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                            <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                            <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
                            <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
                        </div>
                    </md-input-container>
                    <md-button class="md-icon-button" ng-hide="!$ctrl.data.ID || $ctrl.nameEditing" ng-click="$ctrl.editName()"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
                    <md-button class="md-icon-button" ng-hide="!$ctrl.data.ID || !$ctrl.nameEditing" ng-click="$ctrl.saveName()"><md-icon md-svg-icon="core:check"></md-icon></md-button>
                    <md-button class="md-icon-button" ng-hide="!$ctrl.data.ID || !$ctrl.nameEditing" ng-click="$ctrl.cancelEditName()"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                </div>
            </div>
            <div flex layout="row">
                <md-input-container flex="50">
                    <label>{{"COMMON_PHONE"|translate}}</label>
                    <input type="text" name="Phone" ng-model="$ctrl.data.Phone">
                    <help-hint hint="org.phone"></help-hint>
                </md-input-container>
                <md-input-container flex="50">
                    <label>{{"COMMON_URL"|translate}}</label>
                    <input type="text" name="Url" ng-model="$ctrl.data.Url">
                    <help-hint hint="org.url"></help-hint>
                </md-input-container>
            </div>
            <div layout="row" layout-align="center center">
                <img ng-src="{{$ctrl.file[0].lfDataUrl || $ctrl.data.Picture}}"
                  class="md-avatar"
                  ng-class="{'hidden': !$ctrl.file[0].lfDataUrl && !$ctrl.data.Picture}"
                  alt="{{phone.options.face}}"
                  style="height: 40px" />
                <div style="width:2em"></div>
                <div class="flex">
                    <lf-ng-md-file-input lf-files="$ctrl.file" flex lf-filesize="1MB" name="picture" lf-api="$ctrl.lfApi"></lf-ng-md-file-input>
                    <div ng-messages="asForm.picture.$error" >
                        <div ng-message="filesize">File size too large.</div>
                    </div>
                </div>
                <md-button ng-click="$ctrl.uploadFile(orgForm)" ng-if="!$ctrl.isNewElement" ng-disabled="!$ctrl.file.length">upload</md-button>
            </div>
        </md-card-content>
        <div ng-messages="$ctrl.sendErrorMessage" role="alert">
        </div>
        <md-card-actions layout="row" layout-align="end center">
            <div flex>
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                <cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-api-error-service>
            </div>
            <md-button type="submit"><md-icon md-svg-icon="core:check"></md-icon> {{$ctrl.data.ID ? "Save" : "Create"}}</md-button>
            <md-button ng-click="$ctrl.cancel()"><md-icon md-svg-icon="core:close"></md-icon> {{"COMMON_CANCEL"|translate}}</md-button>
        </md-card-actions>
    </form>
</md-card>


<md-list-item ng-if="!$ctrl.asForm" ng-click="$ctrl.showMy()" class="md-2-line">
    <md-icon md-svg-icon="core:group" ng-if="!$ctrl.data.Picture" ng-class="md-avatar-icon"></md-icon>
    <img ng-src="{{$ctrl.data.Picture}}?25" class="md-avatar" alt="{{phone.options.face}}" ng-if="$ctrl.data.Picture"/>
    <div class="md-list-item-text" flex>
        <h3>{{$ctrl.data.DisplayName}}</h3>
        <p>
            <span>{{"COMMON_NAME"|translate}}: {{$ctrl.data.Name}}</span>
            <span ng-if="$ctrl.Phone">{{"COMMON_PHONE"|translate}}: <b>{{ $ctrl.data.Phone}}</b></span>
            <span ng-if="$ctrl.Url">{{"COMMON_URL"|translate}}: <b>{{ $ctrl.data.URl}}</b></span>
        </p>
    </div>
    <md-menu   class="md-secondary" md-position-mode="target-right target">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3" >
            <md-menu-item >
                <md-button aria-label="icon"ng-click="$ctrl.actionForm()">
                    <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button aria-label="icon"ng-click="$ctrl.delete($event)">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-list-item>
<md-list-item ng-if="$ctrl.data.ID == $ctrl.stateParams.orgId && !$ctrl.isNew()" class="org-full-info">
    <div ng-cloak flex>
        <md-content>
            <!--<md-tabs md-dynamic-height md-border-bottom >-->
                <!--<md-tab label="Teams">-->
                    <!--<md-content class="md-padding">-->
                        <!--<cmp-organization-card-team-list ng-if="$ctrl.data.ID" organization-id="$ctrl.data.ID"></cmp-organization-card-team-list>-->
                    <!--</md-content>-->
                <!--</md-tab>-->
                <!--<md-tab label="Service accounts">-->
                    <!--<md-content class="md-padding">-->
                        <!--<cmp-wsa-card-list ws-id="$ctrl.data.Workspace"></cmp-wsa-card-list>-->
                    <!--</md-content>-->
                <!--</md-tab>-->
            <!--</md-tabs>-->


            <md-tabs md-dynamic-height md-border-bottom md-selected="selectedIndex">
                <md-tab ng-if="$ctrl.canManage">
                    <md-tab-label>{{"SETITNGS_COMMON_TITLE_TEAMS"|translate}}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content>
                            <cmp-organization-card-team-list ng-if="$ctrl.data.ID" organization-id="$ctrl.data.ID"></cmp-organization-card-team-list>
                        </md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab>
                    <md-tab-label>{{'SETITNGS_COMMON_TITLE_SERVICE_ACCOUNTS'|translate}}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content>
                            <cmp-wsa-card-list ws-id="$ctrl.data.Workspace"></cmp-wsa-card-list>
                        </md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab ng-if="$ctrl.GlobalSettings.billing_enabled">
                    <md-tab-label>
                        {{'SETTITNGS_COMMON_TITLE_BILLING'|translate}}
                        <md-icon md-svg-icon="core:alert" class="md-warn" alt="Billing issue" ng-if="$ctrl.data.billingFailed" title="Billing issue"></md-icon>
                    </md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content>
                            <cmp-billing-ws
                                    ng-if="selectedIndex == 2"
                                    ws-id="$ctrl.data.Name"
                            ></cmp-billing-ws>

                            <cmp-invoice-ws ws-id="$ctrl.data.Name"></cmp-invoice-ws>
                        </md-content>
                    </md-tab-body>
                </md-tab>
            </md-tabs>

        </md-content>
    </div>
</md-list-item>
