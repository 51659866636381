<div>
    <div layout="row" layout-align="start center">
        <md-input-container flex="15">
            <label translate>COMMON_VERSION</label>
            <md-select ng-model="$ctrl.version">
                <md-option ng-repeat="v in $ctrl.versions" ng-value="v">{{v.Version}}</md-option>
            </md-select>
        </md-input-container>
        <div>Status {{$ctrl.version.Status}}</div>
        <div class="flex layout-align-end-center layout-row" style="width: 100%; padding-top: 2em;" ng-if="$ctrl.version.Status == 'ok'">
            <cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>
            <a layout="row"
               class="md-button md-raised"
               style="margin-left: 0.5em"
               ng-disabled="isDownloading"
               ng-click="$ctrl.download()">
                <md-icon md-svg-icon="core:link"></md-icon>
                <span style="margin: 0 10px">download</span>
                <md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>
            </a>
        </div>
    </div>


    <form ng-if="$ctrl.canManage" name="upload" ng-submit="$ctrl.uploadFile(upload, fileM, 'upload', 'model', fApiM)" layout="column" style="width: 100%">
        <div layout="row" layout-align="center center">
            <lf-ng-md-file-input
                    lf-files="fileM"
                    flex progress
                    ng-disabled="$ctrl.isLoading.model" required
                    lf-api="fApiM"></lf-ng-md-file-input>
            <md-button type="submite" ng-disabled="$ctrl.isLoading.model || !fileM || !fileM.length">
                <span ng-show="!$ctrl.isLoading.model" >{{"COMMON_UPLOAD" | translate}}</span>
                <div flex linear ng-if="$ctrl.isLoading.model">
                    {{$ctrl.loadingProgres}}%
                    <md-progress-linear ng-if="$ctrl.isLoading.model" flex></md-progress-linear>
                </div>
            </md-button>
            <md-button ng-click="$ctrl.addFile = false">{{"COMMON_CANCEL" | translate}}</md-button>
        </div>
        <cmp-error-toast ng-if="$ctrl.loadingError.model" error="$ctrl.loadingError.model"></cmp-error-toast>
    </form>
</div>