<md-dialog style="min-width: 400px; width: 70%;" id="simplyConfigDialog">
    <md-toolbar>
        <div class="md-toolbar-tools">
            <div ng-switch="action">
                <h2>Simply form config</h2>
            </div>
            <span flex></span>
            <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
                <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content style="height: 500px" >
        <md-tabs md-border-bottom md-dynamic-height >
            <md-tab md-selected="$ctrl.selectedIndex">
                <md-tab-label>View</md-tab-label>
                <md-tab-body>
                    <cmp-applications-simply app-controller="$ctrl.appController" options="formOptions" layout="column" layout-align="start"></cmp-applications-simply>
                </md-tab-body>
            </md-tab>
            <md-tab>
                <md-tab-label>Config</md-tab-label>
                <md-tab-body>
                    <cmp-applications-simply-config app-controller="$ctrl.appController" options="formOptions" index="$ctrl.selectedIndex" layout="column" layout-align="start" ></cmp-applications-simply-config>
                </md-tab-body>
            </md-tab>
            <md-tab>
                <md-tab-label>Template</md-tab-label>
                <md-tab-body>
                    <cmp-application-edit-template app-controller="$ctrl.appController" index="$ctrl.selectedIndex" layout="column" layout-align="start" flex ></cmp-application-edit-template>
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </md-dialog-content>
</md-dialog>
