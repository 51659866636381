<md-list-item class="md-3-line layout-align-start-center" ng-if="!$ctrl.asForm">
    <md-icon md-svg-icon="core:{{$ctrl.Type}}" class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text flex">
        <div layout="row" layout-align="start center"><h4>{{$ctrl.data.name}}</h4> </div>
        <!--<h4>{{$ctrl.data.name}}</h4>-->
        <p><cmp-storage-view data="$ctrl.data"></cmp-storage-view>
        <br/>
            Mount path:{{$ctrl.data.mountPath}}
        </p>
        <form ng-if="$ctrl.addFile && $ctrl.canUploadFile" name="upload" ng-submit="$ctrl.uploadFile(upload)" layout="column">
            <div layout="row" layout-align="center center">
                <lf-ng-md-file-input lf-files="$ctrl.file" flex progress ng-disabled="$ctrl.isLoading" required lf-api="$ctrl.lfApi"></lf-ng-md-file-input>
                <md-button type="submite" ng-disabled="$ctrl.isLoading || !$ctrl.file || !$ctrl.file.length">
                    <span ng-show="!$ctrl.isLoading" >{{"COMMON_UPLOAD" | translate}}</span>
                    <div flex linear ng-if="$ctrl.isLoading">
                        {{$ctrl.loadingProgres}}%
                        <md-progress-linear ng-if="$ctrl.isLoading" flex></md-progress-linear>
                    </div>
                </md-button>
                <md-button ng-click="$ctrl.addFile = false">{{"COMMON_CANCEL" | translate}}</md-button>
            </div>
            <cmp-error-toast ng-if="$ctrl.loadingError" error="$ctrl.loadingError"></cmp-error-toast>
        </form>
    </div>

    <md-button ng-if="!$ctrl.data.readOnly && $ctrl.canUploadFile" ng-click="$ctrl.addFile = true " ng-disabled="$ctrl.addFile" style="margin:auto">{{"COMMON_UPLOAD_FILE" | translate}}</md-button>
    <md-button ng-if="$ctrl.data.readOnly" ng-disabled="$ctrl.addFile" style="margin:auto">{{"READ_ONLY" | translate}}</md-button>
    <!--<div class="flex-10 " style="margin:auto">{{$ctrl.data.mountPath}}</div>-->
    <div class="flex-10 layout-row layout-align-center-center" style="margin:auto" ng-if="$ctrl.isLibDir">
        <md-checkbox ng-model="$ctrl.data.isLibDir" style="margin: auto" ng-disabled="true" aria-label="Library Dir"></md-checkbox>
    </div>
    <!--<div class="flex-10 layout-row layout-align-center-center" style="margin:auto">-->
        <!--<md-checkbox ng-model="$ctrl.data.isTrainLogDir" style="margin: auto" ng-disabled="true" aria-label="Train Log Dir"></md-checkbox>-->
    <!--</div>-->


    <!--<form name="upload" ng-submit="$ctrl.uploadFile(upload)" layout="row" layout-align="center center">-->
        <!--<lf-ng-md-file-input lf-files="$ctrl.file" flex></lf-ng-md-file-input>-->
    <!--</form>-->
    <md-menu class="md-secondary" md-position-mode="target-right target">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" >
        <!--<md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="$ctrl.data.readOnly">-->
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.canUploadFile">
                <md-button aria-label="icon" ng-click="$ctrl.addFile = true ">
                    <md-icon md-svg-icon="core:upload"></md-icon> {{"COMMON_UPLOAD_FILE"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="!$ctrl.envList.Error && !$ctrl.data.readOnly">
                <md-button aria-label="icon" ng-click="$ctrl.edit()">
                    <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="!$ctrl.data.readOnly">
                <md-button aria-label="icon" ng-click="$ctrl.delete()">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
    <div ng-if="$ctrl.data.saving" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.3);" layout="row" layout-align="center center">
        <div style="color: #fff; font-size: 1.5em; ">{{$ctrl.data.saving}}</div>
    </div>
</md-list-item>

<div ng-if="$ctrl.asForm">
    <form name='add' ng-submit="$ctrl.save(add, $ctrl.data)" class="flex">
        <md-card class="flex">
            <md-card-content>
                <cmp-storage
                        data="$ctrl.data"
                        types="$ctrl.types"
                        cluster-storages="$ctrl.clusterStorageList"
                        workspace-name="$ctrl.workspaceName ? $ctrl.workspaceName : $ctrl.application.WorkspaceName"
                        cluster-id="$ctrl.application.ClusterID"
                        form="add"
                        is-serving="$ctrl.isServing"
                    ></cmp-storage>
                {{$ctrl.workspacaName}}
              <!--<md-checkbox ng-model="$ctrl.data.isTrainLogDir"-->
                           <!--ng-disabled="$ctrl.getType() == 'gitRepo' || $ctrl.isTrainLogDir" aria-label="Train Log">-->
                <!--Train Log Dir-->
              <!--</md-checkbox>-->
              <md-checkbox ng-model="$ctrl.data.isLibDir" ng-if="$ctrl.isLibDir" aria-label="Library Dir">Library Dir</md-checkbox>
            </md-card-content>
            <md-card-actions ng-if="!$ctrl.isTrainLogDir && !$ctrl.fromApplicationForm" class="layout-row layout-align-start-center">
                <div class="flex">
                    <md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>
                    <!--<cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage" class="flex"></cmp-api-error-service>-->
                </div>
                <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL" | translate}}</md-button>
                <md-button type="submit" ng-if="$ctrl.isNewElement" class="md-raised md-primary" ng-disabled="!add.$valid">{{"COMMON_ADD" | translate}}</md-button>
                <md-button type="submit" ng-if="!$ctrl.isNewElement" class="md-raised md-primary" ng-disabled="!add.$valid">{{"COMMON_SAVE" | translate}}</md-button>
            </md-card-actions>
        </md-card>
    </form>
</div>
