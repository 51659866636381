<table style="width: 100%" class="cmp-billing-plan md-table">
    <tbody>
    <tr>
        <td></td>
        <td  class="selected">
            <h4>Plan 1</h4>
            <div class="price layout-align-center-center layout-column" layout="column" layout-align="center center">
                <div class="amount ng-binding"><md-icon md-svg-icon="core:usd" role="img" aria-label="core:usd"><svg xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" focusable="false"><g id="usd"><path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"></path></g></svg></md-icon>0</div>
                <div class="currency ng-binding">per day</div>
            </div>
        </td>
        <td>
            <h4>Base</h4>
            <div class="price layout-align-center-center layout-column" layout="column" layout-align="center center">
                <div class="amount ng-binding"><md-icon md-svg-icon="core:usd" role="img" aria-label="core:usd"><svg xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" focusable="false"><g id="usd"><path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"></path></g></svg></md-icon>5</div>
                <div class="currency ng-binding">per day</div>
            </div>
        </td>
        <td>
            <h4>Plan 3</h4>
            <div class="price layout-align-center-center layout-column" layout="column" layout-align="center center">
                <div class="amount ng-binding"><md-icon md-svg-icon="core:usd" role="img" aria-label="core:usd"><svg xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" focusable="false"><g id="usd"><path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"></path></g></svg></md-icon>10</div>
                <div class="currency ng-binding">per day</div>
            </div>
        </td>
        <td>
            <h4>Plan 4</h4>
            <div class="price layout-align-center-center layout-column" layout="column" layout-align="center center">
                <div class="amount ng-binding"><md-icon md-svg-icon="core:usd" role="img" aria-label="core:usd"><svg xmlns="http://www.w3.org/2000/svg" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" focusable="false"><g id="usd"><path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"></path></g></svg></md-icon>20</div>
                <div class="currency ng-binding">per month</div>
            </div>
        </td>
    </tr>
    <tr class="desc">
        <td class="title"></td>
        <td>This is temporary free plan. This plan will disable soon.</td>
        <td>Unlimited CPU time usage during trial period. Perfect for basic ML projects.</td>
        <td>This is temporary free plan. This plan will disable soon.</td>
        <td>Unlimited CPU time usage during trial period. Perfect for basic ML projects.</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
        <th colspan="5">Prepaid</th>
    </tr>
    <tr>
        <td class="title">GPU (hours)</td>
        <td>-</td>
        <td>2</td>
        <td>*</td>
        <td>*</td>
    </tr>
    <tr>
        <td class="title">GPU geforce gtx 1080 ti (hours)</td>
        <td>-</td>
        <td>1</td>
        <td>-</td>
        <td>*</td>
    </tr>
    <tr>
        <td class="title">GPU Geforce GTX Titan X (hours)</td>
        <td>-</td>
        <td>1</td>
        <td>-</td>
        <td>*</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
        <th colspan="5">Limits</th>
    </tr>
    <tr>
        <td class="title">CPU (core)</td>
        <td>1</td>
        <td>2</td>
        <td>3</td>
        <td>4</td>
    </tr>
    <tr>
        <td class="title">Memory (GB)</td>
        <td>1</td>
        <td>4</td>
        <td>8</td>
        <td>16</td>
    </tr>
    <tr>
        <td class="title">Replicas</td>
        <td>1</td>
        <td>1</td>
        <td>2</td>
        <td>1</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
        <th colspan="5">Cost</th>
    </tr>
    <tr>
        <td  class="title">GPU Geforce GTX Titan X <br/> ( item*hour)</td>
        <td>$ 5</td>
        <td>$ 5</td>
        <td>$ 5</td>
        <td>$ 5</td>
    </tr>
    <tr>
        <td  class="title">Disk (Gb*day)</td>
        <td>$ 1</td>
        <td>$ 0.3</td>
        <td>$ 0.8</td>
        <td>$ 1.6</td>
    </tr>
    <tr>
        <td  class="title">Extra user (user*day)</td>
        <td>$ 1</td>
        <td>$ 1</td>
        <td>$ 2</td>
        <td>$ 1</td>
    </tr>
    <tr>
        <td  class="title"></td>
        <td class="selected"><md-button class="action">Selected</md-button></td>
        <td><md-button class="action">Subscribe</md-button></td>
        <td><md-button class="action">Subscribe</md-button></td>
        <td><md-button class="action">Subscribe</md-button></td>
    </tr>
    </tbody>
</table>