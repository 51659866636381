<div class="">
    <div class="flex layout-row layout-align-start-start">
        <div class="title flex layout-row layout-align-start-center" >
            <img class="media" alt="" ng-src="{{$ctrl.workspace.Picture}}" src="{{$ctrl.workspace.Picture}}">
            <span ng-repeat="item in $ctrl.pageTitleConfig">
                <a ng-if="!$last" ui-sref="{{item.state}}({{item.params}})" class="item" translate>{{item.title}}</a>
                <span ng-if="$last" class="item" translate>{{item.title}}</span><span ng-if="!$last">/</span>
            </span>
            <!--<md-button class="md-icon-button" ng-click="$ctrl.info()" aria-label="{{'COMMON_MORE'|translate}}">-->
                <!--<md-icon md-svg-icon="core:chevron-down" class="icon s16 hide-xs md-header-theme"  aria-hidden="true"></md-icon>-->
            <!--</md-button>-->
        </div>
        <md-menu md-position-mode="target-right target" ng-show="false">
            <button ng-click="$mdMenu.open()"
                    layout="row"
                    layout-align="start center">
                <span>View:</span>&nbsp;<strong>{{$ctrl.projectViewType}}</strong>
                <md-icon md-svg-icon="core:chevron-down" class="icon s16" hide-xs="" aria-hidden="true"></md-icon>
            </button>
            <md-menu-content width="4">
                <md-menu-item ng-repeat="(key, view) in $ctrl.projectPageViewTabs">
                    <a href ng-click="setViewType(key)" class="md-button">
                        {{view.title}}
                    </a>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
    <div class="info padding-media" ng-if="$ctrl.asFull">
        <div>Phone: {{$ctrl.workspace.Phone}} </div>
        <div>URL: {{$ctrl.workspace.Url}} </div>
    </div>
</div>
