<div layout="row" layout-align="space-around" ng-if="$ctrl.isLoading">
    <md-progress-linear md-mode="indeterminate"></md-progress-linear>
</div>

<div layout="row" layout-align="space-between center" class="topline">
    <!--<h3><a ui-sref="wsChartsList">Charts</a></h3>-->
    <div flex></div>
    <md-button -ng-if="!$ctrl.listEmpty" ng-click="$ctrl.changeView(chartListView)" class="md-secondary md-icon-button">
        <md-icon ng-if="!$ctrl.grid" md-svg-icon="core:view-grid"></md-icon>
        <md-icon ng-if="$ctrl.grid" md-svg-icon="core:view-sequential"></md-icon>
    </md-button>
</div>
<div
        class="chartList"
        ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'">
    <div
            class="col_row_item chartList_Item"
            ng-repeat="item in $ctrl.list"
            ng-include=" 'kuberlab/catalog/base/card/card_small.html' "></div>
</div>
<div ng-if="$ctrl.listEmpty"  ng-include=" 'kuberlab/catalog/base/card/list_empty.html' "></div>
<div style="text-align: center; margin-bottom: 2em;" ng-if="!$ctrl.listEmpty">
    <md-button ui-sref="{{$ctrl.stateList}}">{{'COMMON_MORE'|translate}}</md-button>
</div>