<!--<div layout="row" layout-align="space-between center" layout-padding>-->
    <!--<div class="flex" layout="row" layout-align="space-between start" >-->
        <!--<div class="flex" >-->
            <!--<cmp-application-cluster-link class="flex" data="$ctrl.appController.data"></cmp-application-cluster-link>-->
            <!--<span ng-if="$ctrl.appController.data.Author">{{'COMMON_AUTHOR' | translate}}: {{$ctrl.appController.data.Author.DisplayName}}</span>-->
            <!--<p ng-if="$ctrl.appController.data.Description">{{$ctrl.appController.data.Description}}</p>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->
<cmp-viewer-md resource-url="$ctrl.resourceUrl" layout-padding></cmp-viewer-md>
