

<md-tabs ng-if="$ctrl.a" md-dynamic-height md-border-bottom>
    <md-tab  label="{{value.key}}" ng-repeat="(i, value) in $ctrl.data">
        <md-input-container  flex>
            <label >key</label>
            <input type="text" ng-model="$ctrl.data[i].key">
        </md-input-container>
        <md-input-container  flex>
            <label >input</label>
            <input type="text" ng-model="$ctrl.data[i].input">
        </md-input-container>
        <md-input-container  flex>
            <label >output</label>
            <input type="text" ng-model="$ctrl.data[i].output">
        </md-input-container>
        <md-input-container  flex>
            <label >description</label>
            <input type="text" ng-model="$ctrl.data[i].description">
        </md-input-container>

        <div class="cmp-block-compact-content" flex>
            <cmp-object data="$ctrl.data[i].params"></cmp-object>

        </div>
    </md-tab>
</md-tabs>


<!--<div ng-if="$ctrl.a" ng-repeat="(i, value) in $ctrl.data">-->
    <!--<md-input-container  flex>-->
        <!--<label >key</label>-->
        <!--<input type="text" ng-model="$ctrl.data[i].key">-->
    <!--</md-input-container>-->
    <!--<md-input-container  flex>-->
        <!--<label >input</label>-->
        <!--<input type="text" ng-model="$ctrl.data[i].input">-->
    <!--</md-input-container>-->
    <!--<md-input-container  flex>-->
        <!--<label >output</label>-->
        <!--<input type="text" ng-model="$ctrl.data[i].output">-->
    <!--</md-input-container>-->
    <!--<md-input-container  flex>-->
        <!--<label >description</label>-->
        <!--<input type="text" ng-model="$ctrl.data[i].description">-->
    <!--</md-input-container>-->

    <!--<div class="cmp-block-compact-content" flex>-->
        <!--<cmp-object data="$ctrl.data[i].params"></cmp-object>-->

    <!--</div>-->
<!--</div>-->

<div ng-if="!$ctrl.a">
    <md-input-container  flex>
        <label >key</label>
        <input type="text" ng-model="$ctrl.data.key">
    </md-input-container>
    <md-input-container  flex>
        <label >input</label>
        <input type="text" ng-model="$ctrl.data.input">
    </md-input-container>
    <md-input-container  flex>
        <label >output</label>
        <input type="text" ng-model="$ctrl.data.output">
    </md-input-container>
    <md-input-container  flex>
        <label >description</label>
        <input type="text" ng-model="$ctrl.data.description">
    </md-input-container>

    <div class="cmp-block-compact-content" flex>
        <cmp-object data="$ctrl.data.params"></cmp-object>
    </div>
</div>
