<!--<div class="packageEnterList">-->
    <!--<md-chips-->
            <!--ng-model="$ctrl.List"-->
            <!--readonly="false"-->
            <!--md-removable="true"-->
            <!--md-enable-chip-edit="true"-->
            <!--md-separator-keys="' '"-->
            <!--md-add-on-blur="true"-->
            <!--placeholder="Enter a packges...">-->
    <!--</md-chips>-->
    <!--<div class="layout-row layout-align-start-senter">-->
        <!--<div class="flex"></div>-->
        <!--<md-button ng-click="$ctrl.save()">Install</md-button>-->
    <!--</div>-->
<!--</div>-->
<div class="logList" ></div>
<form ng-submit="$ctrl.save()" class="layout-row layout-align-start-center" ng-disabled="$ctrl.saving">
    <md-input-container class="flex" >
        <label translate>{{"MLAPPS_PIP_TITLE_INSTALL"}}</label>
        <input type="text" ng-model="$ctrl.pack"/>
    </md-input-container>
    <md-button type="submite">{{"COMMON_INSTALL" | translate}}</md-button>
</form>

<cmp-api-error-service error="$ctrl.Error" ng-if="$ctrl.Error"> </cmp-api-error-service>


