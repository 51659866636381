
<div ng-switch="$ctrl.data.Config.spec.template">

    <cmp-serving-template-image ng-switch-when="image" params="$ctrl.params" parent="$ctrl"></cmp-serving-template-image>
    <cmp-serving-template-video ng-switch-when="video" params="$ctrl.params" parent="$ctrl"></cmp-serving-template-video>
    <cmp-serving-template-webrtc ng-switch-when="webrtc" params="$ctrl.params" parent="$ctrl"></cmp-serving-template-webrtc>
    <div ng-switch-default="">
            <cmp-form-constructor options="$ctrl.params" ng-if="$ctrl.data.Type == 'project'" multipart="true" ng-model="$ctrl.config" api="api" class="md-padding"></cmp-form-constructor>
            <cmp-form-constructor options="$ctrl.params" ng-if="$ctrl.data.Type != 'project'" ng-model="$ctrl.config" api="api" class="md-padding"></cmp-form-constructor>
            <div class="md-padding" >
                <div layout="row" layout-align="start center">
                    <div flex>
                        <cmp-error-toast ng-if="$ctrl.savingError" error="$ctrl.savingError"></cmp-error-toast>
                        <md-progress-linear  ng-if="$ctrl.sending"></md-progress-linear>
                    </div>
                    <md-button class="md-raised md-primary" style="width: 200px" ng-click="$ctrl.send()" ng-disabled="$ctrl.sending" >Apply</md-button>
                </div>
                <pre class="log-view" ng-if="$ctrl.response">
                {{ $ctrl.response | json }}
            </pre>
            </div>
            <cmp-serving-jobs ng-if="$ctrl.data.Type == 'project'" data="$ctrl.data"></cmp-serving-jobs>
    </div>

</div>

<seaction class="cmp-block-compact">
    <h5 class="cmp-block-compact-title">Request parameters</h5>
    <div class="cmp-block-compact-content">

        <md-tabs md-border-bottom>
            <md-tab ng-repeat="item in $ctrl.requestTemplates">
                <md-tab-label>{{item == 'md' ? 'description' : item }}</md-tab-label>
                <md-tab-body>
                    <pre ng-if="item != 'md'">{{$ctrl.requestList[item]}}</pre>
                    <div ng-if="item == 'md'" btf-markdown="$ctrl.requestList[item]"></div>
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </div>
</seaction>

<section class="cmp-block-compact">
    <h5 class="cmp-block-compact-title">Response format</h5>
    <div class="cmp-block-compact-content">
        <pre ng-if="!$ctrl.data.Config.spec.responseTemplate">{{$ctrl.responseExample | json }}</pre>
        <pre ng-if="$ctrl.data.Config.spec.responseTemplate">{{$ctrl.data.Config.spec.responseTemplate}}</pre>
    </div>

</section>
