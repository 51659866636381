<md-input-container flex="50">
    <label>name</label>
    <md-select ng-model="$ctrl.ngModel[item.name].name" ng-change="$ctrl.change()">
        <md-option ng-value="v.name" ng-repeat="v in $ctrl.volumes">{{ v.name }}</md-option>
    </md-select>
</md-input-container>
<md-input-container flex="50"  ng-if="!$ctrl.selectVersion" ng-disable="$ctrl.type != 'gitRepo' ">
    <label>version</label>
    <input ng-model="$ctrl.ngModel[item.name].version" ng-if="!$ctrl.selectVersion" ng-disable="$ctrl.type != 'gitRepo' ">
    <md-select ng-if="$ctrl.selectVersion" ng-model="$ctrl.ngModel[item.name].version">
        <md-option ng-value="ver.version" ng-repest="ver in $ctrl.selectVersion">{{ver.version}}</md-option>
    </md-select>
</md-input-container>
