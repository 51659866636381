<md-input-container flex="50" >
    <label>{{"COMMON_NAME"|translate}}</label>
    <input
            type="text"
            name="Name"
            ng-model="$ctrl.data.Name"
            ng-maxlength="$ctrl.validate.maxName"
            ng-minlength="$ctrl.validate.minName"
            ng-pattern="$ctrl.validate.namePattern"
            required>
    <help-hint hint="wsa.add.displayname"></help-hint>
    <div ng-messages="addWSA.Name.$error">
        <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
        <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
        <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
        <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
    </div>
</md-input-container>
<md-input-container flex="50">
    <label>{{"COMMON_DESCRIPTION"|translate}}</label>
    <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
    <help-hint hint="wsa.add.description"></help-hint>
</md-input-container>
<md-input-container flex="50">
    <label>{{"WSA_AWS_ACCSESS_KEY_ID"|translate}}</label>
    <input type="text" ng-model="$ctrl.data.SetCredentials.access_key_id" name="access_key_id">
    <help-hint hint="wsa.add.amazonkey"></help-hint>
</md-input-container >
<md-input-container flex="50">
    <label>{{"WSA_AWS_SECRET_ACCSESS_KEY"|translate}}</label>
    <input type="password" ng-model="$ctrl.data.SetCredentials.secret_access_key" name="secret_access_key"  autocomplete="new-password">
    <help-hint hint="wsa.add.amazonkey"></help-hint>
</md-input-container>