<cmp-layout-title-login ng-if="!$ctrl.whoami"></cmp-layout-title-login>
<md-menu-bar ng-if="$ctrl.whoami">
    <md-menu>
        <button ng-click="$mdMenu.open()">
            {{$ctrl.whoami.Name}}
        </button>
        <md-menu-content>
            <md-menu-item ng-repeat="item in $ctrl.Owners">
                <md-button ng-click="$ctrl.AuthService.authInService(item.service)">
                    {{item.title}}
                </md-button>
            </md-menu-item>
            <md-menu-divider role="separator"></md-menu-divider>
            <md-menu-item >
                <md-button ng-click="$ctrl.Logout()">
                    <md-icon md-font-icon="icon-logout" class="icon md-default-theme md-font material-icons icon-logout" aria-hidden="true"></md-icon>
                    Logout
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-menu-bar>

