<md-dialog aria-label="Mango (Fruit)" id="servingGalleryDisalog">
    <form ng-cloak name="serve" ng-submit="save()">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2 translate>{{$ctrl.data.Gallery ? 'Edit gallery' : 'Add to gallery'}}</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>

            <div class="md-padding" layout="column" layout-align="start">
                <div class="img" >
                    <img ng-if="$ctrl.data.Picture" src="{{$ctrl.data.Picture}}">
                    <input type="file" ng-model="pic" app-filereader>
                </div>
                <md-input-container flex>
                    <label>Display Name</label>
                    <input ng-model="$ctrl.data.DisplayName">
                </md-input-container>
                <md-input-container flex>
                    <label>Description</label>
                    <textarea ng-model="$ctrl.data.Description"></textarea>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions flex layout="column" >
            <div>
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            </div>
            <div>
                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button type="submit">
                    {{"COMMON_OK" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
