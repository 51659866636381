<div flex layout="row" layout-align="start">
    <div flex="20" class="cmp-menu-v" layout="column" layout-align="start">
        <h5>Tasks resources list</h5>
        <md-content flex>
            <ul class="sortable sortable-1" dnd-container  dnd-sortable-list = "$ctrl.appController.data.Configuration.spec.tasks">
                <li ng-repeat="item in $ctrl.appController.data.Configuration.spec.tasks" id="{{t = 'task' + $index}}" dnd-sortable dnd-on-sortend = "$ctrl.onSortEnd()">
                    <a ui-sref="wsApplication.tab({tabItem: item.name})" ng-class="{active: ($index == $ctrl.taskID) }" layout-align="start center" layout="row" flex>
                        <md-icon md-svg-src="core:{{$ctrl.taskTypesIcon[item.type]}}"></md-icon>
                        <span class="label" flex>{{item.name}}</span>
                    </a>
                    <md-divider ng-if="!$last"></md-divider>
                </li>
                <li flex>
                    <md-menu md-position-mode="target-right target" flex layout-align="center" style="    width: 100%;  display: flex;">
                        <md-button ng-click="$ctrl.listAddTask(); $mdMenu.open();" class="md-raised md-primary" style="width: 100%"><md-icon md-svg-src="core:plus"></md-icon> add task</md-button>
                        <md-menu-content width="4">
                            <md-menu-item ng-repeat="item in $ctrl.newTaskTemplateList">
                                <md-button ng-click="$ctrl.addTaskTemplate(item.data)">{{item.name}}</md-button>
                            </md-menu-item>
                            <md-menu-item ng-repeat="item in $ctrl.extTaskTemplateList">
                                <md-button ng-click="$ctrl.addTaskTemplate(null, item)">template {{item.Name}}</md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </li>
            </ul>
        </md-content>
    </div>

    <!--<md-content flex>-->
    <div flex layout="column">

        <form name="applicationTask" flex layout-align="start" layout="column" style="padding: 0 1em; position: relative" ng-if="$ctrl.appController.data.Configuration.spec.tasks.length">
            <div ng-if="$ctrl.task.isNew || $ctrl.task.isEdit" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, .4); z-index: 1 ">
                <md-card ng-if="$ctrl.task.isNew || $ctrl.task.isEdit">
                    <div name="taskName"  class="md-padding">
                        <div flex layout="row" layout-align="start center">
                            <md-input-container flex>
                                <label>Task name</label>
                                <input name="newTask"
                                       ng-model="$ctrl.newTaskName"
                                       ng-change="$ctrl.checkTaskName(applicationTask.newTask)"
                                       ng-pattern="$ctrl.validatePattern.nameTaskPattern"
                                       required >
                                <div ng-messages="applicationTask.newTask.$error">
                                    <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                                    <div ng-message="errorname">already use</div>
                                </div>
                            </md-input-container>
                            <md-input-container flex>
                                <label>task type</label>
                                <md-select ng-model="$ctrl.appController.data.Configuration.spec.tasks[$ctrl.taskID].type" >
                                    <md-option ng-repeat="item in $ctrl.taskTypes" ng-value="item">{{item}}</md-option>
                                </md-select>
                            </md-input-container>
                            <md-button ng-click="$ctrl.cancelNewName()">cancel</md-button>
                            <md-button ng-click="$ctrl.setNewName(applicationTask, $ctrl.newTaskName)" ng-disabled="!applicationTask.newTask.$valid" class="md-raised md-primary">ок</md-button>
                        </div>
                        <cmp-error-toast ng-if="$ctrl.taskNameError" error="$ctrl.taskNameError"></cmp-error-toast>
                    </div>
                </md-card>
            </div>
            <div name="applicationTask" class="layout-row layout-align-start-center">
                <h4>{{"APPLICATION_TASK" | translate }} {{$ctrl.task.name}} <span ng-if="$ctrl.task.parentTask"> PARENT {{$ctrl.task.parentTask}}</span> <span ng-if="$ctrl.task.type">(type: {{$ctrl.task.type}})</span> </h4>
                <div class="flex layout-row layout-align-statr-center" style="padding-left: 1em;">
                <md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>
                </div>
                <md-button ng-click="$ctrl.saveAction(applicationTask, 'save')" ng-disabled="$ctrl.task.isNew || $ctrl.task.isEdit">{{"COMMON_SAVE" | translate}}</md-button>
                <md-button ng-disabled="!$ctrl.appController.data.Enabled" ng-click="$ctrl.saveAction(applicationTask, 'build')" class="md-raised md-primary" ng-disabled="$ctrl.task.isNew || $ctrl.task.isEdit">{{"MLAPP_START_BUILD" | translate}}</md-button>
                <md-menu class="md-secondary" md-position-mode="target-right target" >
                    <md-button class="md-icon-button" ng-click="$mdMenu.open()" ng-disabled="$ctrl.task.isNew">
                        <md-icon md-svg-icon="core:short-menu"></md-icon>
                    </md-button>
                    <md-menu-content width="3">
                        <md-menu-item>
                            <md-button ng-click="$ctrl.editTask();">
                                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                            </md-button>
                        </md-menu-item>
                        <md-menu-item>
                            <md-button ng-click="$ctrl.deleteTask($ctrl.task)">
                                <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                            </md-button>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
            </div>
            <md-content flex>

                <cmp-applications-task-volumes-revision
                        default-volume-mapping="$ctrl.taskDefMapping"
                        task="$ctrl.appController.data.Configuration.spec.tasks[$ctrl.taskID]"
                        application="$ctrl.appController.data"
                        volumes="$ctrl.taskVolumes"
                ></cmp-applications-task-volumes-revision>
                <md-input-container class="flex" ng-if="!$ctrl.isUix" style="width: 100%">
                    <label translate>MLAPP_TIMEOUT_MINUTES</label>
                    <input type="number" ng-model="$ctrl.appController.data.Configuration.spec.tasks[$ctrl.taskID].timeoutMinutes" />
                    <help-hint hint="application.timeout_minutes"></help-hint>
                </md-input-container>

                <div class="cmp-block-compact" ng-if="!$ctrl.task.isNew">
                    <div flex layout="row" layout-align="start center">
                        <h5 flex class="cmp-block-compact-title">Task resources</h5>
                    </div>

                    <div class="cmp-block-compact-content">
                        <md-tabs md-selected="$ctrl.resourceID" md-dynamic-height md-border-bottom >
                            <md-tab
                                    ng-repeat="tab in $ctrl.appController.data.Configuration.spec.tasks[$ctrl.taskID].resources"
                                    ui-sref="wsApplication.tab({resourceName: tab.name})"
                            >
                                <md-tab-label>
                                    {{tab.name}}
                                </md-tab-label>
                                <md-tab-body >
                                    <cmp-applications-task
                                            application="$ctrl.appController.data"
                                            data="$ctrl.appController.data.Configuration.spec.tasks[$ctrl.taskID].resources[$index]"
                                            form="applicationTask"
                                            list-controller="$ctrl"
                                            style="position: relative"
                                    ></cmp-applications-task>
                                </md-tab-body>
                            </md-tab>
                            <md-tab ng-if="!( $ctrl.task.type == 'export' && $ctrl.task.resources.length >= 1 )">
                                <md-tab-label>+</md-tab-label>
                                <md-tab-body>

                                    <cmp-applications-task
                                            ng-if="$ctrl.task.resources.length == $ctrl.resourceID || !$ctrl.task.resources "
                                            application="$ctrl.appController.data"
                                            data="$ctrl.newResource"
                                            form="applicationTask"
                                            is-new="true"
                                            list-controller="$ctrl"
                                            style="position: relative"
                                    ></cmp-applications-task>

                                </md-tab-body>
                            </md-tab>
                        </md-tabs>
                    </div>
                </div>
            </md-content>



        </form>

        <div ng-if="!$ctrl.appController.data.Configuration.spec.tasks.length" flex style="text-align:center;">
            <form name="applicationTask" layout="row" layout-align="start center">
                <div flex></div>
                <div class="flex layout-row layout-align-statr-center" style="padding-left: 1em;">
                    <md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>
                </div>
                <md-button ng-click="$ctrl.saveAction(applicationTask, 'save')">{{"COMMON_SAVE" | translate}}</md-button>
                <md-button ng-click="$ctrl.saveAction(applicationTask, 'build')" class="md-raised md-primary" ng-disabled="true" >{{"MLAPP_START_BUILD" | translate}}</md-button>
                <md-menu class="md-secondary" md-position-mode="target-right target" >
                    <md-button class="md-icon-button" ng-click="$mdMenu.open()" ng-disabled="true" >
                        <md-icon md-svg-icon="core:short-menu"></md-icon>
                    </md-button>
                    <md-menu-content width="3">
                    </md-menu-content>
                </md-menu>
            </form>
        </div>
    <!--</md-content>-->
    </div>
</div>


