<layout-page-content-list class="menuLevel layoutMenu" >
    <h3><a ui-sref="workspace"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{$ctrl.WS.DisplayName}}</span></a></h3>
    <!--<cmp-applications-short flex></cmp-applications-short>-->
    <cmp-projects-list-short flex></cmp-projects-list-short>
</layout-page-content-list>


<layout-page-content-item flex layout="column" layout-align="start">

    <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
        <layout-button-submenu></layout-button-submenu>
        <layout-back></layout-back>

        <h3>{{$ctrl.data.DisplayName}}</h3>
        <div flex></div>
        <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
            <md-menu>
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" >
                    <md-icon md-svg-icon="core:info"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item ng-if="$ctrl.data.Description">
                        <span>{{$ctrl.data.Description}}</span>
                    </md-menu-item>

                    <md-menu-item >
                        <span> Repository: <a href="{{$ctrl.data.RepositoryURL}}" target="_blank">{{$ctrl.data.RepositoryURL}}</a></span>
                    </md-menu-item>

                    <md-menu-item>
                        <span>Directory: <span>{{$ctrl.data.RepositoryDir}}</span></span>
                    </md-menu-item>

                </md-menu-content>
            </md-menu>
            <md-menu class="md-secondary" md-position-mode="target-right target">
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item ng-if="!$ctrl.envList.Error">
                        <md-button aria-label="icon" ng-click="$ctrl.actionForm()">
                            <md-icon md-svg-icon="core:edit"></md-icon> edit project
                        </md-button>
                    </md-menu-item>
                    <md-menu-item>
                        <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'PROJECT_DELETE_DIALOG_MESSAGE', 'PROJECT_DELETE_DIALOG_TITLE')">
                            <md-icon md-svg-icon="core:delete"></md-icon> delete project "{{$ctrl.data.DisplayName}}"
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-menu-bar>

    </layout-page-content-title>



    <div flex ng-if="$ctrl.asForm" ng-include=" 'kuberlab/projects/card/project_card_new.html' "></div>

    <div ng-if="!$ctrl.asForm" flex class="" flex layout="column" layout-align="start">
        <div  ng-if="!$ctrl.envList.Error" class="projectContent">
            <md-tabs class="itemContent" md-dynamic-height md-border-bottom>
                <md-tab ng-repeat="tab in $ctrl.projectViewTabs track by $index"
                        md-active="$ctrl.$state.params.tab == tab.title"
                        ng-click="$ctrl.goTab(tab.title)">
                    <md-tab-label>
                        {{tab.title}}
                    </md-tab-label>
                </md-tab>
            </md-tabs>
        </div>
        <md-content flex ui-view="tabContent" class="tabContent md-padding"></md-content>
    </div>
</layout-page-content-item>
