<md-progress-linear ng-if="$ctrl.service.isLoading" md-mode="indeterminate" flex></md-progress-linear>
<md-list>
    <div ng-include=" 'kuberlab/projects/card/project_head_title_list.html' "></div>
    <cmp-project-cluster
            ng-repeat="item in $ctrl.list"
            data="item"
            list-controller="$ctrl"
            id="tabItem_{{item.Name}}"
    ></cmp-project-cluster>
    <div layout="row" >
      <div flex></div>
      <md-button class="md-raised md-primary" ng-click="$ctrl.addCluster()" ng-if="$ctrl.permissions.can('project.manage')" layout-align="end center">
        <md-icon md-svg-icon="core:cluster"></md-icon>
        Add cluster
      </md-button>
    </div>
</md-list>
<cmp-project-cluster-new
        flex
        id="tabItem_new"
        ng-if="$ctrl.$state.params.tabItem == 'new'"
        service="$ctrl.service"
        class="paddingListItemTabContent"
></cmp-project-cluster-new>
<div layout="row" class="md-padding" ng-if="!$ctrl.service.isLoading && (!$ctrl.list || !$ctrl.list.length)">
    <span translate>PROJECT_NO_CLUSTERS</span>
</div>
<!--<div flex id="tabItem_new">-->
    <!--<form ng-if="$ctrl.newCluster" name="newCluster" ng-submit="$ctrl.saveNewCluster(newCluster)">-->
        <!--<md-card>-->
            <!--<md-card-content>-->
                    <!--<div flex ng-if="$ctrl.wsaError">-->
                        <!--{{$ctrl.wsaError}}-->
                    <!--</div>-->
                    <!--<div flex>-->
                        <!--<md-input-container class="md-block">-->
                            <!--<label>Name</label>-->
                            <!--<input type="text" ng-model="$ctrl.newCluster.Name" name="Name" required>-->
                        <!--</md-input-container>-->
                        <!--<md-input-container class="md-block">-->
                            <!--<label>Service account</label>-->
                            <!--<md-select ng-model="$ctrl.newCluster.ServiceAccount" required>-->
                                <!--<md-option ng-repeat="wsa in $ctrl.wsaList" value="{{wsa.ID}}">-->
                                    <!--{{wsa.DisplayName}}-->
                                <!--</md-option>-->
                            <!--</md-select>-->
                        <!--</md-input-container>-->
                        <!--<md-input-container class="md-block">-->
                            <!--<label>src</label>-->
                            <!--<input type="text" ng-model="$ctrl.newClustar.Src">-->
                        <!--</md-input-container>-->
                    <!--</div>-->
                <!--</md-card-content>-->
                <!--<md-card-action flex layout="row" layout-align="end center">-->
                    <!--<md-button type="submit">save</md-button>-->
                    <!--<md-button ng-click="$ctrl.cancelNewCluster()">cancel</md-button>-->
                <!--</div>-->
            <!--</md-card-action>-->
        <!--</md-card>-->
    <!--</form>-->
<!--</div>-->
<!--<md-button ng-click="$ctrl.addCluster()">add cluster</md-button>-->
