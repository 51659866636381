<cmp-form-constructor
        flex="100"
        ng-model="$ctrl.values"
        api="$ctrl.api"
        options="$ctrl.options"
        cluster-id="$ctrl.data.ClusterID"
        workspace="$ctrl.data.WorkspaceName"
        app-volumes="$ctrl.appController.data.Configuration.spec.volumes"
></cmp-form-constructor >
<div class="cmp-block-compact" flex>
    <h6 class="cmp-block-compact-title" translate>options</h6>
    <div class="cmp-block-compact-content" layout="row" layout-align="start center" layout-wrap>
        <md-input-container flex="50">
            <md-checkbox ng-model="$ctrl.spec.options.saveStreamPreviews">Save stream previews</md-checkbox>
        </md-input-container>
        <md-input-container flex="50">
            <label>Edge host</label>
            <input ng-model="$ctrl.spec.options.edgeHost" ng-pattern="'^[a-zA-Z0-9-_.]*$'" ng-disabled="$ctrl.data.ClusterID != 'edge'">
        </md-input-container>
        <md-input-container flex="50">
            <label>Audience host</label>
            <input ng-model="$ctrl.spec.options.audienceHost" type="url">
        </md-input-container>
    </div>
</div>
