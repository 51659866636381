<form name="storageForm" ng-submit="$ctrl.saveStorage(storageForm)">
    <md-card>
        <md-card-content>
            <div class="layout-row layout-align-start-center">
                <md-input-container class="flex">
                    <label>Name</label>
                    <input type="text" ng-model="$ctrl.data.DisplayName">
                </md-input-container>

                <md-input-container class="flex">
                    <label translate>COMMON_IDENT</label>
                    <input
                            type="text"
                            name="Name"
                            ng-model="$ctrl.data.Name"
                            ng-disabled="!$ctrl.isNewElement || !$ctrl.can('edit')"
                            ng-maxlength="$ctrl.validate.maxName"
                            ng-minlength="$ctrl.validate.minName"
                            ng-pattern="$ctrl.validate.namePattern"
                    >
                    <div ng-messages="storageForm.Name.$error">
                        <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                        <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                        <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
                        <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
                    </div>
                </md-input-container>

                <!--<md-input-container  class="flex-33">-->
                    <!--<md-checkbox ng-model="$ctrl.data.Default" >Default</md-checkbox>-->
                <!--</md-input-container>-->
            </div>
            <div class="layout-row layout-align-start-center">
                <md-input-container class="flex">
                    <label>{{"COMMON_DESCRIPTION" | translate}}</label>
                    <textarea ng-model="$ctrl.data.Description" class="flex"></textarea>
                </md-input-container>
            </div>

            <cmp-storage
                    data="$ctrl.data"
                    types="$ctrl.types"
                    only-data="true"
                    cluster-storages="$ctrl.clusterStorageList"
            ></cmp-storage>
            <div>

                <md-input-container  class="flex-33">
                    <md-checkbox ng-model="$ctrl.data.readOnly" ng-disabled="!$ctrl.can('edit')">readOnly</md-checkbox>
                </md-input-container>
            </div>
            <!--{{$ctrl.data}}-->


            <!--<div class="layout-row layout-align-start-center">-->
                <!--<md-input-container class="flex">-->
                    <!--<label>Type</label>-->
                    <!--<input type="text" ng-model="$ctrl.type" ng-disabled="true">-->
                <!--</md-input-container>-->
            <!--</div>-->
            <!--<div ng-switch="$ctrl.type">-->
                <!--<div ng-switch-when="nfs" class="layout-row layout-align-start-center">-->
                    <!--<md-input-container  class="flex-33">-->
                        <!--<label>Server</label>-->
                        <!--<input type="text" ng-model="$ctrl.typeData.server" ng-disabled="!$ctrl.can('edit')">-->
                    <!--</md-input-container>-->
                    <!--<md-input-container  class="flex-33">-->
                        <!--<label>Path</label>-->
                        <!--<input type="text" ng-model="$ctrl.typeData.path" ng-disabled="!$ctrl.can('edit')">-->
                    <!--</md-input-container>-->
                    <!--<md-input-container  class="flex-33">-->
                        <!--<md-checkbox ng-model="$ctrl.typeDate.readOnly" ng-disabled="!$ctrl.can('edit')">readOnly</md-checkbox>-->
                    <!--</md-input-container>-->
                <!--</div>-->
            <!--</div>-->
        </md-card-content>
        <md-card-action class="layout-row layout-align-start-center">
            <div class="flex">
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                <cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-api-error-service>
            </div>
            <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL"| translate}}</md-button>
            <md-button class="md-raised md-primary" type="submit">{{'COMMON_SAVE' | translate}}</md-button>
        </md-card-action>
    </md-card>
</form>
