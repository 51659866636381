<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak name="serve" ng-submit="$ctrl.serve(serve)">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2 translate>COMMON_SERVING</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="layout-row layout-align-start-top layout-wrap md-padding"   >
                <md-input-container flex="33">
                    <label translate>COMMON_WORKSPACE</label>
                    <md-select ng-model="$ctrl.serveData.WorkspaceName" ng-change="$ctrl.setWs($ctrl.serveData.WorkspaceName)" required>
                        <md-option ng-repeat="item in $ctrl.wsList" ng-value="item.Name">{{item.DisplayName}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="33">
                    <label translate>COMMON_CLUSTER</label>
                    <md-select ng-model="$ctrl.serveData.ClusterID" mg-disabled="!$ctrl.clusters" required >
                        <md-option ng-repeat="item in $ctrl.clusters" ng-value="item.ClusterID"><cmp-cluster-name cluster="item"></cmp-cluster-name></md-option>
                        <md-option ng-if="$ctrl.data.Interface == 'inference'" ng-value="'edge'" >edge</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="33">
                    <label translate>COMMON_NAME</label>
                    <input type="text" ng-model="$ctrl.serveData.Name" required
                        ng-pattern="$ctrl.validate.idPattern"
                        name="serveName"
                        ng-model-options='{ debounce: 500 }'
                    >
                    <div ng-messages="serve.serveName.$error" multiple md-auto-hide="false">
                        <div ng-message="pattern">{{'FORM_ERROR_ID_PATTERN' | translate}}</div>
                    </div>
                </md-input-container>
<!--&lt;!&ndash;{{$ctrl.serveData.WorkspaceName}}&ndash;&gt;-->
<!--{{$ctrl.data.Interface}}-->
                <!--<div ng-if="$ctrl.data.Interface == 'inference'">-->
                    <!--<cmp-form-constructor-->
                            <!--flex="100"-->
                            <!--ng-if="$ctrl.data.Interface == 'inference'"-->
                            <!--ng-model="$ctrl.serveData.Values"-->
                            <!--api="$ctrl.apiForm"-->
                            <!--options="$ctrl.version^[a-z0-9][a-z0-9-_]{1,33}[a-z0-9]+$.Form"-->
                            <!--cluster-id="$ctrl.serveData.ClusterID"-->
                            <!--workspace="$ctrl.serveData.WorkspaceName"-->
                            <!--app-volumes="$ctrl.appController.data.Configuration.spec.volumes"-->
                    <!--&gt;</cmp-form-constructor >-->
                    <!--<div class="cmp-block-compact">-->
                        <!--<h6 class="cmp-block-compact-title" translate>options</h6>-->
                        <!--<div class="cmp-block-compact-content" layout="row" layout-align="start center" layout-wrap>-->
                            <!--<md-input-container flex="50">-->
                                <!--<md-checkbox ng-model="$ctrl.serveData.Config.spec.options.saveStreamPreviews">saveStreamPreviews</md-checkbox>-->
                            <!--</md-input-container>-->
                            <!--<md-input-container flex="50">-->
                                <!--<label>signature</label>-->
                                <!--<input ng-model="$ctrl.serveData.Config.spec.options.edgeHost" ng-pattern="^[a-z0-9][a-z0-9-_]{1,33}[a-z0-9]+$" ng-disabled="$ctrl.serveData.ClusterID != 'edge'">-->
                            <!--</md-input-container>-->
                        <!--</div>-->
                    <!--</div>-->

                <!--</div>-->
                <cmp-serving-form-inference-config
                        ng-if="$ctrl.data.Interface == 'inference'"
                        values="$ctrl.serveData.Values"
                        app-controller="$ctrl.appController"
                        data="$ctrl.serveData"
                        api="$ctrl.api"
                        flex="100"
                        options="$ctrl.version.Form"
                        spec="$ctrl.serveData.Config.spec"
                        full="$ctrl.serveData"

                ></cmp-serving-form-inference-config>

                <cmp-serving-form-config
                        ng-if="$ctrl.data.Interface == 'mlmodel'"
                        data="$ctrl.serveData.Config"
                        serving="$ctrl.serveData"
                        flex="100"
                        workspace-name="$ctrl.serveData.WorkspaceName"
                        cluster-id="$ctrl.serveData.ClusterID"
                        is-model="$ctrl.isModel"
                        ws-id="$ctrl.serveData.WorkspaceName"></cmp-serving-form-config>


            </div>
        </md-dialog-content>

        <md-dialog-actions flex layout="column" >
            <div>
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            </div>
            <div>
                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button type="submit">
                    {{"COMMON_SERVE" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
