

<!--<div ng-if="$ctrl.status">-->
<div >
    <div id="media" ng-if="$ctrl.audio.active || $ctrl.video.active" flex layout-align="center center" layout="column">
        <div>
            <h4>Media</h4>
            <!--<audio id="audio" autoplay="true"></audio>-->
            <video id="video" autoplay="true" playsinline="true" style="width: 640px; height: 480px"></video>
        </div>
        <please-wait ng-if="$ctrl.status && $ctrl.status != 'track'">Stream {{$ctrl.status}}.</please-wait>
    </div>
    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
    <div layout="row" layout-align="start center">
        <!--<div>{{$ctrl.status}}</div>-->
        <md-button ng-click="$ctrl.start()" ng-if="!$ctrl.status" class="md-primary">start live video</md-button>
        <md-button ng-click="$ctrl.stop()" ng-if="$ctrl.status" class="md-primary md-raised">stop</md-button>
        <div flex></div>
        <md-button class="md-raised" ng-click="$ctrl.showLog = !$ctrl.showLog">{{$ctrl.showLog ? 'hide logs': 'show logs'}}</md-button>
        <md-button class="md-raised" ng-click="$ctrl.openFullscreen()">full screen</md-button>
        <!--<div class="option">-->
            <!--&lt;!&ndash;<input id="use-video" ng-model="$ctrl.video.active" type="checkbox"/>&ndash;&gt;-->
            <!--<label for="use-video">Use video</label>-->
            <!--<select id="video-resolution" ng-model="$ctrl.video.resolution">-->
                <!--<option value="" selected>Default resolution</option>-->
                <!--<option value="320x240">320x240</option>-->
                <!--<option value="640x480">640x480</option>-->
                <!--<option value="960x540">960x540</option>-->
                <!--<option value="1280x720">1280x720</option>-->
            <!--</select>-->
            <!--<select id="video-codec" ng-model="$ctrl.video.codec">-->
                <!--<option value="default" selected>Default codecs</option>-->
                <!--<option value="VP8/90000">VP8</option>-->
                <!--<option value="H264/90000">H264</option>-->
            <!--</select>-->
        <!--</div>-->
    </div>


    <div ng-if="$ctrl.showLog" class="podLog">

        <div>
            <h5>answer sdp:</h5>
            <pre class="log-view log">{{$ctrl.answer_sdp}}</pre>
        </div>
        <div><h5>offer sdp:</h5>
            <pre class="log-view log">{{$ctrl.offer_sdp}}</pre>
        </div>
        <div><h5>signaling log:</h5>
            <pre class="log-view log">{{$ctrl.signalingLog}}</pre>
        </div>
    </div>

</div>
