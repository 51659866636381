<md-card class="chartCardFull">
    <md-card-title>
        <md-card-title-text class="padding-content">
            <div class="md-subhead" flex layout="row" layout-align="space-between center">
                <div flex>
                    <!--<md-menu-bar id="chartVer">-->
                        <md-menu md-position-mode="target-left target">
                            <button ng-click="$mdMenu.open()"
                                    layout="row"
                                    layout-align="start center">
                                <span>version:</span>&nbsp;<strong>{{$ctrl.version}}</strong>
                                <md-icon md-svg-icon="core:chevron-down" class="icon s16" hide-xs="" aria-hidden="true"></md-icon>
                            </button>
                            <md-menu-content  width="3">
                                <md-menu-item ng-repeat="data in $ctrl.chartVersions">
                                    <a ui-sref="wsChartItem({chartVersion: data.Version})" class="md-button">
                                        {{data.Version}}
                                    </a>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    <!--</md-menu-bar>-->
                </div>


                <div layout="row" layout-align="end center">

                    <md-card-actions layout="row" layout-align="end center">
                        <cmp-chart-rating
                                data="$ctrl.data"
                                resource="$ctrl.resource"
                                resource-params="{wsId: $ctrl.data.WorkspaceName, chartName: $ctrl.data.Name}">
                        </cmp-chart-rating>
                        <md-button>
                            <md-icon md-svg-icon="core:comments"></md-icon>
                            {{$ctrl.data.Comments}}
                            <md-tooltip>comments</md-tooltip>
                        </md-button>
                        <!--<md-button flex="33">-->
                            <!--<md-icon md-svg-icon="core:chart-download"></md-icon>-->
                            <!--2003-->
                            <!--<md-tooltip>download</md-tooltip>-->
                        <!--</md-button>-->
                    </md-card-actions>


                    <md-menu class="md-secondary" md-position-mode="target-right target">
                        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                            <md-icon md-svg-icon="core:short-menu"></md-icon>
                        </md-button>
                        <md-menu-content width="3">
                            <!--<md-menu-item >-->
                                <!--<md-button aria-label="icon" ng-click="$ctrl.verifiedRequest()">-->
                                    <!--<md-icon md-svg-icon="core:edit"></md-icon> verified request-->
                                <!--</md-button>-->
                            <!--</md-menu-item>-->
                            <md-menu-item>
                                <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'CHART_DELETE_DIALOG_MESSAGE', 'CHART_DELETE_DIALOG_TITLE')">
                                    <md-icon md-svg-icon="core:delete"></md-icon> delete chart
                                </md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>


            </div>

        </md-card-title-text>
    </md-card-title>
    <md-card-content class="padding-content layout-row layout-align-space-between-center" >
        <div class="md-media-xl card-media" layout style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'></div>
        <!--<img src="{{$ctrl.data.Picture}}" class="md-media-xl card-media" alt="$ctrl.data.Name">-->
        <md-card-actions layout="column" layout-align="start start" flex class="chartInfo">
            <div class="md-headline">{{$ctrl.data.DisplayName}}
            </div>
            <div ng-if="$ctrl.data.Published" class="published">Published</div>
            <div ng-if="!$ctrl.data.Published" class="not published">Not published</div>
            <div class="broken" ng-if="$ctrl.data.Broken">Broken</div>

            <p ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</p>

            <div>
                <strong>Version:</strong> {{$ctrl.data.Version}}<br/>
                <strong>Repository:</strong> <a href="{{$ctrl.data.RepositoryURL}}" target="_blank">{{$ctrl.data.RepositoryURL}}</a><br/>
                <strong>Dir:</strong> {{$ctrl.data.RepositoryDir}}<br/>
                <span ng-if="$ctrl.data.Keywords && $ctrl.data.Keywords.length > 0"><strong>Keywords:</strong> <span ng-repeat="k in $ctrl.data.Keywords" class="keyword"><a ui-sref="catalog({search:k})">{{k}}</a></span></span>
            </div>


            <div class="flex layout-align-end-center layout-row" style="width: 100%; padding-top: 2em;">
                <md-progress-circular ng-if="$ctrl.instaling" class="md-hue-2" md-diameter="20px"></md-progress-circular>
                <md-button  style="margin-left: 0.5em" class="md-raised" ng-click="$ctrl.install($event)" ng-disabled="$ctrl.instaling"><md-icon md-svg-icon="core:install"></md-icon> install to my</md-button>
                <cmp-api-error-service ng-if="$ctrl.installError" error="$ctrl.installError" format="'dialog'"></cmp-api-error-service>
                <cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>

                <a layout="row"
                    class="md-button md-raised"
                    style="margin-left: 0.5em"
                    ng-disabled="isDownloading"
                    ng-click="$ctrl.download()">
                    <md-icon md-svg-icon="core:link"></md-icon>
                    <span style="margin: 0 10px">download</span>
                    <md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>
                </a>
                <!--<a ui-sref="wsChartItem({chartName : item.Name})" md-button>more...</a>-->
            </div>
            <!--<div class="errorInfo" ng-if="$ctrl.deleteError">{{$ctrl.deleteError}}</div>-->
            <cmp-api-error-service ng-if="$ctrl.deleteError" error="$ctrl.deleteError" format="'dialog'"></cmp-api-error-service>

        </md-card-actions>
    </md-card-content>

    <md-tabs md-border-bottom md-dynamic-height  >
        <md-tab ng-repeat="tab in $ctrl.chartPageTabs"
                md-active="$ctrl.$state.params.tab == tab.title"
                ng-click="$ctrl.goTab(tab.title)">
            <md-tab-label>
                {{tab.title}}
            </md-tab-label>
        </md-tab>
    </md-tabs>
    <md-content class="fullInfo padding-content" ui-view="tabContent"></md-content>

</md-card>



