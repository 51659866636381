<div class="flex layout-row layout-align-start-start">
    <md-input-container flex>
        <label>{{"COMMON_NAME"|translate}}</label>
        <input
                type="text"
                name="Name"
                ng-model="$ctrl.data.Name"
                ng-maxlength="$ctrl.validate.maxName"
                ng-minlength="$ctrl.validate.minName"
                ng-pattern="$ctrl.validate.namePattern"
                required>
        <help-hint hint="wsa.add.displayname"></help-hint>
        <div ng-messages="addWSA.Name.$error">
            <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
            <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
            <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
            <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
        </div>
    </md-input-container>
    <md-input-container flex>
        <label>{{"COMMON_DESCRIPTION"|translate}}</label>
        <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
        <help-hint hint="wsa.add.description"></help-hint>
    </md-input-container>
</div>


<md-input-container flex>
    <label>{{"WSA_DOCKER_KEY"|translate}}</label>
    <textarea type="text" name="Credentials" ng-model="$ctrl.data.SetCredentials.auths" ng-trim="false" ></textarea>
    <help-hint hint="wsa.add.auths"></help-hint>
</md-input-container >
<div flex style="text-align: center" translate>COMMON_OR</div>
<div flex layout="row" layout-align="start center">
    <md-input-container flex>
        <label>{{"WSA_DOCKER_SERVER"|translate}}</label>
        <input type="text" name="Server" ng-model="$ctrl.data.SetCredentials.server" >
        <help-hint hint="wsa.add.server"></help-hint>
    </md-input-container >
    <md-input-container flex>
        <label>{{"WSA_DOCKER_USER_NAME"|translate}}</label>
        <input type="text"  ng-model="$ctrl.data.SetCredentials.username"  autocorrect="off" >
        <help-hint hint="wsa.add.usernaem"></help-hint>
    </md-input-container >
    <md-input-container flex>
        <label>{{"WSA_DOCKER_PASSWORD"|translate}}</label>
        <input type="password" ng-model="$ctrl.data.SetCredentials.password"  autocorrect="off" autocomplete="new-password" >
        <help-hint hint="wsa.add.password"></help-hint>
    </md-input-container>
</div>