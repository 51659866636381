<div flex layout="row">
    <md-input-container flex="50">
        <label translate>MLAPP_DISPLAYNAME</label>
        <input type="text"
               name="DisplayName"
               ng-model="$ctrl.data.DisplayName"
               required
               minlength="2"
               ng-model-options='{ debounce: 1000 }'
               ng-change="$ctrl.pickupName(newMlApp)"
        >
        <help-hint hint="application.displayname"></help-hint>
        <div ng-messages="newMlApp.DisplayName.$error">
            <div ng-message="required">App's name should be filled</div>
            <div ng-message="minlength">App's name is too short</div>
        </div>
    </md-input-container>
    <md-input-container flex="50" >
        <label translate>MLAPP_NAME</label>
        <input ng-disabled="!$ctrl.newElement" type="text"
               name="Name"
               ng-model="$ctrl.data.Name"
               ng-model-options='{ debounce: 1000 }'
               ng-change="$ctrl.checkName(newMlApp)">
        <help-hint hint="application.name"></help-hint>
        <div ng-messages="newMlApp.Name.$error">
            <div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.nameErrorMessage" error="nameErrorMessage"></cmp-error-toast></div>
        </div>
    </md-input-container>
</div>
<md-input-container class="flex" style="width: 100%">
    <label>{{"COMMON_DESCRIPTION"|translate}}</label>
    <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
</md-input-container>
<!--<div class="flex layout-row">-->
    <!--<md-input-container class="flex-50">-->
        <!--<label translate><label translate>MLAPP_TIMEOUT_MINUTES</label></label>-->
        <!--<input type="number" ng-model="$ctrl.data.MLConfiguration.KuberlabTFConfiguration.TimeoutMinutes" />-->
        <!--<help-hint hint="application.timeout_minutes"></help-hint>-->
    <!--</md-input-container>-->

    <!--<md-input-container class="flex-50">-->
        <!--<md-checkbox ng-model="$ctrl.data.MLConfiguration.KuberlabTFConfiguration.Jupyter.UseGPU">Jupyter use GPU</md-checkbox>-->
        <!--<help-hint hint="application.jupyter_gpu"></help-hint>-->
    <!--</md-input-container>-->
<!--</div>-->

<!--<md-divider class="flex"></md-divider>-->
<div class="cmp-block-compact">
    <h4 class="cmp-block-compact-title">Link project</h4>
    <div class="cmp-block-compact-content">
        <div ng-if="$ctrl.newElement" flex layout="row" style="width: 100%;">
            <md-input-container flex>
                <label translate>MLAPP_PROJECT</label>
                <md-select ng-model="$ctrl.data.ProjectName" ng-change="$ctrl.changeProject()" required>
                    <md-option ng-value="pr.Name" ng-repeat="pr in $ctrl.projectsList">
                        {{ pr.DisplayName }}
                    </md-option>
                </md-select>
                <help-hint hint="application.project"></help-hint>
            </md-input-container>
            <md-input-container flex>
                <label translate>MLAPP_CLUSTER</label>
                <md-select ng-model="$ctrl.data.ClusterName" required ng-disabled="!$ctrl.data.ProjectName">
                    <md-option ng-value="item.Name" ng-repeat="item in $ctrl.clusterList">
                        {{ item.Name }}
                    </md-option>
                </md-select>
                <help-hint hint="application.cluster"></help-hint>
            </md-input-container>
        </div>
        <div ng-if="!$ctrl.newElement" flex layout="row" style="width: 100%;">
            <md-input-container flex>
                <label>{{"MLAPP_PROJECT"| translate}}</label>
                <input ng-model="$ctrl.data.ProjectDisplayName" type="text" ng-disabled="true">
                <help-hint hint="applications.projectid"></help-hint>
            </md-input-container>
            <md-input-container flex>
                <label>{{"MLAPP_CLUSTER"| translate}}</label>
                <input ng-model="$ctrl.data.ClusterName" type="text" ng-disabled="true">
                <help-hint hint="applications.cluster"></help-hint>
            </md-input-container>
        </div>
    </div>
</div>

<div class="cmp-block-compact" ng-if="!$ctrl.data.Configuration.allowed_tabs || ($ctrl.data.Configuration.allowed_tabs && $ctrl.data.Configuration.allowed_tabs.edit)">
    <h4 class="cmp-block-compact cmp-clickable" ng-click="Configuration = !!!Configuration">
        {{"COMMON_CONFIGURATION" | translate}}
        <md-icon md-svg-icon="core:chevron-down" ng-if="!Configuration"></md-icon>
        <md-icon md-svg-icon="core:chevron-up" ng-if="Configuration"></md-icon>
    </h4>
    <div class="cmp-block-compact-content"  ng-if="Configuration">
        <div class="cmp-block-compact-content" style="width: 100%;" flex>
            <md-input-container style="width:100%;" flex>
                <label>Docker</label>
                <md-select ng-model="$ctrl.data.Configuration.spec.docker_account_ids" multiple  ng-if="$ctrl.wsaList.length">
                    <md-option ng-repeat="item in $ctrl.wsaList " ng-value="item.ID">{{item.Service}}:{{item.Name}}</md-option>
                </md-select>
                <textarea ng-disabled="true" ng-if="!$ctrl.wsaList.length" >{{ "COMMON_LIST_EMPTY" | translate}}</textarea>
                <help-hint hint="wsa.docker.account"></help-hint>
            </md-input-container>
        </div>

        <div ng-if="$ctrl.data.Configuration.spec.uix.length">
            <md-tabs md-dynamic-height md-border-bottom>
                <md-tab label="{{uix.name}}" ng-repeat="uix in $ctrl.data.Configuration.spec.uix">
                    <md-content class="md-padding">
                        <cmp-applications-task application="$ctrl.data" data="uix" is-uix="1" ></cmp-applications-task>
                    </md-content>
                </md-tab>
            </md-tabs>
            <!--<cmp-applications-task application="$ctrl.data" data="uix" ng-repeat="uix in $ctrl.data.Configuration.spec.uix"></cmp-applications-task>-->
        </div>

    </div>
</div>
