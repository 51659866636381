<md-dialog style="width:450px;">
    <form ng-cloak name="signUp" ng-submit="$ctrl.submit(signUp)" >
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Autentification by email</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>
        <md-dialog-content ng-if="!$ctrl.registerComplite">
            <md-tabs md-selected="$ctrl.selectedIndex" md-dynamic-height md-border-bottom>
                <md-tab><md-tab-label>login</md-tab-label></md-tab>
                <md-tab><md-tab-label>Registration</md-tab-label></md-tab>
            </md-tabs>

            <div ng-if="$ctrl.selectedIndex == 1" >
                <div class="flex layout-row layout-align-start layout-padding layout-wrap" ng-if="!$ctrl.registerComplite">
                    <md-input-container class="flex-50">
                        <label translate>COMMON_DISPLAYNAME</label>
                        <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" required ng-model-options='{ debounce: 1000 }'>
                        <div ng-messages="signUp.DisplayName.$error" ng-show="signUp.DisplayName.$dirty">
                            <div ng-message="required">This is required!</div>
                        </div>
                    </md-input-container>
                    <md-input-container class="flex-50">
                        <label>Login</label>
                        <input
                                type="text"
                                name="Login"
                                ng-model="$ctrl.data.Login"
                                ng-maxlength="$ctrl.validate.maxName"
                                ng-minlength="$ctrl.validate.minName"
                                ng-pattern="$ctrl.validate.namePattern"
                                ng-model-options='{ debounce: 1000 }'
                        >
                        <div ng-messages="signUp.Login.$error">
                            <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                            <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                            <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
                            <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
                        </div>
                    </md-input-container>

                    <md-input-container class="flex" style="min-width: 100% ">
                        <label>Email</label>
                        <input type="email" name="Email" ng-model="$ctrl.data.Email" required ng-model-options='{ debounce: 1000 }'>
                        <div ng-messages="signUp.Email.$error" ng-show="signUp.Email.$dirty">
                            <div ng-message="required">This is required!</div>
                        </div>
                    </md-input-container>

                    <md-input-container class="flex-50">
                        <label>Password</label>
                        <input
                                type="password"
                                name="Password"
                                ng-model="$ctrl.data.Password"
                                required
                                minlength="5"
                                ng-model-options='{ debounce: 1000 }'
                        >
                        <div ng-messages="signUp.Password.$error" ng-show="signUp.Password.$dirty">
                            <div ng-message="required">This is required!</div>
                            <div ng-message="minlength">That's too short! Min length 5.</div>
                        </div>
                    </md-input-container>
                    <md-input-container class="flex-50">
                        <label>Password confirm</label>
                        <input
                                type="password"
                                name="PasswordConfirm"
                                ng-model="$ctrl.PasswordConfirm"
                                required
                                minlength="5"
                                ng-model-options='{ debounce: 1000 }'
                                ng-change="$ctrl.passConfirm(signUp)"
                        >
                        <div ng-messages="signUp.PasswordConfirm.$error" ng-show="signUp.PasswordConfirm.$dirty">
                            <div ng-message="required">This is required!</div>
                            <div ng-message="confirm">NotConfirm</div>
                        </div>
                    </md-input-container>

                    <md-input-container class="flex-50">
                        <label>Organization</label>
                        <input type="text" name="Organization" ng-model="$ctrl.data.Organization">
                    </md-input-container>
                    <md-input-container class="flex-50">
                        <label>Phone</label>
                        <input type="text" name="Phone" ng-model="$ctrl.data.Phone">
                    </md-input-container>

                    <md-input-container ng-if="$ctrl.settings.can_register_gitlab">
                        <md-checkbox ng-model="$ctrl.data.CreateGitlabAccount" aria-label="Create Kuberlab GitLab account?">
                          Create Kuberlab GitLab account
                        </md-checkbox>
                    </md-input-container>
                    <div    ng-if="$ctrl.settings.recaptcha_key"
                            vc-recaptcha
                            theme="'light'"
                            key="$ctrl.settings.recaptcha_key"
                            on-create="setWidgetId(widgetId)"
                            on-success="setResponse(response)"
                            on-expire="cbExpiration()"
                    ></div>

                </div>


            </div>

            <div class="flex layout-padding layout-column layout-align-start" ng-if="$ctrl.selectedIndex == 0" >
                <md-input-container class="flex">
                    <label>Login/Email</label>
                    <input
                            type="text"
                            name="LoginOrEmail"
                            ng-model="$ctrl.dataLogin.LoginOrEmail"
                            required
                    >
                    <div ng-messages="login.LoginOrEmail.$error">
                        <div ng-message="required">is required field</div>
                    </div>
                </md-input-container>
                <md-input-container class="flex">
                    <label>Password</label>
                    <input type="password" name="Password" ng-model="$ctrl.dataLogin.Password" required minlength="5">
                    <div ng-messages="login.Password.$error" ng-show="signUp.Password.$dirty">
                        <div ng-message="required">This is required!</div>
                    </div>
                </md-input-container>
            </div>
            <cmp-api-error-service ng-if="$ctrl.error" error="$ctrl.error"></cmp-api-error-service>
        </md-dialog-content>
        <md-dialog-actions layout="row" class="layout-align-start" ng-if="!$ctrl.registerComplite">
            <span class="flex"></span>
            <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL" | translate}}</md-button>
            <md-button type="submit" class="md-raised md-primary">{{"COMMON_OK" | translate}}</md-button>
        </md-dialog-actions>
        <md-dialog-content ng-if="$ctrl.registerComplite" >
            <div  class="layout-padding">
                <p>Please click on the link that has been sent to your email to complete the registration.</p>
                <p>If you didn't receive verification email, please contact us at support@kuberlab.com.</p>
            </div>

        </md-dialog-content>

        <md-dialog-actions layout="row" class="layout-align-start" ng-if="$ctrl.registerComplite">
            <span class="flex"></span>
            <md-button ng-click="$ctrl.cancel()">{{"COMMON_OK" | translate}}</md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
