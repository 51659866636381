<div flex layout="row">
    <md-input-container flex="50">
        <label>{{"WSA_GOOGLE_TITLE_PROJECT"|translate}}</label>
        <input type="hidden" ng-model="$ctrl.data.SetCredentials.Service">
        <input type="hidden" ng-model="$ctrl.data.SetCredentials.Project">
        <md-select ng-model="$ctrl.googleServiceProject" md-selected-text="$ctrl.googleProjectName()" ng-change="$ctrl.googleProjectCheckReconnect()" required>
            <md-optgroup data-ng-repeat="serv in $ctrl.listController.googleProjects()" label="{{serv.ServiceName}} ({{serv.ServiceEmail}})" >
                <md-option
                        ng-if="serv.Projects"
                        ng-repeat="item in serv.Projects"
                        ng-value="item.projectId">{{item.name}}</md-option>
                <md-option
                        ng-if="serv.Error"
                        value="_reconnect_{{serv.ServiceId}}">Connection error. Reconnect?</md-option>
            </md-optgroup>
        </md-select>
        <help-hint hint="wsa.add.googleaccount"></help-hint>
    </md-input-container>
    <md-input-container flex="50" >
        <label>{{"COMMON_NAME"|translate}}</label>
        <input
                ng-change="$ctrl.googleManualDisplayName()"
                type="text"
                name="Name"
                ng-model="$ctrl.data.Name"
                ng-maxlength="$ctrl.validate.maxName"
                ng-minlength="$ctrl.validate.minName"
                ng-pattern="$ctrl.validate.namePattern"
                required>
        <help-hint hint="wsa.add.displayname"></help-hint>
        <div ng-messages="addWSA.Name.$error">
            <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
            <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
            <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
            <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
        </div>
    </md-input-container>
</div>

<div flex layout="row">
    <md-input-container flex>
        <label>{{"COMMON_DESCRIPTION"|translate}}</label>
        <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
        <help-hint hint="wsa.add.description"></help-hint>
    </md-input-container>
</div>