<md-list-item class="paddingListItemTabContent md-3-line" ng-click="false">
    <md-icon md-svg-icon="core:cluster" ng-class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text flex">
        <h4>
            <b ng-if="!this.listController.params && !$ctrl.listController.params.WorkspaceName">{{$ctrl.data.WorkspaceName}}</b>
            {{$ctrl.data.DisplayName}}
            <span ng-if="$ctrl.data.Global"> {{$ctrl.data.Free ? "COMMON_GLOBAL_FREE" : "COMMON_GLOBAL" | translate}}  </span>
            <span ng-if="$ctrl.listController.type == 'own' && $ctrl.data.From.ClusterName "> from ({{$ctrl.data.From.WorkspaceName}}/{{$ctrl.data.From.ProjectName}}/{{$ctrl.data.From.ClusterName}}) </span>
            <span ng-if="$ctrl.listController.type == 'available'"> link to ({{$ctrl.data.From.WorkspaceName}}) </span>
            <!--<span ng-if="$ctrl.data.ResourceLabel">, label: <b>{{$ctrl.data.ResourceLabel}}</b></span>-->
        </h4>

        <div class="cmp-block-compact-content" style="padding-bottom: 1em;">
            <p ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</p>
            <p ng-if="$ctrl.data.Shared && $ctrl.data.Shared.limits">
                <b>Cluster limit:</b>
                <span ng-repeat="(key, item) in  $ctrl.data.Shared.limits ">{{key}}: {{item}}<span ng-if="!$last">, </span></span>
            </p>
            <md-tabs md-dynamic-height >
                <md-tab
                        ng-repeat="tab in $ctrl.tabs" id="tab_{{$ctrl.title}}"
                        md-active="$ctrl.currentTab == tab.name"
                        md-on-select="$ctrl.goTab(tab.name)",
                        md-border-bottom
                >
                    <md-tab-label >{{tab.title}}</md-tab-label>
                    <md-tab-body >
                        <div
                                ng-if="tab.active"
                                style="height: 100%; width: 100%;"
                                bind-html-compile="tab.content"></div>
                    </md-tab-body>
                </md-tab>
            </md-tabs>
        </div>
    </div>
    <!--<md-menu  class="md-secondary" md-position-mode="target-right target"-->
              <!--ng-if="($ctrl.listController.type == 'own' && !$ctrl.data.Global) || (($ctrl.data.Global && $ctrl.Admin ) || !$ctrl.data.Global) ">-->
    <md-menu  class="md-secondary" md-position-mode="target-right target"
              ng-if="($ctrl.listController.type == 'own' && $ctrl.data.Global && $ctrl.Admin ) || !$ctrl.data.Global ">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item ng-if="($ctrl.listController.type == 'own' && !$ctrl.data.Global) || ($ctrl.data.HasResourceLabel) ">
                <md-button aria-label="icon" ng-click="$ctrl.share($event)">
                    <md-icon md-svg-icon="core:share"></md-icon> {{"COMMON_SHARE"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="($ctrl.data.Global && $ctrl.Admin) || !$ctrl.data.Global">
                <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'SHARED_CLUSTER_DELETE_DIALOG_MESSAGE', 'SHARED_CLUSTER_DELETE_DIALOG_TITLE')">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-list-item>

