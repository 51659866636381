<div layout-align="start center" layout="row">
    <!--<md-button ng-click="$ctrl.viewFullFilterAction()" ng-if="$ctrl.taskparams.length">Full filter</md-button>-->
    <md-button ng-click="$ctrl.viewFullFilterAction()" >{{"COMMON_FILTER" | translate}}</md-button>
    <md-input-container flex>
        <label translate>COMMON_EXPERIMENT</label>
        <md-select ng-model="$ctrl.search.branch">
            <md-option ng-value="undefined">All</md-option>
            <md-option ng-repeat="i in $ctrl.experiments" ng-value="i">{{i}}</md-option>
        </md-select>
    </md-input-container>


    <md-input-container flex >
        <label translate>{{'MLAPPS_STATUS_FILTER'}}</label>
        <md-select ng-model="$ctrl.search.status">
            <md-option ng-value="undefined">All</md-option>
            <md-option ng-value="'Running'">Running</md-option>
            <md-option ng-value="'Succeeded'">Succeeded</md-option>
            <md-option ng-value="'Canceled'">Canceled</md-option>
            <md-option ng-value="'Failed'">Failed</md-option>
            <md-option ng-value="'Terminated'">Terminated</md-option>
        </md-select>
    </md-input-container>
</div>
