<md-list class="flex">
    <div ng-repeat="item in $ctrl.list">
        <cmp-serving-volume-item
                data="item"
                application="$ctrl.application"
                list-controller="$ctrl"
                workspace-name="$ctrl.workspaceName"
        ></cmp-serving-volume-item>
        <md-divider ng-if="!$last"></md-divider>
    </div>
    <cmp-serving-volume-item
            ng-if="$ctrl.Source"
            data="$ctrl.Source"
            application="$ctrl.application"
            list-controller="$ctrl"
            is-new-element="true"
            workspace-name="$ctrl.workspaceName"
    ></cmp-serving-volume-item>
    <div class="layout-row layout-align-end-center">
        <md-button class="md-raised md-primary" ng-if="!$ctrl.Source" ng-click="$ctrl.getNewSource()">
            <md-icon md-svg-icon="core:plus"></md-icon>
            {{"COMMON_ADD" | translate}}
        </md-button>
    </div>
</md-list>




