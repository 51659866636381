<!--<md-button class="" ng-click="$ctrl.createNew($ctrl.version)">+ new version</md-button>-->

<cmp-inference-version-item-full ng-class="{fullView : item.Version == $ctrl.version.Version}" list-controller="$ctrl"  data="$ctrl.version" can-manage="$ctrl.canManage" ng-if="$ctrl.version">
</cmp-inference-version-item-full>

<div class="versionHistory">
    <div flex layout="row" layout-align="start center">

        <h3 flex>History</h3>
        <md-button class="" ng-click="$ctrl.createNew()">+ new version</md-button>
    </div>
    <cmp-inference-version-item list-controller="$ctrl" ng-repeat="item in $ctrl.versions" data="item" can-manage="$ctrl.canManage" ng-if="item.Version">
    </cmp-inference-version-item>
</div>
