<div>
    <form name="form" ng-submit="$ctrl.save(form)" layout="column" >
    <!--<form name="form" layout="column" >-->
        <div layout="row" layout-align="start center" layout-wrap="">
            <md-input-container flex ng-include="'kuberlab/core/template/name.html'" onload="fieldName={disabled: !$ctrl.isNewElement}"></md-input-container>
            <md-input-container flex >
                <label>Size Gb</label>
                <input type="number" name="size" ng-model="$ctrl.data.SizeGb" required ng-disabled="!$ctrl.isNewElement"/>
                <div ng-messages="form.size.$error">
                    <div ng-message="required" translate>FORM_ERROR_REQUIRED</div>
                </div>
            </md-input-container>
            <md-input-container flex >
                <label translate>COMMON_CLUSTER</label>
                <md-select ng-model="$ctrl.data.ClusterID" name="cluster" required ng-disabled="!$ctrl.isNewElement">
                    <md-option ng-repeat="item in $ctrl.clusters" ng-value="item.ClusterID" ng-switch="item.ClusterType">
                        <span ng-switch-when="infrastructure" >{{item.ClusterType}}/{{item.ClusterID}}</span>
                        <span ng-switch-when="shared" >{{item.ClusterType}}/{{item.Name}}</span>
                        <span ng-switch-when="global" >{{item.ClusterType}}/{{item.Name}}</span>

                    </md-option>
                </md-select>
                <div ng-messages="form.cluster.$error">
                    <div ng-message="required" translate>FORM_ERROR_REQUIRED</div>
                </div>
            </md-input-container>
            <md-input-container style="width: 100%" ng-include="'kuberlab/core/template/description.html'" onload="fieldDescription={disabled: false}"></md-input-container>
            <div layout="row" layout-align="start center" style="width: 100%" >
                <div flex>
                    <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                    <cmp-error-toast ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-error-toast>
                </div>
                <md-button ng-click="$ctrl.cancel()" translate="COMMON_CANCEL"></md-button>
                <md-button type="submit" ng-disabled="!form.$valid" class="md-raised md-primary" translate="COMMON_SAVE"></md-button>
                <!--<md-button ng-click="$ctrl.save(form)" class="md-raised md-primary" translate="COMMON_SAVE"></md-button>-->
            </div>
        </div>
    </form>
</div>
