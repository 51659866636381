<md-card>
    <md-card-content layout="column">
        <div layout="row" layout-align="start start">
            <md-button class="md-icon-button icon-tree-point" ng-click="opened = !!!opened">
                <md-icon md-svg-icon="core:{{opened ? 'minus-box' : 'plus-box' }}"></md-icon>
            </md-button>
            <div class="name flex">
                <div style="font-weight: bold" ui-sref="wsApplication.tab({ tabItem: $ctrl.data.task_name + ':' +$ctrl.data.build})">{{$ctrl.data.task_name}}:{{$ctrl.data.build}}<span ng-if="$ctrl.data.exec_info.name">&nbsp;&nbsp;-&nbsp;&nbsp;{{$ctrl.data.exec_info.name}}</span> <span ng-if="$ctrl.data.serve" class="status {{$ctrl.data.serve.status}}" > ({{"COMMON_SERVING" | translate}}<span ng-if="!$ctrl.data.serving">:direct</span>)</span></span></div>
                <div style="font-size: 90%" >{{"COMMON_EXPERIMENT" | translate }}: {{$ctrl.data.branch}}, {{"COMMON_AUTHOR" | translate }}: {{$ctrl.data.author}}</div>
                <p ng-if="$ctrl.data.comment">{{$ctrl.data.comment}}</p>
            </div>
            <div layout="row" layout-align="end center" style="height: 40px">
                <div class="md-padding"></div>
                <div class="wordTime" >
                    <div class="layout-row layout-align-start-center">
                        <md-icon md-svg-icon="core:calendar" class="tsIcon"></md-icon>
                        <div class=" flex layout-column layout-align-start-start">
                            <span class="ts">{{ $ctrl.data.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                            <span class="ts" ng-if="$ctrl.data.stop_time">{{ $ctrl.data.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <md-menu-bar style="margin-right: 0; padding-right: 0;">

                <item-status-icon data="{Health: $ctrl.data.status}" class="widthAuto"></item-status-icon>
                <md-menu class="menu nested-menu" md-position-mode="target-right target" ng-if="$ctrl.permissions.can('application.manage')">
                <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item >
                        <md-button ng-if="!$ctrl.data.serve"
                                   class="_md-raised _md-primary"
                                   ng-click="$ctrl.listController.getServeDialog($event, $ctrl.data)"
                                   ng-disabled="!$ctrl.appController.data.Enabled" >
                            <md-icon md-svg-icon="core:play"></md-icon>
                            {{"MLAPPS_JOB_SERVE" | translate}}
                        </md-button>
                        <md-button
                                ng-if="$ctrl.data.serve"
                                class="_md-raised _md-primary"
                                ng-click="$ctrl.listController.action('stop_serving', {task: $ctrl.data.task_name, build: $ctrl.data.serve.build})"
                                ng-disabled="!$ctrl.data.serve.status == 'Running' || $ctrl.loading"
                        ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
                    </md-menu-item>



                    <md-menu-item >
                        <md-menu  >
                            <md-button ng-click="$ctrl.openMenu($mdMenu, $event)">
                                <md-icon md-svg-icon="core:save" style="margin-right: 16px;"></md-icon>
                                {{"COMMON_EXPORT" | translate}}
                            </md-button>
                            <md-menu-content width="2">
                                <md-menu-item ng-repeat="eTask in $ctrl.listTask()">
                                    <md-button ng-click="$ctrl.listController.action('export', {task: $ctrl.data.task_name, build: $ctrl.data.build, eTask:eTask.name})">
                                        <md-icon md-svg-icon="core:progress-upload"></md-icon>
                                        {{ eTask.name }}</md-button>
                                </md-menu-item>
                                <!--<md-menu-item ng-if="!$ctrl.listTask().length">-->
                                    <!--<md-button >not task export</md-button>-->
                                <!--</md-menu-item>-->
                                <md-divider ng-if="$ctrl.listTask().length" ></md-divider>
                                <md-menu-item >
                                    <md-button ng-click="$ctrl.listController.getSaveDialog($event, $ctrl.data)" ng-disabled="!$ctrl.appController.data.Enabled">
                                    <md-icon md-svg-icon="core:save"></md-icon>
                                    {{"COMMON_DEFAULT" | translate}}
                                    </md-button>
                                </md-menu-item>

                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>





                    <md-menu-item ng-if="$ctrl.data.git_revision">
                        <md-button ui-sref="wsApplication.tab({revision: $ctrl.data.git_revision, tab: 'models', tabItem: $ctrl.data.task_name})">
                            <md-icon md-svg-icon="core:download"></md-icon> {{"PROJECT_CHECKOUT"|translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item ng-if="$ctrl.data.status == 'Running' || $ctrl.data.status == 'Pending' || $ctrl.data.status == 'Starting'">
                        <md-button ng-click="$ctrl.listController.action('stop', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                            <md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP"|translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item >
                        <md-button ng-click="$ctrl.listController.action('restart', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                            <md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESTART"|translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item>
                        <md-button aria-label="icon" ui-sref="wsApplication.tab({tab: 'metrics', tabItem: $ctrl.data.name})">
                            <md-icon md-svg-icon="core:chart"></md-icon> {{"COMMON_METRICS"|translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item>
                        <md-button aria-label="icon" ng-click="opened = !!!opened">
                            <md-icon md-svg-icon="core:note-text"></md-icon> {{"COMMON_LOGS"|translate}}
                        </md-button>
                        <!--<md-button aria-label="icon" ui-sref="wsApplication.tab({tab: 'logs', tabItem: $ctrl.data.name})">-->
                        <!--<md-icon md-svg-icon="core:note-text"></md-icon> {{"COMMON_LOGS"|translate}}-->
                        <!--</md-button>-->
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item>
                        <md-button aria-label="icon" ng-click="$ctrl.listController.action('delete', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                            <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            </md-menu-bar>
        </div>

        <!--<div layout-align="start">-->
            <div layout="row" layout-align="start center" ng-if="$ctrl.data.exec_info">
                <div style="width:50px;"></div>
                <div  style="font-size: 80%" flex>
                    <!--<div ng-repeat="(k, v) in $ctrl.data.exec_info">-->
                    <!--<b>{{k}}</b>:&nbsp;{{v}}<span ng-if="!$last">; </span>-->
                    <!--</div>-->
                    <div ng-if="$ctrl.paramsDoc">Documents: <span ng-repeat="v in $ctrl.paramsDoc" ><span data-ng-bind-html="v.value"></span><span ng-if="!$last">, </span></span></div>

                    <div ng-repeat="v in $ctrl.paramsGlobal | orderBy: 'label' ">
                        <b>{{v.label}}</b>:&nbsp;<span data-ng-bind-html="v.value"></span><span ng-if="!$last">; </span>
                    </div>
                    <div ng-if="$ctrl.hesAd">
                        <div ng-repeat="( n, p ) in $ctrl.paramsAdvansed">

                            <div class="cmp-block-compact-title cmp-clickable" ng-click="Additional[n] = !!!Additional[n]" layout="row"  layout-align="start center">
                                <md-icon md-svg-icon="core:chevron-down" ng-if="!Additional[n]"></md-icon>
                                <md-icon md-svg-icon="core:chevron-up" ng-if="Additional[n]"></md-icon>
                                <div><b>{{n}}</b> group parameters</div>
                                <div style="width: 2em"></div>
                                <hr flex/>
                            </div>
                            <div style="padding-left: 24px" class="cmp-block-compact-content" ng-if="Additional[n]">
                                <div ng-repeat="v in p  | orderBy: 'label' ">
                                    <b>{{v.label}}</b>:&nbsp;<span data-ng-bind-html="v.value"></span><span ng-if="!$last">; </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        <!--</div>-->
    </md-card-content>
    <div ng-if="$ctrl.data.status == 'Running'" class="applicationMetricShort layout-row layout-align-center " style="background-color: #eee">
        <div class="item layout-column layout-align-center-center ">
            <div class="value">{{!$ctrl.data.Stats.cpu_usage.Value ? 0 : ( $ctrl.data.Stats.cpu_usage.Value > 1000 ? $ctrl.data.Stats.cpu_usage.Value.toFixed(0) : $ctrl.data.Stats.cpu_usage.Value.toFixed(2) ) }}</div>
            <div class="legend" >{{'MLAPP_METRIC_cpu_usage' | translate}} {{$ctrl.data.Stats.cpu_usage.Measure}}</div>
        </div>
        <div class="item layout-column layout-align-center-center ">
            <div class="value">{{!$ctrl.data.Stats.gpu_usage.Value ? 0  : ( $ctrl.data.Stats.gpu_usage.Value > 1000 ? $ctrl.data.Stats.gpu_usage.Value.toFixed(0) : $ctrl.data.Stats.gpu_usage.Value.toFixed(2) ) }}</div>
            <div class="legend" >{{'MLAPP_METRIC_gpu_usage' | translate}} {{$ctrl.data.Stats.gpu_usage.Measure}}</div>
        </div>
        <div class="item layout-column layout-align-center-center ">
            <div class="value">{{!$ctrl.data.Stats.memory_bytes.Value ? 0 : ( $ctrl.data.Stats.memory_bytes.Value > 1000 ? $ctrl.data.Stats.memory_bytes.Value.toFixed(0) : $ctrl.data.Stats.memory_bytes.Value.toFixed(2) ) }}</div>
            <div class="legend" >{{'MLAPP_METRIC_memory_bytes' | translate}} {{$ctrl.data.Stats.memory_bytes.Measure}}</div>
        </div>
    </div>
    <md-card-content ng-if="opened" >
        <md-tabs class="md-dynamic-height md-border-bottom" flex md-border-bottom md-selected="selectedIndex">
            <md-tab flex="">
                <md-tab-label>
                    Logs
                </md-tab-label>
                <md-tab-body flex layout="row">
                    <cmp-applications-logs-job  flex layout="row" application="$ctrl.appController.data" data="$ctrl.data"></cmp-applications-logs-job>
                </md-tab-body>
            </md-tab>
            <md-tab flex>
                <md-tab-label>metrics</md-tab-label>
                <md-tab-body>
                    <cmp-applocation-job-mertincs active="selectedIndex == 1" app-controller="$ctrl.appController" task="$ctrl.data"></cmp-applocation-job-mertincs>
                </md-tab-body>
            </md-tab>
            <md-tab ng-if="$ctrl.data.serve">
                <md-tab-label>
                    Serving
                </md-tab-label>
                <md-tab-body>
                    <div class="md-padding" layout="row" layout-align="start center" ng-if="$ctrl.data.serving">
                        <cmp-serving-icon data="$ctrl.data.serving"></cmp-serving-icon>
                        <div>
                            <h4 style="margin: 0">{{$ctrl.data.serving.Task}}:{{$ctrl.data.serving.Build}} <span style="font-weight: normal" ng-if="$ctrl.data.UserDisplayName">( {{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.serving.UserDisplayName}} )</span></h4>
                            <div><b>Address:</b> {{$ctrl.data.serve.inner_address}}</div>
                            <div><a ui-sref="wsServing.tab({servingName: $ctrl.data.serving.Name, wsId: $ctrl.data.serving.WorkspaceName})">serving page</a></div>
                        </div>
                        <div flex></div>
                        <md-button
                                ng-if="$ctrl.permissions.can('application.manage')"
                                class="md-raised md-primary"
                                ng-click="$ctrl.listController.action('stop_serving', {task: $ctrl.data.task_name, build: $ctrl.data.serve.build})"
                                ng-disabled="!$ctrl.data.serving.Status == 'Running' || $ctrl.loading"
                        ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
                    </div>
                    <div class="md-padding" layout="row" layout-align="start center" ng-if="!$ctrl.data.serving && $ctrl.data.serve">
                        <cmp-serving-icon data="$ctrl.data.serve"></cmp-serving-icon>
                        <div>
                            <h4 style="margin: 0">{{$ctrl.data.serve.serving}}:{{$ctrl.data.serve.build}} </h4>
                            <div><b>Address:</b> {{$ctrl.data.serve.inner_address}}</div>
                        </div>
                        <div flex></div>

                        <md-menu class="menu" md-position-mode="target-right target">
                            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                                <md-icon md-svg-icon="core:short-menu"></md-icon>
                            </md-button>
                            <md-menu-content width="3">
                                <md-menu-item >
                                    <md-button
                                            ng-if="$ctrl.permissions.can('application.manage')"
                                            class="md-raised md-primary"
                                            ng-click="$ctrl.action('stop_serving', {task: $ctrl.data.serve.serving, build: $ctrl.data.serve.build})"
                                            ng-disabled="!$ctrl.data.serve.status == 'Running' || $ctrl.loading"
                                    ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                    <cmp-serving-logs request-data="{servingName: $ctrl.data.serving.Name, wsId: $ctrl.data.serving.WorkspaceName}"></cmp-serving-logs>
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </md-card-content>
</md-card>
