
<md-list class="flex">
    <div class="titleList layout-row layout-align-space-between-center ng-scope">
        <!--<div style="width: 54px"></div>-->
        <b class="flex">Volume</b>
        <!--<b class="flex-10" style="text-align: left">Mount</b>-->
        <b class="flex-10" style="text-align: center">Lib</b>
        <b class="flex-10" style="text-align: center">Output</b>
        <!--<div style="width: 48px;"></div>-->
    </div>
    <div ng-repeat="item in $ctrl.mlapp.MLConfiguration.KuberlabTFConfiguration.Volumes">

        <cmp-mlapps-volumes-item
                data="item",
                mlapp="$ctrl.mlapp"
                list-controller="$ctrl"
        ></cmp-mlapps-volumes-item>
        <md-divider></md-divider>
    </div>
    <cmp-mlapps-volumes-item
            data="$ctrl.Source"
            mlapp="$ctrl.mlapp"
            list-controller="$ctrl"
            is-new-element="true"
    ></cmp-mlapps-volumes-item>
</md-list>

<cmp-api-error-service ng-if="$ctrl.updateError" error="$ctrl.updateError" format="'dialog'"></cmp-api-error-service>
