<div  ng-class="{currentVersion : $ctrl.data.Version == $ctrl.listController.version.Version}">
    <div ng-if="!$ctrl.asForm">
        <div layout="row" layout-align="start " flex class="title" >
            <div class="cmp-clickable"  layout="row" layout-align="start center" ng-click="$ctrl.activate()" flex >
                <div layout="row" layout-align="start center">
                    <div layout="row">Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</div>
                    <md-button ng-if="$ctrl.data && $ctrl.data.Status == 'error'  "  class="md-warn"><md-tooltip md-direction="right">{{$ctrl.data.StatusError}}</md-tooltip>Error</md-button>
                    <md-button ng-disabled="true" ng-if="$ctrl.data && $ctrl.data.Status == 'uploading'  "  class="md-warn">{{$ctrl.data.Status}}</md-button>
                    <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>
                </div>
                <div class="versionMessage"  flex>{{$ctrl.data.Message}}</div>
                <div>{{$ctrl.data.Updated | timePassed }}</div>
            </div>
            <div ng-include="'kuberlab/mlmodel/version/menu-item.html'"></div>
        </div>
    </div>
</div>