<!--<script src="user_card_controller.js"></script>-->


<layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
    <layout-button-submenu></layout-button-submenu>
    <h3>{{$ctrl.data.DisplayName}}</h3>
    <div class="flex"></div>
    <md-menu   class="md-secondary" md-position-mode="target-right target">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3" >
            <md-menu-item >
                <md-button aria-label="icon"ng-click="$ctrl.editAction()">
                    <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <!--<md-button aria-label="icon"ng-click="$ctrl.delete($event)">-->
                <md-button aria-label="icon"ng-click="$ctrl.deleteAccount()">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>

</layout-page-content-title>


<md-content flex>


    <md-card ng-if="!$ctrl.asForm" class="user-">

        <md-card-content class="layout-row layout-align-space-between-start" >
            <div class="md-media-xl card-media" layout="column" style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>

            </div>
            <md-card-actions layout="column" layout-align="start" flex class="chartInfo">
                <!--<div layout="row" layout-align="start center">-->
                <!--<div flex></div>-->
                <!--<md-menu   class="md-secondary" md-position-mode="target-right target">-->
                <!--<md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">-->
                <!--<md-icon md-svg-icon="core:short-menu"></md-icon>-->
                <!--</md-button>-->
                <!--<md-menu-content width="3" >-->
                <!--<md-menu-item >-->
                <!--<md-button aria-label="icon"ng-click="$ctrl.editAction()">-->
                <!--<md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}-->
                <!--</md-button>-->
                <!--</md-menu-item>-->
                <!--<md-menu-item>-->
                <!--&lt;!&ndash;<md-button aria-label="icon"ng-click="$ctrl.delete($event)">&ndash;&gt;-->
                <!--<md-button aria-label="icon"ng-click="$ctrl.deleteAccount()">-->
                <!--<md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}-->
                <!--</md-button>-->
                <!--</md-menu-item>-->
                <!--</md-menu-content>-->
                <!--</md-menu>-->
                <!--</div>-->
                <!--<h3>{{$ctrl.data.DisplayName}}</h3>-->
                <p>
                <div>{{"COMMON_NAME"|translate}}: {{$ctrl.data.Login}}</div>
                <div ng-if="$ctrl.data.Email">Email: {{$ctrl.data.Email}}</div>
                <div ng-if="$ctrl.data.Phone">{{"COMMON_PHONE"|translate}}: <b>{{ $ctrl.data.Phone}}</b></div>
                <div ng-if="$ctrl.data.Url">{{"COMMON_URL"|translate}}: <b>{{ $ctrl.data.URl}}</b></div>
                </p>
            </md-card-actions>
        </md-card-content>


        <md-content ui-view=""></md-content>
    </md-card>


    <md-card ng-if="$ctrl.asForm && !$ctrl.setPassword" md-theme="default" >

        <form name="cuForm" ng-submit="$ctrl.save(cuForm)">
            <md-card-content layout="column">
                <div flex layout="row">
                    <md-input-container flex="50">
                        <label>{{"COMMON_NAME"|translate}}</label>
                        <input name="DisplayName" required ng-model="$ctrl.updData.DisplayName" required minlength="2" focus-me="shouldBeOpen">
                        <help-hint hint="user.displayname"></help-hint>
                        <div ng-messages="cuForm.DisplayName.$error">
                            <div ng-message="required">Name should be filled</div>
                            <div ng-message="minlength">Name is too short</div>
                        </div>
                    </md-input-container>
                    <div flex="50" class="layout-row layout-align-start-center">
                        <md-input-container flex>
                            <label>{{"COMMON_IDENTIFICATION"|translate}}</label>
                            <input ng-model="$ctrl.updData.Login" ng-disabled="!$ctrl.loginEditing">
                            <help-hint hint="user.login"></help-hint>
                            <div ng-messages="cuForm.Name.$error">
                                <div ng-message="errorname">{{$ctrl.nameErrorMessage}}</div>
                            </div>
                        </md-input-container>
                        <md-button class="md-icon-button" ng-hide="$ctrl.loginEditing" ng-click="$ctrl.editLogin()"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
                        <md-button class="md-icon-button" ng-hide="!$ctrl.loginEditing" ng-click="$ctrl.saveLogin()"><md-icon md-svg-icon="core:check"></md-icon></md-button>
                        <md-button class="md-icon-button" ng-hide="!$ctrl.loginEditing" ng-click="$ctrl.cancelEditLogin()"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                    </div>
                </div>
                <div flex layout="row">
                    <md-input-container flex="50">
                        <label>{{"COMMON_ORGANIZATION"|translate}}</label>
                        <input type="text" name="Organization" ng-model="$ctrl.updData.Organization">
                        <help-hint hint="user.organization"></help-hint>
                    </md-input-container>
                    <md-input-container flex="50">
                        <label>{{"COMMON_PHONE"|translate}}</label>
                        <input type="text" name="Phone" ng-model="$ctrl.updData.Phone">
                        <help-hint hint="user.phone"></help-hint>
                    </md-input-container>
                </div>
                <div flex layout="row" layout-align="start center">
                    <div flex="50" layout="column">
                        <div ng-if="$ctrl.editEmail && !$ctrl.editEmailComplite" flex layout-align="start center" >
                            <md-input-container flex>
                                <label>Email</label>
                                <input type="email" ng-model="$ctrl.dataEmail.Email" name="newEmail" required/>
                                <div ng-messages="cuForm.newEmail.$error">
                                    <div ng-message="required" class="message slide-left">You did not enter a field</div>
                                </div>
                            </md-input-container>
                            <md-button ng-click="$ctrl.sendEmail(cuForm)" class="md-icon-button"><md-icon md-svg-icon="core:check"></md-icon></md-button>
                            <md-button ng-click="$ctrl.editEmail = false" class="md-icon-button"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                        </div>
                        <cmp-error-toast ng-if="$ctrl.editEmailError" error="$ctrl.editEmailError"></cmp-error-toast>
                        <div ng-if="$ctrl.editEmail && $ctrl.editEmailComplite">
                            <md-input-container> Please confirm your new email!</md-input-container>
                        </div>
                        <div ng-if="!$ctrl.editEmail" flex layout="row" layout-align="start center">
                            <md-input-container flex>
                                <label>Email</label>
                                <input type="text" ng-disabled="true" ng-model="$ctrl.email">
                            </md-input-container>
                            <md-button ng-click="$ctrl.editEmail = true" class="md-icon-button"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
                        </div>
                    </div>
                    <div flex="50">
                        <md-input-container><a href="" ng-click="$ctrl.setPassword = true">
                            {{ $ctrl.data.HasPassword ? "USER_PASS_CHANGE" : "USER_PASS_SET" | translate }}
                        </a>
                            <!--<span ng-if="!$ctrl.data.HasPassword">Set password</span>-->
                            <!--<span ng-if="$ctrl.data.HasPassword">Change password</span></a>-->
                        </md-input-container>
                    </div>
                </div>
                <div layout="row" layout-align="center center">
                    <img ng-src="{{$ctrl.data.Picture}}?25" class="md-avatar" ng-if="$ctrl.data.Picture" style="height: 40px"/>
                    <div style="width:2em"></div>
                    <div class="flex">
                        <lf-ng-md-file-input lf-files="$ctrl.file" flex lf-filesize="1MB" name="picture" lf-api="$ctrl.lfApi"></lf-ng-md-file-input>
                        <div ng-messages="cuForm.picture.$error" >
                            <div ng-message="filesize" translate>FORM_ERROR_FILESIZE</div>
                        </div>
                    </div>
                    <md-button ng-click="$ctrl.uploadFile(cuForm)" ng-disabled="!$ctrl.file.length">upload</md-button>
                </div>
            </md-card-content>


            <div flex>
                <md-progress-linear ng-if="$ctrl.isLoading"></md-progress-linear>
                <cmp-api-error-service ng-if="$ctrl.uploadError" error="$ctrl.uploadError" ></cmp-api-error-service>
            </div>
            <md-card-actions layout="row" layout-align="end center" class="layout-align-end-center layout-row">
                <md-button type="submit"><md-icon md-svg-icon="core:check"></md-icon> {{"COMMON_SAVE"|translate}}</md-button>
                <md-button type="button" ng-click="$ctrl.viewAction()"><md-icon md-svg-icon="core:close"></md-icon> {{"COMMON_CANCEL"|translate}}</md-button>
            </md-card-actions>
        </form>
    </md-card>
    <cmp-user-password ng-if="$ctrl.setPassword" set-password="$ctrl.setPassword"></cmp-user-password>

</md-content>

