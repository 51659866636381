<div>
    <div ng-if="!$ctrl.asForm">
        <div layout="row" layout-align="start center" flex class="title" ng-if="$ctrl.data.Version">
            <div class=""  layout="column" layout-align="start start" flex >
            <!--<div class="cmp-clickable"  layout="row" layout-align="start center" flex ui-sref="wsCatalogDataItem($ctrl.listData)" >-->
                <div flex layout="row" layout-align="start center">
                    <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn small">Editing</md-button>
                    <h4 >{{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}} </h4>
                    <div class="small" style="padding: 0 1em;">{{$ctrl.data.Updated | timePassed}}</div>
                </div>
            </div>
            <div >
                <md-button class="md-raised small" ng-click="$ctrl.listController.createNew($ctrl.data)" ng-if="$ctrl.canManage" ><md-icon md-svg-icon="core:copy"></md-icon>clone</md-button>
                <md-button class="md-raised small" ng-click="$ctrl.commit()" ng-if="$ctrl.data.Editing && $ctrl.canManage"><md-icon md-svg-icon="core:commit"></md-icon>commit</md-button>
            </div>
            <div ng-include="'kuberlab/catalog/base/version-view/menu-item.html'"></div>
        </div>

        <div class="versionMessage" ng-if="$ctrl.data.Message" style="padding-bottom: 1em">{{$ctrl.data.Message}}</div>

        <div ng-if="$ctrl.data.Version">
            <cmp-catalog-version-tree-v2 ctrl="$ctrl" resource="$ctrl.listController.resource" api="$ctrl.treeApi" edit="$ctrl.data.Editing"></cmp-catalog-version-tree-v2>
        </div>
    </div>
    <form ng-if="$ctrl.data.isNew" ng-submit="$ctrl.saveNew(nameVersion)" name="nameVersion" class="setNewVersion" flex layout="column" layout-align="center center">
        <md-card layout="column" layout-align="canter" class="md-padding"  style="width: 400px">
            <h4 style="text-align:center">Create new version</h4>
            <p ng-if="$ctrl.data.From" style="text-align: center" >From version {{$ctrl.data.From}}</p>
            <md-input-container>
                <label>Set new version</label>
                <input ng-model="$ctrl.newVersion" />
            </md-input-container>
            <md-input-container>
                <label translate>COMMON_MESSAGE</label>
                <textarea ng-model="$ctrl.data.Message" style=" max-height: 200px;"></textarea>
            </md-input-container>
            <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>
            <div layout="row" layout-align="center">
                <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button type="submit">create</md-button>
            </div>
        </md-card>
    </form>

</div>
