<md-list-item ng-if="$ctrl.asForm">
    <form name="newTeam" ng-submit="$ctrl.save(newTeam)" flex>
        <md-card flex layout="row"  layout-align="space-between center">
            <md-card-content flex>
                <md-input-container flex>
                    <labal>{{"COMMON_DISPLAY_NAME"|translate}}</labal>
                    <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" />
                </md-input-container>
            </md-card-content>
            <md-card-actions>
                <md-button type="submit">{{"COMMON_SAVE"|translate}}</md-button>
                <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL"|translate}}</md-button>
            </md-card-actions>
        </md-card>
    </form>
</md-list-item>

<md-list-item ng-if="!$ctrl.asForm">
    <md-icon md-svg-icon="core:group" ></md-icon>
    <div class="md-list-item-text" flex>
        <h5>{{$ctrl.data.DisplayName}}</h5>
    </div>
    <md-menu ng-if="$ctrl.data.ID != 'Owners'" class="md-secondary">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item >
                <md-button aria-label="icon" ng-click="$ctrl.actionForm()">
                    <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button aria-label="icon" ng-click="$ctrl.delete()">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>

</md-list-item>
<md-divider></md-divider>
<md-card>
    <md-content>
        <md-tabs md-dynamic-height md-border-bottom md-selected="selectedIndex" >
            <md-tab md-on-select="user_tab = 'serv'">
                <md-tab-label>{{"COMMON_USERS"|translate}}</md-tab-label>
                <md-tab-body class="md-padding">
                    <md-content>
                        <cmp-team-user-list ng-if="!$ctrl.asForm" organization-id="$ctrl.organizationId" team-id="$ctrl.data.ID"  is-owner-team="$ctrl.data.Permissions.owner" flex layout="row"  layout-align="space-between none"></cmp-team-user-list>
                    </md-content>
                </md-tab-body>
            </md-tab>
            <md-tab md-on-select="user_tab = 'perm'" ng-if="!$ctrl.data.Permissions.owner">
                <md-tab-label>{{"SETTINGS_TEAM_PERMISSION_TITLE"|translate}}</md-tab-label>
                <md-tab-body class="md-padding">
                    <md-content>
                        <cmp-team-rights team-id="$ctrl.data.ID" org-id="$ctrl.organizationId" class="listTeamRights" is-owner-team="$ctrl.data.Permissions.owner"></cmp-team-rights>
                    </md-content>
                </md-tab-body>
            </md-tab>
            <md-tab md-on-select="user_tab = 'token'">
                <md-tab-label>{{'SETTINGS_TOKEN_TITLE' | translate }}</md-tab-label>
                <md-tab-body class="md-padding">
                    <md-content>
                        <cmp-user-token ng-if="user_tab == 'token'" org="$ctrl.organizationId" team="$ctrl.data.ID"></cmp-user-token>
                    </md-content>
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </md-content>
</md-card>

<!--<cmp-team-user-list ng-if="!$ctrl.asForm" organization-id="$ctrl.organizationId" team-id="$ctrl.data.ID"  is-owner-team="$ctrl.data.IsOwnerTeam" flex layout="row"  layout-align="space-between none"></cmp-team-user-list>-->
<md-divider></md-divider>
