<div class="innerContentBorder mlappList" ng-class="{'limitHeagth' : '$ctrl.limitHeatht()'}">
    <md-list ng-if="$ctrl.list.length" >
        <!--<cmp-mlapps-list-item ng-repeat="item in $ctrl.list" mlapp="item"></cmp-mlapps-list-item>-->
        <md-list-item class="paddingListItemTabContent md-3-line" ng-repeat="data in $ctrl.list | orderBy : ['-Enabled', 'DisplayName']" ui-sref="wsMlapp({mlappName: data.Name})">
            <md-icon md-svg-icon="core:mlapp"  class="md-avatar-icon"></md-icon>
            <md-button ng-disabled="true" ng-click="$ctrl.enabled()"  class="md-secondary" ng-if="!data.Enabled" >{{'COMMON_DISABLED' | translate}}</md-button>
            <div class="md-list-item-text flex">
                <h4 >{{data.DisplayName}} ({{data.Name}})</h4>
                <p class="description" ng-if="data.Description">{{ data.Description }}</p>
            </div>
            <md-button ui-sref="wsProject({projectName: data.ProjectName})" class="link md-secondary"><md-icon md-svg-icon="core:link"></md-icon> {{data.ProjectDisplayName}}</md-button>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>

    </md-list>
    <md-button class="allList" ng-if="$ctrl.limitHeatht()" ui-sref="wsMlappsList()">{{"COMMON_ALL_LIST" | translate}} ({{$ctrl.list.length}})</md-button>
</div>
<div ng-if="$ctrl.listEmpty"  ng-include=" 'kuberlab/mlapps/card/list_empty.html' "></div>