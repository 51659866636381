<div layout="row" layout-align="start" flex ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && !$ctrl.data.Editing"  class="contentVersion" md-whiteframe="1" style="height: 450px" >
    <div flex="33" layout="column" layout-align="start ">
        <h4>tree dir</h4>
        <md-content flex>
            <cmp-catalog-version-tree ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.CatalogResource"></cmp-catalog-version-tree>
        </md-content>
    </div>
    <div style="width: 10px"></div>
    <cmp-catalog-version-viewer ctrl="$ctrl" file-url="$ctrl.fileUrl" file-info="$ctrl.fileInfo" flex layout="column" layout-align="start"></cmp-catalog-version-viewer>
</div>

<div layout="row" layout-align="start" flex ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && $ctrl.data.Editing && !$ctrl.uploadFullFile" class="contentVersion" style="height: 450px" >
    <div flex="33" layout="column" layout-align="start">
        <div layout="row" layout-align="start center">
            <h4 flex>tree dir</h4>
            <md-button ng-click="$ctrl.addFolder()">add folder</md-button>
        </div>
        <md-content flex>
            <cmp-catalog-version-tree ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.CatalogResource" edit="true"></cmp-catalog-version-tree>
        </md-content>
        <div layout="row" layout-align="center">
            <md-button ng-click="$ctrl.commit()">commit</md-button>
        </div>
    </div>
    <div style="width: 10px"></div>
    <div flex layout="column" layout-align="start"  ng-if="$ctrl.data.Editing">
        <form name="upload" ng-submit="$ctrl.uploadFile(upload)"  layout="row" layout-align="start center">
            <md-input-container flex><label>upload path</label><input name="path" ng-model="$ctrl.fileUrl"></md-input-container>
            <input type="file" name="file" ng-model="$ctrl.file" multiple>
            <md-button type="submit">upload</md-button>
        </form>
        <md-content flex>
            <cmp-catalog-version-uploading-files  ng-repeat="f in $ctrl.uploadingFiles" data="f" ctrl="$ctrl" ></cmp-catalog-version-uploading-files>
        </md-content>
    </div>
</div>