<div ng-if="$ctrl.fileUrl && !$ctrl.fileInfo.dir" flex layout="column" layout-align="center center" class="{{$ctrl.fileFormat}}Content">

    <div class="fileName">file {{$ctrl.fileUrl}}</div>
    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
    <div ng-switch="$ctrl.fileFormat"  ng-if="!$ctrl.bigFile" style="width: 100%;" flex class="content"  layout="column" layout-align="center center">
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        <div ng-switch-when="txt" ui-ace="{readOnly: true, onLoad: aceLoaded}" ng-model="$ctrl.file" flex style="height: 360px; width: 100%;"></div>
        <!--<img ng-switch-when="img" ng-if="$ctrl.file" src="data:{{$ctrl.fileContentType}};base64,{{$ctrl.file | arraybuffertobase64}}"  />-->
        <!--<img ng-switch-when="img" ng-if="$ctrl.file" src="data:{{$ctrl.fileContentType}};base64,{{$ctrl.file}}"  />-->
        <img ng-switch-when="img" src="{{$ctrl.downloadLink()}}"  />
        <div ng-switch-default class="desc">
            File not available
        </div>

    </div>

    <div ng-if="$ctrl.bigFile" class="content" flex  layout="column" layout-align="center center">
        <div class="desc">
            File is very big (<span ng-bind-html="$ctrl.ctrl.listController.CatalogService.datasetVersionSize($ctrl.ctrl.data)"></span>)
        </div>
    </div>

    <div layout="row" layout-align="center center">
        <a href="{{$ctrl.downloadLink(true)}}" target="_blank" class="md-button" >download</a>
    </div>

</div>