<md-dialog>
    <form ng-cloak name="saveTask" ng-submit="continue(saveTask)">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <div ng-switch="action">
                    <h2 ng-switch-when="save">Save</h2>
                    <h2 ng-switch-when="build">Save and execute task {{task.name}}</h2>
                </div>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="md-padding" layout="column">
                <h4 flex>{{'MLAPP_EXPERIMENT' | translate}} {{branch}}</h4>
                <div flex layout="row">
                    <md-input-container flex >
                        <label translate>COMMON_COMMENT</label>
                        <textarea ng-model="data.comment" flex ng-disabled="data.DoNotSave"></textarea>
                    </md-input-container>
                </div>
                <div flex>
                    <div flex layout="row" layout-align="start center">
                        <md-input-container flex="50">
                            <md-checkbox ng-model="createNew" ng-change="changeCreateNew(createNew)" style="margin-bottom: 0;">{{'MLAPP_EXPERIMENT_NEW' | translate}}</md-checkbox>
                        </md-input-container>
                        <md-input-container flex="50" style="width: 250px;">
                            <label>{{'MLAPP_EXPERIMENT' | translate}}</label>
                            <input ng-model="newBranch" name="newBranch" ng-disabled="!createNew" pattern="^[a-z0-9][a-z0-9-_]{1,33}[a-z0-9]+$">
                            <!--<input ng-model="newBranch" name="newBranch" ng-disabled="!createNew" >-->
                            <div ng-messages="saveTask.newBranch.$error" ng-messages-multiple="ng-messages-multiple"  class="error-messages slide-right">
                                <div ng-message="pattern" translate>FORM_ERROR_BRANCH_PATTERN</div>
                            </div>
                        </md-input-container>
                    </div>
                </div>
                <div flex ng-if="action != 'save' ">
                    <md-input-container>
                        <md-checkbox ng-model="data.DoNotSave" ng-disabled="createNew" >Without commit</md-checkbox>
                    </md-input-container>
                </div>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
            </div>
            <md-button ng-click="cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button type="submit" >
                {{action == 'save' ? 'COMMON_SAVE' : "COMMON_EXECUTE" | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
