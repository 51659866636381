<form name="newMlmodel" ng-submit="$ctrl.save(newMlmodel, $event)" flex>
    <md-card>
        <md-card-content layout="row" layout-wrap>
            <md-input-container flex="50">
                <label translate>COMMON_DISPLAYNAME</label>
                <input type="text" ng-model="$ctrl.data.DisplayName" required
                       ng-change="$ctrl.namepickup()"
                       ng-model-options='{ debounce: 1000 }'
                >
            </md-input-container>
            <md-input-container flex="50">
                <label translate>COMMON_IDENT</label>
                <input  ng-disabled="!$ctrl.isNewElement"
                        name="Name"
                        ng-model="$ctrl.data.Name"
                        ng-pattern="$ctrl.validate.namePattern"
                        ng-change="$ctrl.checkName(newMlmodel)"
                        ng-model-options='{ debounce: 1000 }'
                >
                <help-hint hint="chart.name"></help-hint>
                <div ng-messages="newMlmodel.Name.$error">
                    <!--<div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.nameErrorMessage" error="$ctrl.nameErrorMessage"></cmp-error-toast></div>-->
                    <div ng-message="errorname">{{'ERROR_TEXT_invalidName' | translate}}</div>
                    <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                    <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                </div>
            </md-input-container>
            <md-input-container flex="100">
                <label translate>COMMON_DESCRIPTION</label>
                <textarea ng-model="$ctrl.data.Description"></textarea>
            </md-input-container>
            <div flex="100" layout="column">
                <md-chips
                        ng-model="$ctrl.data.Keywords"
                        md-separator-keys="[$ctrl.$mdConstant.KEY_CODE.ENTER, $ctrl.$mdConstant.KEY_CODE.COMMA]"
                        placeholder="Comma separated keywords"
                        secondary-placeholder="Comma separated keywords"
                        md-add-on-blur="true"
                        md-enable-chip-edit="true"
                ></md-chips>

                <!--<cmp-autolist-single-->
                        <!--class="flex"-->
                        <!--data="$ctrl.data.Keywords"-->
                        <!--form="$ctrl.form"-->
                <!--&gt;</cmp-autolist-single>-->
            </div>

            <!--<md-input-container flex="50">-->
                <!--<label translate>COMMON_KEYWORDS</label>-->
                <!--<input type="text" ng-model="$ctrl.Keywords" >-->
            <!--</md-input-container>-->
            <md-input-container flex="50" ng-if="$ctrl.isNewElement">
                <md-checkbox ng-model="$ctrl.data.Published"><span translate>COMMON_PUBLISHED</span></md-checkbox>
            </md-input-container>



            <div ng-if="$ctrl.data.Interface == 'mlmodel' && !$ctrl.isNewElement">
                <div class="cmp-block-compact" ng-if="!$ctrl.data.Configuration.allowed_tabs || ($ctrl.data.Configuration.allowed_tabs && $ctrl.data.Configuration.allowed_tabs.edit)">
                    <h4 class="cmp-block-compact cmp-clickable" ng-click="$ctrl.fullConfiguration = !!!$ctrl.fullConfiguration">
                        {{"COMMON_CONFIGURATION" | translate}}
                        <md-icon md-svg-icon="core:chevron-down" ng-if="!$ctrl.fullConfiguration"></md-icon>
                        <md-icon md-svg-icon="core:chevron-up" ng-if="$ctrl.fullConfiguration"></md-icon>
                    </h4>
                    <div class="cmp-block-compact-content"  ng-if="$ctrl.fullConfiguration">
                        <cmp-serving-form-config data="$ctrl.data.Config.serving" serving="$ctrl.version.Config.serving" flex="100" is-model="true" model-spec="$ctrl.data.Config.servingSpec" ></cmp-serving-form-config>
                        <cmp-serving-form-spec data="$ctrl.data.Config.servingSpec" flex="100"></cmp-serving-form-spec>
                    </div>
                </div>
            </div>

        </md-card-content>
        <md-card-action flex layout="row" layout-align="space-between center">
            <div flex class="md-padding">
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-error-toast>
            </div>
            <div>
                <md-button type="submit" ng-disabled=" !$ctrl.canSave(newMlmodel) "><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
                <md-button ng-click="$ctrl.cancel()"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
            </div>
        </md-card-action>
        <cmp-api-error-service ng-if="$ctrl.savingError" error="$ctrl.savingError" format="text"></cmp-api-error-service>
    </md-card>
</form>
