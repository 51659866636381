
<cmp-catalog-version-view-item-full
        ng-class="{fullView : item.Version == $ctrl.version.Version}"
        list-controller="$ctrl"
        data="$ctrl.version"
        can-manage="$ctrl.canManage"
        ng-if="$ctrl.version"
>
</cmp-catalog-version-view-item-full>

<div class="versionHistory">
    <div flex layout="row" layout-align="start center">

        <h3 flex>History</h3>
        <md-button class="" ng-click="$ctrl.createNew()" ng-if="$ctrl.canManage">+ new version</md-button>
    </div>
    <cmp-catalog-version-view-item list-controller="$ctrl" ng-repeat="item in $ctrl.versions" data="item" can-manage="$ctrl.canManage" ng-if="item.Version">
    </cmp-catalog-version-view-item>
</div>
