<home-card-welcom-content>
    <h2><cmp-logo></cmp-logo></h2>
    <div class="content">
        <!--Your email has been confirmed. Wait for admin will accept your registration. Thank you!-->
        Your email has been confirmed. Thank you!
    </div>
</home-card-welcom-content>
<home-card-welcom-actions >
    <md-button ui-sref="home" class="md-raised md-primary">Sign In</md-button>
</home-card-welcom-actions>
