<layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
    <md-icon md-svg-icon="core:wsApplicationsList"></md-icon>
    <h3 flex>{{$ctrl.WS.DisplayName}} create project</h3>
    <!--<md-button ng-if="$ctrl.permissions.can('application.manage')" ui-sref="wsApplicationsAdd" class="md-raised md-primary">{{'MLAPPS_LIST_EMPTY_ACTION' | translate}}</md-button>-->
</layout-page-content-title>

<md-content class="md-padding">

    <div class="layout-padding layout-align-start layout-column flex">
        <!--<h4 translate>APPLICATION_CREATE_TAB_RECOMMENDED_TITLE</h4>-->
        <cmp-chart-recomended ws-id="$ctrl.$state.params.wsId"></cmp-chart-recomended>

        <div ng-if="false">
          <h4 translate>APPLICATION_INPORT_GIT</h4>
          <div>
              <!--<div ng-click="open = !open" class="applicationRepoImportBtn">Export application config from git-->
                  <!--<md-icon ng-if="!open" md-svg-icon="core:chevron-down"></md-icon>-->
                  <!--<md-icon ng-if="open" md-svg-icon="core:chevron-up"></md-icon>-->
              <!--</div>-->
              <md-card>
                  <md-card-content>
                      <cmp-repo-select repository-url="$ctrl.repo.RepositoryURL" repository-dir="$ctrl.repo.repositoryDir" ></cmp-repo-select>
                  </md-card-content>
                  <!--<cmp-repo url="$ctrl.repo.RepositoryURL" dir="$ctrl.repo.repositoryDir"></cmp-repo>-->
                  <md-card-action class="layout-row layout-align-start-center">
                      <div class="flex">
                          <md-progress-linear ng-if="$ctrl.scanning"></md-progress-linear>
                          <cmp-api-error-service ng-if="$ctrl.scanError" error="$ctrl.scanError"></cmp-api-error-service>
                      </div>
                      <md-button ng-click="$ctrl.scan($ctrl.repo)">Scan</md-button>
                  </md-card-action>
              </md-card>
          </div>
        </div>
    </div>

</md-content>
