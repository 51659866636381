<div layout="column" layout-align="start"  ng-if="$ctrl.data.exec_info" class="info">
    <div ng-if="$ctrl.paramsDoc">Documents: <span ng-repeat="v in $ctrl.paramsDoc" ><span data-ng-bind-html="v.value"></span><span ng-if="!$last">, </span></span></div>

    <div ng-repeat="v in $ctrl.paramsGlobal | orderBy: 'label' ">
        <b>{{v.label}}</b>:&nbsp;<span data-ng-bind-html="v.value"></span><span ng-if="!$last">; </span>
    </div>
    <div ng-if="$ctrl.hesAd">
        <div ng-repeat="( n, p ) in $ctrl.paramsAdvansed">

            <div class="cmp-block-compact-title cmp-clickable" ng-click="Additional[n] = !!!Additional[n]" layout="row"  layout-align="start center">
                <md-icon md-svg-icon="core:chevron-down" ng-if="!Additional[n]"></md-icon>
                <md-icon md-svg-icon="core:chevron-up" ng-if="Additional[n]"></md-icon>
                <div><b>{{n}}</b> group parameters</div>
                <div style="width: 2em"></div>
                <hr flex/>
            </div>
            <div style="padding-left: 24px" class="cmp-block-compact-content" ng-if="Additional[n]">
                <div ng-repeat="v in p  | orderBy: 'label' ">
                    <b>{{v.label}}</b>:&nbsp;<span data-ng-bind-html="v.value"></span><span ng-if="!$last">; </span>
                </div>
            </div>
        </div>
    </div>
</div>
