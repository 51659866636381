

<div class="paddingListItemTabContent md-3-line cmp-clickable" layout="row" layout-align="start center">
    <div flex layout="row" layout-align="start center" ui-sref="wsServing.tab({servingName: $ctrl.data.Name})">
        <item-status-icon data="$ctrl.data" class="width-64"></item-status-icon>
        <div class="md-list-item-text flex" layout="row" layout-align="space-between center"><!--serving: {{$ctrl.data}}-->
            <div>
                <h4>{{$ctrl.ServingService.Name($ctrl.data)}}
                    <span style="font-weight: normal" ng-if="$ctrl.data.UserDisplayName">( {{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.UserDisplayName}} )</span>
                </h4>
                <div ng-include="'kuberlab/serving/card/serving_link_model.html'"></div>
            </div>
            <div>
                <cmp-application-cluster-link data="$ctrl.data"></cmp-application-cluster-link>
            </div>
        </div>
    </div>
    <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
        <div class="md-secondary" ng-include="'kuberlab/serving/card/serving_menu.html'"></div>
    </md-menu-bar>
</div>
