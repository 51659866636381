<md-card class="chartCardSmall">
    <md-card-title>
        <md-card-title-media>
            <div class="md-media-sm card-media" layout style='background: url("{{item.Picture}}") center center no-repeat; background-size: contain;'>
                <!--<img src="{{item.Picture}}" alt="item.Name">-->
            </div>
        </md-card-title-media>
        <md-card-title-text>
            <a class="md-headline chartTitle" >{{item.DisplayName}}</a>
            <span class="md-subhead" ng-if="item.Type == 'mlapp'">(is mlapp)</span>
            <span class="md-subhead chartVersion">latest version: {{item.Version}}</span>
            <span class="md-subhead chartVersion" ng-if="!$ctrl.rec">owner: {{item.WorkspaceDisplayName}}</span>
            <span class="md-subhead" ng-if="!$ctrl.isWs">
                <a ui-sref="workspace({wsId : item.WorkspaceName})" class="chartWS">
                    <md-icon md-svg-icon="core:group" ng-class="md-avatar-icon" aria-hidden="true"></md-icon>{{item.WorkspaceDisplayName}}
                </a>
            </span>
            <p ng-if="item.Description"  class="chartDescription">{{item.Description}}</p>
        </md-card-title-text>

    </md-card-title>

    <md-card-actions layout="row" layout-align="space-between stretch">
      <md-button class="md-raised md-primary md-button" flex ng-click="$ctrl.Install($event, item)">
        <md-icon md-svg-icon="core:install"></md-icon>
        Install
      </md-button>
    </md-card-actions>
</md-card>
