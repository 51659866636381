<div>
    <div ng-if="!$ctrl.onlyData" class="layout-row layout-align-start-start">
        <md-input-container class="flex-33">
            <label translate>COMMON_NAME</label>
            <input ng-model="$ctrl.data.name" name="volumeName" required min="3" ng-disabled="$ctrl.name" ng-pattern="'^[a-z0-9][-a-z0-9]{0,31}[a-z0-9]$'">
            <div ng-messages="$ctrl.form.volumeName.$error">
                <div ng-message="pattern">Invalid volume name: ‘{{$ctrl.form.volumeName.$viewValue}}’. Valid name must be 33 characters or less and must begin and end with an lower case alphanumeric character ([a-z0-9]) with dashes (-) and lower case alphanumerics between</div>
            </div>
        </md-input-container>
        <md-input-container class="flex-33">
            <label>Sub Path</label>
            <input ng-model="$ctrl.data.subPath" type="text">
        </md-input-container>
        <md-input-container class="flex-33">
            <label>MountPath</label>
            <input ng-model="$ctrl.data.mountPath" type="text" >
        </md-input-container>
        <!--<md-input-container class="flex-33">-->
        <!--<label>Lib path</label>-->
        <!--<md-checkbox ng-model="$ctrl.data.IsLibDir" type="text" ></md-checkbox>-->
        <!--</md-input-container>-->
    </div>
    <!--{{$ctrl.types}}-->
    <div class="layout-row layout-align-start-start">
        <md-input-container class="flex">
            <label translate>COMMON_TYPE</label>
            <md-select ng-model="$ctrl.cType" ng-change="$ctrl.changeType()" required>
                <md-option ng-value="key" ng-repeat="(key, item) in $ctrl.types" >{{item | translate}}</md-option>
            </md-select>
        </md-input-container>
    </div>

    <div ng-switch="$ctrl.cType" class="flex">
        <cmp-storage-nfs
                ng-switch-when="nfs"
                data="$ctrl.data.nfs"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-nfs>
        <cmp-storage-pvc
                ng-switch-when="persistentVolumeClaim"
                data="$ctrl.data.persistentVolumeClaim"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-pvc>
        <cmp-storage-git
                ng-switch-when="gitRepo"
                data="$ctrl.data.gitRepo"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"
        ></cmp-storage-git>
        <cmp-storage-host
                ng-switch-when="hostPath"
                data="$ctrl.data.hostPath"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-host>
        <cmp-storage-cluster
                ng-switch-when="clusterStorage"
                data="$ctrl.data.clusterStorage"
                cluster-storage="$ctrl.clusterStorages"
                cluster-id="$ctrl.clusterId"
                workspace-name="$ctrl.workspaceName"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-cluster>
        <cmp-storage-s3bucket
                ng-switch-when="s3bucket"
                data="$ctrl.data.s3bucket"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-s3bucket>
        <cmp-storage-persistent
                ng-switch-when="persistentStorage"
                data="$ctrl.data.persistentStorage"
                class="layout-row layout-align-start-start"
                required="$ctrl.required"></cmp-storage-persistent>
        <cmp-storage-dataset
                ng-switch-when="dataset"
                data="$ctrl.data.dataset"
                class="layout-row layout-align-start-start"
                workspace-name="$ctrl.workspaceName"
                required="$ctrl.required"></cmp-storage-dataset>
        <cmp-storage-datasetfs
                ng-switch-when="datasetFS"
                data="$ctrl.data.datasetFS"
                class="layout-row layout-align-start-start"
                workspace-name="$ctrl.workspaceName"
                required="$ctrl.required"></cmp-storage-datasetfs>
        <cmp-storage-model
                ng-switch-when="model"
                data="$ctrl.data.model"
                class="layout-row layout-align-start-start"
                workspace-name="$ctrl.workspaceName"
                required="$ctrl.required"></cmp-storage-model>
        <cmp-storage-model
                ng-switch-when="emptyDir"
                data="$ctrl.data.emptyDir"
                class="layout-row layout-align-start-start"
                workspace-name="$ctrl.workspaceName"
                required="$ctrl.required"></cmp-storage-model>
    </div>
</div>