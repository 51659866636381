<div class="item layout-column layout-align-center-center ">
    <div class="value">{{!$ctrl.data.Stats.cpu_usage.Value ? 0 : ( $ctrl.data.Stats.cpu_usage.Value > 1000 ? $ctrl.data.Stats.cpu_usage.Value.toFixed(0) : $ctrl.data.Stats.cpu_usage.Value.toFixed(2) ) }}</div>
    <div class="legend" >{{'MLAPP_METRIC_cpu_usage' | translate}} {{$ctrl.data.Stats.cpu_usage.Measure}}</div>
</div>
<div class="item layout-column layout-align-center-center ">
    <div class="value">{{!$ctrl.data.Stats.gpu_usage.Value ? 0  : ( $ctrl.data.Stats.gpu_usage.Value > 1000 ? $ctrl.data.Stats.gpu_usage.Value.toFixed(0) : $ctrl.data.Stats.gpu_usage.Value.toFixed(2) ) }}</div>
    <div class="legend" >{{'MLAPP_METRIC_gpu_usage' | translate}} {{$ctrl.data.Stats.gpu_usage.Measure}}</div>
</div>
<div class="item layout-column layout-align-center-center ">
    <div class="value">{{!$ctrl.data.Stats.memory_bytes.Value ? 0 : ( $ctrl.data.Stats.memory_bytes.Value > 1000 ? $ctrl.data.Stats.memory_bytes.Value.toFixed(0) : $ctrl.data.Stats.memory_bytes.Value.toFixed(2) ) }}</div>
    <div class="legend" >{{'MLAPP_METRIC_memory_bytes' | translate}} {{$ctrl.data.Stats.memory_bytes.Measure}}</div>
</div>
