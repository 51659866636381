<!--{{$ctrl.data}}-->
<md-list-item class="md-2-line" ng-if="!$ctrl.asForm">
    <md-icon md-svg-icon="core:{{$ctrl.Type}}" class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text">
        <div layout="row" layout-align="start center"><h4>{{$ctrl.data.name}}</h4><span ng-if="$ctrl.data.readOnly"> read only</span></div>
        <p><cmp-storage-view data="$ctrl.data"></cmp-storage-view></p>
    </div>
    <md-button class="md-secondary md-icon-button" ng-click="$ctrl.edit()"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
    <md-button class="md-secondary md-icon-button" ng-click="$ctrl.delete()"><md-icon md-svg-icon="core:delete"></md-icon></md-button>
</md-list-item>

<md-list-item ng-if="$ctrl.asForm">
    <form name='add' ng-submit="$ctrl.save(add, $ctrl.newSource)" class="flex">
        <md-card>
            <md-card-content>
                <cmp-storage data="$ctrl.data" ></cmp-storage>
            </md-card-content>
            <md-card-actions class="layout-row layout-align-start-center">
                <div class="flex">
                    <!--<md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>-->
                    <!--<cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage" class="flex"></cmp-api-error-service>-->
                </div>
                <md-button ng-if="$ctrl.isNewElement" class="md-raised md-primary">Add</md-button>
                <md-button ng-if="!$ctrl.isNewElement" ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button ng-if="!$ctrl.isNewElement" class="md-raised md-primary">save</md-button>
            </md-card-actions>
        </md-card>
    </form>
</md-list-item>
