<div class="flex"  ng-if="!$ctrl.application.SharedClusterID && !$ctrl.application.GlobalClusterID">
    <md-icon md-svg-icon="core:link"></md-icon> {{"COMMON_CLUSTER" | translate}}:
    <a ui-sref="wsProject({projectName: $ctrl.application.ProjectName})" >{{$ctrl.application.ProjectDisplayName}}</a>/
    <a ui-sref="wsProject({projectName: $ctrl.application.ProjectName, envName:$ctrl.application.Environment })" >{{$ctrl.application.Environment}}</a>/
    <a ui-sref="wsProject.tab({projectName: $ctrl.application.ProjectName, envName:$ctrl.application.Environment, tab: 'clusters', tabItem: $ctrl.application.ClusterName })" >{{$ctrl.application.ClusterName}}</a>
</div>
<div class="flex" ng-if="$ctrl.application.SharedClusterID">
    <md-button ui-sref="sharedCluster.tab({tab: 'available', tabItem: $ctrl.application.SharedClusterID })" class="link">
        <md-icon md-svg-icon="core:share"></md-icon>
        Shared cluster: {{$ctrl.application.SharedClusterName}}
    </md-button>
</div>
<div class="flex" ng-if="$ctrl.application.GlobalClusterID">
    <md-button class="link" ui-sref="sharedCluster.tab({tab: 'available', tabItem: $ctrl.application.GlobalClusterID })" >
        <md-icon md-svg-icon="core:share"></md-icon>
        Global cluster: {{$ctrl.application.GlobalClusterName}}
    </md-button>
</div>