<md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
<!--<form class="newFolder" name="newFolder" ng-submit="$ctrl.saveFolderCreating(newFolder)" ng-if="$ctrl.edit" flex layout="row" layout-align="start center">-->
    <!--<md-icon></md-icon>-->
    <!--<md-input-container><input name="Name" ng-change="$ctrl.newFolderChange(newFolder)" ng-model="$ctrl.newFolderCreating.name" flex></md-input-container>-->
    <!--<md-button type="submit" class="md-icon-button" ><md-icon md-svg-icon="core:check"></md-icon></md-button>-->
    <!--<md-button ng-click="$ctrl.resetNewFolder()" class="md-icon-button"><md-icon md-svg-icon="core:close"></md-icon></md-button>-->
<!--</form>-->

<form class="newFolder" name="newFolder" ng-submit="$ctrl.saveFolderCreating(newFolder)" ng-if="$ctrl.newFolderCreating" flex layout="row" layout-align="start center">
    <md-icon></md-icon>
    <md-input-container><input name="Name" ng-change="$ctrl.newFolderChange(newFolder)" ng-model="$ctrl.newFolderCreating.name" flex></md-input-container>
    <md-button type="submit" class="md-icon-button" ><md-icon md-svg-icon="core:check"></md-icon></md-button>
    <md-button ng-click="$ctrl.resetNewFolder()" class="md-icon-button"><md-icon md-svg-icon="core:close"></md-icon></md-button>
</form>


<ul  ng-show="$ctrl.isOpen">
    <li ng-repeat="item in $ctrl.chield | orderBy:['new', '-dir','name'] | limitTo : $ctrl.limit " ng-class="{error: item.error, selected: $ctrl.ctrl.fileInfo == item}">
        <div flex layout="row" layout-align="start center" class="title">
            <a ng-click="$ctrl.selectItem(item)" flex class="cmp-clickable" layout="row" layout-align="start start">
                <md-icon md-svg-icon="core:{{item.api.isOpen ? 'menu-down': 'menu-right'}}" ng-if="item.dir"></md-icon>
                <md-icon ng-if="!item.dir"></md-icon>
                <md-icon md-svg-icon="core:tree-{{item.dir ? 'folder': 'file'}}"></md-icon>
                <div flex class="fileName"><span ng-class="{newFolder: item.new}">{{item.name}}</span></div>
                <div ng-if="!item.dir" style="    font-size: 12px; display: block;  padding: 6px 0 0 1em    ;" ng-bind-html="item.sSize" ></div>
            </a>
            <md-progress-circular ng-if="$ctrl.deleting.indexOf(item.name) != -1" class="md-hue-2" md-diameter="16px" style="margin: 0 20px"></md-progress-circular>
            <!--<md-button ng-if="$ctrl.deleting.indexOf(item.name) == -1" ng-click="$ctrl.delete(item)" class="md-icon-button" ng-if="$ctrl.edit"><md-icon md-svg-icon="core:delete"></md-icon></md-button>-->
            <md-button ng-click="$ctrl.delete(item)" class="md-icon-button" ng-if="$ctrl.edit"><md-icon md-svg-icon="core:delete"></md-icon></md-button>
        </div>

        <cmp-catalog-version-tree
                ng-if="item.dir"
                data="item"
                path="$ctrl.path ? $ctrl.path + '/' + item.name : item.name "
                ctrl="$ctrl.ctrl"
                api="item.api"
                edit="$ctrl.edit"
                list-controller="$ctrl.listController"
                resource="$ctrl.resource"
                tree-filter="$ctrl.treeFilter"
        ></cmp-catalog-version-tree>
    </li>
    <li ng-if="$ctrl.hasMore()" ng-click="$ctrl.more() " class="cmp-clickable" style="text-align: center">more</li>
</ul>
