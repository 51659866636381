<md-card class="flex layout-row layout-align-space-between-center">
    <md-card-content>
        <p data-ng-bind-html="'COMMON_LIST_EMPTY' | translate" ></p>
    </md-card-content>
    <md-button ng-if="$ctrl.isWs && $ctrl.permissions.can('mlapp.manage')" ui-sref="{{$ctrl.stateNew}}" class="md-raised md-primary">{{'COMMON_LIST_EMPTY_ACTION' | translate}}</md-button>
    <!--<md-menu  ng-if="!$ctrl.isWs && $ctrl.ws && $ctrl.ws.list > 1" md-position-mode="target-right target" >-->
        <!--<button ng-click="$mdMenu.open()">-->
            <!--{{'MLMODEL_LIST_EMPTY_ACTION' | translate}}-->
        <!--</button>-->
        <!--<md-menu-content class="cmp-user-card-header">-->
            <!--<md-menu-item ng-repeat="data in $ctrl.ws">-->
                <!--<md-button ui-sref="wsChartAdd({wsId: data.Name})" class="no-padding">-->
                    <!--<span class="label">{{data.DisplayName}}</span>-->
                <!--</md-button>-->
            <!--</md-menu-item>-->
        <!--</md-menu-content>-->
    <!--</md-menu>-->
</md-card>
