<!--<div  style="min-height: 450px">-->
    <!--<div ng-if="!$ctrl.asForm && $ctrl.data.Version">-->
        <!--<div layout="row" layout-align="start center" flex class="title" >-->
            <!--<div class=""  layout="row" layout-align="start center" flex >-->
                <!--<div flex layout="row" layout-align="start center">-->
                    <!--<h4 >Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</h4>-->
                    <!--<md-button class="md-raised" ng-click="$ctrl.listController.createNew($ctrl.data)" ng-if="$ctrl.canManage" >Clone version</md-button>-->
                <!--</div>-->
                <!--<md-button ng-if="$ctrl.data && $ctrl.data.Status == 'error'  "  class="md-warn"><md-tooltip md-direction="left">{{$ctrl.data.StatusError}}</md-tooltip>Error</md-button>-->
                <!--<md-button ng-disabled="true" ng-if="$ctrl.data && $ctrl.data.Status == 'uploading'  "  class="md-warn">{{$ctrl.data.Status}}</md-button>-->
                <!--<md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>-->
                <!--<div>{{$ctrl.data.Updated | date: 'dd/MM/yyyy HH:mm'}}</div>-->
            <!--</div>-->
            <!--<md-button ng-click="$ctrl.commit($event)" class="md-raised" ng-if="$ctrl.data.Editing && $ctrl.canManage">commit</md-button>-->

            <!--<div ng-include="'kuberlab/mlmodel/version/menu-item.html'"></div>-->
        <!--</div>-->
        <!--<div layout="row" layout-align="end center">-->
            <!--<md-button ng-click="$ctrl.listController.serveDialog($event, $ctrl.data)" class="md-raised"  ng-if="!$ctrl.data.Editing && $ctrl.data.Status =='ok'" >{{"COMMON_SERVE" | translate}}</md-button>-->
            <!--<md-button href="{{$ctrl.data.DownloadURL}}" target="_blank" download ng-if="$ctrl.data.DownloadURL" class="md-raised" >-->
                <!--<md-icon md-svg-icon="core:link"></md-icon>{{"COMMON_DOWNLOAD" | translate}}<span ng-if="$ctrl.data.SizeBytes"> (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)</span>-->
            <!--</md-button>-->
        <!--</div>-->
        <!--<div class="versionMessage" ng-if="$ctrl.data.Message">{{$ctrl.data.Message}}</div>-->

        <!--<md-tabs md-dynamic-height md-border-bottom ng-if="$ctrl.data.Version && $ctrl.data.Version == $ctrl.listController.version.Version">-->
            <!--<md-tab label="dir">-->
                <!--<md-content  ng-include="'kuberlab/mlmodel/version/dir.html'"></md-content>-->
            <!--</md-tab>-->
            <!--<md-tab label="config">-->
                <!--<md-content  ng-include="'kuberlab/mlmodel/version/info.html'"></md-content>-->
            <!--</md-tab>-->
        <!--</md-tabs>-->

        <!--<div ng-if="$ctrl.uploadFullFile" style="height: 450px" >-->
            <!--<div  class="setNewVersion" layout="row" layout-align="center center">-->
                <!--<md-card>-->
                    <!--<div flex layout="row" layout-align="start center" class="md-padding">-->
                        <!--<div flex>upload model: </div> <cmp-catalog-version-uploading-files  ng-repeat="f in $ctrl.uploadingFiles" data="f" ctrl="$ctrl" action="'upload'" file-param="'model'" ></cmp-catalog-version-uploading-files>-->
                    <!--</div>-->
                <!--</md-card>-->
            <!--</div>-->
        <!--</div>-->

    <!--</div>-->

    <!--<div ng-if="$ctrl.asForm" flex class="editForm">-->
        <!--<div layout="row" layout-align="start" flex >-->
            <!--<div flex="50">-->
                <!--<md-input-container>-->
                    <!--<label>Version</label>-->
                    <!--<input ng-model="$ctrl.data.Version" ng-disable="true">-->
                <!--</md-input-container>-->
            <!--</div>-->
            <!--<div flex="50">-->
                <!--<md-content flex>-->
                    <!--<cmp-dataset-version-tree ng-if="$ctrl.data.Version" ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" list-controller="$ctrl.listController" edit="true"></cmp-dataset-version-tree>-->
                <!--</md-content>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
<div>
    <div ng-if="!$ctrl.asForm">
        <div layout="row" layout-align="start center" flex class="title" ng-if="$ctrl.data.Version">
            <div class=""  layout="column" layout-align="start start" flex >
                <!--<div class="cmp-clickable"  layout="row" layout-align="start center" flex ui-sref="wsCatalogDataItem($ctrl.listData)" >-->
                <div flex layout="row" layout-align="start center">
                    <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn small">Editing</md-button>
                    <h4 >{{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}} </h4>
                    <div class="small" style="padding: 0 1em;">{{$ctrl.data.Updated | timePassed}}</div>
                </div>
            </div>
            <div >
                <md-button ng-if="$ctrl.canManage" class="md-raised small" ng-click="$ctrl.listController.createNew($ctrl.data)" ><md-icon md-svg-icon="core:copy"></md-icon>clone</md-button>
                <md-button ng-if="$ctrl.data.Editing && $ctrl.canManage" class="md-raised small" ng-click="$ctrl.commit()" ><md-icon md-svg-icon="core:commit"></md-icon>commit</md-button>
                <md-button ng-if="!$ctrl.data.Editing && $ctrl.data.Status =='ok'"  ng-click="$ctrl.listController.serveDialog($event, $ctrl.data)" class="md-raised small"  >{{"COMMON_SERVE" | translate}}</md-button>
            </div>
            <div ng-include="'kuberlab/catalog/base/version-view/menu-item.html'"></div>
        </div>

        <div class="versionMessage" ng-if="$ctrl.data.Message" style="padding-bottom: 1em">{{$ctrl.data.Message}}</div>

        <div ng-if="$ctrl.data.Version">
            <md-tabs md-dynamic-height md-border-bottom ng-if="$ctrl.data.Version && $ctrl.data.Version == $ctrl.listController.version.Version">
                <md-tab label="dir">
                    <!--<md-content class="md-padding" ng-include="'kuberlab/mlmodel/version/dir.html'"></md-content>-->
                    <md-content style="padding: 2px">
                        <cmp-catalog-version-tree-v2 ctrl="$ctrl" resource="$ctrl.listController.resource" api="$ctrl.treeApi" edit="$ctrl.data.Editing"></cmp-catalog-version-tree-v2>
                    </md-content>
                </md-tab>
                <md-tab label="config">
                    <md-content  ng-include="'kuberlab/mlmodel/version/info.html'"></md-content>
                </md-tab>
            </md-tabs>
            <!--<cmp-catalog-version-tree-v2 ctrl="$ctrl" resource="$ctrl.listController.resource" api="$ctrl.treeApi" edit="$ctrl.data.Editing"></cmp-catalog-version-tree-v2>-->
        </div>
    </div>
<style>
    .setNewVersion cmp-catalog-version-uploading-files md-content {display: none;}
</style>

    <form ng-if="$ctrl.data.isNew" ng-submit="$ctrl.saveNew(nameVersion)" name="nameVersion" class="setNewVersion" flex layout="column" layout-align="center center" >
        <md-card layout="column" layout-align="canter" class="md-padding" style="width: 400px">
            <h4 style="text-align:center">Create new version</h4>
            <p ng-if="$ctrl.data.From" style="text-align:center" >From version {{$ctrl.data.From}}</p>
            <md-input-container>
                <label>Set new version</label>
                <input ng-model="$ctrl.newVersion" required />
            </md-input-container>

            <div flex layout="row" layout-align="start" ng-if="!$ctrl.data.From">
                <input type="file" name="file" id="file" ng-model="$ctrl.fullFile" class="md-button"  ng-disabled="$ctrl.data.From" accept=".tar, .gz" />
                <label for="file">archive (tar or tar.gz)</label>
            </div>
            <md-input-container>
                <label translate>COMMON_MESSAGE</label>
                <textarea flex ng-model="$ctrl.data.Message" style="max-height: 200px;"></textarea>
            </md-input-container>

            <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>

            <div layout="row" layout-align="center">
                <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button type="submit">create</md-button>
            </div>
        </md-card>
    </form>

</div>
