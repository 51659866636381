<md-list-item class="paddingListItemTabContent md-3-line" ui-sref="wsMlapp({mlappName: $ctrl.data.Name})">
    <md-icon md-svg-icon="core:mlapp"  class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text flex">
        <h4 >{{$ctrl.data.DisplayName}} ({{$ctrl.data.Name}})</h4>
        <p class="description" ng-if="$ctrl.data.Description">{{ $ctrl.data.Description }}</p>
    </div>
    <md-button ui-sref="wsProject({projectName: $ctrl.data.ProjectName})" class="link md-secondary"><md-icon md-svg-icon="core:link"></md-icon> {{$ctrl.data.ProjectDisplayName}}</md-button>
    <md-button ng-click="$ctrl.enabled()"  class="md-secondary" ng-if="$ctrl.data.Enabled" translate>{{'COMMON_DISABLED'}}</md-button>
    <div class="md-secondary" ng-include="'kuberlab/mlapps/card/mlapps_menu.html'"></div>
</md-list-item>
