
<md-progress-linear ng-if="$ctrl.isLoading" md-mode="indeterminate" flex></md-progress-linear>
<cmp-api-error-service  ng-if="$ctrl.DeployError" error="$ctrl.DeployError" format="'dialog'"></cmp-api-error-service>
<!--<div flex class="applicationInfo" ng-if="!$ctrl.loadingError ">-->
<div flex class="applicationInfo" >
    <cmp-error-toast  ng-if="$ctrl.loadingError" error="$ctrl.loadingError"></cmp-error-toast>
    <div layout="row" layout-align="end center" ng-if="$ctrl.permissions.can('project.manage') && !$ctrl.loadingError">
        <!--<div class="errorInfo">{{$ctrl.DeployError}}</div>-->
        <div class="flex"></div>
        <md-button ng-click="$ctrl.installChart($event)">
            <md-icon md-svg-icon="core:play"></md-icon>
            install
        </md-button>
        <md-button ng-click="$ctrl.deploy()">
            <md-icon md-svg-icon="core:play"></md-icon>
            deploy
        </md-button>
    </div>
    <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.yaml"></div>
    <!--<div ng-jsoneditor="onLoad" ng-model="$ctrl.data" options="$ctrl.editorOptions" ></div>-->

    <div ng-if="$ctrl.dataModefy && $ctrl.permissions.can('project.manage')" class="layout-row layout-align-start-center">
        <div class="flex"></div>
        <md-button ng-click="$ctrl.updateData()">Save</md-button>
        <md-button ng-click="$ctrl.getData()">Cancel</md-button>
    </div>


    <form ng-if="!$ctrl.dataModefy && $ctrl.permissions.can('project.manage')" name="uploadYaml" ng-submit="$ctrl.uploadFile(uploadYaml)" layout="row" layout-align="center center">
        <lf-ng-md-file-input lf-files="$ctrl.file" flex></lf-ng-md-file-input>
        <md-button type="submite">upload</md-button>
    </form>

</div>

