<!--<md-list>-->
    <!--<cmp-applications-sources-item-->
            <!--ng-repeat="item in $ctrl.application.Sources"-->
            <!--data="item",-->
            <!--application="$ctrl.application"-->
            <!--list-controller="$ctrl"-->
            <!--&gt;</cmp-applications-sources-item>-->
    <!--<cmp-applications-sources-item-->
            <!--data="$ctrl.Source"-->
            <!--application="$ctrl.application"-->
            <!--list-controller="$ctrl"-->
            <!--is-new-element="true"-->
    <!--&gt;</cmp-applications-sources-item>-->
<!--</md-list>-->



<cmp-applications-volumes application="$ctrl.application"></cmp-applications-volumes>
<div class="layout-row layout-align-start-center">
    <div class="flex"></div>
    <md-button ng-click="$ctrl.save()">Save</md-button>
</div>

<cmp-api-error-service ng-if="$ctrl.updateError" error="$ctrl.updateError" format="'dialog'"></cmp-api-error-service>
