<!--<div class="projectItemStatus">-->
    <!--<md-icon md-svg-icon="{{$ctrl.data.GPUUsed ? 'core:applicationGPU' : 'core:application'}}"  class="md-avatar-icon status {{$ctrl.data.Health}}" ></md-icon>-->
    <!--<div class="ContainersCount">{{$ctrl.data.ContainersCount}}<span ng-if="$ctrl.data.RunningTasks">/{{$ctrl.data.RunningTasks}}</span></div>-->
    <!--<div style="-->
        <!--position: absolute;-->
        <!--bottom: 5px;-->
        <!--left: 5px;-->
        <!--width: 10px;-->
        <!--height: 10px;-->
        <!--background-color: red;"-->
         <!--class="blink" ng-if="$ctrl.data.RunningTasks" ></div>-->
    <!--<md-tooltip>Health: {{$ctrl.data.Health}}. Containers: {{$ctrl.data.ContainersCount}}. <span ng-if="$ctrl.data.RunningTasks">Running task: {{$ctrl.data.RunningTasks}}.</span> <span ng-if="$ctrl.data.GPUUsed">GPU usage: {{$ctrl.data.GPUUsed}}.</span></md-tooltip>-->
<!--</div>-->

<md-button class="projectStatus {{$ctrl.data.Health}}" >
    <md-icon md-svg-icon="core:status{{$ctrl.data.Health}}" class="status {{$ctrl.data.Health}}" ></md-icon>
    <span>{{$ctrl.data.ContainersCount}}|{{$ctrl.data.RunningTasks}}</span>
    <span ng-if="$ctrl.data.GPUUsed">G</span>
    <md-tooltip>Health: {{$ctrl.data.Health}}. Containers: {{$ctrl.data.ContainersCount}}. <span ng-if="$ctrl.data.RunningTasks">Running task: {{$ctrl.data.RunningTasks}}.</span> <span ng-if="$ctrl.data.GPUUsed">GPU usage: {{$ctrl.data.GPUUsed}}.</span></md-tooltip>
</md-button>
