<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak name="serve" ng-submit="save()">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2 translate>COMMON_SERVING</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="layout-row layout-align-start-top layout-wrap md-padding"   >
                <cmp-mlmodel-serving-form data="$ctrl.data.Config" flex="100"></cmp-mlmodel-serving-form>
                <!--<cmp-mlmodel-serving-spec data="$ctrl.data.Config.spec" flex="100"></cmp-mlmodel-serving-spec>-->
            </div>
        </md-dialog-content>

        <md-dialog-actions flex layout="column" >
            <div>
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            </div>
            <div>
                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button type="submit">
                    {{"COMMON_SERVE" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
