<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak name="serve" ng-submit="$ctrl.serve(serve)">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2 translate>COMMON_SERVING</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="layout-row layout-align-start-top layout-wrap md-padding"   >
                <md-input-container flex="33">
                    <label translate>COMMON_WORKSPACE</label>
                    <md-select ng-model="$ctrl.serveData.WorkspaceName" ng-change="$ctrl.setWs($ctrl.serveData.WorkspaceName)" required>
                        <md-option ng-repeat="item in $ctrl.wsList" ng-value="item.Name">{{item.DisplayName}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="33">
                    <label translate>COMMON_CLUSTER</label>
                    <md-select ng-model="$ctrl.serveData.ClusterID" mg-disabled="!$ctrl.clusters" required >
                        <md-option ng-repeat="item in $ctrl.clusters" ng-value="item.ClusterID"><cmp-cluster-name cluster="item"></cmp-cluster-name></md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="33">
                    <label translate>COMMON_NAME</label>
                    <input type="text" ng-model="$ctrl.serveData.Name" required>
                </md-input-container>

                <cmp-mlmodel-serving-form data="$ctrl.serveData.Config" flex="100"></cmp-mlmodel-serving-form>


            </div>
        </md-dialog-content>

        <md-dialog-actions flex layout="column" >
            <div>
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            </div>
            <div>
                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button type="submit">
                    {{"COMMON_SERVE" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
