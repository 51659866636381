<div ng-if="($ctrl.isNew || $ctrl.isEdit) && $ctrl.listController" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, .4); z-index: 2 ">
    <md-card ng-if="$ctrl.isNew && $ctrl.listController">
        <div class="md-padding">
            <div flex layout="row" layout-align="start center">
                <md-input-container flex>
                    <label>Name new resource</label>
                    <input name="nameResource" ng-model="$ctrl.data.name" ng-pattern="$ctrl.listController.validatePattern.nameTaskPattern" required>
                    <div ng-messages="$ctrl.form.nameResource.$error">
                        <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                    </div>
                </md-input-container>
                <md-button ng-click="$ctrl.listController.cancelAddResource()" ng-disabled="!$ctrl.listController.task.resources.length">cancel</md-button>
                <md-button ng-click="$ctrl.listController.addResource()" ng-disabled="!$ctrl.form.nameResource.$valid" class="md-raised md-primary" >add</md-button>
            </div>
        </div>
    </md-card>
    <md-card ng-if="$ctrl.isEdit && $ctrl.listController">
        <div class="md-padding">
            <div flex layout="row" layout-align="start center">
                <md-input-container flex>
                    <label>Rename resource</label>
                    <input name="nameResource" ng-model="newName" ng-value="$ctrl.data.name" ng-pattern="$ctrl.listController.validatePattern.nameTaskPattern" required>
                    <div ng-messages="$ctrl.form.nameResource.$error">
                        <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                    </div>
                </md-input-container>
                <md-button ng-click="$ctrl.isEdit = false">cancel</md-button>
                <md-button ng-click="$ctrl.rename(newName)" ng-disabled="!$ctrl.form.nameResource.$valid" class="md-raised md-primary">ok</md-button>
            </div>
        </div>
    </md-card>
</div>


<div class="cmp-block-compact">
    <div flex layout="row" layout-align="start center">
        <h5 flex class="cmp-block-compact-title">{{"APPLICATION_RESOURCE" | translate}} {{$ctrl.data.name}}</h5>
        <cmp-template-select type="$ctrl.isServe ? 'serving' : 'task'" ng-model="$ctrl.data"></cmp-template-select>
        <md-menu class="md-secondary" md-position-mode="target-right target" >
            <md-button class="md-icon-button" ng-click="$mdMenu.open()" ng-disabled="$ctrl.isNew">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <md-menu-item>
                    <md-button ng-click="$ctrl.startEdit()">
                        <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                    </md-button>
                </md-menu-item>
                <md-menu-item>
                    <md-button ng-click="$ctrl.copy()">
                        <md-icon md-svg-icon="core:plus"></md-icon> copy
                    </md-button>
                </md-menu-item><md-menu-item>
                    <md-button ng-click="$ctrl.delete()">
                        <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
    <div class="cmp-block-compact-content">

        <div class="layout-row layout-align-start-start layout-wrap">
            <md-input-container class="flex-100">
                <label translate>MLAPP_EXEC_DIR</label>
                <input type="text" ng-model="$ctrl.data.workDir">
            </md-input-container>
            <md-input-container flex="100">
                <label translate>MLAPP_CMD</label>
                <textarea ng-model="$ctrl.data.command" ></textarea>
            </md-input-container>

            <md-input-container class="flex" >
                <label>Node allocator</label>
                <md-select ng-model="$ctrl.data.nodes" ng-disabled="!$ctrl.Allocatednodes || $ctrl.Allocatednodes.length == 0">
                    <md-option ng-value="item" ng-repeat="item in $ctrl.Allocatednodes">{{item}}</md-option>
                </md-select>
            </md-input-container>
        </div>



        <div class="task-resource-env cmp-block-compact">
            <h5 class="cmp-block-compact-title" >{{"MLAPP_RESOURCES" | translate}}
                <!--<cmp-docs-link doc="'ml_models/task_resource'" title="'task resource'"></cmp-docs-link>-->
            </h5>
            <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
                <md-input-container class="flex-50" >
                    <label>CPU (<span>m</span>)</label>
                    <input type="text" ng-model="$ctrl.data.resources.requests.cpu" />
                </md-input-container>
                <md-input-container class="flex-50" >
                    <label>CPU Limit (<span>m</span>)</label>
                    <input type="text" ng-model="$ctrl.data.resources.limits.cpu" />
                </md-input-container>

                <md-input-container class="flex-50" >
                    <label>Memory (<span>Mi,Gi</span>)</label>
                    <input type="text" ng-model="$ctrl.data.resources.requests.memory" />
                </md-input-container>
                <md-input-container class="flex-50" >
                    <label>Memory Limit (<span>Mi,Gi</span>)</label>
                    <input type="text" ng-model="$ctrl.data.resources.limits.memory" />
                </md-input-container>

                <md-input-container class="flex-50" >
                    <label>GPU</label>
                    <input type="number" ng-model="$ctrl.data.resources.accelerators.gpu" min="0" ng-disabled="!$ctrl.canWsBilling"/>
                </md-input-container>
                <md-input-container class="flex-50" >
                    <label>Replicas</label>
                    <input type="number" ng-model="$ctrl.data.replicas" min="0" />
                </md-input-container>

            </div>
        </div>


        <div class="task-images cmp-block-compact">
            <h5 class="cmp-block-compact-title">Images</h5>
            <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">

                <div class="flex-50 layout-row layout-align-start">
                    <cmp-select ng-model="$ctrl.data.images.cpu" options="$ctrl.imgList.cpu"  cmp-required="true" md-floating-label="cpu" flex label="'cpu'"></cmp-select>
                    <div class="help-block error" ng-show="$ctrl.form['image_cpu'].$error.required">{{"FORM_ERROR_REQUIRED" | translate}}</div>
                </div>

                <div class="flex-50 layout-row layout-align-start">
                    <cmp-select ng-model="$ctrl.data.images.gpu" options="$ctrl.imgList.gpu"   md-floating-label="gpu" flex label="'gpu'"></cmp-select>
                    <div class="help-block error" ng-show="$ctrl.form['image_gpu'].$error.required">{{"FORM_ERROR_REQUIRED" | translate}}</div>
                </div>

            </div>
        </div>

        <div class="cmp-block-compact">
            <h5 class="cmp-block-compact-title cmp-clickable" ng-click="Advanced = !!!Advanced" >
                {{"COMMON_ADVANCED" | translate}}
                <md-icon md-svg-icon="core:chevron-down" ng-if="!Advanced"></md-icon>
                <md-icon md-svg-icon="core:chevron-up" ng-if="Advanced"></md-icon>
            </h5>

            <div class="cmp-block-compact-content" ng-if="Advanced">

                <div class="cmp-block-compact">
                    <h5 class="cmp-block-compact-title" translate>MLAPP_ENV_ARGS</h5>
                    <div class="cmp-block-compact-content">
                        <cmp-autolist
                                data="$ctrl.data.env"
                                class="flex"
                                labels="['name', 'value']"
                                pattern="{name: {
                            pattern: '^[A-Z][_A-Z0-9]{0,61}[A-Z0-9]$',
                            message: 'Valid name must be 63 characters or less and must begin with an upper case alpha [A-Z] and end with an upper case alphanumeric character ([A-Z0-9]) with underline (_) and upper case alphanumerics between'}}"
                                form="$ctrl.form"
                        ></cmp-autolist>
                    </div>
                </div>

                <div class="cmp-block-compact" >
                    <h5 class="cmp-block-compact-title" translate>COMMON_PORTS</h5>
                    <div class="cmp-block-compact-content" >
                        <md-input-container flex='50' ng-if="!$ctrl.isServe && !$ctrl.isUix">
                            <label translate>port</label>
                            <input type="number" ng-model="$ctrl.data.port" >
                        </md-input-container>
                        <cmp-autolist
                                ng-if="$ctrl.isServe || $ctrl.isUix"
                                data="$ctrl.data.ports"
                                class="flex"
                                labels="['name', 'protocol', 'port', 'targetPort']"
                                attr="{
                                    type: {
                                        port: 'number',
                                        targetPort: 'number'
                                    },
                                    label: {
                                        targetPort: 'target port'
                                    }
                                }"
                                form="$ctrl.form"
                        ></cmp-autolist>
                    </div>
                </div>

                <div layout="row" layout-align="start center">
                    <md-input-container flex>
                        <label translate>Default mount path</label>
                        <input type="text" ng-model="$ctrl.data.default_mount_path">
                    </md-input-container>
                    <md-input-container flex>
                        <md-checkbox ng-model="$ctrl.data.default_volume_mapping">Default volume mapping</md-checkbox>
                    </md-input-container>
                </div>

                <div class="task-volumes cmp-block-compact" ng-if="!$ctrl.data.default_volume_mapping">
                    <h5>Volumes</h5>
                    <div class="cmp-block-compact-content">
                        <cmp-applications-task-volumes
                                volumes="$ctrl.data.volumes"
                                application="$ctrl.application"
                        ></cmp-applications-task-volumes>
                    </div>
                </div>


                <div class="cmp-block-compact"  ng-if="!$ctrl.isServe && !$ctrl.isUix" >
                    <h5 class="cmp-block-compact-title" translate>Policy</h5>
                    <div class="cmp-block-compact-content" layout="row" layout-aligm="start " >
                        <md-input-container flex>
                            <label>Restart policy</label>
                            <md-select ng-model="$ctrl.data.restartPolicy">
                                <md-option ng-value="'Never'">Never</md-option>
                                <md-option ng-value="'OnFailure'">On failure</md-option>
                            </md-select>
                        </md-input-container>
                        <md-input-container  flex>
                            <label>Max restart count</label>
                            <input ng-model="$ctrl.data.maxRestartCount" type="number" step="1" min="0">
                        </md-input-container>
                        <md-input-container>
                            <md-checkbox ng-model="$ctrl.data.is_permanent" style="margin:0">is permanent</md-checkbox>
                        </md-input-container>
                    </div>
                </div>

            </div>
        </div>

        <cmp-serving-form-spec ng-if="$ctrl.isServe" data="$ctrl.data.spec" flex="100"></cmp-serving-form-spec>

        <cmp-api-error-service
                    ng-if="$ctrl.updateError"
                    error="$ctrl.updateError"
                    format="'dialog'"
        ></cmp-api-error-service>
    </div>
</div>
