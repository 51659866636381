<md-menu md-position-mode="target-right target" style="width: 100%;" class="">
    <div class="blockMenuItem" flex layout="row" ng-click="$mdMenu.open()" ><md-icon md-svg-icon="core:wss"></md-icon><span class="label">Workspaces <md-icon md-menu-origin md-svg-icon="core:menu-down"></md-icon></span></div>
    <md-menu-content width="4" class="userWsList layoutMenuDialog" >
        <md-menu-item  ng-repeat="data in $ctrl.ws  | orderBy:['Type','DisplayName'] " class="userWsListItem ">
            <a ui-sref="workspace({wsId : data.Name})" class="blockMenuItem">
                <div org-icon data="data" ng-if="data.Picture" ></div>
                <!--<img ng-src="{{data.Picture}}" class="orgIcon" ng-if="data.Picture"/>-->
                <md-icon md-svg-icon="core:org" ng-if="!data.Picture"></md-icon>
                <span class="label">{{data.DisplayName}}</span>
            </a>
        </md-menu-item>
    </md-menu-content>
</md-menu>
<a ui-sref="workspace({wsId: $ctrl.workspace.Name})" class="blockMenuItem">
    <!--<org-icon data="$ctrl.workspace" ></org-icon>-->
    <div org-icon data="$ctrl.workspace" ></div>
    <!--<img ng-src="{{$ctrl.workspace.Picture}}" class="icon" alt="{{$ctrl.userData.DisplayName}}"/>-->
    <span class="label">{{$ctrl.workspace.DisplayName}}</span>
</a>

<md-divider></md-divider>

<a ng-repeat="(item, key) in $ctrl.statesList" ui-sref="{{key}}({wsId: $ctrl.workspace.Name})" class="blockMenuItem" ui-sref-active="current">
    <md-icon md-svg-icon="core:{{key}}"></md-icon>
    <span class="label">{{item}}</span>
    <!--<md-tooltip md-direction="right">{{item}}</md-tooltip>-->
</a>
