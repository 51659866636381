<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Serve project</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="md-dialog-content">
                <cmp-serving-form-project-config data="$ctrl.config" application="$ctrl.application1" flex="100"></cmp-serving-form-project-config>
            </div>
        </md-dialog-content>


        <md-dialog-actions layout="column" >
            <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
            <cmp-error-toast ng-if="error" error="error"></cmp-error-toast>
            <div layout="row">

                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button ng-click="serve(params)" ng-if="!res" ng-disabled="$ctrl.loading">
                    {{"MLAPPS_JOB_SERVE" | translate}}
                </md-button>
                <md-button ng-click="continue(params)" ng-if="res">
                    {{"COMMON_CONTINUE" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
