
<div class="md-padding">
    <div class="layout-row layout-align-start-center">
        <h2 class="flex" translate>{{'MLAPPS_JOBS_TITLE'}}</h2>
        <div class="flex-20 layout-row" ng-include="'kuberlab/mlapps/card/mlapps_jobs_filter.html'"></div>
    </div>
    <cmp-api-error-service ng-if="$ctrl.errorList" error="$ctrl.errorList"></cmp-api-error-service>
    <cmp-api-error-service ng-if="$ctrl.errorRequest" error="$ctrl.errorRequest" format="'dialog'"></cmp-api-error-service>
    <div class="chartList"
         ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'"
         name="chartListView">
        <div class="item_view  chartList_Item" ng-repeat="item in $ctrl.Jobs | filter: {status:$ctrl.statusFilter}" >
            <md-card>
                <md-card-title>
                    <md-card-title-text>
                        <div class="md-headline layout-row layout-align-start-center">
                            <span class="flex">{{item.name}}</span>
                            <md-menu class="md-secondary" md-position-mode="target-right target">
                                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                                </md-button>
                                <md-menu-content width="3">
                                    <!--<md-menu-item  ng-if="item.status == 'succeeded'">-->
                                        <!--<md-button class="md-primary">Start</md-button>-->
                                    <!--</md-menu-item>-->
                                    <md-menu-item ng-if="item.status == 'Running'">
                                        <md-button >
                                            <md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP"|translate}}
                                        </md-button>
                                    </md-menu-item>
                                    <md-menu-divider></md-menu-divider>
                                    <md-menu-item>
                                        <md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'metrics', tabItem: item.name})">
                                            <md-icon md-svg-icon="core:chart"></md-icon> {{"COMMON_METRICS"|translate}}
                                        </md-button>
                                    </md-menu-item>
                                    <md-menu-item>
                                        <md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'logs', tabItem: item.name})">
                                            <md-icon md-svg-icon="core:logs"></md-icon> {{"COMMON_LOGS"|translate}}
                                        </md-button>
                                    </md-menu-item>
                                    <md-menu-divider></md-menu-divider>
                                    <md-menu-item>
                                        <md-button aria-label="icon" ng-click="$ctrl.delete(item)">
                                            <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                                        </md-button>
                                    </md-menu-item>
                                </md-menu-content>
                            </md-menu>
                        </div>
                        <!--<div class="layout-row layout-align-end-center">-->
                            <!--<div class="status {{item.status}}" >{{item.status}}</div>-->
                        <!--</div>-->
                        <div class="md-subhead wordTime" >

                            <div class="layout-row layout-align-start-center">
                                <md-icon md-svg-icon="core:calendar" style="margin: auto 16px auto 8px"></md-icon>
                                <div class=" flex layout-column layout-align-start-start">
                                    <span>{{ item.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                                    <span ng-if="item.stop_time">{{ item.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                                    <!--<span ng-if="!item.stop_time">Running</span>-->
                                </div>
                                <div class="layout-row layout-align-end-center">
                                    <div class="status {{item.status}}" >{{item.status}}</div>
                                </div>
                            </div>

                        </div>
                    </md-card-title-text>
                </md-card-title>
                <md-card-content ng-if="item.status == 'Running'" class="mlappMetricShort layout-row layout-align-center-start ">
                    <div class="item"><div class="value">{{item.Stats.cpu_usage.Value.toFixed(2)}}{{item.Stats.cpu_usage.Measure}}</div><div class="legend" translate>MLAPP_METRIC_cpu_usage</div></div>
                    <div class="item"><div class="value">{{item.Stats.gpu_usage.Value.toFixed(2)}}{{item.Stats.gpu_usage.Measure}}</div><div class="legend" translate>MLAPP_METRIC_gpu_usage</div></div>
                    <div class="item"><div class="value">{{item.Stats.memory_bytes.Value.toFixed(2)}}{{item.Stats.memory_bytes.Measure}}</div><div class="legend" translate>MLAPP_METRIC_memory_bytes</div></div>
                </md-card-content>
                <!--<md-card-action class="layout-align-start-center layout-row">-->
                    <!--<div class="flex"></div>-->
                    <!--<md-menu class="md-secondary" md-position-mode="target-right target">-->
                        <!--<md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">-->
                            <!--<md-icon md-svg-icon="core:short-menu"></md-icon>-->
                        <!--</md-button>-->
                        <!--<md-menu-content width="3">-->
                            <!--<md-menu-item  ng-if="item.status == 'succeeded'">-->
                                <!--<md-button class="md-primary">Start</md-button>-->
                            <!--</md-menu-item>-->
                            <!--<md-menu-item ng-if="item.status == 'Running'">-->
                                <!--<md-button class="md-warn">Stop</md-button>            -->
                            <!--</md-menu-item>-->
                            <!--<md-menu-divider></md-menu-divider>-->
                            <!--<md-menu-item>-->
                                <!--<md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'metrics', tabItem: item.name})">-->
                                    <!--<md-icon md-svg-icon="core:chart"></md-icon> {{"COMMON_METRICS"|translate}}-->
                                <!--</md-button>-->
                            <!--</md-menu-item>-->
                            <!--<md-menu-item>-->
                                <!--<md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'logs', tabItem: item.name})">-->
                                    <!--<md-icon md-svg-icon="core:logs"></md-icon> {{"COMMON_LOGS"|translate}}-->
                                <!--</md-button>-->
                            <!--</md-menu-item>-->
                            <!--<md-menu-divider></md-menu-divider>-->
                            <!--<md-menu-item>-->
                                <!--<md-button aria-label="icon" ng-click="$ctrl.delete(item)">-->
                                    <!--<md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}-->
                                <!--</md-button>-->
                            <!--</md-menu-item>-->
                        <!--</md-menu-content>-->
                    <!--</md-menu>-->
                <!--</md-card-action>-->
            </md-card>
        </div>
    </div>
</div>