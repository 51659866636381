<md-menu-bar>
    <md-menu>
        <button ng-click="$mdMenu.open()">
            Login
        </button>
        <md-menu-content>
            <auth-service-list type="'Login'"></auth-service-list>
            <md-list-item>
                <a ui-sref="login">login</a>
            </md-list-item>
        </md-menu-content>
    </md-menu>
</md-menu-bar>