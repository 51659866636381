<layout-page-content-list class="menuLevel layoutMenu" >
    <!--<h3><a ui-sref="workspace"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{$ctrl.WS.DisplayName}}</span></a></h3>-->
    <layout-ws></layout-ws>
    <cmp-applications-short flex></cmp-applications-short>
</layout-page-content-list>


<layout-page-content-item flex layout="column" layout-align="start">

    <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
        <layout-button-submenu></layout-button-submenu>
        <layout-back></layout-back>

        <!--<div ng-include="'kuberlab/applications/card/project-item-status.html'"></div>-->
        <item-status-icon data="$ctrl.data" class="widthAuto"></item-status-icon>
        <h3>{{$ctrl.data.DisplayName}}</h3>
        <span style="display: inline-block; padding: 0 0.5em"> - </span>
        <cmp-application-branch-select current-revision="$ctrl.appRevision" ></cmp-application-branch-select>
        <div flex></div>
        <md-checkbox ng-model="$ctrl.isAdvanced" ng-change="$ctrl.setViewProject()" style="margin-bottom: 0" > Advanced</md-checkbox>
        <!--<div ui-view="sub-titl"></div>-->
        <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
            <md-menu>
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" >
                    <md-icon md-svg-icon="core:info"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item ng-if="$ctrl.data.Description">
                        <span>{{$ctrl.data.Description}}</span>
                    </md-menu-item>

                    <md-menu-item ng-if="$ctrl.data.Author">
                        <span >{{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.Author.DisplayName}}</span>
                    </md-menu-item>

                    <md-menu-item>
                        <span>cluster: <cmp-application-cluster-link class="flex" data="$ctrl.data" style="display: inline"></cmp-application-cluster-link></span>
                    </md-menu-item>

                </md-menu-content>
            </md-menu>
            <md-button ng-disabled="true" class="md-secondary" ng-if="!$ctrl.data.Enabled">{{'COMMON_DISABLED' | translate}}</md-button>
            <div class="md-secondary" ng-include="'kuberlab/applications/card/applications_menu.html'"></div>
        </md-menu-bar>

    </layout-page-content-title>

    <div flex layout-align="start" layout="column">
        <form ng-if="$ctrl.asForm"  name="newProject" ng-submit="$ctrl.save(newProject, true)">
            <md-card>
                <md-card-content >
                    <div class="md-dialog-content" ng-include="'kuberlab/applications/card/applications_card_form.html'"></div>
                </md-card-content>
                <md-card-actions layout="row" ng-include="'kuberlab/applications/card/applications_card_form_action.html'"></md-card-actions>
            </md-card>
        </form>

        <div ng-if="!$ctrl.asForm" flex layout-align="start" layout="column" >

            <md-card ng-if="$ctrl.data.DisableReason">

                <md-card-title class="layout-align-start-center worning">
                    <md-card-title-media>
                        <div class="md-media-sm card-media" layout>
                            <md-icon md-svg-icon="core:alert"></md-icon>
                        </div>
                    </md-card-title-media>
                    <md-card-title-text style="min-width: 0px">
                        <span class="md-headline one-line">{{$ctrl.data.DisableReason}}</span>
                    </md-card-title-text>
                </md-card-title>
                <md-card-content ng-if="$ctrl.data.DisableReason.length > 50">{{$ctrl.data.DisableReason}}</md-card-content>
            </md-card>
            <cmp-error-toast class="worning" ng-if="$ctrl.data.Health == 'Provisioning' "  error="{data:{Error: 'Provisioning new resources. Please wait.' }}"></cmp-error-toast>

            <div flex ng-if="!$ctrl.envList.Error" class="" layout="column" layou-align="start">
                <div layout="row" layout-align="start center" class="">
                    <md-tabs class="itemContent" md-dynamic-height flex md-border-bottom>
                        <md-tab ng-repeat="tab in $ctrl.viewTabs track by $index"
                            md-active="$ctrl.$state.params.tab == tab.name"
                            ui-sref="wsApplication.tab({tab: tab.name})">
                                {{tab.displayName | translate}}
                            </md-tab-label>
                        </md-tab>

                                {{tab.displayName | translate}}
                            </md-tab-label>
                        </md-tab>
                    </md-tabs>
                </div>


                <md-content id="applicationFullContent" flex layout="row" layout-align="start" class="md-padding">
                    <div ng-if="$ctrl.initialized.summary" ng-show="$ctrl.$state.params.tab == 'summary'" flex layout="row" layout-align="start">
                        <cmp-application-summary app-controller="$ctrl" flex></cmp-application-summary>
                    </div>

                    <div ng-if="$ctrl.initialized.sources" ng-show="$ctrl.$state.params.tab == 'sources'" flex layout="row" layout-align="start">
                        <cmp-applications-volumes app-controller="$ctrl" list="$ctrl.data.Configuration.spec.volumes" flex></cmp-applications-volumes>
                    </div>

                    <div ng-if="$ctrl.initialized.models" ng-show="$ctrl.$state.params.tab == 'models'" flex layout="row" layout-align="start">
                        <cmp-applications-models app-controller="$ctrl" flex layout="row" layout-align="start"></cmp-applications-models>
                    </div>

                    <div ng-if="$ctrl.initialized.metrics" ng-show="$ctrl.$state.params.tab == 'metrics'" flex layout="row" layout-align="start">
                        <cmp-applications-metrics2 app-controller="$ctrl" flex></cmp-applications-metrics2>
                    </div>

                    <div ng-if="$ctrl.initialized.history" ng-show="$ctrl.$state.params.tab == 'history'" flex layout="row" layout-align="start">
                        <cmp-applications-history app-controller="$ctrl" ng-if="$ctrl.$state.params.tab == 'history'" flex></cmp-applications-history>
                    </div>

                    <div ng-if="$ctrl.initialized.jobs" ng-show="$ctrl.$state.params.tab == 'jobs'" flex layout="row" layout-align="start">
                        <cmp-applications-jobs app-controller="$ctrl" flex layout-align="start" layout="column"></cmp-applications-jobs>
                    </div>

                    <!--<div ng-if="$ctrl.initialized.install" ng-show="$ctrl.$state.params.tab == 'install'" flex layout="row" layout-align="start">-->
                        <!--<cmp-applications-pip application="$ctrl.data" flex></cmp-applications-pip>-->
                    <!--</div>-->

                    <div ng-if="$ctrl.initialized.status" ng-show="$ctrl.$state.params.tab == 'status'" flex layout="row" layout-align="start">
                        <cmp-applications-status application="$ctrl.data" flex></cmp-applications-status>
                    </div>

                    <div ng-repeat="uix in $ctrl.data.Configuration.spec.uix" ng-if="$ctrl.initialized[uix.name]" ng-show="$ctrl.$state.params.tab == uix.name" flex layout="row" layout-align="start">
                        <cmp-application-uix ng-if="$ctrl.data.Enabled" app-controller="$ctrl" uix="uix" flex></cmp-application-uix>
                        <cmp-applications-disabled ng-if="!$ctrl.data.Enabled"  application="$ctrl.data" app-controller="$ctrl" flex></cmp-applications-disabled>
                    </div>

                    <div ng-if="$ctrl.initialized.simple" ng-show="$ctrl.$state.params.tab == 'simple'" flex layout="row" layout-align="start">
                        <cmp-applications-simply app-controller="$ctrl" options="formOptions" flex></cmp-applications-simply>
                    </div>
                </md-content>

            </div>
            <cmp-api-error-service ng-if="$ctrl.apiError" error="$ctrl.apiError" format="'dialog"></cmp-api-error-service>
        </div>
    </div>
    <please-wait ng-if="$ctrl.data.DoNotSave" >Project is updating. Please wait.</please-wait>
</layout-page-content-item>
