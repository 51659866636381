
<div class="md-padding">
    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>

    <cmp-error-toast ng-if="$ctrl.list.health_error" error="{data:{Error: $ctrl.list.health_error}}"></cmp-error-toast>
    <div class="layout-row layout-align-start">
        <h3 class="flex">Project status: {{ ($ctrl.application.Enabled ? 'COMMON_ENABLED' : 'COMMON_DISABLED') | translate}} <span class="small">( Health: {{$ctrl.application.Health}} )</span></span></h3>
        <md-button ng-click="$ctrl.getList()" class="md-raised"><md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_REFRESH" | translate}}</md-button>
    </div>
    <div class="task-resource-env cmp-block-compact" ng-repeat="item in $ctrl.list.component_states">
        <div class="cmp-block-compact-title layout-row layout-align-start-center cmp-clickable" ng-click="item.showMore = !!!item.showMore" >
        <!--<div class="cmp-block-compact-title layout-row layout-align-start-center cmp-clickable" ng-click="$ctrl.openDialog($event, item)" >-->
            <div class="icon-tree-point">
                <md-icon md-svg-icon="core:minus-box" ng-if="item.showMore"></md-icon>
                <md-icon md-svg-icon="core:plus-box" ng-if="!item.showMore"></md-icon>
            </div>
            <div style="width: 10px"></div>
            <div flex>
                <h4 class="flex" >{{item.name}}</h4>
                <span ng-if="item.reason">{{item.reason}}</span>
            </div>
            <div class="flex-15">type: {{item.type}}</div>
            <div class="flex-15 status {{item.status}}">{{item.status}}</div>
            <div style="width: 10px"></div>
        </div>
        <div ng-if="item.showMore" style="padding:  0 2em 1em 3em;">
            <p ng-if="!item.resource_states.length">{{"MLAPP_STATUS_RESOUTCE_EMPTY" | translate}}.</p>
            <div class="cmp-block-compact layout-column layout-align-start" ng-repeat="r in item.resource_states">
                <div class="layout-row layout-align-start">
                    <div class="flex" style="min-width:0; ">{{r.name}}</div>
                    <div class="flex-5"></div>
                    <div class="status {{r.status}}" style="min-width:0; ">{{r.status}}</div>
                </div>
                <div ng-if="r.events.length">
                    <h5 class="cmp-block-compact-title" translate>COMMON_EVENTS</h5>
                    <div class="cmp-block-compact-content">
                        <div ng-if="r.events.length">
                            <table class="resource_states">
                                <tr>
                                    <!--<th>#</th>-->
                                    <th>Message</th>
                                    <th>Source</th>
                                    <th>Count</th>
                                    <th>First seen</th>
                                    <th>Last seen</th>
                                </tr>
                                <tr ng-repeat="event in r.events">
                                    <!--<td></td>-->
                                    <td style="width: 30%">{{event.message}}</td>
                                    <td>{{event.source.component}}</td>
                                    <td>{{event.count}}</td>
                                    <td class="ts">{{ event.firstTimestamp | date:"MM/dd/yyyy 'at' h:mma" }}</td>
                                    <td class="ts">{{ event.lastTimestamp | date:"MM/dd/yyyy 'at' h:mma" }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-divider ng-if="!$last"></md-divider>
    </div>
</div>
