<div flex layout-align="start center" layout="row">
    <div flex></div>
    <md-button class="md-raised" ng-click="$ctrl.createNew()" ng-if="$ctrl.canManage">+ new version</md-button>
</div>

<cmp-catalog-version-item-full ng-class="{fullView : item.Version == $ctrl.version.Version}" list-controller="$ctrl"  data="$ctrl.version" can-manage="$ctrl.canManage" ng-if="$ctrl.version">
</cmp-catalog-version-item-full>

<div class="versionHistory">
    <div flex layout="row" layout-align="start center">

        <h3 flex>History</h3>
        <md-button class="" ng-click="$ctrl.createNew()" ng-if="$ctrl.canManage">+ new version</md-button>
    </div>
    <cmp-catalog-version-item  list-controller="$ctrl" ng-repeat="item in $ctrl.versions" data="item" can-manage="$ctrl.canManage" ng-if="item.Version">
    </cmp-catalog-version-item>
</div>



<!--<md-button ng-click="$ctrl.createNew()">+ new version</md-button>-->

<!--<div>-->
    <!--<cmp-dataset-version-item list-controller="$ctrl" ng-repeat="item in $ctrl.versions" data="item">-->
    <!--</cmp-dataset-version-item>-->
<!--</div>-->

<!--<div>-->
    <!--tree-->
    <!--<div>{{$ctrl.tree}}</div>-->
    <!--<cmp-dataset-version-tree ctrl="$ctrl" api="$ctrl.treeApi" ></cmp-dataset-version-tree>-->
<!--</div>-->

<!--<div ng-if="$ctrl.versions && $ctrl.versions.length">-->
    <!--<div layout="row" layout-align="start center">-->
        <!--<md-input-container flex="15">-->
            <!--<label translate>COMMON_VERSION</label>-->
            <!--<md-select ng-model="$ctrl.version">-->
                <!--<md-option ng-repeat="v in $ctrl.versions" ng-value="v">{{v.Version}} </md-option>-->
            <!--</md-select>-->
        <!--</md-input-container>-->
        <!--<div ng-if="$ctrl.version.SizeBytes">-->
            <!--Size: <span ng-bind-html="$ctrl.CatalogService.datasetVersionSize($ctrl.version)"></span>-->
        <!--</div>-->
        <!--&lt;!&ndash;<div class="flex layout-align-end-center layout-row" style="width: 100%; ">&ndash;&gt;-->
            <!--&lt;!&ndash;<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>&ndash;&gt;-->
            <!--&lt;!&ndash;<a layout="row"&ndash;&gt;-->
                <!--&lt;!&ndash;class="md-button md-raised"&ndash;&gt;-->
                <!--&lt;!&ndash;style="margin-left: 0.5em"&ndash;&gt;-->
                <!--&lt;!&ndash;ng-disabled="isDownloading"&ndash;&gt;-->
                <!--&lt;!&ndash;ng-click="$ctrl.download()">&ndash;&gt;-->
                <!--&lt;!&ndash;<md-icon md-svg-icon="core:link"></md-icon>&ndash;&gt;-->
                <!--&lt;!&ndash;<span style="margin: 0 10px">download</span>&ndash;&gt;-->
                <!--&lt;!&ndash;<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>&ndash;&gt;-->
            <!--&lt;!&ndash;</a>&ndash;&gt;-->
        <!--&lt;!&ndash;</div>&ndash;&gt;-->

         <!--<div class="flex layout-align-end-center layout-row" style="width: 100%; ">-->
            <!--&lt;!&ndash;<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>&ndash;&gt;-->
            <!--&lt;!&ndash;<a layout="row"&ndash;&gt;-->
               <!--&lt;!&ndash;href="{{$ctrl.version.DownloadURL}}"&ndash;&gt;-->
               <!--&lt;!&ndash;traget="_blank"&ndash;&gt;-->
                <!--&lt;!&ndash;class="md-button md-raised"&ndash;&gt;-->
                <!--&lt;!&ndash;style="margin-left: 0.5em"&ndash;&gt;-->
                <!--&lt;!&ndash;ng-disabled="isDownloading"&ndash;&gt;-->
                <!--&lt;!&ndash;ng-click="$ctrl.download()">&ndash;&gt;-->
            <!--<a layout="row"-->
               <!--href="{{$ctrl.version.DownloadURL}}"-->
               <!--target="_blank"-->
                <!--class="md-button md-raised"-->
                <!--style="margin-left: 0.5em"-->
               <!--download-->
            <!--&gt;-->
                <!--<md-icon md-svg-icon="core:link"></md-icon>-->
                <!--<span style="margin: 0 10px">download</span>-->
                <!--&lt;!&ndash;<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>&ndash;&gt;-->
            <!--</a>-->
        <!--</div>-->

        <!--<md-menu class="md-secondary" md-position-mode="target-right target">-->
            <!--<md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.canManage">-->
                <!--<md-icon md-svg-icon="core:short-menu"></md-icon>-->
            <!--</md-button>-->
            <!--<md-menu-content width="3">-->
                <!--<md-menu-item>-->
                    <!--<md-button aria-label="icon" ng-click="$ctrl.delete()">-->
                        <!--<md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}-->
                    <!--</md-button>-->
                <!--</md-menu-item>-->
            <!--</md-menu-content>-->
        <!--</md-menu>-->
    <!--</div>-->
<!--</div>-->
<!--<div>-->
<!--Form upload dataset use <a href="https://github.com/kuberlab/pluk/releases/latest" target="_blank">kdataset</a>-->
<!--</div>-->
