<div ng-if="$ctrl.viewInfra">
    <h4><a ui-sref="wsProjectsList" translate >WS_PROJECTS</a></h4>
    <cmp-projects-card-home flex ></cmp-projects-card-home>
</div>

<div>
    <h4><a ui-sref="wsStorages" translate >STORAGE_PERSISTEN_STORAGE</a></h4>
    <cmp-storage-persistent-list can-edit="true" class="innerContentBorder"></cmp-storage-persistent-list>
</div>

<div ng-if="$ctrl.viewInfra">
    <h4><a translate >SHARED_CLUSTER_AVAILABEL</a></h4>
    <cmp-shared-cluster-list class="innerContentBorder" type="'available'" params="{WorkspaceName: $ctrl.$state.params.wsId}"></cmp-shared-cluster-list>
</div>
