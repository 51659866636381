<div layout="row" layout-align="start center">
    <h2>video template</h2>
    <div class="md-padding"></div>
    <md-progress-linear ng-if="$ctrl.saving" flex=""></md-progress-linear>
</div>
<cmp-error-toast ng-if="$ctrl.savingError" error="$ctrl.savingError"></cmp-error-toast>
<div>
    <div class="md-padding" >
        <div>
            <style>
                .servingVideoStreamList td {
                    vertical-align: top;
                    word-wrap: break-word;
                    word-break: break-all;
                    width: 30%;
                    overflow: auto
                }
            </style>
            <form ng-submit="$ctrl.create(addRTMP)" layout="column" layout-align="start" name="addRTMP">
                <table ng-if="$ctrl.list.length" class="servingVideoStreamList">
                    <tr>
                        <th>key</th>
                        <th>input</th>
                        <th>output</th>
                        <th>action</th>
                    </tr>
                    <tr ng-repeat="item in $ctrl.list">
                        <td>{{item.key}}<p ng-if="item.description">{{item.description}}</p><p ng-if="item.params">params: {{item.params}}</p>
                            <!--{{item}}-->
                        </td>
                        <td >
                            <div ng-if="item.input">{{item.input}}</div>
                            <div ng-if="!item.input"> - </div>

                        </td>
                        <td>{{item.output}}</td>
                        <td style="text-align: center; ">
                            <div style="width: 50px">
                                <md-menu class="md-secondary" md-position-mode="target-right target">
                                    <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" >
                                        <md-icon md-svg-icon="core:short-menu"></md-icon>
                                    </md-button>
                                    <md-menu-content width="4">
                                        <md-menu-item ng-if="$ctrl.isPlay(item.output)" >
                                            <md-button ng-click="$ctrl.playDialog($event, item)" ><md-icon md-svg-icon="core:play"></md-icon>play</md-button>
                                        </md-menu-item>
                                        <md-menu-item >
                                            <md-button ng-click="$ctrl.copyPlayLink(item, 'play')"><md-icon md-svg-icon="core:link"></md-icon>copy play link</md-button>
                                        </md-menu-item>
                                        <md-menu-item ng-if="!item.input">
                                            <md-button ng-click="$ctrl.copyPlayLink(item, 'internal')"><md-icon md-svg-icon="core:link"></md-icon>copy rtmp internal link</md-button>
                                        </md-menu-item>
                                        <md-menu-item ng-if="!item.input">
                                            <md-button ng-click="$ctrl.copyPlayLink(item, 'expernal')"><md-icon md-svg-icon="core:link"></md-icon>copy rtmp external link</md-button>
                                        </md-menu-item>
                                        <md-menu-item >
                                            <md-button class=""  ng-click="$ctrl.delete($evemt, item)"><md-icon md-svg-icon="core:delete"></md-icon>delete</md-button>
                                        </md-menu-item>

                                    </md-menu-content>
                                </md-menu>
                                <md-button ng-click="$ctrl.playDialog($event, item)" ng-if="$ctrl.isPlay(item.output)" class="md-button md-raised md-icon-button" ><md-icon md-svg-icon="core:play"></md-icon></md-button>
                            </div>

                        </td>
                    </tr>
                </table>
                <h4 style="margin-bottom: 0;">New stream config</h4>
                <div layout="row" layout-wrap>
                    <md-input-container flex="33">
                        <label>key</label>
                        <input ng-model="$ctrl.newStream.key" >
                    </md-input-container>
                    <md-input-container flex="33" >
                        <label>input</label>
                        <input ng-model="$ctrl.newStream.input" >
                    </md-input-container>
                    <md-input-container flex="33" >
                        <label>output</label>
                        <input ng-model="$ctrl.newStream.output" required>
                    </md-input-container>
                    <md-input-container flex>
                        <label>params</label>
                        <textarea ng-model="$ctrl.newStream.params"></textarea>
                    </md-input-container>
                    <md-input-container flex>
                        <label>description</label>
                        <textarea ng-model="$ctrl.newStream.description"></textarea>
                    </md-input-container>
                </div>
                <div flex layout="row" layout-align="start center">
                    <div flex><md-progress-linear ng-if="$ctrl.creating"></md-progress-linear></div>
                    <md-button type="submnit" class="md-raised" ng-disabled="!addRTMP.$valid || $ctrl.creating">create</md-button>
                </div>
                <div class="md-padding" ng-if="$ctrl.errorCreate"><cmp-error-toast  flex error="{data:  {Error: $ctrl.errorCreate.error}}"></cmp-error-toast></div>
            </form>
        </div>
    </div>
</div>
