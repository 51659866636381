<md-content class="md-padding">
<div class="layout-row layout-align-end-center" ng-if="!$ctrl.mainPage">
    <md-input-container>
        <label>Name</label>
        <input ng-model="$ctrl.filter.DisplayName" ng-change="$ctrl.saveFilter()">
    </md-input-container>
    <md-checkbox ng-model="$ctrl.orderContainersCount" ng-change="$ctrl.orderWithCount()">Containers Count</md-checkbox>
    <md-button ng-click="$ctrl.order('DisplayName')" ng-class=" $ctrl.orderParam.DisplayName == 'DisplayName' ? 'cmp-order-up' : 'cmp-order-down'">Name </md-button>
    <md-input-container class="flex-15">
        <label>Disabled/Enabled</label>
        <md-select ng-model="$ctrl.filter.Enabled" ng-change="$ctrl.saveFilter()">
            <md-option ng-value="undefined">All</md-option>
            <md-option ng-value="true">Enabled</md-option>
            <md-option ng-value="false">Disabled</md-option>
        </md-select>
    </md-input-container>
    <md-checkbox ng-model="$ctrl.Recent" ng-change="$ctrl.changeResent()">Recent</md-checkbox>
</div>

<div layout="row" layout-align="space-around" ng-if="!$ctrl.list">
  <md-progress-linear md-mode="indeterminate"></md-progress-linear>
</div>

<div ng-class="{'limitHeagth' : $ctrl.limitHeigth(), 'innerContentBorder' : true,  'applicationList' : true}">
    <md-list ng-if="$ctrl.list.length" >
        <div ng-repeat="item in $ctrl.list | orderBy : $ctrl.orderListPrams | filter : $ctrl.filter">
            <cmp-applications-list-item data="item" list-controller="$ctrl"></cmp-applications-list-item>
            <md-divider ng-if="!$last"></md-divider>
        </div>
    </md-list>
    <md-button class="allList" ng-if="$ctrl.limitHeigth()" ui-sref="wsApplicationsList()">{{"COMMON_ALL_LIST" | translate}} ({{$ctrl.list.length}})</md-button>
</div>

<div ng-if="$ctrl.listEmpty"  ng-include=" 'kuberlab/applications/card/list_empty.html' "></div>
</md-content>
