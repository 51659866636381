<script type="text/ng-template" id="/multi-button.html">
    <md-button style="margin: 0;" ng-click="$ctrl.multiAction('delete')" ng-disabled="!$ctrl.deleteList.length || $ctrl.multiActionDo" class="md-icon-button"><md-icon md-svg-icon="core:delete"></md-icon><md-tooltip>delete</md-tooltip></md-button>
    <md-button style="margin: 0;" ng-click="$ctrl.multiAction('restart')" ng-disabled="!$ctrl.deleteList.length || $ctrl.multiActionDo" class="md-icon-button"><md-icon md-svg-icon="core:reload"></md-icon><md-tooltip>restart</md-tooltip></md-button>
    <md-button style="margin: 0;" ng-click="$ctrl.multiAction('stop')" ng-disabled="!$ctrl.deleteList.length || $ctrl.multiActionDo" class="md-icon-button"><md-icon md-svg-icon="core:stop"></md-icon><md-tooltip>stop</md-tooltip></md-button>
</script>


<md-progress-linear ng-if="$ctrl.apiLoading"></md-progress-linear>
<cmp-api-error-service ng-if="$ctrl.errorList" error="$ctrl.errorList"></cmp-api-error-service>
<cmp-api-error-service ng-if="$ctrl.errorRequest" error="$ctrl.errorRequest" format="'dialog'"></cmp-api-error-service>
<cmp-error-toast ng-if="$ctrl.apiError" error="$ctrl.apiError"></cmp-error-toast>
<!--{{$ctrl.deleteList}}-->
<div flex layout="row" layout-align="start" ng-class="{hasJob: $ctrl.job }" >
    <div id="applicationJobsList" class="applicationJobsList"  layout="column" layout-align="start" flex="{{$ctrl.job ? '30' : '' }}">

        <div class="filterControls" ng-if="$ctrl.job" layout="row" layout-align="end">
            <div ng-if="$ctrl.deleteList.length" layout="row" layout-align="end center" >
                <ng-include src="'/multi-button.html'"></ng-include>
            </div>
            <md-button ng-click="$ctrl.viewFilter =! $ctrl.viewFilter" class="md-icon-button"><md-icon md-svg-icon="core:{{$ctrl.viewFilter ? 'filter-remove' : 'filter'}}"></md-icon></md-button>
            <!--<md-button ng-if="$ctrl.viewFilter" ng-click="$ctrl.search = {}; $ctrl.viewFilter=true"><md-icon md-svg-icon="core:filter-remove"></md-icon> clear filter</md-button>-->
        </div>
        <div>

            <div class="layout-row layout-align-start-center" ng-if="!$ctrl.job || $ctrl.viewFilter">
                <h2 class="flex" translate ng-if="!$ctrl.job" >{{'MLAPPS_JOBS_TITLE'}}</h2>
                <div flex layout="row" layout-align="end" ng-include="'kuberlab/applications/card/applications_jobs_filter.html'"></div>
                <ng-include src="'/multi-button.html'"></ng-include>
                <md-button ng-if="!$ctrl.job" ng-click="$ctrl.getList(true)" class="md-raised" ng-disabled="$ctrl.viewFullFilter"><md-icon md-svg-icon="core:reload"></md-icon> {{ 'COMMON_UPDATE' | translate }}</md-button>
            </div>

            <md-card ng-if="$ctrl.viewFullFilter" ng-class="{}">
                <cmp-application-jobs-filter-new class="md-padding"
                                                 data="$ctrl.fData"
                                                 open="$ctrl.viewFullFilter"
                                                 list="$ctrl.fJobs"
                                                 api-loading="$ctrl.apiLoading"
                                                 api-error="$ctrl.apiError"></cmp-application-jobs-filter-new>
            </md-card>
        </div>


        <!--<div ng-if="$ctrl.deleteList.length" layout="row" layout-align="end center"><md-button ng-click="$ctrl.multiDelete()">delete</md-button> </div>-->
        <md-content flex class=""  name="chartListView">
            <div class="item_view  chartList_Item" ng-if="$ctrl.Jobs && !$ctrl.Jobs.length && !$ctrl.apiLoading && !ctrl.apiError">
                <md-card>
                    <md-card-content layout="column">
                        <div layout="row" layout-align="start center">
                            <div class="md-padding" translate>COMMON_LIST_EMPTY</div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <md-list>
                <div style="padding: 0"
                     class="item_view  chartList_Item"
                     ng-repeat="item in ( $ctrl.fJobs ? $ctrl.fJobs : $ctrl.Jobs ) | filter : $ctrl.search : true"
                     id="{{item.name}}"
                     layout="column"
                    >
                    <cmp-application-job-item data="item" app-controller="$ctrl.appController" list-controller="$ctrl" current-job="$ctrl.job" layout-align="start"></cmp-application-job-item>
                    <md-divider ng-if="!$last"></md-divider>
                </div>
            </md-list>

        </md-content>
    </div>
    <div style="width: 10px" ng-if="$ctrl.job"></div>
    <div flex ng-if="$ctrl.job"  layout="column" layout-align="start">
        <cmp-application-job-item-full flex layout="column" layout-align="start" data="$ctrl.job" app-controller="$ctrl.appController" list-controller="$ctrl"></cmp-application-job-item-full>
    </div>
</div>
