<h4><a ui-sref="wsApplicationsList"><md-icon md-svg-icon="core:wsApplicationsList"></md-icon><span class="label">Projects</span></a></h4>
<md-divider></md-divider>
<div class="blockMenu" ng-if="$ctrl.list.length">
    <a class="blockMenuItem"
       ui-sref="wsApplication({applicationName: item.Name})"
       ng-repeat="item in $ctrl.list | orderBy : $ctrl.orderListPrams | filter : $ctrl.filter"
       ui-sref-active="current">
        <md-icon md-svg-icon="core:status{{item.Health}}" class="status {{$ctrl.data.Health}}" ></md-icon>
        <span class="label" layout="row" layout-align="space-between center">{{item.DisplayName}}</span>
    </a>
</div>

