<md-button class="md-icon-button" ng-click="$ctrl.open()" ng-class="{noOpen: !$ctrl.isOpen}" ng-if="$ctrl.active" id="listNotifyButton">
    <md-icon md-svg-icon="core:bell"></md-icon>
</md-button>

<div id="listNotify" ng-if="$ctrl.isOpen" class="md-whiteframe-2dp" layout="column" layout-align="start">
    <h4 layout="row" layout-align="start">
        <div flex>System notifications</div>
        <md-button class="md-icon-button md-button-small" ng-click="$ctrl.open()" >
            <md-icon md-svg-icon="core:close"></md-icon>
        </md-button>

    </h4>
    <div class="listNotifyContent flex">
        <div ng-repeat="item in $ctrl.list" class="listNotifyItem" >
            <h5>{{ "NOTIFY_" + item.Type | translate:item }}</h5>
            <div ng-switch="item.Type">
                <div ng-switch-when="repo_deploy_key">{{item.Data.url}}</div>
                <div ng-switch-when="test">object_id: {{item.Data.object_id}}</div>
            </div>
            <div layout="row" layout-align="start center">
                <div flex></div>
                <md-button ng-click="$ctrl.action(item)">fix it</md-button>
                <!--<md-button ng-click="$ctrl.close(item)">close</md-button>-->
            </div>
            <hr ng-if="!$last">
        </div>
    </div>
</div>