<!--<md-list-item class="paddingListItemTabContent md-3-line"  ui-sref="wsApplication({applicationName: $ctrl.data.Name})">-->
<div class="paddingListItemTabContent md-3-line cmp-clickable" layout="row" layout-align="start center">
    <div flex layout="row" layout-align="start center" ui-sref="wsApplication({applicationName: $ctrl.data.Name})">
        <!--<div ng-include="'kuberlab/applications/card/project-item-status.html'"></div>-->
        <item-status-icon data="$ctrl.data"></item-status-icon>
        <div class="md-list-item-text flex" layout="row" layout-align="space-between center">
            <div>
                <h4>{{$ctrl.data.DisplayName}} <span style="font-weight: normal" ng-if="$ctrl.data.Author">( {{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.Author.DisplayName}} )</span></h4>
                <p class="description" ng-if="$ctrl.data.Description">{{ $ctrl.data.Description }}</p>
            </div>
            <div>
                <cmp-application-cluster-link data="$ctrl.data"></cmp-application-cluster-link>
            </div>
        </div>
        <md-button ng-disabled="true" ng-click="$ctrl.enabled()"  class="md-secondary" ng-if="!$ctrl.data.Enabled" >{{'COMMON_DISABLED' | translate}}</md-button>
    </div>
    <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
        <div class="md-secondary" ng-include="'kuberlab/applications/card/applications_menu.html'"></div>
    </md-menu-bar>
</div>
