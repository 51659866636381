<div  class="servingSpec" layout="row" layout-align="start" layout-wrap flex>
    <div  ng-switch="item.TYPE" ng-repeat="item in $ctrl.opt" flex="{{item.width ? item.width : 100}}" layout="row"  layout-align="start ">

        <md-input-container ng-switch-when="array" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <md-select ng-model="$ctrl.ngModel[item.name]">
                <md-option ng-value="opt" ng-repeat="opt in item.value">{{ opt }}</md-option>
            </md-select>
        </md-input-container>

        <div ng-switch-when="bytes" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" type="file"  app-filereader >
        </div>

        <div ng-switch-when="byte" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input ng-model="$ctrl.ngModel[item.name]" nane="{{item.type + '_' + item.name}}" type="file"  app-filereader >
        </div>

        <md-input-container ng-switch-when="boolean" flex>
            <md-checkbox ng-model="$ctrl.ngModel[item.name]">{{item.label ? item.label : item.name}}</md-checkbox>
        </md-input-container>

        <md-input-container ng-switch-when="bool" flex>
            <md-checkbox ng-model="$ctrl.ngModel[item.name]">{{item.label ? item.label : item.name}}</md-checkbox>
        </md-input-container>

        <md-input-container ng-switch-when="select" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <md-select ng-model="$ctrl.ngModel[item.name]">
                <md-option ng-value="opt" ng-repeat="opt in item.options">{{ opt }}</md-option>
            </md-select>
        </md-input-container>

        <div class="cmp-block-compact" ng-switch-when="storage" flex>
            <h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>
            <div class="cmp-block-compact-content">
                <cmp-storage
                        data="$ctrl.ngModel[item.name]"
                        available="item.available"
                        workspace-name="$ctrl.workspace"
                        cluster-id="$ctrl.clusterId"
                        only-data="true"
                        form="add"
                ></cmp-storage>
            </div>
        </div>

        <md-input-container ng-switch-when="string" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="text" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>

        <md-input-container ng-switch-when="int" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="int8" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="int16" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="int32" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="int64" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>

        <md-input-container ng-switch-when="uint" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="uint8" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="uint16" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>

        <md-input-container ng-switch-when="float" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="any" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>
        <md-input-container ng-switch-when="double" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="number" step="any" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>

        <md-input-container ng-switch-when="text" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <textarea type="text" ng-model="$ctrl.ngModel[item.name]"></textarea>
        </md-input-container>

        <div class="cmp-block-compact" ng-switch-when="group" flex>
            <h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>
            <div class="cmp-block-compact-content" flex>
                <cmp-form-constructor  ng-model="$ctrl.ngModel" api="$ctrl.groupApis[item.name]" options="item.elements" flex></cmp-form-constructor >
            </div>
        </div>


        <div class="cmp-block-compact" ng-switch-when="list" flex>
            <h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>
            <div class="cmp-block-compact-content" flex>
                <cmp-autolist-single
                        class="flex"
                        data="$ctrl.ngModel[item.name]"
                        form="$ctrl.form"
                        type="item.listType"
                ></cmp-autolist-single>
                <!--<cmp-autolist-->
                        <!--flex-->
                        <!--data="$ctrl.ngModel[item.name]"-->
                        <!--labels="['name']"-->
                        <!--attr="{-->
                                    <!--type: {-->
                                        <!--type: 'select'-->
                                    <!--},-->
                                    <!--volumes: {-->
                                       <!--type: item.types-->
                                    <!--}-->
                                <!--}"-->
                        <!--form="$ctrl.form"-->
                <!--&gt;</cmp-autolist>-->
            </div>
        </div>

        <div class="cmp-block-compact" ng-switch-when="volume" flex>
            <h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>
            <div class="cmp-block-compact-content" flex layout="row" layout-align="start">
                <cmp-select-project-volume volumes="$ctrl.appVolumes" app-controller="$ctrl.appController" ng-model="$ctrl.ngModel[item.name]"></cmp-select-project-volume>
                <!--<md-input-container flex="50">-->
                    <!--<label>name</label>-->
                    <!--<md-select ng-model="$ctrl.ngModel[item.name].name">-->
                        <!--<md-option ng-value="v.name" ng-repeat="v in $ctrl.appVolumes">{{ v.name }}</md-option>-->
                    <!--</md-select>-->
                <!--</md-input-container>-->
                <!--<md-input-container flex="50">-->
                    <!--<label>version</label>-->
                    <!--<input ng-model="$ctrl.ngModel[item.name].version">-->
                <!--</md-input-container>-->
            </div>
        </div>


        <!--<div class="cmp-block-compact" ng-switch-when="list" flex>-->
            <!--<h5 class="cmp-block-compact-title">{{item.label ? item.label : item.name}}</h5>-->
            <!--<div class="cmp-block-compact-content" flex>-->
                <!--<cmp-autolist-->
                        <!--flex-->
                        <!--data="$ctrl.ngModel[item.name]"-->
                        <!--labels="['name', 'type']"-->
                        <!--attr="{-->
                                    <!--type: {-->
                                        <!--type: 'select'-->
                                    <!--},-->
                                    <!--volumes: {-->
                                       <!--type: item.types-->
                                    <!--}-->
                                <!--}"-->
                        <!--form="$ctrl.form"-->
                <!--&gt;</cmp-autolist>-->
            <!--</div>-->
        <!--</div>-->

        <md-input-container ng-switch-default="" flex>
            <label >{{item.label ? item.label : item.name}} ({{item.type}})</label>
            <input type="text" ng-model="$ctrl.ngModel[item.name]">
        </md-input-container>

    </div>
</div>