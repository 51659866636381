<div class="contentPageTitle">
    <div class="main_width_greed inner">
        <div class="title flex layout-row layout-align-start-center">
            <span class="item">{{"SETITNGS_COMMON_TITLE_PAGA"|translate}}</span>
        </div>
    </div>
</div>

<div class="main_width_greed">
    <div class="state_settings flex layout-row layout-align-space-between-stretch">
        <md-content class="state_settings_menu">
            <md-list class="leftMenu">
                <md-list-item>
                    <a ui-sref="settingsCurrentUser" ui-sref-active="active" class="flex layout-row layout-align-start-center" >
                        <md-icon md-svg-icon="core:user" class="md-avatar"></md-icon><span class="flex">{{$ctrl.currentUser.DisplayName}}</span>
                    </a>
                </md-list-item>
                <cmp-organizations-menu-list></cmp-organizations-menu-list>
            </md-list>
        </md-content>
        <md-content flex ui-view="" style="background-color: transparent!important;">
            <cmp-user-card-big data="$ctrl.currentUser" id="settings_userCard" state-params="$ctrl.stateParams"></cmp-user-card-big>
            <md-card>
                <cmp-organizations-list state-params="$ctrl.stateParams"></cmp-organizations-list>
            </md-card>
        </md-content>
    </div>
</div>