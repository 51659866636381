<md-dialog style="min-width: 400px; width: 70%;">
    <form ng-cloak name="saveTask" ng-submit="$ctrl.saveTemplate(saveTask)">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <div ng-switch="action">
                    <h2>Tempale edit</h2>
                </div>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="md-padding" layout="column">
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.template"></div>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
            </div>
            <md-button ng-click="$ctrl.cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button type="submit" >
                {{ 'COMMON_SAVE' | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
