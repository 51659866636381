<h4><a ui-sref="wsServingList"><md-icon md-svg-icon="core:wsServingList"></md-icon><span class="label">Servings</span></a></h4>
<md-divider></md-divider>
<div class="blockMenu">
    <a class="blockMenuItem" ng-repeat="item in $ctrl.list | orderBy : $ctrl.orderListPrams | filter : $ctrl.filter " ui-sref="wsServing({servingName: item.Name})" ui-sref-active="current">
        <md-icon md-svg-icon="core:status{{item.Health}}" class="status {{$ctrl.data.Health}}" ></md-icon>
        <span class="label" layout="row" layout-align="space-between center">{{$ctrl.Service.Name(item)}}
            <!--{{item.DisplayName || item.Name}}-->
        </span>
    </a>
</div>

