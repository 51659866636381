<md-button class="editButton md-raised" ng-if="$ctrl.CurrentUser.Admin" ng-click="$ctrl.edit()"> edit </md-button>
<md-button
        class="md-fab md-mini"
        aria-label="help"
        ng-if="!$ctrl.link && $ctrl.CurrentUser.Admin">
        ?
        <!--<md-icon md-svg-icon="core:help"></md-icon>-->
</md-button>


<md-button
        class="md-fab md-mini helpButton"
        aria-label="help"
        href="{{$ctrl.link}}"
        target="_blank"
        ng-if="$ctrl.link && $ctrl.CurrentUser.Admin">
        ?
    <!--<md-icon md-svg-icon="core:help"></md-icon>-->
</md-button>
