<div class="layout-row layout-align-start-start layout-wrap">
    <md-input-container flex="100">
        <label>Name</label>
        <input type="text" ng-model="$ctrl.data.Name">
    </md-input-container>
    <!--<md-input-container flex="50">-->
        <!--<label>Application source</label>-->
        <!--<input type="text" ng-model="$ctrl.data.ApplicationSource">-->
    <!--</md-input-container>-->
    <md-input-container flex="50">
        <label>Application source</label>
        <md-select ng-model="$ctrl.data.ApplicationSource">
            <md-option ng-repeat="item in $ctrl.application.Configuration.spec.volumes" ng-value="item.name">{{item.name}}</md-option>
            <md-option value="__s3">s3</md-option>
        </md-select>
    </md-input-container>
    <md-input-container flex="50">
        <label>Application source sub path</label>
        <input type="text" ng-model="$ctrl.data.ApplicationSourceSubPath">
    </md-input-container>
</div>

<cmp-serving-form-spec ng-if="$ctrl.data.Config.spec" data="$ctrl.data.Config.spec" flex="100" project="true"></cmp-serving-form-spec>

