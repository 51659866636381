<!--<script src="https://js.stripe.com/v3/"></script>-->

<form id="payment-form" ng-submit="$ctrl.checkCard()" >

    <md-card>
        <md-card-content class="layout-row layout-wrap layout-align-start">
            <md-input-container class="flex-50">
                <label>Name</label>
                <input type="text" name="Name" ng-model="$ctrl.data.name" required>
            </md-input-container>


            <md-input-container class="flex-50">
                <label>Email</label>
                <input type="email" name="Email" ng-model="$ctrl.data.email" required>
            </md-input-container>

            <md-input-container class="flex-50">
                <label>Zip/Postal Code</label>
                <input name="address_zip" ng-model="$ctrl.data.address_zip" required>
            </md-input-container>

            <md-input-container class="flex-50">
                <label>Addres line</label>
                <input name="address_line1" ng-model="$ctrl.data.address_line1" required>
            </md-input-container>

            <md-input-container class="flex-50">
                <label>City</label>
                <input name="address_city" ng-model="$ctrl.data.address_city" required>
            </md-input-container>

            <md-input-container class="flex-50">
                <label>State/Province</label>
                <input name="address_city" ng-model="$ctrl.data.address_state" required>
            </md-input-container>

          <md-input-container class="flex-100" ng-if="!$ctrl.change">
            <label>Coupon code</label>
            <input name="couponCode" ng-model="$ctrl.data.couponCode">
          </md-input-container>

            <!--<label>Credit or debit card</label>-->
            <md-input-container class="flex" style="width: 100%; min-width: auto;">

                <div id="card-element" ></div>
            </md-input-container>
            <div ng-if="$ctrl.cardError">{{$ctrl.cardError}}</div>

        </md-card-content>

        <md-card-action class="layout-row layout-align-start">
            <div class="flex">

            </div>
            <md-button ng-click="$ctrl.cancel()" ng-disabled="$ctrl.submitInProgress">
              {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button type="submit" ng-disabled="$ctrl.submitInProgress">
              {{'COMMON_SUBSCRIBE' | translate}}
            </md-button>
        </md-card-action>
    </md-card>
</form>
