<div class="fullInfo margin-left" flex layout="column">

  <cmp-api-error-service error="$ctrl.uploadError" ng-if="$ctrl.uploadError"></cmp-api-error-service>
  <div>
    <form ng-submit="$ctrl.grepAction()" layout="row" layout-align="start center">
      <md-input-container flex>
        <label>Grep</label>
        <input ng-model="$ctrl.grep">
      </md-input-container>
      <md-button type="submit">{{"COMMON_SEARCH" | translate}}</md-button>
    </form>
    <md-progress-linear ng-if="$ctrl.uploadPods"></md-progress-linear>
    <cmp-applications-logs-job-pod flex ng-repeat="pod in $ctrl.Pods"
                                   pod="pod"
                                   application="$ctrl.application"
                                   job="$ctrl.data"
                                   resource="$ctrl.resource"
                                   grep="$ctrl.grep"></cmp-applications-logs-job-pod>
  </div>
</div>