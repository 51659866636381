

<!--<md-tabs md-dynamic-height md-border-bottom md-selected="$ctrl.index">-->
    <!--<md-tab  label="{{value.key}}" ng-repeat="(i, value) in $ctrl.data">-->
        <!--<md-input-container  flex>-->
            <!--<label >key</label>-->
            <!--<input type="text" ng-model="$ctrl.data[i].key">-->
        <!--</md-input-container>-->
        <!--<md-input-container  flex>-->
            <!--<label >input</label>-->
            <!--<input type="text" ng-model="$ctrl.data[i].input">-->
        <!--</md-input-container>-->
        <!--<md-input-container  flex>-->
            <!--<label >output</label>-->
            <!--<input type="text" ng-model="$ctrl.data[i].output">-->
        <!--</md-input-container>-->
        <!--<md-input-container  flex>-->
            <!--<label >description</label>-->
            <!--<input type="text" ng-model="$ctrl.data[i].description">-->
        <!--</md-input-container>-->

        <!--<div class="cmp-block-compact-content" flex>-->
            <!--<cmp-object data="$ctrl.data[i].params"></cmp-object>-->
        <!--</div>-->
    <!--</md-tab>-->
    <!--<md-tab label="+" md-on-select="$ctrl.addStream()"></md-tab>-->
<!--</md-tabs>-->


<md-tabs md-selected="$ctrl.index" md-dynamic-height>
    <!--<img ng-src="img/angular.png" class="centered">-->
    <md-tab ng-repeat="(i, value) in $ctrl.data" >
        <md-tab-label>
            {{value.key}}
            <md-icon md-svg-icon="core:close" ng-click="$ctrl.removeStream( i, $event )"></md-icon>
            <!--<img src="img/removeTab.png" ng-click="removeTab(tab)" class="delete">-->
        </md-tab-label>
        <md-tab-body>
            <md-input-container  flex>
                <label >key</label>
                <input type="text" ng-model="$ctrl.data[i].key">
            </md-input-container>
            <md-input-container  flex>
                <label >input</label>
                <input type="text" ng-model="$ctrl.data[i].input">
            </md-input-container>
            <md-input-container  flex>
                <label >output</label>
                <input type="text" ng-model="$ctrl.data[i].output">
            </md-input-container>
            <md-input-container  flex>
                <label >description</label>
                <input type="text" ng-model="$ctrl.data[i].description">
            </md-input-container>

            <div class="cmp-block-compact-content" flex>
                <cmp-object data="$ctrl.data[i].params" keys="$ctrl.paramsKeys"></cmp-object>
            </div>
        </md-tab-body>
    </md-tab>

    <md-tab>
        <md-tab-label>
            <md-icon md-svg-icon="core:plus" ng-click="$ctrl.addStream($event)"></md-icon>
        </md-tab-label>
        <md-tab-body>
            <md-button ng-click="$ctrl.addStream($event)">add streamm</md-button>
        </md-tab-body>
    </md-tab>


    <!--<md-tab label="+" md-on-select="$ctrl.addStream()"></md-tab>-->
</md-tabs>
