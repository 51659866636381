<div class="contentVersion">
    <cmp-serving-form-config data="$ctrl.data.Config.serving" serving="$ctrl.data.Config.serving" flex="100" is-model="true" model-spec="$ctrl.data.Config.servingSpec"></cmp-serving-form-config>
    <cmp-serving-form-spec data="$ctrl.data.Config.servingSpec" flex="100"></cmp-serving-form-spec>
    <div layout="row" layout-align="start center" ng-if="$ctrl.canManage">
        <div flex>
            <md-progress-linear ng-if="$ctrl.saving" error="$ctrl.saving"></md-progress-linear>
            <cmp-error-toast ng-if="$ctrl.saveError" error="$ctrl.saveError"></cmp-error-toast>
        </div>
        <!--<md-button ng-click="$ctrl.edit = !$ctrl.edit" ng-disabled="$ctrl.saving">{{'COMMON_CANCEL' | translate}}</md-button>-->
        <md-button ng-click="$ctrl.saveConfig($ctrl.data)" ng-disabled="$ctrl.saving">{{'COMMON_SAVE' | translate}}</md-button>
    </div>
</div>