<md-menu-bar style="margin-right: 0; padding-right: 0;">
    <md-menu class="menu nested-menu" md-position-mode="target-right target" ng-if="$ctrl.permissions.can('application.manage')">
        <md-button class="md-icon-button" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item >
                <md-button ng-if="!$ctrl.data.serve"
                           class="_md-raised _md-primary"
                           ng-click="$ctrl.listController.getServeDialog($event, $ctrl.data)"
                           ng-disabled="!$ctrl.appController.data.Enabled" >
                    <md-icon md-svg-icon="core:play"></md-icon>
                    {{"MLAPPS_JOB_SERVE" | translate}}
                </md-button>
                <md-button
                        ng-if="$ctrl.data.serve"
                        class="_md-raised _md-primary"
                        ng-click="$ctrl.listController.action('stop_serving', {task: $ctrl.data.task_name, build: $ctrl.data.serve.build})"
                        ng-disabled="!$ctrl.data.serve.status == 'Running' || $ctrl.loading"
                ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
            </md-menu-item>



            <md-menu-item >
                <md-menu  >
                    <md-button ng-click="$ctrl.openMenu($mdMenu, $event)">
                        <md-icon md-svg-icon="core:save" style="margin-right: 16px;"></md-icon>
                        {{"COMMON_EXPORT" | translate}}
                    </md-button>
                    <md-menu-content width="2">
                        <md-menu-item ng-repeat="eTask in $ctrl.listTask()">
                            <md-button ng-click="$ctrl.listController.action('export', {task: $ctrl.data.task_name, build: $ctrl.data.build, eTask:eTask.name})">
                                <md-icon md-svg-icon="core:progress-upload"></md-icon>
                                {{ eTask.name }}</md-button>
                        </md-menu-item>
                        <md-divider ng-if="$ctrl.listTask().length" ></md-divider>
                        <md-menu-item >
                            <md-button ng-click="$ctrl.listController.getSaveDialog($event, $ctrl.data)" ng-disabled="!$ctrl.appController.data.Enabled">
                                <md-icon md-svg-icon="core:save"></md-icon>
                                {{"COMMON_DEFAULT" | translate}}
                            </md-button>
                        </md-menu-item>

                    </md-menu-content>
                </md-menu>
            </md-menu-item>
            <md-menu-item ng-if="$ctrl.data.git_revision">
                <md-button ui-sref="wsApplication.tab({revision: $ctrl.data.git_revision, tab: 'models', tabItem: $ctrl.data.task_name})">
                    <md-icon md-svg-icon="core:download"></md-icon> {{"PROJECT_CHECKOUT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="$ctrl.data.status == 'Running' || $ctrl.data.status == 'Pending' || $ctrl.data.status == 'Starting'">
                <md-button ng-click="$ctrl.listController.action('stop', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                    <md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item >
                <md-button ng-click="$ctrl.listController.action('restart', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                    <md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESTART"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-divider></md-menu-divider>
            <md-menu-item>
                <md-button aria-label="icon" ui-sref="wsApplication.tab({tab: 'metrics', tabItem: $ctrl.data.name})">
                    <md-icon md-svg-icon="core:chart"></md-icon> {{"COMMON_METRICS"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button aria-label="icon" ng-click="opened = !!!opened">
                    <md-icon md-svg-icon="core:note-text"></md-icon> {{"COMMON_LOGS"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-divider></md-menu-divider>
            <md-menu-item>
                <md-button aria-label="icon" ng-click="$ctrl.listController.action('delete', {task: $ctrl.data.task_name, build: $ctrl.data.build})">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-menu-bar>
