<form name="newChart" ng-submit="$ctrl.save(newChart, $event)" flex>
    <md-card>
        <md-card-content layout="column">
            <cmp-repo-select
                repository-url="$ctrl.data.RepositoryUrl"
                repository-dir="$ctrl.data.RepositoryDir"
            ></cmp-repo-select>
            <md-checkbox ng-model="$ctrl.scanAction"><span translate>CHART_SCAN</span></md-checkbox>
            <md-input-container ng-if="!$ctrl.scanAction">
                <label translate>COMMON_IDENT</label>
                <input
                    name="Name"
                    ng-model="$ctrl.data.Name"
                    ng-change="$ctrl.checkName(newChart)"
                    ng-maxlength="$ctrl.validate.maxName"
                    ng-minlength="$ctrl.validate.minName"
                    ng-pattern="$ctrl.validate.namePattern"
                >
                <help-hint hint="chart.name"></help-hint>
                <div ng-messages="newChart.Name.$error">
                    <div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.nameErrorMessage" error="$ctrl.nameErrorMessage"></cmp-error-toast></div>
                    <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                    <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                    <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
                    <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
                </div>
            </md-input-container>
        </md-card-content>
        <md-card-action flex layout="row" layout-align="space-between center">
            <div flex>
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
            </div>
            <div>
                <md-button type="submit" ng-disabled=" !$ctrl.canSave(newChart) "><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
                <md-button ng-click="$ctrl.cancel()"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
            </div>
        </md-card-action>
        <cmp-api-error-service ng-if="$ctrl.savingError" error="$ctrl.savingError" format="text"></cmp-api-error-service>
    </md-card>
</form>