<md-card style="margin: 0px">
    <md-list>
        <md-list-item ng-if="$ctrl.edit" class="small" >
            <p>Use GUI only for creating the small datasets with few files. To upload real dataset with many files you can use <a href="https://github.com/kuberlab/pluk/releases" target="_blank">kdataset CLI utility</a></p>
        </md-list-item>
        <md-divider ng-if="$ctrl.edit" ></md-divider>
        <md-list-item class="secondary-button-padding small" >
            <p>
                <span ng-repeat="item in $ctrl.currentDir" class="fileName">
                    <span ng-if="!$first"> / </span>
                    <span ng-if="$last">
                        <span ng-if="item.name" ng-class="{newFolder: item.new}">{{item.name}}</span>
                        <md-icon class="s16" ng-if="!item.name" md-svg-icon="core:tree-folder"></md-icon>
                    </span>
                    <a ng-if="!$last" ng-click="$ctrl.selectParent($index+1)" >
                        <span ng-if="item.name" ng-class="{newFolder: item.new}">{{item.name}}</span>
                        <md-icon class="s16"  ng-if="!item.name" md-svg-icon="core:tree-folder"></md-icon>
                    </a>
                </span>
            </p>
            <div class="md-secondary " >

                <md-button ng-if="!$ctrl.edit" href="{{$ctrl.ctrl.data.DownloadURL}}" target="_blank" class="md-button md-raised small" download >
                    <md-icon md-svg-icon="core:link" class="s16"></md-icon>
                    Download (<span ng-bind-html="$ctrl.ctrl.listController.CatalogService.datasetVersionSize($ctrl.ctrl.data)"></span>)
                </md-button>

                <span>
                    <md-menu ng-if="$ctrl.edit" class="md-secondary" md-position-mode="target-right target">
                        <md-button class="md-primary md-raised small" ng-click="$mdMenu.open()">new</md-button>
                        <md-menu-content width="3">
                            <md-menu-item>
                                <md-button ng-click="$ctrl.addFolder($event)"><md-icon class="s16" md-svg-icon="core:tree-folder"></md-icon> folder</md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button ng-click="$ctrl.addFiles($event)"><md-icon class="s16" ng-if="!item.name" md-svg-icon="core:tree-file"></md-icon> files</md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </span>

                <md-button ng-click="$ctrl.getTree()" class="md-icon-button small" ><md-icon class="s16" md-svg-icon="core:reload"></md-icon></md-button>
            </div>
        </md-list-item >
        <md-divider></md-divider>
        <md-list-item ng-if="$ctrl.currentDir.length > 1" ng-click="$ctrl.selectParent($ctrl.currentDir.length - 1)">
            <p>
                <md-icon class="s16" md-svg-icon="core:tree-folder"></md-icon>
                <span class="fileName">..</span></span>
            </p>
        </md-list-item>
        <md-content style="max-height: 500px;">
            <md-list-item class="secondary-button-padding small" ng-click="$ctrl.selectItem(item)" class="cmp-clickable" ng-repeat="item in $ctrl.chield | orderBy:['new', '-dir','name'] | limitTo : $ctrl.limit ">
                <p>
                    <md-icon class="s16" md-svg-icon="core:tree-{{item.dir ? 'folder': 'file'}}"></md-icon>
                    <span class="fileName"><span ng-class="{newFolder: item.new}">{{item.name}}</span></span>
                </p>
                <div class="md-secondary" >
                    <span>{{item.modtime | timePassed }}</span>
                    <span  style="min-width: 80px; text-align: right; display: inline-block;"><span ng-if="!item.dir" ng-bind-html="item.sSize"></span></span>
                    <md-button ng-click="$ctrl.delete(item, $event)" class="md-icon-button small" ng-if="$ctrl.edit"><md-icon class="s16" md-svg-icon="core:delete"></md-icon></md-button>
                </div>
            </md-list-item>

            <md-list-item ng-if="$ctrl.hasMore()" ng-click="$ctrl.more() "><p>more...</p></md-list-item>
            <md-list-item class="" ng-if="!$ctrl.chield.length">
                <p class="md-padding" ng-if="$ctrl.edit"> The folder is empty. You can <a ng-click="$ctrl.addFolder($event)">create folders</a> or <a ng-click="$ctrl.addFiles($event)">add files</a>.</p>
                <p class="md-padding" ng-if="!$ctrl.edit"> Version not have files. </p>
            </md-list-item>
        </md-content>
        <please-wait ng-if="$ctrl.loading">Update list. Plase wait.</please-wait>
    </md-list>
</md-card>
