<div ng-if="!$ctrl.asForm" >
    <md-button ng-click="$ctrl.edit()">edit</md-button>
    <div ng-if="$ctrl.file" btf-markdown="$ctrl.file"></div>
    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
</div>
<div ng-if="$ctrl.asForm">
    <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.file"></div>
    <div layout="row" layout-align="start center">
        <div flex>
            <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
        </div>
        <md-button ng-click="$ctrl.cancel()">{{"COMMON_cancel" | translate}}</md-button>
        <md-button ng-click="$ctrl.save()">{{"COMMON_SAVE" | translate}}</md-button>
    </div>
</div>
