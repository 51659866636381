
<table>
    <thead>
        <tr>
            <th ng-repeat="m in $ctrl.meta">
                <md-select ng-model="$ctrl.filter[m.name]" ng-if="m.filtered">
                    <md-option ng-repeat="item in $ctrl.filtered[m.name].list" ng-value="item">{{item}}</md-option>
                </md-select>
                <span ng-if="!m.filtered">{{m.label || m.name}}</span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr ng-repeat="item in $ctrl.data | filter : $ctrl.filter ">
            <td ng-repeat="m in $ctrl.meta" ng-switch="m.type">
                <img ng-switch-when="image" ng-click="$ctrl.parent.showDialog(item[m.name], m.type)" src='data:image/jpeg;base64,{{item[m.name]}}' />
                <spam ng-switch-when="data">
                    <a ng-if="item[m.name]" ng-click="$ctrl.parent.showDialog(item[m.name], m.type)">{{m.type}}</a>
                    <spam ng-if="!item[m.name]">-</spam>
                </spam>
                <span ng-switch-when="edit" >
                    <a ng-repeat="v in item[m.name].values" ng-click="$ctrl.sendClarify(v, item, m)" class="md-button" style="margin: 0; text-transform: lowercase;"><md-icon md-svg-icon="core:check"></md-icon>{{v.name}}</a>
                    <a ng-if="item[m.name].alternate" ng-click="$ctrl.showClarifyDialog({}, item, m)" class="md-button" style="margin: 0; text-transform: lowercase;" ><md-icon md-svg-icon="core:check"></md-icon> other</a>
                </span>

                <span ng-switch-default >{{item[m.name] | d3filter:m.format }}</span>
            </td>
        </tr>
    </tbody>
</table>

<!--<pre>{{$ctrl.meta | jsonStringify}}</pre>-->
<!--<pre>{{$ctrl.data | jsonStringify}}</pre>-->
