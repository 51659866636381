<div>
    <div layout="row" layout-align="start center">
        <md-input-container flex="15">
            <label translate>COMMON_VERSION</label>
            <md-select ng-model="$ctrl.version" ng-change="$ctrl.getVersionsComplite()">
                <md-option ng-repeat="v in $ctrl.versions" ng-value="v">{{v.Version}}</md-option>
            </md-select>
        </md-input-container>
        <div flex></div>
        <md-button ng-click="$ctrl.install()">{{ 'COMMON_INSTALL' | translate }}</md-button>
        <!--<md-button ng-click="$ctrl.download()">{{ 'COMMON_DOWNLOAD' | translate }}</md-button>-->

        <a layout="row"
           class="md-button md-raised"
           style="margin-left: 0.5em"
           ng-disabled="$ctrl.isDownloading"
           ng-click="$ctrl.download()">
            <md-icon md-svg-icon="core:link"></md-icon>
            <span style="margin: 0 10px">download</span>
            <md-progress-circular ng-if="$ctrl.isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>
        </a>

        <!---->
        <!--<div class="flex layout-align-end-center layout-row" style="width: 100%; padding-top: 2em;" ng-if="$ctrl.version.Status == 'ok'">-->
            <!--<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>-->
            <!--<a layout="row"-->
               <!--class="md-button md-raised"-->
               <!--style="margin-left: 0.5em"-->
               <!--ng-disabled="isDownloading"-->
               <!--ng-click="$ctrl.download()">-->
                <!--<md-icon md-svg-icon="core:link"></md-icon>-->
                <!--<span style="margin: 0 10px">download</span>-->
                <!--<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>-->
            <!--</a>-->
        <!--</div>-->
    </div>
    <cmp-error-toast ng-if="$ctrl.downloadError" error="$ctrl.downloadError"></cmp-error-toast>
    <div
            ui-ace="{mode: 'yaml', onLoad: $ctrl.aceLoaded}"
            ng-model="$ctrl.yaml"
    ></div>
</div>