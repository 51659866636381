<div layout="row" class="applicationMetrics">
    <section class="md-content leftMenu" flex="25">

        <h4>Metrics</h4>
        <ul>
            <li ng-repeat="item in $ctrl.Metrics" >
                <md-checkbox ng-model="item.active"  ng-disabled="$ctrl.loading" ng-change="$ctrl.changeMetricsList()">{{item.title | translate}}</md-checkbox>
            </li>
        </ul>
        <!--{{$ctrl.Metrics}}-->
    </section>
    <section class="md-content md-padding" flex>
        <div layout="row" layout-align="start center">
            <div flex></div>

            <md-input-container >
                <md-checkbox ng-model="$ctrl.details" style="margin: 0;" ng-change="$ctrl.getTaskGraph()" ng-disabled="$ctrl.loading" >Details</md-checkbox>
            </md-input-container>

            <md-menu ng-disabled="$ctrl.loading" >
                <md-button aria-label="Open phone interactions menu" ng-click="$mdMenu.open()">
                    {{$ctrl.timeIntervanTitle()}}
                </md-button>
                <md-menu-content width="4">
                    <md-menu-item ng-repeat="item in $ctrl.timeIntervals">
                        <md-button ng-click="$ctrl.setTimeInterval({start: null, end: null, interval: item.value})">
                            {{item.title}}
                        </md-button>
                </md-menu-content>
            </md-menu>

            <md-menu ng-if="$ctrl.currentAction == 'taskGraph'" ng-disabled="$ctrl.loading" >
                <md-button aria-label="Open phone interactions menu" ng-click="$mdMenu.open()">
                    {{$ctrl.intervanTitle()}}
                </md-button>
                <md-menu-content width="4">
                    <md-menu-item ng-repeat="item in $ctrl.intervals">
                        <md-button ng-click="$ctrl.setInterval(item.value)">
                            {{item.title}}
                        </md-button>
                </md-menu-content>
            </md-menu>
            <md-button ng-click="$ctrl.getTaskGraph()" class="md-raised" ng-disabled="$ctrl.loading" ><md-icon md-svg-icon="core:reload"></md-icon> update</md-button>
            <!--<md-button ng-click="$ctrl.updateMetrics()" class="md-raised" ng-disabled="$ctrl.loading" ><md-icon md-svg-icon="core:reload"></md-icon> update</md-button>-->
        </div>
        <cmp-error-toast ng-if="$ctrl.apiError" error="$ctrl.apiError"></cmp-error-toast>


        <div ng-repeat=" item in $ctrl.Metrics " ng-if="item.active" class="metricItem" >
            <h4 translate>{{item.title}}</h4>
            <div class="loading" flex layout="column" layout-align="center" ng-if="$ctrl.loading">
              <md-progress-circular style="margin:auto"></md-progress-circular>
            </div>

            <nvd3 options='item.options' data="$ctrl.d3Metrics.metrics[item.name].series" api="item.api" flex on-ready="$ctrl.nvd3Callback" id="{{item.title}}" style="height:350px" ></nvd3>
        </div>
    </section>
</div>

