<md-menu class="md-secondary" md-position-mode="target-right target">
    <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
        <md-icon md-svg-icon="core:short-menu"></md-icon>
    </md-button>
    <md-menu-content width="3">
        <md-menu-item ng-if="$ctrl.permissions.can('mlapp.manage')" >
            <md-button aria-label="icon" ng-if="$ctrl.data.Enabled" ng-click="$ctrl.enabled()">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_DISABLE"|translate}}
            </md-button>
            <md-button aria-label="icon" ng-if="!$ctrl.data.Enabled" ng-click="$ctrl.enabled()">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_ENABLE"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('mlapp.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.actionForm()">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="$ctrl.permissions.can('mlapp.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'MLAPP_DELETE_DIALOG_MESSAGE', 'MLAPP_DELETE_DIALOG_TITLE')">
                <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-divider></md-menu-divider>
        <md-menu-item>
            <!--<md-button aria-label="icon" href="{{$ctrl.downloadURL()}}" target="_blank" ng-load="$ctrl.fileLoad()">-->
                <!--<md-icon md-svg-icon="core:chart-download"></md-icon> {{"COMMON_DOWNLOAD" | translate}}-->
            <!--</md-button>-->
            <md-button aria-label="icon" ng-click="$ctrl.download()" >
                <md-icon md-svg-icon="core:chart-download"></md-icon> {{"COMMON_DOWNLOAD" | translate}}
            </md-button>
        </md-menu-item>
    </md-menu-content>
</md-menu>