    <!--<form ng-if="$ctrl.data.Type != 'project'" name="servingConfug" ng-submit="$ctrl.send(servingConfug, fileM, fApiM)" layout="column" layout-align="start" >-->
        <!--<div>-->
            <!--<h5>Request params</h5>-->
            <!--<div flex layout="row" layout-align="start center">-->

                <!--<md-input-container flex>-->
                    <!--<label>port</label>-->
                    <!--<input type="number" ng-model="$ctrl.rConfig.port" required ng-change="$ctrl.setUrl()">-->
                <!--</md-input-container >-->
                <!--<md-input-container flex>-->
                    <!--<label>model</label>-->
                    <!--<input type="text" ng-model="$ctrl.rConfig.model" required ng-change="$ctrl.setUrl()">-->
                <!--</md-input-container >-->
                <!--<md-input-container flex>-->
                    <!--<label>signature</label>-->
                    <!--<input ng-model="$ctrl.rConfig.signature" ng-disabled="!$ctrl.rConfig.port && !$ctrl.rConfig.model" ng-change="$ctrl.setUrl()">-->
                <!--</md-input-container>-->
                <!--<md-input-container flex>-->
                    <!--<label>version</label>-->
                    <!--<input ng-model="$ctrl.rConfig.version" ng-disabled="!$ctrl.rConfig.signature" ng-change="$ctrl.setUrl()">-->
                <!--</md-input-container>-->
            <!--</div>-->
            <!--<div>Request url: {{$ctrl.requestUrl}}</div>-->
        <!--</div>-->
    <!--</form>-->
    <cmp-gallery-serving-parameters data="$ctrl.data" auto-request="false"></cmp-gallery-serving-parameters>
