<div ng-if="!$ctrl.asForm">
    <div layout="row" layout-align="start" flex class="title" >
        <div class="cmp-clickable"  layout="row" layout-align="start center" flex ng-click="$ctrl.activate()">
        <!--<div class="cmp-clickable"  layout="row" layout-align="start center" flex ui-sref="wsCatalogDataItem($ctrl.listData)" >-->
            <div flex>Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</div>
            <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>
            <div>{{$ctrl.data.Updated | date: 'dd/MM/yyyy HH:mm'}}</div>
        </div>
        <a layout="row"
           ng-if="!$ctrl.data.Editing"
           href="{{$ctrl.data.DownloadURL}}"
           target="_blank"
           class="md-button md-raised"
           style="margin-left: 0.5em"
           download
        >
            <md-icon md-svg-icon="core:link"></md-icon>
            Download (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)
        </a>

        <md-button ng-click="$ctrl.commit()" class="md-raised" ng-if="$ctrl.data.Editing">commit</md-button>

        <md-menu class="md-secondary" md-position-mode="target-right target">
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.listController.canManage">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <md-menu-item>
                    <md-button ng-click="$ctrl.listController.delete($ctrl.data)">
                        <md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}
                    </md-button>
                </md-menu-item>
                <md-menu-item ng-if="$ctrl.data.Editing">
                    <md-button
                       href="{{$ctrl.data.DownloadURL}}"
                       target="_blank"
                       download
                    >
                        <md-icon md-svg-icon="core:link"></md-icon>Download (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
    <div layout="row" layout-align="start" flex ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && !$ctrl.data.Editing"  class="contentVersion" md-whiteframe="1" style="height: 450px" >
        <div flex="33" layout="column" layout-align="start ">
            <h4>tree dir</h4>
            <md-content flex>
                <cmp-dataset-version-tree ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource"></cmp-dataset-version-tree>
            </md-content>
        </div>
        <div style="width: 10px"></div>
        <cmp-dataset-version-viewer ctrl="$ctrl" file-url="$ctrl.fileUrl" file-info="$ctrl.fileInfo" flex layout="column" layout-align="start"></cmp-dataset-version-viewer>
    </div>

    <div layout="row" layout-align="start" flex ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && $ctrl.data.Editing" class="contentVersion" style="height: 450px" >
        <div flex="33" layout="column" layout-align="start">
            <div layout="row" layout-align="start center">
                <h4 flex>tree dir</h4>
                <md-button ng-click="$ctrl.addFolder()">add folder</md-button>
            </div>
            <md-content flex>
                <cmp-dataset-version-tree ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" edit="true"></cmp-dataset-version-tree>
            </md-content>
            <div layout="row" layout-align="center">
                <md-button ng-click="$ctrl.commit()">commit</md-button>
            </div>
        </div>
        <div style="width: 10px"></div>
        <div flex layout="column" layout-align="start"  ng-if="$ctrl.data.Editing">
            <form name="upload" ng-submit="$ctrl.uploadFile(upload)"  layout="row" layout-align="start center">
                <md-input-container flex><label>upload path</label><input name="path" ng-model="$ctrl.fileUrl"></md-input-container>
                <input type="file" name="file" ng-model="$ctrl.file" multiple>
                <md-button type="submit">upload</md-button>
            </form>
            <md-content flex>
                <cmp-dataset-version-uploading-files  ng-repeat="f in $ctrl.uploadingFiles" data="f" ctrl="$ctrl" ></cmp-dataset-version-uploading-files>
            </md-content>
        </div>
    </div>
</div>

<div ng-if="$ctrl.asForm" flex class="editForm">
    <div layout="row" layout-align="start" flex >
        <div flex="50">
            <md-input-container>
                <label>Version</label>
                <input ng-model="$ctrl.data.Version" ng-disable="true">
            </md-input-container>
        </div>
        <div flex="50">
            <md-content flex>
                <cmp-dataset-version-tree ng-if="$ctrl.data.Version" ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" edit="true"></cmp-dataset-version-tree>
            </md-content>
        </div>
    </div>


</div>

<form ng-if="$ctrl.data.isNew" ng-submit="$ctrl.saveNew(nameVersion)" name="nameVersion" class="setNewVersion" flex layout="column" layout-align="center center">
    <md-card layout="column" layout-align="canter" class="md-padding">
        <p ng-if="$ctrl.data.From">From version {{$ctrl.data.From}}</p>
        <md-input-container>
            <label>Set new version</label>
            <input ng-model="$ctrl.newVersion" />
        </md-input-container>
        <div layout="row" layout-align="center">
            <md-button ng-click="$ctrl.cancel()">cancel</md-button>
            <md-button type="submit">set</md-button>
        </div>
    </md-card>
</form>