<layout-page-content-list class="menuLevel layoutMenu" >
    <h3 ng-if="$ctrl.isCatalog"><a ui-sref="catalog"><md-icon md-svg-icon="core:catalog"></md-icon><span class="label">Catalog</span></a></h3>
    <div ng-if="!$ctrl.isCatalog">
        <layout-ws></layout-ws>
        <h4><a ui-sref="wsCatalog"><md-icon md-svg-icon="core:wsCatalog"></md-icon><span class="label">Catalog</span></a></h4>
    </div>

    <!--<h3><a ui-sref="workspace"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{$ctrl.WS.DisplayName}}</span></a></h3>-->
    <md-divider></md-divider>
    <cmp-catalog-menu is-item="true"></cmp-catalog-menu>
</layout-page-content-list>


<layout-page-content-item flex layout="column" layout-align="start">

    <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
        <layout-button-submenu></layout-button-submenu>
        <layout-back></layout-back>
        <h3>{{$ctrl.data.DisplayName}}</h3>
        <div flex></div>
        <md-menu-bar  class="padding-zero layout-row layout-align-end-center">

            <div ng-if="!$ctrl.isCatalog && !$ctrl.data.Global">
                <md-icon md-svg-icon="{{$ctrl.data.Published ? 'core:publish' : 'core:unpublish' }}"></md-icon>
                <md-tooltip>{{ $ctrl.data.Published ? "Published" : "not published"  | translate}}</md-tooltip>
                <!--<md-buttin ng-if="$ctrl.data.Published" class="published">Published</md-buttin>-->
                <!--<md-button ng-if="!$ctrl.data.Published" class="not published">Not published</md-button>-->
            </div>
            <md-button ng-if="$ctrl.data.Global && !$ctrl.isCatalog" class="published">
                <b>GL</b>
                <md-tooltip>in global catalog</md-tooltip>
            </md-button>
            <cmp-catalog-rating
                    data="$ctrl.data"
                    resource="$ctrl.resource"
                    resource-params="{wsId: $ctrl.data.WorkspaceName, Name: $ctrl.data.Name, Interface:$ctrl.data.Interface}">
            </cmp-catalog-rating>
            <md-button>
                <md-icon md-svg-icon="core:comments"></md-icon>
                {{$ctrl.data.Comments}}
                <md-tooltip>comments</md-tooltip>
            </md-button>

            <md-menu class="md-secondary" md-position-mode="target-right target" ng-if="!$ctrl.isCatalog">
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.canManage">
                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item ng-if="!$ctrl.isChart">
                        <md-button aria-label="icon" ng-click="$ctrl.actionForm()">
                            <md-icon md-svg-icon="core:edit"></md-icon>{{ "COMMON_EDIT"  | translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item  ng-if="!$ctrl.data.Global">
                        <md-button aria-label="icon" ng-click="$ctrl.publish()">
                            <md-icon md-svg-icon="{{$ctrl.data.Published ? 'core:unpublish' : 'core:publish' }}"></md-icon>{{ $ctrl.data.Published ? "COMMON_UNPUBLISH" : "COMMON_PUBLISH"  | translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item ng-if="$ctrl.data.Published">
                        <md-button aria-label="icon" ng-click="$ctrl.global()">
                            <md-icon md-svg-icon="{{$ctrl.data.Global ? 'core:unpublish' : 'core:publish' }}"></md-icon>{{ $ctrl.data.Global ? "COMMON_UNGLOBAL_CATALOG" : "COMMON_GLOBAL_CATALOG"  | translate}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item>
                        <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, $ctrl.dialogMessage, $ctrl.dialogTitle)">
                            <md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-menu-bar>

    </layout-page-content-title>

<md-content flex>
    <md-card class="chartCardFull catalogCardItem">

        <md-card-content class="padding-content layout-row layout-align-space-between-start" >
            <div class="md-media-xl card-media" layout="column" style='background: url("{{$ctrl.pic}}") center center no-repeat; background-size: contain;'>
            <!--<div class="md-media-xl card-media" layout="column" style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>-->
                <md-button ng-if="!$ctrl.isChart && $ctrl.canManage" class="md-icon-button edit" aria-label="Add service"  ng-click=" $ctrl._editPic = !!!$ctrl._editPic" >
                    <md-icon md-svg-icon="core:edit"></md-icon>
                </md-button>

                <form class="uploadFile" ng-if="$ctrl._editPic" name="Picture" ng-submit="$ctrl.uploadFile(Picture, fileP, 'picture', 'picture', lfApiP)" layout="column">
                    <div layout="row" layout-align="center center">
                        <lf-ng-md-file-input
                                lf-files="fileP"
                                flex progress
                                ng-disabled="$ctrl.isLoading.picture"
                                lf-browse-label=" "
                                lf-remove-label=" "
                                required
                                lf-api="lfApiP"></lf-ng-md-file-input>

                    </div>
                    <div layout="row" layout-align="start center">
                        <md-button flex="50" type="submite" ng-disabled="$ctrl.isLoading.picture || !fileP || !fileP.length">
                            <span ng-show="!$ctrl.isLoading.picture" >{{"COMMON_UPLOAD" | translate}}</span>
                            <div flex linear ng-if="$ctrl.isLoading.picture">
                                {{$ctrl.loadingProgres}}%
                                <md-progress-linear ng-if="$ctrl.isLoading.picture" flex></md-progress-linear>
                            </div>
                        </md-button>
                        <md-button flex="50" ng-click="$ctrl.addFile = false">{{"COMMON_CANCEL" | translate}}</md-button>
                    </div>

                    <cmp-error-toast ng-if="$ctrl.loadingError.picture" error="$ctrl.loadingError.Picture"></cmp-error-toast>
                </form>
            </div>
            <!--<img src="{{$ctrl.data.Picture}}" class="md-media-xl card-media" alt="$ctrl.data.Name">-->
            <md-card-actions layout="column" layout-align="start" flex class="chartInfo">
                <div flex layout="row" layout-align="space-between center" style="width: 100%">
                    <div flex></div>


                </div>
                <div ng-if="!$ctrl.asForm">

                    <div class="md-headline">{{$ctrl.data.DisplayName}}</div>
                    <div ng-if="$ctrl.isCatalog"><md-icon md-svg-icon="core:link" ></md-icon> <a ui-sref="wsCatalog.list.item($ctrl.$state.params)">{{$ctrl.data.WorkspaceName}}</a></div>
                    <div ng-if="$ctrl.data.ForkedFrom">
                        <md-icon md-svg-icon="core:share" ></md-icon> forked from  <a ui-sref="wsCatalog.list.item({item: $ctrl.data.ForkedFrom.Name , Interface: $ctrl.data.Interface, wsId: $ctrl.data.ForkedFrom.WorkspaceName})">{{$ctrl.data.ForkedFrom.WorkspaceDisplayName}}/{{$ctrl.data.ForkedFrom.DisplayName}}</a>
                    </div>
                    <!--<div ng-if="$ctrl.data.Published" class="published">Published</div>-->
                    <!--<div ng-if="!$ctrl.data.Published" class="not published">Not published</div>-->

                    <!--<div ng-if="$ctrl.data.Global && $ctrl.isCatalog" class="published"><b>GLOBAL</b></div>-->


                    <div class="broken" ng-if="$ctrl.data.Broken">Broken</div>

                    <p ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</p>

                    <div>
                        <!--<span ng-if="$ctrl.data.Keywords && $ctrl.data.Keywords.length > 0"><strong>Keywords:</strong> <span ng-repeat="k in $ctrl.data.Keywords" class="keyword"><a ui-sref="$ctrl.$state.go($ctrl.stateCatalog, {search:k})">{{k}}</a></span></span>-->
                        <span ng-if="$ctrl.data.Keywords && $ctrl.data.Keywords.length > 0"><strong>Keywords:</strong> <span ng-repeat="k in $ctrl.data.Keywords" class="keyword">
                            <!--<a ui-sref="{{$ctrl.listState}}({ Interface: $ctrl.data.Interface, search:k })">{{k}}</a>-->
                            <a ui-sref="catalog_new.list({ Interface: $ctrl.data.Interface, search:k })">{{k}}</a>
                        </span></span>
                    </div>


                    <div ng-if="$ctrl.isChart">
                        <strong>Version:</strong> {{$ctrl.data.Version}}<br/>
                        <strong>Repository:</strong> <a href="{{$ctrl.data.RepositoryURL}}" target="_blank">{{$ctrl.data.RepositoryURL}}</a><br/>
                        <strong>Dir:</strong> {{$ctrl.data.RepositoryDir}}<br/>

                        <!--<span ng-if="$ctrl.data.Keywords && $ctrl.data.Keywords.length > 0"><strong>Keywords:</strong> <span ng-repeat="k in $ctrl.data.Keywords" class="keyword"><a ui-sref="catalog({search:k})">{{k}}</a></span></span>-->
                        <div  class="flex layout-align-end-center layout-row" style="width: 100%; padding-top: 2em;">
                            <md-progress-circular ng-if="$ctrl.instaling" class="md-hue-2" md-diameter="20px"></md-progress-circular>
                            <md-button  style="margin-left: 0.5em" class="md-raised" id="application-install-button" ng-click="$ctrl.install($event)" ng-disabled="$ctrl.instaling"><md-icon md-svg-icon="core:install"></md-icon> install to my</md-button>
                            <cmp-api-error-service ng-if="$ctrl.installError" error="$ctrl.installError" format="'dialog'"></cmp-api-error-service>
                            <cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>
                        </div>

                    </div>

                    <div layout="row" layout-align="start center"  ng-if="$ctrl.forkList && !$ctrl.errorForkName">
                        <div flex></div>
                        <md-progress-circular class="md-hue-2" md-diameter="20px" ng-if="$ctrl.forking"></md-progress-circular>
                        <md-menu  md-position-mode="target-right target" >
                            <md-button class="md-raised" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.forkList.length || $ctrl.forking">{{"COMMON_FORK" | translate}}</md-button>
                            <md-menu-content width="3" class="catalogFork">
                                <md-menu-item ng-repeat="item in $ctrl.forkList">
                                    <md-button ng-click="$ctrl.fork(item)" class="catalogForkItem" flex layout="row" layout-align="start start" ng-disabled="!item.can || item.current" >
                                        {{item.ws.DisplayName}} <span ng-if="!item.can" class="readOnly"> ({{"READ_ONLY" | translate}})</span><span ng-if="item.current" class="readOnly"> (current WS)</span>
                                    </md-button>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                    <md-card ng-if="$ctrl.errorForkName">
                        <form class="md-padding" name="setForkName" ng-submit="$ctrl.forkWithName(setForkName)" >
                            {{$ctrl.errorForkName.data.Error}}
                            <div flex layout="row" layout-align="start center">
                                <md-input-container flex>
                                    <label>New name</label>
                                    <input ng-model="$ctrl.forkName" required ng-disabled="$ctrl.forking" >
                                </md-input-container>
                                <md-button type="submit" ng-disabled="$ctrl.forking" >fork</md-button>
                                <md-button ng-click="$ctrl.cancelForkName()" ng-disabled="$ctrl.forking" >cancel</md-button>
                                <md-progress-circular class="md-hue-2" md-diameter="20px" ng-if="$ctrl.forking"></md-progress-circular>
                            </div>
                        </form>
                    </md-card>



                    <cmp-api-error-service ng-if="$ctrl.deleteError" error="$ctrl.deleteError" format="'dialog'"></cmp-api-error-service>
                </div>
                <div ng-if="$ctrl.asForm" ng-include="'kuberlab/catalog/base/card/card_new.html'"></div>
            </md-card-actions>
        </md-card-content>
        <div ui-view></div>
    </md-card>
</md-content>
</layout-page-content-item>

