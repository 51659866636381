<layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
    <layout-back></layout-back>
    <h3 flex>{{$ctrl.WS.DisplayName}} create projects</h3>
    <md-button ng-if="$ctrl.permissions.can('application.manage')" ui-sref="wsApplicationsAdd" class="md-raised md-primary">{{'MLAPPS_LIST_EMPTY_ACTION' | translate}}</md-button>
</layout-page-content-title>

<form name="newProject" ng-submit="$ctrl.save(newProject, true)" flex >
    <md-card class="innerContentBorder">
        <md-card-content layout="column">
            <div flex layout="row">
                <md-input-container flex="50">
                    <label translate>COMMON_NAME</label>
                    <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" required minlength="2" ng-change="$ctrl.pickupName()">
                    <help-hint hint="project.displayname"></help-hint>
                    <div ng-messages="newProject.DisplayName.$error">
                        <div ng-message="required">Project's name should be filled</div>
                        <div ng-message="minlength">Project's name is too short</div>
                    </div>
                </md-input-container>
                <md-input-container flex="50" >
                    <label translate>COMMON_IDENT</label>
                    <input
                            ng-disabled="!$ctrl.newElement"
                            type="text"
                            name="Name"
                            ng-model="$ctrl.data.Name"
                            ng-change="$ctrl.checkName(newProject)"
                            ng-maxlength="$ctrl.validate.maxName"
                            ng-minlength="$ctrl.validate.minName"
                            ng-pattern="$ctrl.validate.namePattern"
                    >
                    <help-hint hint="project.name"></help-hint>
                    <div ng-messages="newProject.Name.$error">
                        <div ng-message="errorname">{{$ctrl.nameErrorMessage}}</div>
                        <!--<div ng-message="pattern">patternh {{$ctrl.validate.namePattern}}</div>-->
                        <div ng-message="pattern">{{'ERROR_TEXT_invalidName' | translate}}</div>
                        <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
                        <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
                    </div>
                </md-input-container>
            </div>

            <md-input-container >
                <label>Description</label>
                <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
            </md-input-container>

            <cmp-repo-select
                    ng-if="$ctrl.newElement"
                    repository-url="$ctrl.data.RepositoryURL"
                    branch="true"
                    env="$ctrl.data.Environment"
                    repository-dir="$ctrl.data.RepositoryDir"
                    without-revision="true"
            >
            </cmp-repo-select>
        </md-card-content>

        <md-card-actions layout="row" layout-align="end center">
            <div flex>
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.saveErrorMessage" error="$ctrl.saveErrorMessage"></cmp-error-toast>
                <!--<div ng-if="$ctrl.saveErrorMessage" class="errorInfo">{{$ctrl.saveErrorMessage.Error}}</div>-->
            </div>

            <div>
                <md-button type="submit" ng-disabled="$ctrl.saving || !$ctrl.data.RepositoryURL"><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
                <md-button ng-click="$ctrl.cancel()" ng-disabled="$ctrl.saving"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
            </div>

        </md-card-actions>
    </md-card>
</form>
