<md-menu class="md-secondary" md-position-mode="target-right target" >
    <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.permissions.can('serving.manage')">
        <md-icon md-svg-icon="core:short-menu"></md-icon>
    </md-button>
    <md-menu-content width="3">
        <!--<md-menu-item ng-if="$ctrl.permissions.can('application.manage')" >-->
            <!--<md-button aria-label="icon" ng-if="$ctrl.data.Enabled" ng-click="$ctrl.action('disable')">-->
                <!--<md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_DISABLE"|translate}}-->
            <!--</md-button>-->
            <!--<md-button aria-label="icon" ng-if="!$ctrl.data.Enabled" ng-click="$ctrl.action('enable')">-->
                <!--<md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_ENABLE"|translate}}-->
            <!--</md-button>-->
        <!--</md-menu-item>-->
        <!--<md-menu-item ng-if="$ctrl.permissions.can('application.manage')" >-->
            <!--<md-button aria-label="icon" ng-click="$ctrl.action('reset')">-->
                <!--<md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESET"|translate}}-->
            <!--</md-button>-->
        <!--</md-menu-item>-->
        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('application.manage') && !$ctrl.full">
            <md-button aria-label="icon" ng-click="$ctrl.editDialog()">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="$ctrl.permissions.can('serving.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.action('restart')">
                <md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESTART"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="$ctrl.permissions.can('serving.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.action( $ctrl.data.Disabled ? 'enable' : 'disable' ) ">
                <md-icon md-svg-icon="{{ $ctrl.data.Disabled ? 'core:publish' : 'core:unpublish'}}"></md-icon> {{ $ctrl.data.Disabled ? "COMMON_ENABLE" : "COMMON_DISABLE" |translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="$ctrl.permissions.can('serving.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, $ctrl.$translate('MLAPP_SERVING_DELETE_DIALOG_MESSAGE', {projectName: $ctrl.data.Name}), 'MLAPP_SERVING_DELETE_DIALOG_TITLE')">
                <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="$ctrl.Admin">
            <md-button ng-click="$ctrl.toGallery()">{{$ctrl.data.Gallery ? 'remove from gallery' : "add to Gallery"}}</md-button>
        </md-menu-item>

        <md-menu-item ng-if="$ctrl.Admin && $ctrl.data.Gallery">
            <md-button ng-click="$ctrl.toGlobalDialog()">edit gallery</md-button>
        </md-menu-item>


        <!--<md-menu-divider ng-if="$ctrl.full"></md-menu-divider>-->
        <!--<md-menu-item ng-if="$ctrl.full">-->
            <!--<md-button aria-label="icon" ng-click="$ctrl.download()" >-->
                <!--<md-icon md-svg-icon="core:chart-download"></md-icon> {{"COMMON_DOWNLOAD" | translate}}-->
            <!--</md-button>-->
        <!--</md-menu-item>-->
    </md-menu-content>
</md-menu>