<div ng-if="!$ctrl.asForm" flex layout="row" layout-align="start center">
    <div flex>
        {{$ctrl.data.DisplayName}} - {{$ctrl.data.Created | date:'medium'}}
        <div ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</div>
        <div ng-if="$ctrl.data.Token">Remember new token: <b>{{$ctrl.data.Token}}</b></div>
    </div>
    <div ng-if="!$ctrl.data.Enabled">
        {{"COMMON_DISABLED" | translate}}
    </div>
    <md-menu-bar class="padding-zero layout-row layout-align-end-center md-secondary">
        <md-menu  md-position-mode="target-right target">
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <md-menu-item ng-if="!$ctrl.data.Enabled">
                    <md-button aria-label="icon"  ng-click="$ctrl.action('enable')">
                        <md-icon md-svg-icon="core:enable"></md-icon> {{"COMMON_ENABLE" | translate}}</md-button>
                </md-menu-item>
                <md-menu-item ng-if="$ctrl.data.Enabled">
                    <md-button aria-label="icon"  ng-click="$ctrl.action('disable')">
                        <md-icon md-svg-icon="core:enable"></md-icon> {{"COMMON_DISABLE" | translate}}</md-button>
                </md-menu-item>
                <md-menu-item >
                    <md-button aria-label="icon"  ng-click="$ctrl.deleteConfirm()">
                        <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE" | translate}}</md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-menu-bar>

</div>
<form name="token" ng-submit="$ctrl.save(token)" ng-if="$ctrl.asForm" >
    <md-card flex>
        <md-card-title>
            New token
        </md-card-title>
        <md-card-content  layout="row" >
            <md-input-container flex=>
                <label translate >COMMON_DISPLAYNAME</label>
                <input type="text" name="tokenName" ng-model="$ctrl.data.DisplayName" required ng-pattern="$ctrl.validate.idPattern">
                <div ng-messages="token.tokenName.$error" multiple md-auto-hide="false">
                    <div ng-message="pattern">{{'FORM_ERROR_ID_PATTERN' | translate}}</div>
                </div>
            </md-input-container>
            <!--<textarea ng-model="$ctrl.data.Description" wrap="soft" ></textarea>-->
            <md-input-container flex=>
                <label translate>COMMON_DESCRIPTION</label>
                <textarea ng-model="$ctrl.data.Description" ng-trim="false" ></textarea>
            </md-input-container>
            <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL" | translate }}</md-button>
            <md-button type="submit" class="md-raised">{{"COMMON_CREATE" | translate }}</md-button>
        </md-card-content>
        <cmp-error-toast ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-error-toast>
    </md-card>

</form>
