<div ng-if="!$ctrl.asForm">
    <div layout="row" layout-align="start " flex class="title" >
        <div class="cmp-clickable"  layout="row" layout-align="start center" ng-click="$ctrl.activate()" flex >
            <div >Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</div>
            <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>
            <div class="versionMessage"  flex>{{$ctrl.data.Message}}</div>
            <div class="" style="padding: 0 10px;" ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></div>
            <div>{{$ctrl.data.Updated | timePassed }}</div>
        </div>
        <div ng-include="'kuberlab/catalog/base/version-view/menu-item.html'"></div>
    </div>
</div>
