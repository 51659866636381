<div layout-align="start start" layout="row">
    <md-card flex>
        <md-card-title>
            <md-card-title-text>

                <span class="md-headline">Beginner</span>

                <span class="md-subhead">Ideal for beginners to experiment with deep learning</span>
            </md-card-title-text>
        </md-card-title>
        <md-card-content>
            <div class="desc">
                <div class="price" layout="row" layout-align="center center"> <span class="count">$0</span> <span>per month</span></div>
                +<br/>
                <b>GPU Powerups</b>
            </div>
            <ul>
                <li>Unlimited public projects & public datasets</li>
                <li>1 concurrent job</li>
                <li>Starting at 10 GB storage</li>
            </ul>
        </md-card-content>
        <md-card-action>

        </md-card-action>
    </md-card>

    <md-card flex>
        <md-card-title>
            <md-card-title-text>

                <span class="md-headline">Data Scientist</span>
                <span class="md-subhead">Built for experienced software engineers and data scientists</span>
            </md-card-title-text>
        </md-card-title>
        <md-card-content>
            <div class="desc">
                <div class="price" layout="row" layout-align="center center"><span class="count">$9</span> <span>per month</span></div>
                +<br/>
                <b>GPU Powerups</b>
            </div>
            <ul>
                <li>Unlimited public projects & public datasets</li>
                <li>Unlimited private projects & private datasets</li>
                <li>Up to 8 concurrent jobs</li>
                <li>Starting at 100 GB storage</li>
            </ul>
        </md-card-content>
        <md-card-action>

        </md-card-action>
    </md-card>
</div>

