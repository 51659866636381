<md-menu-bar
    id="title_user_card"
    class="full_height">
    <md-menu md-position-mode="left bottom"
        class="full_height">
        <button ng-click="$mdMenu.open()"
            class="full_height"
            layout="row"
            layout-align="start center">
            <img ng-src="{{$ctrl.currentUser.Avatar}}" class="md-avatar" alt="{{$ctrl.userData.Name}}"/>
            {{$ctrl.currentUser.Name}}
            <md-icon md-svg-icon="core:chevron-down" class="icon s16" hide-xs="" aria-hidden="true"></md-icon>
        </button>
        <md-menu-content>
            <md-divider md-inset></md-divider>
            <md-subheader>Owners</md-subheader>
            <md-menu-item ng-repeat="item in $ctrl.Owners">
                <md-button ng-click="$ctrl.OwnerService.setCurrentOwner(item.ID)" ng-class=" $ctrl.isCurrent(item.ID) ? 'md-raised md-primary md-hue-2' : ''" >
                    <md-icon md-svg-icon="{{ $ctrl.OwnerService.getIcon(item.Type) }}" ></md-icon>
                    {{item.Name;}}
                </md-button>
            </md-menu-item>
            <md-menu-divider role="separator"></md-menu-divider>
            <md-menu-item >
                <a ui-sref="userSettings" class="md-button"><md-icon>settings</md-icon>Settings</a>
                <!--<md-button ng-click="$ctrl.Logout()">-->
                    <!--<md-icon md-font-icon="icon-logout" class="icon md-default-theme md-font material-icons icon-logout" aria-hidden="true"></md-icon>-->
                    <!--Settings-->
                <!--</md-button>-->
            </md-menu-item>
            <md-menu-item >
                <md-button ng-click="$ctrl.logout()">
                    <md-icon>exit_to_app</md-icon>Logout
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-menu-bar>
<button class="md-icon-button md-button"
        type="button"
        id="title_info_block"
        ng-click="$ctrl.DashboardService.toggleSidenav('infoBlock')"
        aria-label="Toggle quick panel">
    <md-icon md-svg-icon="core:info" aria-hidden="true"></md-icon>
</button>
