<md-menu  md-position-mode="target-right target">
  <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
    <md-icon md-svg-icon="core:short-menu"></md-icon>
  </md-button>
  <md-menu-content width="3">
    <md-menu-item>
      <md-button aria-label="icon"
                 ng-click="$ctrl.deleteConfirm($event, 'PROJECT_DELETE_DIALOG_MESSAGE', 'PROJECT_DELETE_DIALOG_TITLE')">
        <md-icon md-svg-icon="core:delete"></md-icon>
        {{"PROJECT_DELETE_ACTION" | translate}} "{{$ctrl.data.DisplayName}}"
      </md-button>
    </md-menu-item>
  </md-menu-content>
</md-menu>
