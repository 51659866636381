<div>
  <script type="text/ng-template" id="/add-button.html">
    <md-button class="md-raised md-primary" ng-click="$ctrl.getNew()">
      <md-icon md-svg-icon="core:plus"></md-icon>
      {{"COMMON_ADD" | translate}}
    </md-button>
  </script>

  <md-list>
    <md-list-item ng-repeat="volume in $ctrl.volumes">
      <md-icon md-svg-icon="core:{{$ctrl.types[volume.name]}}" class="md-avatar-icon"></md-icon>
      <md-input-container class="flex">
        <label>Name</label>
        <input ng-model="volume.name" ng-disabled="true">
      </md-input-container>
      <md-input-container ng-if="$ctrl.types[volume.name] == 'gitRepo'" flex>
        <label>Revision</label>
        <input ng-model="volume.gitRevision">
      </md-input-container>
      <md-input-container class="flex">
        <label>Sub path</label>
        <input ng-model="volume.subPath">
      </md-input-container>
      <md-input-container class="flex">
        <label>Mount path</label>
        <input ng-model="volume.mountPath">
      </md-input-container>

      <md-button class="md-secondary md-icon-button" ng-click="$ctrl.delete(volume)">
        <md-icon md-svg-icon="core:delete"></md-icon>
      </md-button>
    </md-list-item>

    <div class="layout-row layout-align-end-center">
      <ng-include src="'/add-button.html'" ng-if="!$ctrl.volumes.length && !$ctrl.newVolume"></ng-include>
    </div>

    <md-list-item ng-if="$ctrl.newVolume">
      <md-input-container class="flex">
        <label>Name</label>
        <md-select ng-model="$ctrl.newVolume.name" ng-change="$ctrl.changeVolume()">
          <md-option ng-repeat="v in $ctrl.application.Configuration.spec.volumes" ng-value="v.name">{{v.name}}
          </md-option>
        </md-select>
      </md-input-container>
      <md-input-container ng-if="$ctrl.newVolume.type == 'gitRepo'" flex>
        <label>Revision</label>
        <input ng-model="$ctrl.newVolume.gitRevision">
      </md-input-container>
      <md-input-container class="flex">
        <label>Sub path</label>
        <input ng-model="$ctrl.newVolume.subPath">
      </md-input-container>
      <md-input-container class="flex">
        <label>Mount path</label>
        <input ng-model="$ctrl.newVolume.mountPath">
      </md-input-container>

      <md-button class="md-raised md-primary" ng-click="$ctrl.add($ctrl.newVolume)">
        {{'COMMON_APPLY' | translate}}
      </md-button>
    </md-list-item>

    <div class="layout-row layout-align-end-center">
      <ng-include src="'/add-button.html'" ng-if="$ctrl.volumes.length && !$ctrl.newVolume"></ng-include>
    </div>

  </md-list>
</div>
