
<div>
    <md-card>
        <md-card-content>
            <div class="layout-row layout-align-start-start">
                <md-input-container class="flex-50">
                    <label translate>MLAPP_APP_TYPE</label>
                    <md-select ng-model="$ctrl.model.AppType">
                        <md-option ng-value="'tensorflow'">tensorflow</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container class="flex-50">
                    <label translate>MLAPP_MON_NS</label>
                    <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.MonitoringNamespace">
                </md-input-container>
            </div>
            <div class="layout-row layout-align-start-center">
                <md-input-container class="flex-50">
                    <label translate>MLAPP_CMD</label>
                    <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.Cmd">
                </md-input-container>
                <md-input-container class="flex-50">
                    <label translate>MLAPP_EXEC_DIR</label>
                    <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.ExecutionDir">
                </md-input-container>
            </div>
            <div class="layout-row layout-align-start-center">
                <md-input-container class="flex-50">
                    <label translate>MLAPP_TIMEOUT_MINUTES</label>
                    <input type="number" ng-model="$ctrl.model.KuberlabTFConfiguration.TimeoutMinutes" />
                    <help-hint hint="mlapp.timeout_minutes"></help-hint>
                </md-input-container>
            </div>
            <div class="layout-row layout-align-start-center">
                <md-input-container flex>
                    <label translate>MLAPP_EXEC_ARGS</label>
                    <textarea ng-model="$ctrl.model.KuberlabTFConfiguration.Args"></textarea>
                </md-input-container>
            </div>

            <div class="layout-row layout-align-start-start">
                <div class="flex-50">
                    <b translate>MLAPP_RESOURCES</b>
                    <div class="layout-row layout-align-start-start layout-wrap">
                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>CPU (<span>m</span>)</label>
                            <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.CPU" />
                        </md-input-container>
                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>CPU Limit (<span>m</span>)</label>
                            <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.CPULimit" />
                        </md-input-container>

                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>Memory (<span>Mi,Gi</span>)</label>
                            <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.Memory" />
                        </md-input-container>
                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>Memory Limit (<span>Mi,Gi</span>)</label>
                            <input type="text" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.MemoryLimit" />
                        </md-input-container>

                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>GPU</label>
                            <input type="number" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.GPU" min="0"/>
                        </md-input-container>
                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>Ps pods</label>
                            <input type="number" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.PsPods" min="0" />
                        </md-input-container>
                        <md-input-container class="flex-50" style="padding-right: 5%">
                            <label>Worker pods</label>
                            <input type="number" ng-model="$ctrl.model.KuberlabTFConfiguration.Requests.WorkerPods" min="0" />
                        </md-input-container>
                        <md-input-container class="flex-50"  style="padding-right: 5%">
                            <label>Node allocator</label>
                            <md-select ng-model="$ctrl.model.KuberlabTFConfiguration.NodeAllocator" ng-disabled="!$ctrl.Allocatednodes || $ctrl.Allocatednodes.length == 0">
                                <md-option ng-value="item" ng-repeat="item in $ctrl.Allocatednodes">{{item}}</md-option>
                            </md-select>
                            <!--<input type="text" mg-model="$ctrl.model.KuberlabTFConfiguration.NodeAllocator">-->
                        </md-input-container>
                    </div>
                </div>
                <md-divider></md-divider>
                <div class="flex-50">
                    <b translate>MLAPP_ENV_ARGS</b>
                    <cmp-autolist data="$ctrl.model.KuberlabTFConfiguration.EnvVars" class="flex" labels="['name', 'value']"></cmp-autolist>
                </div>
            </div>
        </md-card-content>

        <md-card-actions class="layout-row layout-align-statr-center">
            <div class="flex layout-row layout-align-statr-center">
                <md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>
            </div>
            <md-button ng-click="$ctrl.save()" >{{"COMMON_SAVE" | translate}}</md-button>
            <md-button ng-click="$ctrl.build()" class="md-primary" >{{"MLAPP_START_BUILD" | translate}}</md-button>
        </md-card-actions>
    </md-card>
</div>
<cmp-api-error-service
            ng-if="$ctrl.updateError"
            error="$ctrl.updateError"
            format="'dialog'"
></cmp-api-error-service>