<div class="logList" ></div>
<form ng-submit="$ctrl.save()" class="layout-row layout-align-start-top" ng-disabled="$ctrl.saving">
    <md-select ng-model="$ctrl.data.manager" class="flex-20" placeholder="container">
        <md-option ng-value="item" ng-repeat="item in $ctrl.packagesList">{{item}}</md-option>
    </md-select>
    <md-input-container class="flex" >
        <label translate>{{"MLAPPS_PIP_TITLE_INSTALL"}}</label>
        <input type="text" ng-model="$ctrl.pack" ng-disabled="$ctrl.saving"/>
    </md-input-container>
    <md-button type="submite" ng-disabled="$ctrl.saving">{{"COMMON_INSTALL" | translate}}</md-button>
</form>
<cmp-api-error-service error="$ctrl.Error" ng-if="$ctrl.Error"> </cmp-api-error-service>
<div class="cmp-block-compact" layout-padding layout="column">
    <div class="cmp-block-compact-title" layout="row" layout-align="start center" flex>
        <h5 flex >{{ $ctrl.packageAll ? "MLAPP_LIB_LIST_ALL" : "MLAPP_LIB_LIST_INSTALING" | translate }}</h5>
        <md-checkbox ng-model="$ctrl.packageAll" ng-change="$ctrl.getList()">all</md-checkbox>
    </div>
    <div class="cmp-block-compact-content">
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        <md-progress-linear ng-if="$ctrl.listLoading"></md-progress-linear>
        <p ng-if="$ctrl.listComplite && (!$ctrl.list ||!$ctrl.list.length) " translate>MLAPPS_PIP_PACKAGES_EMPTY</p>
        <dl layout-padding ng-if="$ctrl.listComplite && $ctrl.list && $ctrl.list.length">
            <div ng-repeat="item in $ctrl.list">
                <dt>Manager: {{item.manager}}</dt>
                <dd>{{item.packages.join(', ')}}</dd>
            </div>
        </dl>
    </div>
</div>
