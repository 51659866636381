<div ng-if="$ctrl.customer">
    <cmp-error-toast ng-if="$ctrl.customer.Failed" error="{data: {Error: 'Failed to Bill. Please check your card'}}" layout-margin></cmp-error-toast>

  <div class="layout-column" layout-margin
       ng-class="{'layout-align-center-center': !$ctrl.customer.Card && !$ctrl.showCardForm}">
    <div flex>
      <md-card ng-if="$ctrl.customer.Card && !$ctrl.showCardForm" layout-fill>
        <md-card-content>
          <div class="layout-row layout-align-start-center">
            <h4 class="flex">Card</h4>
            <md-menu  class="md-secondary" md-position-mode="target-right target">
              <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
              </md-button>
              <md-menu-content width="3">
                <md-menu-item>
                  <md-button ng-click="$ctrl.showCardForm = true; $ctrl.change = true;">{{"COMMON_CHANGE" | translate}}</md-button>
                </md-menu-item>
                <md-menu-item>
                  <md-button class="md-warn" ng-click="$ctrl.unsubscibe()">{{"COMMON_UNSUBSCRIBE" | translate}}</md-button>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
            <!--<a ng-click="$ctrl.showCardForm = true; $ctrl.change = true;" href="#">change</a>-->
          </div>
          <div>{{$ctrl.customer.Card.Brand}} **** **** **** {{$ctrl.customer.Card.LastFour}}</div>
          <div>{{$ctrl.customer.BillingEmail}}</div>
        </md-card-content>
      </md-card>

      <md-button ng-if="!$ctrl.customer.Card && !$ctrl.showCardForm" class="md-raised md-primary"
                 ng-click="$ctrl.showCardForm = true;">{{"COMMON_SUBSCRIBE" | translate  }}
      </md-button>

      <div>
        <cmp-billing-card ng-if="$ctrl.showCardForm" card-token="$ctrl.cardToken" billing="$ctrl"
                          change="$ctrl.change"></cmp-billing-card>
        <cmp-error-toast ng-if="$ctrl.cardError" error="$ctrl.cardError" layout-padding></cmp-error-toast>
      </div>
    </div>

    <div flex ng-if="$ctrl.customer.Card && !$ctrl.change">
      <h4>Subscribe to plans:</h4>

      <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error" layout-padding></cmp-error-toast>

      <!--<div ng-repeat="item in $ctrl.currentPlans" layout="row" layout-align="start start">-->
        <!--<cmp-billing-plan data="item" style="max-width: 200px"></cmp-billing-plan>-->
      <!--</div>-->


      <div ng-if="$ctrl.customerComplite">
        <!--<div class="selectPlans">-->
          <!--<cmp-billing-plan-list plans="$ctrl.currentPlans" ></cmp-billing-plan-list>-->
        <!--</div>-->
        <div class="selectPlans">
          <cmp-billing-plan-list plans="$ctrl.plans" current-plans="$ctrl.currentPlans"  can-add="true" billing-controller="$ctrl"></cmp-billing-plan-list>
        </div>
      </div>



      <!--<div ng-repeat="item in $ctrl.plans">-->
        <!--&lt;!&ndash;<md-checkbox&ndash;&gt;-->
            <!--&lt;!&ndash;ng-value="item.ID"&ndash;&gt;-->
            <!--&lt;!&ndash;ng-model="$ctrl.selectedPlans[item.ID]"&ndash;&gt;-->
            <!--&lt;!&ndash;ng-disabled="item.ID == $ctrl.DEFAULT_PLAN"&ndash;&gt;-->
            <!--&lt;!&ndash;ng-checked="item.ID == $ctrl.DEFAULT_PLAN || $ctrl.subscribedPlans.indexOf(item.ID) > -1"&ndash;&gt;-->
            <!--&lt;!&ndash;ng-click="$ctrl.addToken()"&ndash;&gt;-->
        <!--&lt;!&ndash;&gt;&ndash;&gt;-->
        <!--<md-checkbox-->
                <!--ng-value="item.ID"-->
                <!--ng-model="$ctrl.selectedPlans[item.ID]"-->
                <!--ng-disabled="item.ID == $ctrl.DEFAULT_PLAN"-->
                <!--ng-change="$ctrl.addToken(null, null, item.ID)"-->
        <!--&gt;-->
          <!--<span>{{item.Name}} {{item.ID}}</span>-->

          <!--<md-button class="md-icon-button">-->
            <!--<md-icon md-svg-icon="core:info"></md-icon>-->
            <!--<md-tooltip ng-if="item.ID == $ctrl.DEFAULT_PLAN">Default plan always included</md-tooltip>-->
            <!--<md-tooltip ng-if="item.ID == 'pro-day'">-->
              <!--Allows you be create projects in addition to Default plan-->
            <!--</md-tooltip>-->
          <!--</md-button>-->

        <!--</md-checkbox>-->
      <!--</div>-->

      <!--<div layout="row" ng-if="$ctrl.customer.Card">-->
        <!--<div flex></div>-->
        <!--<md-button class="md-warn" ng-click="$ctrl.unsubscibe()">Unsubscribe</md-button>-->
        <!--<md-button class="md-raised md-primary" ng-click="$ctrl.addToken()">Save</md-button>-->
      <!--</div>-->
    </div>
  </div>

</div>

