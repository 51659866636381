<div class="layout-row layout-align-start-center title md-primary" ng-click="$ctrl.toogle()" >
    <div class="icon-tree-point">
        <md-icon md-svg-icon="core:minus-box" ng-if="$ctrl.open"></md-icon>
        <md-icon md-svg-icon="core:plus-box" ng-if="!$ctrl.open"></md-icon>
    </div>
    <h4 class="flex">{{$ctrl.pod.Name}}</h4>
    <div>
        <md-icon md-svg-icon="core:calendar"></md-icon>
        <span>{{ $ctrl.pod.StartTime | date:"MM/dd/yyyy 'at' h:mma" }}</span>
    </div>
</div>
<pre  ng-if="$ctrl.Log && $ctrl.open" class="log-view log">{{$ctrl.Log.output}}</pre>
