<div flex ng-if="$ctrl.asForm" ng-include=" 'kuberlab/mlapps/card/mlapps_card_new.html' "></div>

<div ng-if="!$ctrl.asForm" flex class="innerContentBorder">
    <div layout="row" layout-align="space-between center" layout-padding>
        <div  flex layout="row" layout-align="space-between start" >
            <div flex >
                Project: <a ui-sref="wsProject({projectName: $ctrl.data.ProjectName})" >{{$ctrl.data.ProjectDisplayName}}</a>/
                <a ui-sref="wsProject({projectName: $ctrl.data.ProjectName, envName:$ctrl.data.Environment })" >{{$ctrl.data.Environment}}</a>/
                <a ui-sref="wsProject.tab({projectName: $ctrl.data.ProjectName, envName:$ctrl.data.Environment, tab: 'clusters', tabItem: $ctrl.data.ClusterName })" >{{$ctrl.data.ClusterName}}</a>
                <p ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</p>
            </div>
            <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
                <md-button ng-disabled="true" class="md-secondary" ng-if="!$ctrl.data.Enabled">{{'COMMON_DISABLED' | translate}}</md-button>
                <div class="md-secondary" ng-include="'kuberlab/mlapps/card/mlapps_menu.html'"></div>
            </md-menu-bar>
        </div>
    </div>

    <md-card ng-if="$ctrl.data.DisableReason">
        <md-card-title class="layout-align-start-center worning">
            <md-card-title-media>
                <div class="md-media-sm card-media" layout>
                    <md-icon md-svg-icon="core:alert"></md-icon>
                </div>
            </md-card-title-media>
            <md-card-title-text>
                <span class="md-headline">{{$ctrl.data.DisableReason}}</span>
            </md-card-title-text>
        </md-card-title>
    </md-card>

    <div flex ng-if="!$ctrl.envList.Error" class="projectContent">
        <md-tabs class="itemContent" md-dynamic-height >
            <md-tab ng-repeat="tab in $ctrl.viewTabs track by $index"
                    md-active="$ctrl.$state.params.tab == tab.title"
                    ng-click="$ctrl.goTab(tab.title)">
                <md-tab-label translate>
                    {{tab.title}}
                </md-tab-label>
            </md-tab>
        </md-tabs>
        <div ui-view="tabContent" class="tabContent"></div>
    </div>
    <cmp-api-error-service ng-if="$ctrl.apiError" error="$ctrl.apiError" format="'dialog"></cmp-api-error-service>
</div>