<div class="layout-row layout-align-end-center" ng-if="!$ctrl.mainPage">
    <md-input-container>
        <label>Name</label>
        <input ng-model="$ctrl.filter.Name" ng-change="$ctrl.saveFilter()">
    </md-input-container>
    <md-checkbox ng-model="$ctrl.orderContainersCount" ng-change="$ctrl.orderWithCount()">Containers Count</md-checkbox>
    <md-button ng-click="$ctrl.order('Name')" ng-class=" $ctrl.orderParam.Name == 'Name' ? 'cmp-order-up' : 'cmp-order-down'">Name </md-button>
    <md-checkbox ng-model="$ctrl.Recent" ng-change="$ctrl.changeResent()">Recent</md-checkbox>
</div>

<div layout="row" layout-align="space-around" ng-if="!$ctrl.list">
    <md-progress-linear md-mode="indeterminate"></md-progress-linear>
</div>

<div ng-class="{'limitHeagth' : $ctrl.limitHeigth(), 'innerContentBorder' : true,  'applicationList' : true}">
    <md-list ng-if="$ctrl.list.length" >
        <div ng-repeat="item in $ctrl.list | orderBy : $ctrl.orderListPrams | filter : $ctrl.filter">
            <cmp-serving-list-item data="item" list-controller="$ctrl"></cmp-serving-list-item>
            <md-divider ng-if="!$last"></md-divider>
        </div>
    </md-list>
</div>

<div ng-if="$ctrl.listEmpty"  >
    <md-card class="flex layout-row layout-align-space-between-center">
        <md-card-content>
            <p data-ng-bind-html="'COMMON_LIST_EMPTY' | translate" ></p>
        </md-card-content>
    </md-card>
</div>
