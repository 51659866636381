<md-card style=" min-width: 335px; max-width: 600px; ">

  <md-card-title>
    <md-card-title-text>
      <span class="md-headline title">Sign
      <span ng-if="$ctrl.is('login')">in</span>
      <span ng-if="$ctrl.is('register')">up</span>
       to <cmp-logo></cmp-logo></span>
    </md-card-title-text>
  </md-card-title>
  <md-card-content layout="column">

    <form name="signIn" ng-submit="$ctrl.login(signIn)" ng-if="$ctrl.is('login')" >
      <div layout="column">
        <md-input-container class="flex md-input-focused ">
          <label>Login/Email</label>
          <input
              name="LoginOrEmail"
              ng-model="$ctrl.dataLogin.LoginOrEmail"
              required
              autocomplete="off"
              ng-focus="expression"
          >
          <div ng-messages="login.LoginOrEmail.$error">
            <div ng-message="required">is required field</div>
          </div>
        </md-input-container>

        <md-input-container class="flex md-input-focused ">
          <label>Password</label>
          <input
              type="password"
              name="Password"
              ng-model="$ctrl.dataLogin.Password"
              required
              minlength="5"
              autocomplete="off"
          />
          <div ng-messages="login.Password.$error" ng-show="signUp.Password.$dirty">
            <div ng-message="required">This is required!</div>
          </div>
        </md-input-container>
      </div>

      <!--<cmp-error-page ng-if="$ctrl.error" compact="true" error="$ctrl.error"></cmp-error-page>-->
      <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>

      <div layout="row">
        <div flex></div>
        <md-button type="submit" class="md-raised md-primary">Sign in</md-button>
      </div>

      <div class="auth-form-services"
           layout-align="start-center"
           layout="column"
           ng-if="$ctrl.ServiceList.length || !$ctrl.ServiceListComplite"
      >
        <h4>Or sign in with service</h4>
        <img ng-src="/{{$ctrl.uiRevCommit}}/img/loader-hr.gif" alt="Loading..." ng-show="!$ctrl.ServiceList" />
        <div ng-if="$ctrl.ServiceList.length">
          <md-button class="md-icon-button" ng-repeat="item in $ctrl.ServiceList"
                     ng-click="$ctrl.loginViaService(item.Name)">
            <md-tooltip md-direction="top">{{"SERVICE_NAME." + item.Name | translate}}</md-tooltip>
            <md-icon md-svg-src="/0/img/icon/color-{{item.Name}}.svg"            class="s24" aria-label="Cake"    ></md-icon>
            <!--<md-icon md-svg-icon="services:color_{{item.Name}}"></md-icon>-->
          </md-button>
        </div>
      </div>
      <div >
        <md-divider></md-divider>

        <div class="auth-form-link-actions" layout="row" layout-align="center center">
          <a href="" class="dimmed" ng-click="$ctrl.goTo('resetPassword')">Forgot password</a>
          <a class="sign_up" ng-hide="$ctrl.registration_disabled" href="" ng-click="$ctrl.goTo('register')">Sign Up</a>
        </div>
      </div>


    </form>

    <form name="signUp" ng-submit="$ctrl.register(signUp)" ng-if="$ctrl.is('register') && !$ctrl.registerComplete">
      <div class="flex layout-row layout-align-start layout-padding layout-wrap">
        <md-input-container class="flex-50">
          <label translate>COMMON_DISPLAYNAME</label>
          <input
              type="text"
              name="DisplayName"
              ng-model="$ctrl.data.DisplayName"
              required
              ng-model-options='{ debounce: 1000 }'
              ng-minlength="$ctrl.validate.nameMin"
              ng-trim="true"
          >
          <div ng-messages="signUp.DisplayName.$error" ng-show="signUp.DisplayName.$dirty">
            <div ng-message="required">This is required!</div>
            <div ng-message="minlength">Min length {{$ctrl.validate.nameMin}}</div>
            <!--<div ng-message="trim"></div>-->
          </div>
        </md-input-container>
        <md-input-container class="flex-50">
          <label>Login</label>
          <input
              type="text"
              name="Login"
              ng-model="$ctrl.data.Login"
              ng-pattern="$ctrl.validate.namePattern"
              ng-model-options='{ debounce: 1000 }'
          >
          <div ng-messages="signUp.Login.$error">
            <div ng-message="pattern">pattern {{$ctrl.validate.namePattern}}</div>
            <div ng-message="maxlength">Maximum length {{$ctrl.validate.nameMax}}</div>
            <div ng-message="minlength">Minimum length {{$ctrl.validate.nameMin}}</div>
          </div>
        </md-input-container>

        <md-input-container ng-if="!$ctrl.usersWithoutEmails" class="flex" style="min-width: 100% ">
          <label>Email</label>
          <input type="email" name="Email" ng-model="$ctrl.data.Email" required ng-model-options='{ debounce: 1000 }'>
          <div ng-messages="signUp.Email.$error" ng-show="signUp.Email.$dirty">
            <div ng-message="required">This is required!</div>
            <div ng-message="email">Not valid email!</div>
          </div>
        </md-input-container>

        <md-input-container flex="50">
          <label>Password</label>
          <input
              type="password"
              name="password"
              ng-model="$ctrl.data.Password"
              required
              ng-pattern="$ctrl.validate.passwordPattern"
          >
          <div ng-messages="signUp.password.$error" ng-messages-multiple="ng-messages-multiple"
               class="error-messages slide-right">
            <div ng-message="required" class="message slide-left">You did not enter a field name</div>
            <div ng-message="pattern" translate>FORM_ERROR_PASS_PATTERN</div>
          </div>
        </md-input-container>
        <md-input-container flex="50">
          <label>Confirm password</label>
          <input
              match-password="password"
              name="confirmPassword"
              ng-model="password.confirm"
              type="password"
              required>
          <div ng-messages="signUp.confirmPassword.$error" ng-messages-multiple="ng-messages-multiple"
               class="error-messages slide-right">
            <div ng-message="required" class="message slide-left">You did not enter a field name</div>
            <div ng-message="passwordMatch" class="message slide-left">Your passwords did not match</div>
          </div>
        </md-input-container>

        <!--<md-input-container ng-if="$ctrl.settings.can_register_gitlab">-->
          <!--<md-checkbox ng-model="$ctrl.data.CreateGitlabAccount" aria-label="Create Kuberlab GitLab account?">-->
            <!--Create Kuberlab GitLab account-->
          <!--</md-checkbox>-->
        <!--</md-input-container>-->
        <!---->
        <md-input-container style="width: 100%">
          <md-checkbox ng-model="$ctrl.data.AgreeToTerms" aria-label="I agree to terms">
            I agree to <a href="https://kuberlab.com/terms" target="_blank">terms</a>
          </md-checkbox>
        </md-input-container>
        <div ng-if="$ctrl.settings.recaptcha_key"
             vc-recaptcha
             theme="'light'"
             key="$ctrl.settings.recaptcha_key"
             on-create="setWidgetId(widgetId)"
             on-success="setResponse(response)"
             on-expire="cbExpiration()"
        ></div>

        <!--<cmp-error-page ng-if="$ctrl.error" compact="true" error="$ctrl.error"></cmp-error-page>-->
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error" flex></cmp-error-toast>

        <div layout="row" flex layout-align="center center">
          <a href="" ng-click="$ctrl.goTo('login')">Go to login</a>
          <div flex></div>
          <md-button type="submit" class="md-raised md-primary" ng-disabled="$ctrl.isRegistering || !$ctrl.data.AgreeToTerms">Sign Up</md-button>
        </div>
      </div>
    </form>

    <div class="flex layout-row layout-align-start layout-padding layout-wrap"
         ng-if="$ctrl.is('register') && $ctrl.registerComplete">
      <div class="layout-padding">
        <p>Please click on the link that has been sent to your email to complete the registration.</p>
        <p>If you didn't receive verification email, please contact us at support@kuberlab.com.</p>
      </div>
    </div>

    <form ng-if="$ctrl.is('resetPassword') " name="reset" ng-submit="$ctrl.resetPassword(reset)">
      <div layout="column" ng-if="!$ctrl.resetComplete">
        <md-input-container class="flex">
          <label>Email</label>
          <input name="Email" type="email" ng-model="$ctrl.dataReset.Email" required>
          <div ng-messages="reset.Email.$error">
            <div ng-message="required">is required field</div>
            <div ng-message="email">Not valid email!</div>
          </div>
        </md-input-container>
        <div ng-if="$ctrl.settings.recaptcha_key"
             vc-recaptcha
             theme="'light'"
             key="$ctrl.settings.recaptcha_key"
             on-create="setWidgetId(widgetId)"
             on-success="setResponse(response)"
             on-expire="cbExpiration()"
        ></div>

        <!--<cmp-error-page ng-if="$ctrl.error" compact="true" error="$ctrl.error" style="width: 100%;"></cmp-error-page>-->
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>

        <div layout="row" flex layout-align="center center">
          <a href="" ng-click="$ctrl.goTo('login')">Go to login</a>
          <div flex></div>
          <md-button type="submit" class="md-raised md-primary">Reset password</md-button>
        </div>
      </div>
      <div layout="column" ng-if="$ctrl.resetComplete">
        Reset link was sent to: {{$ctrl.dataReset.Email}}
      </div>
    </form>

  </md-card-content>
</md-card>
