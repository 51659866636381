<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak name="serve" ng-submit="save()">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2 translate>COMMON_SERVING</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="layout-row layout-align-start-top layout-wrap md-padding"   >
                <!--<cmp-form-constructor-->
                        <!--ng-if="$ctrl.data.Type == 'inference' && $ctrl.vData "-->
                        <!--ng-model="$ctrl.data.InferenceValues"-->
                        <!--api="$ctrl.apiForm"-->
                        <!--options="$ctrl.vData.Form"-->
                        <!--cluster-id="$ctrl.data.ClusterID"-->
                        <!--workspace="$ctrl.data.WorkspaceName"-->
                        <!--app-volumes="$ctrl.appController.data.Configuration.spec.volumes"-->
                <!--&gt;</cmp-form-constructor >-->
                <div ng-switch="$ctrl.data.Type">
                    <!--ng-if="$ctrl.data.Type == 'inference' && $ctrl.vData "-->
                    <cmp-serving-form-inference-config
                            ng-switch-when="inference"
                            values="$ctrl.data.InferenceValues"
                            app-controller="$ctrl.appController"
                            data="$ctrl.data"
                            api="$ctrl.apiForm"
                            options="$ctrl.vData.Form"
                            flex="100"
                            spec="$ctrl.data.Config.spec"
                            full="$ctrl.data"
                    ></cmp-serving-form-inference-config>
                    <cmp-serving-form-project-config
                            ng-switch-when="project"
                            data="$ctrl.data"
                            application="$ctrl.application"
                    ></cmp-serving-form-project-config>
                    <cmp-serving-form-config
                            ng-switch-default
                            ng-if="$ctrl.data.Type != 'inference'"
                            data="$ctrl.data.Config"
                            serving="$ctrl.data"
                            flex="100"
                            workspace-name="$ctrl.data.WorkspaceName"></cmp-serving-form-config>
                </div>
                <cmp-error-toast ng-if="$ctrl.vError" error="$ctrl.vError"></cmp-error-toast>
            </div>
        </md-dialog-content>

        <!--<md-dialog-actions flex layout="column" style="min-height: fit-content;">-->
        <md-dialog-actions flex layout="column" >
            <div>
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            </div>
            <div>
                <md-button ng-click="cancel()">
                    {{"COMMON_CANCEL" | translate}}
                </md-button>
                <md-button type="submit">
                    {{"COMMON_SERVE" | translate}}
                </md-button>
            </div>
        </md-dialog-actions>
    </form>
</md-dialog>
