<!--<md-list>-->
    <!--<cmp-mlapps-sources-item-->
            <!--ng-repeat="item in $ctrl.mlapp.Sources"-->
            <!--data="item",-->
            <!--mlapp="$ctrl.mlapp"-->
            <!--list-controller="$ctrl"-->
            <!--&gt;</cmp-mlapps-sources-item>-->
    <!--<cmp-mlapps-sources-item-->
            <!--data="$ctrl.Source"-->
            <!--mlapp="$ctrl.mlapp"-->
            <!--list-controller="$ctrl"-->
            <!--is-new-element="true"-->
    <!--&gt;</cmp-mlapps-sources-item>-->
<!--</md-list>-->



<cmp-mlapps-volumes mlapp="$ctrl.mlapp"></cmp-mlapps-volumes>
<div class="layout-row layout-align-start-center">
    <div class="flex"></div>
    <md-button ng-click="$ctrl.save()">Save</md-button>
</div>

<cmp-api-error-service ng-if="$ctrl.updateError" error="$ctrl.updateError" format="'dialog'"></cmp-api-error-service>
