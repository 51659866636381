<!--<div class="md-padding chart_menu" style="margin-top: 8px;">-->
<div class="md-padding chart_menu" >
    <div layout="row" layout-align="start center" flex>
        <cmp-pagenate page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
        <div flex></div>
        <div class="layout-row layout-align-end-center" >

            <md-radio-group ng-model="$ctrl.listOrder" layout="row" layout-align="start" class="radioToggleButton" ng-disabled="$ctrl.search" >
                <md-radio-button value="stars" class="md-icon-button"><md-icon md-svg-icon="core:star"></md-icon><md-tooltip>sorting by stars</md-tooltip></md-radio-button>
                <md-radio-button value="updated" class="md-icon-button"><md-icon md-svg-icon="core:time"></md-icon><md-tooltip>sorting by update</md-tooltip></md-radio-button>
                <md-radio-button value="name" class="md-icon-button"><md-icon md-svg-icon="core:alphabetical"></md-icon><md-tooltip>sorting by name</md-tooltip></md-radio-button>
            </md-radio-group>

            <md-checkbox ng-model="$ctrl.listDesc" class="md-checkbox-button md-icon-button" ng-disabled="$ctrl.search" >
                <md-icon md-svg-icon="core:desc"></md-icon>
                <md-tooltip>from larger to smaller</md-tooltip>
            </md-checkbox>

            <!--<md-button  class="md-icon-button"><md-icon md-svg-icon="core:desc"></md-icon></md-button>-->


            <form class="search" ng-submit="$ctrl.find()" name="serach">
                <input type="text" ng-model="$ctrl.search" ng-model-options='{ debounce: 1000 }' ng-change="$ctrl.find()">
                <md-button type="submit" class="md-icon-button"><md-icon md-svg-icon="core:search"></md-icon></md-button>
            </form>
            <md-button ng-click="$ctrl.changeView()" class="md-icon-button">
                <md-icon md-svg-icon="{{$ctrl.catalogListView == 'grid' ? 'core:view-sequential' : 'core:view-grid'}}"></md-icon>
            </md-button>



        </div>
    </div>
</div>
<md-divider></md-divider>

<md-content flex class="md-padding">
    <div ng-if="$ctrl.$state.params.Interface">
        <div ng-if="$ctrl.$state.params.search || $ctrl.$state.params.keyword ">
            <h3 ng-if="$ctrl.$state.params.keyword">Keyword {{$ctrl.$state.params.keyword}}<span ng-if="$ctrl.$state.params.search"> search {{$ctrl.$state.params.search}}</span></h3>
            <h3 ng-if="!$ctrl.$state.params.keyword">Search {{$ctrl.$state.params.search}}</h3>
        </div>
        <cmp-catalog-tag-list ng-if="$ctrl.isCatalog && !$ctrl.$state.params.search && !$ctrl.$state.params.keyword " interface="$ctrl.$state.params.Interface"></cmp-catalog-tag-list>
    </div>

    <!--<div ng-if="$ctrl.isCatalog">isCatalog</div>-->

    <div style="position: relative">

        <div ng-if="$ctrl.loading" style="position: absolute; width: 100%; z-index: 10; top: 0px; left: 0px;"><md-progress-linear></md-progress-linear></div>

        <div class="chartList {{$ctrl.catalogListView}}" ng-if="$ctrl.list.length">
            <cmp-catalog-item-small
                    is-catalog="$ctrl.isCatalog"
                    item-state="$ctrl.itemState"
                    list-state="$ctrl.listState"
                    resource="$ctrl.resource"
                    multy="$ctrl.multy"
                    class="item_view  chartList_Item"
                    ng-repeat="item in $ctrl.list" data="item"></cmp-catalog-item-small>
        </div>
        <div ng-if="!$ctrl.error && $ctrl.complite && !$ctrl.list.length"><p translate>COMMON_LIST_EMPTY</p></div>
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
    </div>
    <div layout="row" layout-align="start">
        <div flex></div>
        <cmp-pagenate page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
    </div>
</md-content>
