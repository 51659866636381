<md-dialog style="min-width: 400px; width: 70%;" id="pipConfigDialog">
    <md-toolbar>
        <div class="md-toolbar-tools">
            <div ng-switch="action">
                <h2>Libs</h2>
            </div>
            <span flex></span>
            <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
                <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content style="height: 500px" >
       <md-content class="md-padding">
           <cmp-applications-pip app-controller="$ctrl.appController" flex></cmp-applications-pip>
       </md-content>
    </md-dialog-content>
</md-dialog>
