<div  style="min-height: 450px">
    <div ng-if="!$ctrl.asForm && $ctrl.data.Version">
        <div layout="row" layout-align="start center" flex class="title" >
            <div class=""  layout="row" layout-align="start center" flex >
                <div flex layout="row" layout-align="start center">
                    <h4 >Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</h4>
                    <md-button class="md-raised" ng-click="$ctrl.listController.createNew($ctrl.data)" ng-if="$ctrl.canManage" >Clone version</md-button>
                </div>
                <!--<md-button ng-if="$ctrl.data && $ctrl.data.Status == 'error'  "  class="md-warn"><md-tooltip md-direction="left">{{$ctrl.data.StatusError}}</md-tooltip>Error</md-button>-->
                <!--<md-button ng-disabled="true" ng-if="$ctrl.data && $ctrl.data.Status == 'uploading'  "  class="md-warn">{{$ctrl.data.Status}}</md-button>-->
                <!--<md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>-->
                <!--<md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>-->
                <div>{{$ctrl.data.Updated | date: 'dd/MM/yyyy HH:mm'}}</div>
            </div>
            <!--<md-button ng-click="$ctrl.commit($event)" class="md-raised" ng-if="$ctrl.data.Editing && $ctrl.canManage">commit</md-button>-->
            <md-button ng-click="$ctrl.listController.serveDialog($event, $ctrl.data)" class="md-raised" >{{"COMMON_SERVE" | translate}}</md-button>

            <div ng-include="'kuberlab/inference/version/menu-item.html'"></div>
        </div>
        <div layout="row" layout-align="end center">
            <md-button ng-click="$ctrl.listController.serveDialog($event, $ctrl.data)" class="md-raised"  ng-if="!$ctrl.data.Editing && $ctrl.data.Status =='ok'" >{{"COMMON_SERVE" | translate}}</md-button>
            <md-button href="{{$ctrl.data.DownloadURL}}" target="_blank" download ng-if="$ctrl.data.DownloadURL" class="md-raised" >
                <md-icon md-svg-icon="core:link"></md-icon>{{"COMMON_DOWNLOAD" | translate}}<span ng-if="$ctrl.data.SizeBytes"> (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)</span>
            </md-button>
        </div>

        <form name="version" ng-submit="$ctrl.serve(version)">
            <md-card layout="column" layout-align="canter" class="md-padding" style="width: 100%">
                <label style="padding: 1em 0.5em">Form</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" readonly  ng-model="$ctrl.yamlForm"></div>

                <label style="padding: 1em 0.5em">Serving config template</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" readonly ng-model="$ctrl.data.ServingConfigTemplate"></div>


                <label style="padding: 1em 0.5em">Edge config template</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" readonly ng-model="$ctrl.data.EdgeConfigTemplate"></div>


                <!--<label style="padding: 1em 0.5em">Start sh</label>-->
                <!--<div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" readonly ng-model="$ctrl.data.StartSh"></div>-->

                <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>
            </md-card>
        </form>


        <div ng-if="$ctrl.uploadFullFile" style="height: 450px" >
            <div  class="setNewVersion" layout="row" layout-align="center center">
                <md-card>
                    <div flex layout="row" layout-align="start center" class="md-padding">
                        <div flex>upload model: </div> <cmp-catalog-version-uploading-files  ng-repeat="f in $ctrl.uploadingFiles" data="f" ctrl="$ctrl" action="'upload'" file-param="'model'" ></cmp-catalog-version-uploading-files>
                    </div>
                </md-card>
            </div>
        </div>

    </div>

    <form ng-if="!$ctrl.data.isNew && $ctrl.asForm" flex class="editForm" name="editVersion" ng-submit="$ctrl.save(editVersion)">
        <div layout="column" layout-align="start" flex >
            <div flex="100">
                <md-input-container>
                    <label>Version</label>
                    <input ng-model="$ctrl.data.Version" ng-disabled="true">
                </md-input-container>
            </div>
            <div flex="100">
                <label style="padding: 1em 0.5em">Form</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.yamlForm"></div>

                <label style="padding: 1em 0.5em">Serving config template</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.ServingConfigTemplate"></div>

                <label style="padding: 1em 0.5em">Edge config template</label>
                <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.EdgeConfigTemplate"></div>

                <!--<label style="padding: 1em 0.5em">Start sh</label>-->
                <!--<div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.StartSh"></div>-->

            </div>
            <div layout="row" layout-align="space-between center">
                <div class="flex">
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                    <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>
                </div>
                <md-button ng-click="$ctrl.cEdit()">cancel</md-button>
                <md-button type="submit">save</md-button>
            </div>
        </div>
    </form>
<style>
    .setNewVersion cmp-catalog-version-uploading-files md-content {display: none;}
</style>

    <form ng-if="$ctrl.data.isNew " ng-submit="$ctrl.saveNew(nameVersion)" name="nameVersion" class="setNewVersion" flex layout="column" layout-align="center center" >
        <md-card layout="column" layout-align="canter" class="md-padding" style="width: 100%;">
            <h4 style="text-align:center">Create new version</h4>
            <p ng-if="$ctrl.data.From" style="text-align:center" >From version {{$ctrl.data.From}}</p>
            <md-input-container>
                <label>Set new version</label>
                <input ng-model="$ctrl.newVersion" required ng-disable="!$ctrl.data.isNew"/>
            </md-input-container>

            <label style="padding: 1em 0.5em">Form</label>
            <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.yamlForm"></div>

            <label style="padding: 1em 0.5em">Serving config template</label>
            <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.ServingConfigTemplate"></div>

            <label style="padding: 1em 0.5em">Edge config template</label>
            <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.EdgeConfigTemplate"></div>

            <!--<label style="padding: 1em 0.5em">Start sh</label>-->
            <!--<div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.data.StartSh"></div>-->

            <!--<cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>-->

            <div layout="row" layout-align="space-between center">
                <div class="flex">
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                    <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>
                </div>
                <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button type="submit">create</md-button>
            </div>


            <!--<div layout="row" layout-align="center">-->
                <!--<md-button ng-click="$ctrl.cancel()">cancel</md-button>-->
                <!--<md-button type="submit">create</md-button>-->
            <!--</div>-->
        </md-card>
    </form>

</div>
