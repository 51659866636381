<!--<md-button class="" ng-click="$ctrl.createNew($ctrl.version)">+ new version</md-button>-->

<cmp-mlmodel-version-item-full ng-class="{fullView : item.Version == $ctrl.version.Version}" list-controller="$ctrl"  data="$ctrl.version" can-manage="$ctrl.canManage" ng-if="$ctrl.version">
</cmp-mlmodel-version-item-full>

<div class="versionHistory">
    <div flex layout="row" layout-align="start center">

        <h3 flex>History</h3>
        <md-button class="" ng-click="$ctrl.createNew()">+ new version</md-button>
    </div>
    <cmp-mlmodel-version-item list-controller="$ctrl" ng-repeat="item in $ctrl.versions" data="item" can-manage="$ctrl.canManage" ng-if="item.Version">
    </cmp-mlmodel-version-item>
</div>

<!--<form ng-submit="$ctrl.addVersion(newVersion)" ng-if="!$ctrl.version && $ctrl.complite" name="newVersion" flex layout="row" layout-align="start center">-->
    <!--<md-input-container flex>-->
        <!--<label>New version</label>-->
        <!--<input type="text" name="new_version" ng-model="$ctrl.newVersionName" required>-->
        <!--<div ng-messages="newVersion.new_version.$error">-->
            <!--<div ng-message="pattern">format \d.\d.\d {{$ctrl.validate.version}}</div>-->
        <!--</div>-->
    <!--</md-input-container>-->
    <!--<md-button type="submit" class="">{{"COMMON_ADD" | translate }}</md-button>-->
    <!--<md-button ng-click="$ctrl.cancelAddVersion()" class="" ng-disabled="$ctrl.versions.length==0">{{"COMMON_CANCEL" | translate }}</md-button>-->
<!--</form>-->

<!--<div ng-if="$ctrl.version">-->
    <!--<div layout="row" layout-align="start center" >-->
        <!--<md-input-container flex="30">-->
            <!--<label translate>COMMON_VERSION</label>-->
            <!--<md-select ng-if="$ctrl.version" ng-model="$ctrl.version">-->
                <!--<md-option ng-repeat="v in $ctrl.versions" ng-value="v">{{v.Version}}</md-option>-->
                <!--<md-option ng-value="null" ng-if="$ctrl.canManage">new version</md-option>-->
            <!--</md-select>-->

        <!--</md-input-container>-->
        <!--<div ng-if="$ctrl.version.SizeBytes">-->
            <!--Size: <span ng-bind-html="$ctrl.CatalogService.datasetVersionSize($ctrl.version)"></span>-->
        <!--</div>-->
        <!--<div flex style="padding-left: 10px">-->
            <!--<div ng-if="$ctrl.version && $ctrl.version.Status != 'ok'  " >Status {{$ctrl.version.Status || 'Undefined'}}</div>-->
            <!--<div ng-if="$ctrl.version && $ctrl.version.StatusError" class="error">{{$ctrl.version.StatusError}}</div>-->
        <!--</div>-->
        <!--&lt;!&ndash;<div class="flex layout-align-end-center layout-row" ng-if="$ctrl.version.Status == 'ok'">&ndash;&gt;-->
            <!--&lt;!&ndash;<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>&ndash;&gt;-->
            <!--&lt;!&ndash;<a layout="row"&ndash;&gt;-->
               <!--&lt;!&ndash;class="md-button md-raised"&ndash;&gt;-->
               <!--&lt;!&ndash;style="margin-left: 0.5em"&ndash;&gt;-->
               <!--&lt;!&ndash;ng-disabled="isDownloading || !$ctrl.version"&ndash;&gt;-->
               <!--&lt;!&ndash;ng-click="$ctrl.download()">&ndash;&gt;-->
                <!--&lt;!&ndash;<md-icon md-svg-icon="core:link"></md-icon>&ndash;&gt;-->
                <!--&lt;!&ndash;<span style="margin: 0 10px">download</span>&ndash;&gt;-->
                <!--&lt;!&ndash;<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>&ndash;&gt;-->
            <!--&lt;!&ndash;</a>&ndash;&gt;-->
            <!--&lt;!&ndash;<md-button ng-click="$ctrl.serveDialog($event)" class="md-raised" ng-disabled="!$ctrl.version">{{"COMMON_SERVE" | translate}}</md-button>&ndash;&gt;-->

        <!--&lt;!&ndash;</div>&ndash;&gt;-->

        <!--<div class="flex layout-align-end-center layout-row" style="width: 100%; " ng-if="$ctrl.version.Status == 'ok'" >-->
            <!--&lt;!&ndash;<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>&ndash;&gt;-->
            <!--&lt;!&ndash;<a layout="row"&ndash;&gt;-->
               <!--&lt;!&ndash;href="{{$ctrl.version.DownloadURL}}"&ndash;&gt;-->
               <!--&lt;!&ndash;traget="_blank"&ndash;&gt;-->
               <!--&lt;!&ndash;class="md-button md-raised"&ndash;&gt;-->
               <!--&lt;!&ndash;style="margin-left: 0.5em"&ndash;&gt;-->
               <!--&lt;!&ndash;ng-disabled="isDownloading"&ndash;&gt;-->
               <!--&lt;!&ndash;ng-click="$ctrl.download()"&ndash;&gt;-->
            <!--&lt;!&ndash;&gt;&ndash;&gt;-->
            <!--<a layout="row"-->
               <!--href="{{$ctrl.version.DownloadURL}}"-->
               <!--target="_blank"-->
               <!--class="md-button md-raised"-->
               <!--style="margin-left: 0.5em"-->
               <!--download-->
            <!--&gt;-->
                <!--<md-icon md-svg-icon="core:link"></md-icon>-->
                <!--<span style="margin: 0 10px">download</span>-->
                <!--&lt;!&ndash;<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>&ndash;&gt;-->
            <!--</a>-->
        <!--</div>-->
        <!--<md-button ng-click="$ctrl.serveDialog($event)" class="md-raised" ng-disabled="!$ctrl.version" ng-if="$ctrl.version.Status == 'ok'" >{{"COMMON_SERVE" | translate}}</md-button>-->

        <!--<md-menu class="md-secondary" md-position-mode="target-right target">-->
            <!--<md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.canManage">-->
                <!--<md-icon md-svg-icon="core:short-menu"></md-icon>-->
            <!--</md-button>-->
            <!--<md-menu-content width="3">-->
                <!--<md-menu-item>-->
                    <!--<md-button ng-click="$ctrl.edit = !$ctrl.edit" ><md-icon md-svg-icon="core:info"></md-icon>{{"COMMON_INFO" | translate}}</md-button></md-menu-item>-->
                <!--<md-menu-item>-->
                    <!--<md-button aria-label="icon" ng-click="$ctrl.delete()">-->
                        <!--<md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}-->
                    <!--</md-button>-->
                <!--</md-menu-item>-->
            <!--</md-menu-content>-->
        <!--</md-menu>-->
    <!--</div>-->

    <!--<div ng-if="$ctrl.version.Source">-->
        <!--Export from: <a ui-sref="wsApplication.tab({wsId: $ctrl.version.Source.WorkspaceName, applicationName: $ctrl.version.Source.ApplicationName, tab: 'jobs', tabItem: 'job_' + $ctrl.version.Source.Task + '_' + $ctrl.version.Source.Build})">-->
            <!--{{$ctrl.version.Source.WorkspaceDisplayName}}/{{$ctrl.version.Source.ApplicationDisplayName}}/jobs/{{$ctrl.version.Source.Task}}:{{$ctrl.version.Source.Build}}-->
        <!--</a>-->
    <!--</div>-->

    <!--<form ng-if="$ctrl.canManage" name="upload" ng-submit="$ctrl.uploadFile(upload, fileM, 'upload', 'model', fApiM)" layout="column" style="width: 100%">-->
        <!--<div layout="row" layout-align="center center">-->
            <!--<lf-ng-md-file-input-->
                    <!--lf-files="fileM"-->
                    <!--flex progress-->
                    <!--ng-disabled="$ctrl.isLoading.model" required-->
                    <!--lf-api="fApiM"></lf-ng-md-file-input>-->
            <!--<md-button type="submite" ng-disabled="$ctrl.isLoading.model || !fileM || !fileM.length">-->
                <!--<span ng-show="!$ctrl.isLoading.model" >{{"COMMON_UPLOAD" | translate}}</span>-->
                <!--<div flex linear ng-if="$ctrl.isLoading.model">-->
                    <!--{{$ctrl.loadingProgres}}%-->
                    <!--<md-progress-linear ng-if="$ctrl.isLoading.model" flex></md-progress-linear>-->
                <!--</div>-->
            <!--</md-button>-->
            <!--<md-button ng-click="$ctrl.addFile = false">{{"COMMON_CANCEL" | translate}}</md-button>-->
        <!--</div>-->
        <!--<cmp-error-toast ng-if="$ctrl.loadingError.model" error="$ctrl.loadingError.model"></cmp-error-toast>-->
    <!--</form>-->
    <!--<cmp-error-toast ng-if="$ctrl.loadingError.fileM" error="$ctrl.loadingError.fileM"></cmp-error-toast>-->

    <!--<div ng-if="$ctrl.edit">-->
        <!--<cmp-serving-form-config data="$ctrl.version.Config.serving" serving="$ctrl.version.Config.serving" flex="100"></cmp-serving-form-config>-->
        <!--<cmp-serving-form-spec data="$ctrl.version.Config.servingSpec" flex="100"></cmp-serving-form-spec>-->

        <!--&lt;!&ndash;<cmp-mlmodel-serving-form data="$ctrl.version.Config.serving" ></cmp-mlmodel-serving-form>&ndash;&gt;-->
        <!--&lt;!&ndash;<cmp-mlmodel-serving-spec data="$ctrl.version.Config.servingSpec"></cmp-mlmodel-serving-spec>&ndash;&gt;-->

        <!--<div layout="row" layout-align="start center" ng-if="$ctrl.canManage">-->
            <!--<div flex>-->
                <!--<md-progress-linear ng-if="$ctrl.saving" error="$ctrl.saving"></md-progress-linear>-->
                <!--<cmp-error-toast ng-if="$ctrl.saveError" error="$ctrl.saveError"></cmp-error-toast>-->
            <!--</div>-->
            <!--<md-button ng-click="$ctrl.edit = !$ctrl.edit" ng-disabled="$ctrl.saving">{{'COMMON_CANCEL' | translate}}</md-button>-->
            <!--<md-button ng-click="$ctrl.saveConfig()" ng-disabled="$ctrl.saving">{{'COMMON_SAVE' | translate}}</md-button>-->
        <!--</div>-->
    <!--</div>-->

<!--</div>-->