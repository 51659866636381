<div class="layout-row layout-align-start-center title md-primary"  >
    <div class="layout-row layout-align-start-center title md-primary flex" ng-click="$ctrl.toogle()" >
        <div class="icon-tree-point">
            <md-icon md-svg-icon="core:minus-box" ng-if="$ctrl.open"></md-icon>
            <md-icon md-svg-icon="core:plus-box" ng-if="!$ctrl.open"></md-icon>
        </div>
        <h4 class="flex">{{$ctrl.pod.Name}}</h4>
        <div>
            <md-icon md-svg-icon="core:calendar"></md-icon>
            <span>{{ $ctrl.pod.StartTime | date:"MM/dd/yyyy 'at' h:mma" }}</span>
        </div>
    </div>
    <!--<md-button ng-click="$ctrl.getLog()" class="md-icon-button" ng-disabled="$ctrl.uploadLog"><md-icon md-svg-icon="core:reload"></md-icon></md-button>-->
</div>
<md-progress-linear ng-if="$ctrl.uploadLog"></md-progress-linear>
<pre  ng-if="$ctrl.Log && $ctrl.open" class="log-view log">{{$ctrl.Log.output}}</pre>
<md-button ng-click="$ctrl.getLog()" class="update-button">
    <md-tooltip md-direction="left">Update log</md-tooltip>
    <md-icon md-svg-icon="core:reload"></md-icon>
</md-button>

<div layout="row" layout-align="start center" ng-if="$ctrl.pod.truncated" >
    <div flex><md-progress-linear ng-if="$ctrl.uploadLog"></md-progress-linear></div>
    <md-button  ng-click="$ctrl.getLog()">Load full log</md-button>
</div>
