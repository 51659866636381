<!--<div class="contentPageTitle">-->
    <!--<div class="main_width_greed inner">-->
        <!--<div class="title flex layout-row layout-align-start-center">-->
            <!--<span class="item">CATALOG</span>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->
<div class="main_width_greed">
    <div class=" innerContent">
        <div layout-align="start" layout="row" class="catalog-common">
            <div flex="20" ui-view="menu" class="catalog-common-menu">
                <cmp-catalog-menu list-state="$ctrl.listState"></cmp-catalog-menu>
            </div>
            <div style="width: 10px"></div>
            <div flex ui-view >
                <!--<cmp-catalog-tag-list-main></cmp-catalog-tag-list-main>-->
            </div>
        </div>
    </div>
</div>




