    <md-list-item layout="row" layout-align="start start" ng-click="$ctrl.goto($event)" ui-sref-active="active" ng-class="{active: $ctrl.data.name == $ctrl.currentJob.name}">
    <!--<md-list-item layout="row" layout-align="start start" ui-sref="wsApplication.tab({ tabItem: $ctrl.data.name})" ui-sref-active="active">-->
        <item-status-icon data="{Health: $ctrl.data.status}" class="widthAuto"></item-status-icon>
        <h4 style="margin: 0; display: flex" flex>{{$ctrl.data.task_name}}:{{$ctrl.data.build}}<span ng-if="$ctrl.data.exec_info.name">&nbsp;&nbsp;-&nbsp;&nbsp;{{$ctrl.data.exec_info.name}}</span> <span ng-if="$ctrl.data.serve" class="status {{$ctrl.data.serve.status}}" > ({{"COMMON_SERVING" | translate}}<span ng-if="!$ctrl.data.serving">:direct</span>)</span></span></h4>
        <div layout="row" ng-if="!$ctrl.listController.job" layout-align="end center" style="height: 40px">
            <div class="md-padding"></div>
            <div class="wordTime" >
                <div class="layout-row layout-align-start-center">
                    <md-icon md-svg-icon="core:calendar" class="tsIcon"></md-icon>
                    <div class=" flex layout-column layout-align-start-start">
                        <span class="ts">{{ $ctrl.data.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                        <span class="ts" ng-if="$ctrl.data.stop_time">{{ $ctrl.data.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div  ng-if="!$ctrl.listController.job" ng-include="'kuberlab/applications/jobs/application_job_menu.html'"></div>
        <div>
            <md-checkbox style="margin: 0;" ng-model="$ctrl.data.toDelete" ng-change="$ctrl.jobSelect($event)"></md-checkbox>
        </div>
    </md-list-item>

    <div layout-align="start" layout="row">
        <div class="infoPadding"></div>
        <div flex layout="column" layout-align="start">
            <div ng-if="!$ctrl.listController.job" style="padding-bottom: 1em;">
                <div style="font-size: 90%" >{{"COMMON_EXPERIMENT" | translate }}: {{$ctrl.data.branch}}, {{"COMMON_AUTHOR" | translate }}: {{$ctrl.data.author}}</div>
                <div ng-if="$ctrl.data.comment">{{$ctrl.data.comment}}</div>
            </div>
            <div ng-include="'kuberlab/applications/jobs/application_job_info.html'"></div>
        </div>
    </div>

    <div ng-if="$ctrl.data.status == 'Running' && !$ctrl.listController.job " class="applicationMetricShort layout-row layout-align-center " style="background-color: #eee" ng-include="'kuberlab/applications/jobs/application_job_status.html'"></div>
