<div ng-if="$ctrl.fileUrl && !$ctrl.fileInfo.dir" flex layout="column" layout-align="center center" class="{{$ctrl.fileFormat}}Content">

    <!--<div class="fileName">file {{$ctrl.fileUrl}}</div>-->
    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
    <div ng-switch="$ctrl.fileFormat"  ng-if="!$ctrl.bigFile" style="width: 100%;" flex class="content"  layout="column" layout-align="center center">
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        <div ng-if="$ctrl.file" ng-switch-when="txt" class="text" style="width: 100%" >
            <div ui-ace="{readOnly: true, onLoad: aceLoaded}" ng-model="$ctrl.file" flex style="height: 360px; width: 100%;"></div>
        </div>
        <!--<img ng-switch-when="img" ng-if="$ctrl.file" src="data:{{$ctrl.fileContentType}};base64,{{$ctrl.file | arraybuffertobase64}}"  />-->
        <!--<img ng-switch-when="img" ng-if="$ctrl.file" src="data:{{$ctrl.fileContentType}};base64,{{$ctrl.file}}"  />-->
        <img ng-switch-when="img" src="{{$ctrl.downloadLink()}}" style=" max-height: 70vh; max-width: 70wh;"  />
        <div ng-switch-default class="desc" style="text-align:center;">
            Preview this file not supported (<span ng-bind-html="$ctrl.ctrl.listController.CatalogService.datasetVersionSize({SizeBytes: $ctrl.fileInfo.size})"></span>)<br />
            <a class="cmp-clickable" ng-click="$ctrl.viewAsText()">view as text</a>
        </div>

    </div>

    <div ng-if="$ctrl.bigFile" class="content" flex  layout="column" layout-align="center center">
        <div class="desc">
            File is too large for preview (<span ng-bind-html="$ctrl.ctrl.listController.CatalogService.datasetVersionSize({SizeBytes: $ctrl.fileInfo.size})"></span>)
        </div>
    </div>

    <div layout="row" layout-align="center center">
        <a href="{{$ctrl.downloadLink(true)}}" target="_blank" class="md-button" >download</a>
    </div>

</div>
