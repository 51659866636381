
<cmp-api-error-service ng-if="$ctrl.deleteError" format="'dialog'" ></cmp-api-error-service>

<md-card class="flex clusterApps">
    <md-progress-linear md-mode="indeterminate" ng-if="$ctrl.deliting"></md-progress-linear>
    <md-card-header>
        <md-card-avatar>
            <md-icon class="md-avatar-icon" md-svg-icon="core:application"></md-icon>
        </md-card-avatar>
        <md-card-header-text class="flex layout-row layout-align-space-between-start">
            <div class="flex layout-column">
                <span class="md-title">{{$ctrl.data.Name}}</span>
                <span class="md-subhead">Version: <span class="clusterStatus {{$ctrl.data.status.toLowerCase()}}">{{$ctrl.data.Version}}</span></span>
                <md-button class="fullInfo" ng-class="{isFull: isFull}" ng-click="isFull = !!!isFull">
                    <md-icon md-svg-icon="core:information"></md-icon> Info
                    <md-icon md-svg-icon="core:chevron-down" ng-if="!isFull" ></md-icon>
                    <md-icon md-svg-icon="core:chevron-up" ng-if="isFull" ></md-icon>
                </md-button>
                <!--<md-button class="fullInfo" ng-class="{isFull: $ctrl.isFull}" ng-click="$ctrl.fulInfo()">-->
                    <!--<md-icon md-svg-icon="core:information"></md-icon> Info-->
                    <!--<md-icon md-svg-icon="core:chevron-down" ng-if="!$ctrl.isFull" ></md-icon>-->
                    <!--<md-icon md-svg-icon="core:chevron-up" ng-if="$ctrl.isFull" ></md-icon>-->
                <!--</md-button>-->
            </div>
            <div>
                <span class="md-subhead">Status: <span class="clusterStatus {{$ctrl.data.status.toLowerCase()}}">{{$ctrl.data.Status}}</span></span>
            </div>
        </md-card-header-text>
        <div class="md-secondary-container">
            <md-menu class="" md-position-mode="target-right target">
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.permissions.can('project.manage')">
                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item >
                        <md-button ng-click="$ctrl.deleteConfirm($event)" ><md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE" | translate}}</md-button>
                    </md-menu-item>
                    <md-menu-item >
                        <md-button ng-click="$ctrl.restart($event)" ><md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESTART" | translate}}</md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </div>
    </md-card-header>

    <md-card-content>
        <div ng-if="isFull" class="list-padding-left">
            <div>First deployed: <md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.data.FirstDeployed | date:"MM/dd/yyyy 'at' h:mma" }}</div>
            <div>Last deployed: <md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.data.LastDeployed | date:"MM/dd/yyyy 'at' h:mma" }}</div>
            <div ng-if="$ctrl.data.Notes" class="flex" style="padding-top: 2em; width: 100%; overflow: auto; max-height: 400px; white-space: pre-wrap;  ">{{$ctrl.data.Notes}}</div>
        </div>
        <!--<div ng-if="$ctrl.isFull" class="list-padding-left">-->
            <!--<div>First deployed: <md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.data.FirstDeployed | date:"MM/dd/yyyy 'at' h:mma" }}</div>-->
            <!--<div>Last deployed: <md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.data.LastDeployed | date:"MM/dd/yyyy 'at' h:mma" }}</div>-->
            <!--<div ng-if="$ctrl.data.Notes" class="flex" style="padding-top: 2em; width: 100%; overflow: auto; max-height: 400px; white-space: pre-wrap;  ">{{$ctrl.data.Notes}}</div>-->
        <!--</div>-->
        <md-list ng-if="$ctrl.data.Services.length" class="list-padding-left">
            <div class="md-subheader">Services</div>
            <div ng-repeat="service in $ctrl.data.Services" >
                <md-list-item class="appService">
                    <md-icon md-svg-icon="core:application"></md-icon>
                    <div  class="md-list-item-text flex">
                        <div class="flex layout-row layout-align-start-center">
                            <h4 class="flex">{{service.Name}}</h4>
                            <div>External IP: {{service.ExternalIP}} <span ng-repeat="port in service.Ports">{{port}}<span ng-if="!$last">, </span></span></div>
                        </div>
                    </div>
                    <div class="md-secondary-container" ng-if="service.Ports && service.Ports.length">
                        <md-menu class="" md-position-mode="target-right target">
                            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                                <md-icon md-svg-icon="core:short-menu"></md-icon>
                            </md-button>
                            <md-menu-content width="3">
                                <md-menu-item  ng-repeat="port in service.Ports">
                                    <a target="_blank"
                                       class="md-button"
                                       href="/api/v1/proxy/namespaces/{{$ctrl.data.Namespace}}/services/{{service.Name}}:{{port.split('/')[1]}}/{{$ctrl.$state.params.wsId}}/{{$ctrl.$state.params.projectName}}/{{$ctrl.cluster.Name}}/{{$ctrl.$state.params.envName}}/"
                                    ><md-icon md-svg-icon="core:link"></md-icon> {{port}} </a>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </md-list-item>
                <md-list-item ng-if="service.Errors && service.Errors.length" class="error">
                    <div class="md-list-item-text md-offset">
                        <div ng-repeat="err in service.Errors">
                            <div class="errorItem {{err.Type}}">
                                <div class="title flex layout-row layout-align-start-center">
                                    <md-icon md-svg-icon="core:alert"></md-icon>
                                    <div class="flex">{{err.Type}}</div>
                                    <div>{{err.Reason}}</div>
                                </div>
                                <p>{{err.Message}}</p>
                            </div>
                        </div>
                    </div>
                </md-list-item>

            </div>
        </md-list>
    </md-card-content>
</md-card>
