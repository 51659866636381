<div  class="billingPlace" >
    <cmp-error-toast ng-if="$ctrl.customer.Failed" error="{data: {Error: 'Failed to Bill. Please check your card'}}" layout-margin></cmp-error-toast>
  <div ng-if="$ctrl.customerComplite && !$ctrl.showCardForm && !$ctrl.cardPromise">
    <div layout="row" layout-align="start center" style="margin: 8px;">
      <h3 flex>Plans</h3>
      <md-menu  class="md-secondary" md-position-mode="target-right target" ng-if="$ctrl.customer.Card">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()"><md-icon md-svg-icon="core:short-menu"></md-icon></md-button>
        <md-menu-content width="3">
          <md-menu-item>
            <md-button ng-click="$ctrl.getPromiseCard()">{{"COMMON_CHANGE" | translate}}</md-button>
          </md-menu-item>
          <md-menu-item>
            <md-button class="md-warn" ng-click="$ctrl.unsubscibe()">{{"COMMON_UNSUBSCRIBE" | translate}}</md-button>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
    <!--<cmp-billing-plan-list cost="$ctrl.cost" plans="$ctrl.plans" current-plans="$ctrl.currentPlans" selected-plans="$ctrl.selectedPlans" can-add="true" billing-controller="$ctrl" is-admin="$ctrl.currentUser.Admin"></cmp-billing-plan-list>-->
    <div ng-repeat="(title, plans) in $ctrl.groupPlans">
      <h4 ng-if="$ctrl.plansShowTitle()" style="padding-left: 8px;">Plans for {{title}}</h4>
      <cmp-billing-plan-list
              cost="$ctrl.cost"
              plans="plans"
              current-plans="$ctrl.currentPlans"
              selected-plans="$ctrl.selectedPlans"
              can-add="true"
              billing-controller="$ctrl"
              is-admin="$ctrl.currentUser.Admin">
      </cmp-billing-plan-list>
    </div>

    <cmp-error-toast ng-if="$ctrl.cardError" error="$ctrl.cardError"></cmp-error-toast>
  </div>
  <div ng-if="!$ctrl.customerComplite">
    <md-progress-linear></md-progress-linear>
  </div>


  <cmp-billing-card ng-if="$ctrl.cardPromise" promise="$ctrl.cardPromise" card-token="$ctrl.cardToken" billing="$ctrl" change="$ctrl.change"></cmp-billing-card>

  <div class="progress" layout="row" ng-if="$ctrl.loading" layout-align="center center">
    <md-progress-circular></md-progress-circular>
  </div>

</div>

