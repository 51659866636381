<md-divider></md-divider>
<md-list-item id="tabItem_{{$ctrl.data.name}}" ng-click="$ctrl.goItem()" class="layout-row layout-align-start-center cmp-clickable paddingListItemTabContent">
    <div class="icon-tree-point">
        <md-icon md-svg-icon="core:minus-box" ng-if="$ctrl.isActive"></md-icon>
        <md-icon md-svg-icon="core:plus-box" ng-if="!$ctrl.isActive"></md-icon>
    </div>
    <div class="flex layout-row layout-align-space-between-center">
        <h4 class="flex-40">{{$ctrl.data.Cluster}}</h4>
        <div class="flex">
            <md-icon md-svg-icon="core:calendar"></md-icon>
            <span class="ts">{{ $ctrl.getDate($ctrl.data.StartTime) | date:"MM/dd/yyyy 'at' h:mma" }}</span>
            <span class="ts"> - {{ $ctrl.getDate($ctrl.data.LastTime) | date:"MM/dd/yyyy 'at' h:mma" }}</span>
        </div>
    </div>
    <div class="status {{$ctrl.data.Status}} flex-15" style="text-align: right">{{$ctrl.data.Status}}</div>
</md-list-item>
<div class="fullInfo margin-left paddingListItemTabContent" ng-if="$ctrl.isActive">
    <md-progress-linear ng-if="$ctrl.uploadPods" ></md-progress-linear>
    <cmp-api-error-service error="$ctrl.uploadError" ng-if="$ctrl.uploadError"></cmp-api-error-service>
    <div>
        <md-progress-linear ng-if="$ctrl.isLoading" flex></md-progress-linear>
        <cmp-api-error-service ng-if="$ctrl.errorLoading" error="$ctrl.errorLoading"></cmp-api-error-service>
        <p ng-if="$ctrl.data.Description"><b>Description:</b> {{$ctrl.data.Description}}</p>
        <div class="layout-row layout-align-start-center log-view-title md-raised md-primary" >
            <h4 class="flex">{{"COMMON_LOG" | translate}}</h4>
            <md-button ng-click="$ctrl.getFullInfo(true)">{{"COMMON_UPDATE" | translate}}</md-button>
        </div>
        <div class="log-view log">
            <pre ng-if="$ctrl.data.Data"  >{{$ctrl.data.Data}}</pre>
            <div ng-if="$ctrl.data.Error" class="logError-Item"><b>Error:</b> <span>{{$ctrl.data.Error}}</span></div>
        </div>
    </div>
</div>
