
<div class="md-padding">
    <div class="layout-row layout-align-start-center">
        <h2 class="flex" translate>{{'MLAPPS_JOBS_TITLE'}}</h2>
        <div class="flex-20 layout-row" ng-include="'kuberlab/mlapps/card/mlapps_jobs_filter.html'"></div>
        <md-button ng-click="$ctrl.changeView(chartListView)" class="md-icon-button">
            <md-icon ng-if="!$ctrl.grid" md-svg-icon="core:view-grid"></md-icon>
            <md-icon ng-if="$ctrl.grid" md-svg-icon="core:view-sequential"></md-icon>
        </md-button>
    </div>
    <cmp-api-error-service ng-if="$ctrl.errorList" error="$ctrl.errorList"></cmp-api-error-service>
    <cmp-api-error-service ng-if="$ctrl.errorRequest" error="$ctrl.errorRequest" format="'dialog'"></cmp-api-error-service>
    <div class="chartList"
         ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'"
         name="chartListView">
        <div class="item_view  chartList_Item" ng-repeat="item in $ctrl.Jobs | filter: {status:$ctrl.statusFilter}" >
            <md-card>
                <md-card-content>
                    <h3 class="name flex">{{item.name}}</h3>
                    <md-menu class="menu" md-position-mode="target-right target" ng-if="$ctrl.permissions.can('mlapp.manage')">
                        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                            <md-icon md-svg-icon="core:short-menu"></md-icon>
                        </md-button>
                        <md-menu-content width="3">
                            <md-menu-item ng-if="item.serve.status != 'Running'" >
                                <md-button class="md-primary" ng-click="$ctrl.getServeDialog($event, item)">
                                    <md-icon md-svg-icon="core:play"></md-icon>
                                    {{"MLAPPS_JOB_SERVE" | translate}}
                                </md-button>
                            </md-menu-item>
                            <!--<md-menu-item ng-if="item.serve.status == 'Running'" >-->
                                <!--<md-button class="md-primary" ng-click="$ctrl.action('stop_serving', item)">{{"MLAPPS_JOB_STOP_SERVE" | translate}}</md-button>-->
                            <!--</md-menu-item>-->
                            <md-menu-item >
                                <md-button ng-click="$ctrl.action('restart', item)">
                                    <md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESTART"|translate}}
                                </md-button>
                            </md-menu-item>
                            <md-menu-item ng-if="item.status == 'Running'">
                                <md-button ng-click="$ctrl.action('stop', item)">
                                    <md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP"|translate}}
                                </md-button>
                            </md-menu-item>
                            <md-menu-divider></md-menu-divider>
                            <md-menu-item>
                                <md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'metrics', tabItem: item.name})">
                                    <md-icon md-svg-icon="core:chart"></md-icon> {{"COMMON_METRICS"|translate}}
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button aria-label="icon" ui-sref="wsMlapp.tab({tab: 'logs', tabItem: item.name})">
                                    <md-icon md-svg-icon="core:note-text"></md-icon> {{"COMMON_LOGS"|translate}}
                                </md-button>
                            </md-menu-item>
                            <md-menu-divider></md-menu-divider>
                            <md-menu-item>
                                <md-button aria-label="icon" ng-click="$ctrl.action('delete', item)">
                                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                                </md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                    <div class="wordTime" >
                        <div class="layout-row layout-align-start-center">
                            <md-icon md-svg-icon="core:calendar" class="tsIcon"></md-icon>
                            <div class=" flex layout-column layout-align-start-start">
                                <span class="ts">{{ item.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                                <span class="ts" ng-if="item.stop_time">{{ item.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                            </div>
                            <div class="layout-row layout-align-end-center">
                                <div class="status {{item.status}}" >{{item.status}}</div>
                            </div>
                        </div>


                    </div>

                    <div ng-if="item.status == 'Running'" class="mlappMetricShort layout-row layout-align-center-start ">
                        <div class="item"><div class="value">{{item.Stats.cpu_usage.Value.toFixed(2)}}{{item.Stats.cpu_usage.Measure}}</div><div class="legend" translate>MLAPP_METRIC_cpu_usage</div></div>
                        <div class="item"><div class="value">{{item.Stats.gpu_usage.Value.toFixed(2)}}{{item.Stats.gpu_usage.Measure}}</div><div class="legend" translate>MLAPP_METRIC_gpu_usage</div></div>
                        <div class="item"><div class="value">{{item.Stats.memory_bytes.Value.toFixed(2)}}{{item.Stats.memory_bytes.Measure}}</div><div class="legend" translate>MLAPP_METRIC_memory_bytes</div></div>
                    </div>

                </md-card-content>
                <md-card-content class="mlappJobServe" ng-if="item.serve.status == 'Running'" style="border-top: 1px solid #ccc" >
                    <div class="flex" >serve: {{item.serve.host}}</div>
                    <div >start: <md-icon md-svg-icon="core:calendar" ></md-icon> <span class="ts">{{ item.serve.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span></div>
                    <md-button ng-if="$ctrl.permissions.can('mlapp.manage')" class="md-primary" ng-click="$ctrl.action('stop_serving', item)"><md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP" | translate}}</md-button>
                </md-card-content>
            </md-card>
        </div>
    </div>
</div>