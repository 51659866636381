<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Serve model</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <cmp-api-error-service ng-if="error" error="error"></cmp-api-error-service>
            <div class="layout-row layout-align-start-top">
                <div class="flex-20 cmp-menu-v">
                    <h5>Serving</h5>
                    <ul>
                        <li ng-repeat="item in $ctrl.application.Configuration.spec.serving" >
                            <a ng-class="{'active' : isCurrent(item)}" ng-click="serveObj = item">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="flex" style="padding: 0 1em;">
                    <cmp-applications-task application="$ctrl.application" data="serveObj"></cmp-applications-task>
                </div>
            </div>

        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
            </div>
            <md-button ng-click="cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button ng-click="serve(params)" ng-if="!error && !res">
                {{"MLAPPS_JOB_SERVE" | translate}}
            </md-button>
            <md-button ng-click="continue(params)" ng-if="!error && res">
                {{"COMMON_CONTINUE" | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
