<md-progress-linear ng-if="$ctrl.isLoading"></md-progress-linear>
<!--<div class="innerContentBorder flex">-->
    <!--<md-tabs class="itemContent" md-dynamic-height >-->
        <!--<md-tab ng-repeat="tab in $ctrl.chartTypes track by $index"-->
                <!--md-active="$ctrl.$state.params.type == tab.type"-->
                <!--ng-click="$ctrl.goTo(tab.type)">-->
            <!--<md-tab-label>-->
                <!--{{tab.title}}-->
            <!--</md-tab-label>-->
        <!--</md-tab>-->
    <!--</md-tabs>-->
<!--</div>-->


<!--<div class="innerContentBorder flex"></div>-->

<!--<div layout="row" layout-align="space-between center" class="innerContentBorder topline">-->
<style>
    .chart_menu .md-nav-bar {
        border-bottom: 0;
    }
</style>
<div class="innerContentBorder chart_menu">
    <div layout="row" layout-align="start center" flex>
        <md-nav-bar class="md-margin flex"
                    md-no-ink-bar="disableInkBar"
                    md-selected-nav-item="$ctrl.$state.params.type"
                    nav-bar-aria-label="navigation">
            <md-nav-item ng-repeat="tab in $ctrl.chartTypes track by $index" md-nav-click="$ctrl.goTo(tab.type)" name="{{tab.type}}">
                {{tab.title | translate}}
            </md-nav-item>
        </md-nav-bar>

        <!--<div>-->
        <!--</div>-->
        <cmp-pagenate page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
        <div class="layout-row layout-align-end-center" >
            <form class="search" ng-submit="$ctrl.find()">
                <input type="text" ng-model="$ctrl.search" ng-model-options='{ debounce: 1000 }' ng-change="$ctrl.find()">
                <md-button type="submit" class="md-icon-button"><md-icon md-svg-icon="core:search"></md-icon></md-button>
            </form>
        </div>
        <md-button ng-click="$ctrl.changeView(chartListView)" class="md-icon-button">
            <md-icon ng-if="!$ctrl.grid" md-svg-icon="core:view-grid"></md-icon>
            <md-icon ng-if="$ctrl.grid" md-svg-icon="core:view-sequential"></md-icon>
        </md-button>
    </div>
</div>

<div class="chartList"
     ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'"
     name="chartListView"
     ng-if="$ctrl.list.length" >
    <div class="item_view  chartList_Item" ng-repeat="item in $ctrl.list" ng-include=" 'kuberlab/charts/card/card_chart_small.html' "></div>
</div>
<!--<div ng-if="!$ctrl.list.length && $ctrl.page == 1 && !$ctrl.isLoading" style="text-align: center; padding: 2em;">List empty</div>-->
<div ng-if="!$ctrl.list.length && $ctrl.page == 1 && !$ctrl.isLoading" ng-include=" 'kuberlab/charts/card/list_empty.html' "></div>
<div ng-if="!$ctrl.list.length && $ctrl.page > 1 && !$ctrl.isLoading" style="text-align: center; padding: 2em;">Page {{$ctrl.page}} no item</div>
<div  class="layout-row layout-align-center-center">
    <!--<div ng-include=" 'kuberlab/charts/card/pagenate.html' "></div>-->
    <cmp-pagenate page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
</div>