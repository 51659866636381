
<div class="cmp-block-compact">
    <h5 class="cmp-block-compact-title" translate>Serving parameters</h5>
    <div class="cmp-block-compact-content">
        <div class="cmp-block-compact" ng-if="!$ctrl.project">
            <h6 class="cmp-block-compact-title" translate>Out filter</h6>
            <div class="cmp-block-compact-content">
                <cmp-autolist-single
                        class="flex"
                        data="$ctrl.data.outFilter"
                        form="$ctrl.form"
                ></cmp-autolist-single>
            </div>
        </div>
        <div class="cmp-block-compact" ng-if="!$ctrl.project">
            <h6 class="cmp-block-compact-title" translate></h6>
            <div class="cmp-block-compact-content" layout="row" layout-align="start center" layout-wrap>
                <md-input-container flex="50">
                    <label>model</label>
                    <input ng-model="$ctrl.data.model">
                </md-input-container>
                <md-input-container flex="50">
                    <label>signature</label>
                    <input ng-model="$ctrl.data.signature">
                </md-input-container>
                <!--<md-input-container flex="50">-->
                    <!--<label>Out mime type</label>-->
                    <!--<input ng-model="$ctrl.data.outMimeType">-->
                <!--</md-input-container>-->
                <md-input-container flex="50">
                    <label>template</label>
                    <md-select ng-model="$ctrl.data.template">
                        <md-option ng-value="">Default</md-option>
                        <md-option ng-value="'image'">image</md-option>
                        <md-option ng-value="'video'">video</md-option>
                        <md-option ng-value="'webrtc'">Webrtc</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="50">
                    <md-checkbox ng-model="$ctrl.data.options.noCache">disable cache</md-checkbox>
                </md-input-container>
                <md-input-container flex="50">
                    <md-checkbox ng-model="$ctrl.data.options.saveStreamPreviews">Store stream previews</md-checkbox>
                </md-input-container>
                <md-input-container flex="50">
                    <md-checkbox ng-model="$ctrl.data.rawInput">raw input</md-checkbox>
                </md-input-container>
            </div>
        </div>
        <div class="cmp-block-compact">
            <h6 class="cmp-block-compact-title" translate>Params</h6>
            <div class="cmp-block-compact-content">
                <cmp-autolist
                        data="$ctrl.data.params"
                        class="flex"
                        labels="['name', 'type', 'value', 'label']"
                        attr="{
                                type: {
                                    type: 'select',
                                    label: 'text'
                                },
                                volumes: {
                                    type:  $ctrl.serving_param_types
                                },
                                list: {
                                    value: true
                                }

                            }"
                        form="$ctrl.form"
                ></cmp-autolist>
            </div>

        </div>
        <div class="cmp-block-compact" ng-if="!$ctrl.project">
            <h6 class="cmp-block-compact-title" translate>Response</h6>
            <div class="cmp-block-compact-content"  layout="row" layout-align="start center" layout-wrap>
                <cmp-autolist
                        data="$ctrl.data.response"
                        class="flex"
                        flex="100"
                        labels="['name', 'type', 'shape', 'description']"
                        attr="{
                                type: {
                                    type: 'select',
                                    shape: 'array',
                                    description: 'text'
                                },
                                volumes: {
                                    type:  ['byte', 'bytes', 'int', 'double', 'string'],
                                    shape: 'number'
                                }
                            }"
                        form="$ctrl.form"
                ></cmp-autolist>
            </div>
        </div>

        <div class="cmp-block-compact" ng-if="!$ctrl.project">
            <h6 class="cmp-block-compact-title" translate>Response template</h6>
            <div class="cmp-block-compact-content"  layout="row" layout-align="start center" layout-wrap>
                <md-input-container flex="100">
                    <!--<label>Response template</label>-->
                    <textarea ng-model="$ctrl.data.responseTemplate" flex="100"></textarea>
                </md-input-container>
            </div>

        </div>
    </div>
</div>
