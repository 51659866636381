<md-menu class="md-secondary" md-position-mode="target-right target" >
    <md-button class="md-icon-button" ng-click="$mdMenu.open()">
        <md-icon md-svg-icon="core:short-menu"></md-icon>
    </md-button>
    <md-menu-content width="3">
        <md-menu-item ng-if="$ctrl.permissions.can('application.manage')" ng-switch="$ctrl.data.Enabled || ( !$ctrl.data.Enabled && $ctrl.data.ContainersCount )" >
            <!--<md-button ng-if="$ctrl.data.Enabled || ( !$ctrl.data.Enabled && $ctrl.data.ContainersCount)" ng-click="$ctrl.action('disable')">-->
                <!--<md-icon md-svg-icon="core:unpublish"></md-icon> {{"COMMON_DISABLE"|translate}}-->
            <!--</md-button>-->
            <md-button ng-if="$ctrl.data.Enabled || ( !$ctrl.data.Enabled && $ctrl.data.ContainersCount)" ng-click="$ctrl.desableConfirm($evemt)">
                <md-icon md-svg-icon="core:unpublish"></md-icon> {{"COMMON_DISABLE"|translate}}
            </md-button>
            <md-button ng-if="!($ctrl.data.Enabled || ( !$ctrl.data.Enabled && $ctrl.data.ContainersCount))" ng-click="$ctrl.action('enable')">
                <md-icon md-svg-icon="core:publish"></md-icon> {{"COMMON_ENABLE"|translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="$ctrl.permissions.can('application.manage')" >
            <md-button ng-click="$ctrl.confirmMessage($event, $ctrl.$translate('MLAPP_RESET_DIALOG_MESSAGE', {projectName: $ctrl.data.Name}), 'MLAPP_RESET_DIALOG_TITLE', 'COMMON_RESET', 'COMMON_CANCEL', $ctrl.action, 'reset')">
                <md-icon md-svg-icon="core:reload"></md-icon> {{"COMMON_RESET"|translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('application.manage') && $ctrl.full">
            <md-button ng-click="$ctrl.actionForm()">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('application.manage') && $ctrl.full">
            <md-button ng-click="$ctrl.dialog('simply')">
                <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_SIMPLY_CONFIG"|translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('application.manage') && $ctrl.full">
            <md-button ng-click="$ctrl.dialog('pip')">
                <md-icon md-svg-icon="core:install"></md-icon> Package list
            </md-button>
        </md-menu-item>

        <md-menu-item ng-if="!$ctrl.envList.Error && $ctrl.permissions.can('application.manage') && $ctrl.full">
            <md-button ng-click="$ctrl.dialog('history')">
                <md-icon md-svg-icon="core:branch"></md-icon> History
            </md-button>
        </md-menu-item>

        <!--<md-menu-item ng-if="$ctrl.permissions.can('application.manage')">-->
            <!--<md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, $ctrl.$translate('MLAPP_DELETE_DIALOG_MESSAGE', {projectName: $ctrl.data.Name}), 'MLAPP_DELETE_DIALOG_TITLE')">-->
                <!--<md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}-->
            <!--</md-button>-->
        <!--</md-menu-item>-->
        <md-menu-item ng-if="$ctrl.permissions.can('application.manage')">
            <md-button aria-label="icon" ng-click="$ctrl.delete()">
                <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
            </md-button>
        </md-menu-item>

        <md-menu-divider ng-if="$ctrl.full"></md-menu-divider>
        <md-menu-item ng-if="$ctrl.full">
            <!--<md-button aria-label="icon" href="{{$ctrl.downloadURL()}}" target="_blank" ng-load="$ctrl.fileLoad()">-->
                <!--<md-icon md-svg-icon="core:chart-download"></md-icon> {{"COMMON_DOWNLOAD" | translate}}-->
            <!--</md-button>-->
            <md-button aria-label="icon" ng-click="$ctrl.download()" >
                <md-icon md-svg-icon="core:chart-download"></md-icon> {{"COMMON_DOWNLOAD" | translate}}
            </md-button>
        </md-menu-item>

        <md-menu-item >
            <md-button aria-label="icon" ng-click="$ctrl.dialog('serve')" >
                <!--<md-icon md-svg-icon="core:chart-download"></md-icon>-->
                <md-icon md-svg-icon="core:play"></md-icon>
                {{"Project serving" | translate}}
            </md-button>
        </md-menu-item>

    </md-menu-content>
</md-menu>
