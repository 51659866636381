<md-card class="chartCardSmall">
    <md-card-title>
        <md-card-title-media>
            <div class="md-media-sm card-media" layout style='background: url("{{item.Picture}}") center center no-repeat; background-size: contain;'>
                <!--<img src="{{item.Picture}}" alt="item.Name">-->
            </div>
        </md-card-title-media>
        <md-card-title-text>
            <a class="md-headline chartTitle" ui-sref="wsChartItem({wsId: item.WorkspaceName, chartName : item.Name})" >
              {{item.DisplayName}}
              <md-icon ng-if="!item.Published" md-svg-icon="core:lock" ng-class="md-avatar-icon" aria-hidden="true">
                <md-tooltip>{{'CHART_NOT_PUBLISHED'|translate}}</md-tooltip>
              </md-icon>
            </a>
            <span class="md-subhead" ng-if="$ctrl.isMlapp(item)">(is mlapp)</span>
            <span class="md-subhead chartVersion">latest version: {{item.Version}}</span>
            <span class="md-subhead" ng-if="!$ctrl.isWs">
                <a ui-sref="workspace({wsId : item.WorkspaceName})" class="chartWS">
                    <md-icon md-svg-icon="core:group" ng-class="md-avatar-icon" aria-hidden="true"></md-icon>{{item.WorkspaceDisplayName}}
                </a>
            </span>
            <p ng-if="item.Description"  class="chartDescription">{{item.Description}}</p>
        </md-card-title-text>

    </md-card-title>

    <md-card-actions layout="row" layout-align="space-between stretch">
        <cmp-chart-rating
                flex="50"
                layout="row" layout-align="space-between stretch"
                data="item"
                resource="$ctrl.resource"
                resource-params="{wsId: item.WorkspaceName, chartName: item.Name}">
        </cmp-chart-rating>

        <md-button flex="50" layout="column" layout-align="center center" ui-sref="wsChartItem({wsId: item.WorkspaceName, chartName : item.Name, tab: 'comments'})" >
                <span>
                <md-icon md-svg-icon="core:comments"></md-icon>
                    {{item.Comments}}
                </span>
            <md-tooltip>comments</md-tooltip>
        </md-button>
        <!--<md-button flex>-->
            <!--<md-icon md-svg-icon="core:chart-download"></md-icon>-->
            <!--2003-->
            <!--<md-tooltip>download</md-tooltip>-->
        <!--</md-button>-->
    </md-card-actions>
</md-card>
