<md-dialog>
    <form ng-cloak>
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Serve model</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <cmp-api-error-service ng-if="error" error="error"></cmp-api-error-service>
            <div ng-if="!error && res" class="md-padding">
                <h3> Serve success</h3>
                <div>
                    <div>Host: {{res.serve.host}}</div>
                </div>
            </div>
            <div class="md-dialog-content" ng-if="!error && !res">
                <div class="layout-row layout-align-start-start layout-wrap">
                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <label>CPU (<span>m</span>)</label>
                        <input type="text" ng-model="params.requests.CPU" />
                    </md-input-container>
                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <label>CPU Limit (<span>m</span>)</label>
                        <input type="text" ng-model="params.requests.CPULimit" />
                    </md-input-container>

                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <label>Memory (<span>Mi,Gi</span>)</label>
                        <input type="text" ng-model="params.requests.Memory" />
                    </md-input-container>
                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <label>Memory Limit (<span>Mi,Gi</span>)</label>
                        <input type="text" ng-model="params.requests.MemoryLimit" />
                    </md-input-container>
                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <md-checkbox ng-model="params.use_gpu" min="0">Use GPU</md-checkbox>
                    </md-input-container>
                    <md-input-container class="flex-50" style="padding-right: 5%">
                        <label>Replicas</label>
                        <input type="number" ng-model="params.replicas" min="0" />
                    </md-input-container>
                    <md-input-container class="flex">
                        <label>extra arguments</label>
                        <input type="text" ng-model="params.extra_args"/>
                    </md-input-container>
                    <!--<md-input-container class="flex-50" style="padding-right: 5%">-->
                        <!--<label>Worker pods</label>-->
                        <!--<input type="number" ng-model="params.WorkerPods" min="0" />-->
                    <!--</md-input-container>-->
                </div>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <!--<md-button href="http://en.wikipedia.org/wiki/Mango" target="_blank" md-autofocus>-->
                <!--More on Wikipedia-->
            <!--</md-button>-->
            <span flex></span>
            <md-button ng-click="cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button ng-click="serve(params)" ng-if="!error && !res">
                {{"MLAPPS_JOB_SERVE" | translate}}
            </md-button>
            <md-button ng-click="continue(params)" ng-if="!error && res">
                {{"COMMON_CONTINUE" | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
