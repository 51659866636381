<layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
    <layout-back></layout-back>
    <h3>{{$ctrl.workspace.DisplayName}} {{'STORAGE_PERSISTEN_STORAGE' | translate}}</h3>
    <div flex></div>
    <md-button ng-disabled="!($ctrl.canEdit && !$ctrl.hasNew())" ng-click="$ctrl.add()" class="md-raised md-primary" ><md-icon md-svg-icon="core:plus"></md-icon>{{'COMMON_ADD' | translate}}</md-button>
</layout-page-content-title>


<md-content class="md-padding" flex>

    <md-list class="md-3-line" ng-if="$ctrl.complite && !$ctrl.error && $ctrl.list && $ctrl.list.length">
        <md-list-item ng-repeat="item in $ctrl.list">
            <cmp-storage-persistent-item data="item" list-controller="$ctrl" flex></cmp-storage-persistent-item>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-list>
    <div layout="row" layout-align="start center">
        <div flex>
            <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            <div layout-padding ng-if="$ctrl.complite && !$ctrl.list.length" translate>STORAGE_PERSISTENT_EMPTY</div>
        </div>
        <!--<md-button ng-if="$ctrl.canEdit && !$ctrl.hasNew() " ng-click="$ctrl.add()" translate="COMMON_ADD"></md-button>-->
    </div>
</md-content>

