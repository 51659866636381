<div  style="min-height: 450px">
    <div ng-if="!$ctrl.asForm">
        <div layout="row" layout-align="start center" flex class="title" ng-if="$ctrl.data.Version">
            <div class=""  layout="row" layout-align="start center" flex >
            <!--<div class="cmp-clickable"  layout="row" layout-align="start center" flex ui-sref="wsCatalogDataItem($ctrl.listData)" >-->
                <div flex layout="row" layout-align="start center">
                    <h4 >Version: {{$ctrl.data.Version ? $ctrl.data.Version : 'undefined'}}</h4>
                    <md-button class="md-raised" ng-click="$ctrl.listController.createNew($ctrl.data)" ng-if="$ctrl.canManage" >new version</md-button>
                </div>
                <md-button ng-disabled="true" ng-if="$ctrl.data.Editing" class="md-warn">Editing</md-button>
                <div>{{$ctrl.data.Created | date: 'dd/MM/yyyy HH:mm'}}</div>
            </div>
            <a layout="row"
               ng-if="!$ctrl.data.Editing"
               href="{{$ctrl.data.DownloadURL}}"
               target="_blank"
               class="md-button md-raised"
               style="margin-left: 0.5em"
               download
            >
                <md-icon md-svg-icon="core:link"></md-icon>
                Download (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)
            </a>

            <md-button ng-click="$ctrl.commit()" class="md-raised" ng-if="$ctrl.data.Editing && $ctrl.canManage">commit</md-button>
            <div ng-include="'kuberlab/catalog/base/version-view/menu-item.html'"></div>
        </div>

        <div class="versionMessage" ng-if="$ctrl.data.Message" style="padding-bottom: 1em">{{$ctrl.data.Message}}</div>












        <div ng-if="$ctrl.data.Version">
            <div layout="row" layout-align="start" flex ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && !$ctrl.data.Editing"  class="contentVersion" md-whiteframe="1" style="height: 450px" >
                <div flex="33" layout="column" layout-align="start ">
                    <div layout="row" layout-align="start">
                        <h4 style="    display: block; margin: 0; margin-right: 16px; line-height: 36px;">tree</h4>
                        <md-input-container style="margin: 0;">
                            <input placeholder="filter" ng-model="treeFilter" ng-model-options='{ debounce: 1000 }'>
                        </md-input-container>
                    </div>
                    <md-content flex>
                        <cmp-catalog-version-tree tree-filter="treeFilter" ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" list-controller="$ctrl.listController"></cmp-catalog-version-tree>
                    </md-content>
                </div>
                <div style="width: 10px"></div>
                <cmp-catalog-version-viewer ctrl="$ctrl" file-url="$ctrl.fileUrl" file-info="$ctrl.fileInfo" flex layout="column" layout-align="start"></cmp-catalog-version-viewer>
            </div>

            <div class="contentVersion" ng-if="$ctrl.data.Version == $ctrl.listController.version.Version && $ctrl.data.Editing" >

                <p class="small">Use GUI only for creating the small datasets with few files. To upload real dataset with many files you can use <a href="https://github.com/kuberlab/pluk/releases" target="_blank">kdataset CLI utility</a></p>
                <div layout="row" layout-align="start" flex style="height: 450px" >
                    <div flex="50" layout="column" layout-align="start">
                        <div layout="row" layout-align="start center">
                            <h4 flex>tree dir</h4>
                            <md-button ng-click="$ctrl.addFolder()">add folder</md-button>
                        </div>
                        <md-content flex>
                            <cmp-catalog-version-tree ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" edit="true"></cmp-catalog-version-tree>
                        </md-content>
                        <div layout="row" layout-align="center" ng-if="$ctrl.canManage">
                            <md-button ng-click="$ctrl.commit()">commit</md-button>
                        </div>
                    </div>
                    <md-divider></md-divider>
                    <!--<div style="width: 10px"></div>-->
                    <div flex="50" layout="column" layout-align="start"  ng-if="$ctrl.data.Editing">
                        <div class="md-padding">
                            <form name="upload" ng-submit="$ctrl.uploadFile(upload)"  layout="row" layout-wrap layout-align="center center">
                                <md-input-container flex="100"><label>upload path</label><input name="path" ng-model="$ctrl.fileUrl"></md-input-container>
                                <input type="file" name="file" ng-model="$ctrl.file" multiple>
                                <md-button type="submit">upload</md-button>
                            </form>
                            <md-content flex>
                                <cmp-catalog-version-uploading-files  ng-repeat="f in $ctrl.uploadingFiles" data="f" ctrl="$ctrl" ></cmp-catalog-version-uploading-files>
                            </md-content>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div ng-if="$ctrl.asForm" flex class="editForm">
        <div layout="row" layout-align="start" flex >
            <div flex="50">
                <md-input-container>
                    <label>Version</label>
                    <input ng-model="$ctrl.data.Version" ng-disable="true">
                </md-input-container>
            </div>
            <div flex="50">
                <md-content flex>
                    <cmp-dataset-version-tree ng-if="$ctrl.data.Version" ctrl="$ctrl" api="$ctrl.treeApi" resource="$ctrl.listController.resource" edit="true"></cmp-dataset-version-tree>
                </md-content>
            </div>
        </div>
    </div>

    <form ng-if="$ctrl.data.isNew" ng-submit="$ctrl.saveNew(nameVersion)" name="nameVersion" class="setNewVersion" flex layout="column" layout-align="center center">
        <md-card layout="column" layout-align="canter" class="md-padding"  style="width: 400px">
            <h4 style="text-align:center">Create new version</h4>
            <p ng-if="$ctrl.data.From" style="text-align: center" >From version {{$ctrl.data.From}}</p>
            <md-input-container>
                <label>Set new version</label>
                <input ng-model="$ctrl.newVersion" />
            </md-input-container>
            <md-input-container>
                <label translate>COMMON_MESSAGE</label>
                <textarea ng-model="$ctrl.data.Message" style=" max-height: 200px;"></textarea>
            </md-input-container>
            <cmp-error-toast ng-if="$ctrl.errorVersion" error="$ctrl.errorVersion"></cmp-error-toast>
            <div layout="row" layout-align="center">
                <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button type="submit">create</md-button>
            </div>
        </md-card>
    </form>

</div>
