
<div ng-if="$ctrl.cu.Admin" layout="row" layout-align="end">
  <cmp-footer-version
          commit="{{$ctrl.revData.uiRevCommit}}"
          author="{{$ctrl.revData.uiRevAuthor}}"
          time-stamp="{{$ctrl.revData.uiRevTimeStamp}}">
  </cmp-footer-version>

</div>
<div layout="row" layout-align="start start" layout-wrap>
  <div flex="50" style="min-width: 300px">
    <cmp-workspace-main-card data="$ctrl.workspace" permissions="$ctrl.permissions"></cmp-workspace-main-card>
    <cmp-workspace-active-tasks ng-if="$ctrl.permissions.can('mlmodel.read')"></cmp-workspace-active-tasks>
    <cmp-workspace-last-projects limit="5" ng-if="$ctrl.permissions.can('mlmodel.read')"></cmp-workspace-last-projects>
    <cmp-workspace-last-servings limit="5" ng-if="$ctrl.permissions.can('serving.read')"></cmp-workspace-last-servings>
  </div>
  <div flex="50" style="min-width: 300px">
    <cmp-workspace-faq></cmp-workspace-faq>
    <cmp-workspace-cluster-stats ng-if="$ctrl.permissions.can('cluster.read')"></cmp-workspace-cluster-stats>
  </div>
</div>




<!--<md-card class="main-page">-->
  <!--<md-nav-bar class="md-margin"-->
              <!--md-no-ink-bar="disableInkBar"-->
              <!--md-selected-nav-item="$ctrl.currentNavItem"-->
              <!--nav-bar-aria-label="navigation">-->
    <!--<md-nav-item ng-repeat="tab in $ctrl.tabs" md-nav-click="$ctrl.show(tab.id)" name="{{tab.id}}"-->
                 <!--ng-if="$ctrl.isCan(tab.id)">-->
      <!--{{tab.title | translate}}-->
    <!--</md-nav-item>-->
  <!--</md-nav-bar>-->


  <!--<md-card-content>-->
    <!--<div ng-if="$ctrl.currentNavItem == 'application'">-->
      <!--&lt;!&ndash;<h3><a ui-sref="wsApplicationsList">{{"MLAPP_TITLE_PAGE_new" | translate}}</a></h3>&ndash;&gt;-->
      <!--&lt;!&ndash;<cmp-applications flex main-page="1"></cmp-applications>&ndash;&gt;-->
      <!--<cmp-applications flex></cmp-applications>-->
      <!--<h4 translate><a ui-sref="wsServingList" >SERVING</a></h4>-->
      <!--<cmp-serving-list flex ></cmp-serving-list>-->
    <!--</div>-->
    <!--<div ng-if="$ctrl.currentNavItem == 'project'">-->
      <!--<cmp-ws-resource-main-page view-infra="$ctrl.viewSettings.infra"></cmp-ws-resource-main-page>-->
    <!--</div>-->
    <!--<div ng-if="$ctrl.currentNavItem == 'chart'">-->
      <!--<div class="catalog-common">-->
        <!--<cmp-workspace-catalog item-state="'wsCatalog.list.item'"></cmp-workspace-catalog>-->
        <!--&lt;!&ndash;<cmp-catalog-list item-state="'wsCatalog.list'" is-catalog="false"></cmp-catalog-list>&ndash;&gt;-->
        <!--&lt;!&ndash;<cmp-catalog-tag-list-main state-name="'wsCatalog.list'"></cmp-catalog-tag-list-main>&ndash;&gt;-->
      <!--</div>-->

      <!--&lt;!&ndash;<cmp-charts-card-home></cmp-charts-card-home>&ndash;&gt;-->
    <!--</div>-->
    <!--&lt;!&ndash;<div ng-if="$ctrl.currentNavItem == 'mlmodel'">&ndash;&gt;-->
      <!--&lt;!&ndash;<cmp-mlmodel-card-home></cmp-mlmodel-card-home>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--&lt;!&ndash;<div ng-if="$ctrl.currentNavItem == 'dataset'">&ndash;&gt;-->
      <!--&lt;!&ndash;<cmp-catalog-data-card-home></cmp-catalog-data-card-home>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--</md-card-content>-->

<!--</md-card>-->
