<form name="newMlApp" ng-submit="$ctrl.save(newProject, true)" flex >
    <md-card class="innerContentBorder">
        <md-card-content layout="column">
            <div ng-if="$ctrl.newElement">
                <div ng-click="open = !open" class="mlappRepoImportBtn">Export mlapp config from git
                    <md-icon ng-if="!open" md-svg-icon="core:chevron-down"></md-icon>
                    <md-icon ng-if="open" md-svg-icon="core:chevron-up"></md-icon>
                </div>
                <md-card ng-if="open">
                    <md-card-content>
                        <cmp-repo-select repository-url="$ctrl.repo.RepositoryURL" repository-dir="$ctrl.repo.repositoryDir" ></cmp-repo-select>
                    </md-card-content>
                    <!--<cmp-repo url="$ctrl.repo.RepositoryURL" dir="$ctrl.repo.repositoryDir"></cmp-repo>-->
                    <md-card-action class="layout-row layout-align-start-center">
                        <div class="flex">
                            <md-progress-linear ng-if="$ctrl.scanning"></md-progress-linear>
                            <cmp-api-error-service ng-if="$ctrl.scanError" error="$ctrl.scanError"></cmp-api-error-service>
                        </div>
                        <md-button ng-click="$ctrl.scan($ctrl.repo)">Scan</md-button>
                    </md-card-action>
                </md-card>
            </div>
            <div flex layout="row">
                <md-input-container flex="50">
                    <label translate>MLAPP_DISPLAYNAME</label>
                    <input type="text"
                           name="DisplayName"
                           ng-model="$ctrl.data.DisplayName"
                           required
                           minlength="2"
                           ng-model-options='{ debounce: 1000 }'
                           ng-change="$ctrl.pickupName(newMlApp)"
                    >
                    <help-hint hint="mlapp.displayname"></help-hint>
                    <div ng-messages="newMlApp.DisplayName.$error">
                        <div ng-message="required">App's name should be filled</div>
                        <div ng-message="minlength">App's name is too short</div>
                    </div>
                </md-input-container>
                <md-input-container flex="50" >
                    <label translate>MLAPP_NAME</label>
                    <input ng-disabled="!$ctrl.newElement" type="text"
                           name="Name"
                           ng-model="$ctrl.data.Name"
                           ng-model-options='{ debounce: 1000 }'
                           ng-change="$ctrl.checkName(newMlApp)">
                    <help-hint hint="mlapp.name"></help-hint>
                    <div ng-messages="newMlApp.Name.$error">
                        <div ng-message="errorname">{{$ctrl.nameErrorMessage}}</div>
                    </div>
                </md-input-container>
            </div>
            <md-input-container >
                <label>{{"COMMON_DESCRIPTION"|translate}}</label>
                <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
            </md-input-container>
            <div class="flex layout-row">
                <md-input-container class="flex-50">
                    <label translate><label translate>MLAPP_TIMEOUT_MINUTES</label></label>
                    <input type="number" ng-model="$ctrl.data.MLConfiguration.KuberlabTFConfiguration.TimeoutMinutes" />
                    <help-hint hint="mlapp.timeout_minutes"></help-hint>
                </md-input-container>

                <md-input-container class="flex-50">
                    <md-checkbox ng-model="$ctrl.data.MLConfiguration.KuberlabTFConfiguration.Jupyter.UseGPU">Jupyter use GPU</md-checkbox>
                    <help-hint hint="mlapp.jupyter_gpu"></help-hint>
                </md-input-container>
            </div>

            <md-divider class="flex"></md-divider>
            <h4>Link project</h4>
            <div ng-if="$ctrl.newElement" flex layout="row" style="width: 100%;">
                <md-input-container flex>
                    <label translate>MLAPP_PROJECT</label>
                    <md-select ng-model="$ctrl.data.ProjectName" ng-change="$ctrl.changeProject()" required>
                        <md-option ng-value="pr.Name" ng-repeat="pr in $ctrl.projectsList">
                            {{ pr.DisplayName }}
                        </md-option>
                    </md-select>
                    <help-hint hint="mlapp.project"></help-hint>
                </md-input-container>
                <!--<md-input-container flex="33">-->
                    <!--<label translate>MLAPP_ENVIRONMENT</label>-->
                    <!--<md-select ng-model="$ctrl.data.Environment" ng-change="$ctrl.changeEnv()" required ng-disabled="!$ctrl.data.ProjectName">-->
                        <!--<md-option ng-value="item.Name" ng-repeat="item in $ctrl.envList">-->
                            <!--{{ item.Name }}-->
                        <!--</md-option>-->
                    <!--</md-select>-->
                    <!--<help-hint hint="mlapp.environment"></help-hint>-->
                <!--</md-input-container>-->
                <md-input-container flex>
                    <label translate>MLAPP_CLUSTER</label>
                    <md-select ng-model="$ctrl.data.ClusterName" required ng-disabled="!$ctrl.data.ProjectName">
                        <md-option ng-value="item.Name" ng-repeat="item in $ctrl.clusterList">
                            {{ item.Name }}
                        </md-option>
                    </md-select>
                    <help-hint hint="mlapp.cluster"></help-hint>
                </md-input-container>
            </div>
            <div ng-if="!$ctrl.newElement" flex layout="row" style="width: 100%;">
                <md-input-container flex>
                    <label>{{"MLAPP_PROJECT"| translate}}</label>
                    <input ng-model="$ctrl.data.ProjectDisplayName" type="text" ng-disabled="true">
                    <help-hint hint="mlapps.projectid"></help-hint>
                </md-input-container>
                <!--<md-input-container flex="33">-->
                    <!--<label>{{"MLAPP_ENVIRONMENT"| translate}}</label>-->
                    <!--<input ng-model="$ctrl.data.Environment" type="text" ng-disabled="true">-->
                    <!--<help-hint hint="mlapps.environment"></help-hint>-->
                <!--</md-input-container>-->
                <md-input-container flex>
                    <label>{{"MLAPP_CLUSTER"| translate}}</label>
                    <input ng-model="$ctrl.data.ClusterName" type="text" ng-disabled="true">
                    <help-hint hint="mlapps.cluster"></help-hint>
                </md-input-container>
            </div>
            <md-divider></md-divider>
            <h4>Volumes</h4>
                <div ng-repeat="item in $ctrl.getVolums({isTrainLogDir : true})">
                    <label>Output</label>
                    <cmp-mlapps-volumes-item
                            data="item"
                            mlapp="$ctrl.data"
                            is-new-element="true"
                            is-train-log-dir="true"
                            types="$ctrl.outputType"
                    ></cmp-mlapps-volumes-item>
                </div>

                <md-list ng-if="$ctrl.getVolums({isTrainLogDir : false}).length" >
                    <label>Other volumes</label>
                    <div ng-repeat="item in $ctrl.getVolums({isTrainLogDir : false})">
                        <cmp-mlapps-volumes-item
                                data="item"
                                mlapp="$ctrl.mlapp"
                        ></cmp-mlapps-volumes-item>
                        <md-divider ng-if="!$last"></md-divider>
                    </div>
                </md-list>

        </md-card-content>

        <md-card-actions layout="row" layout-align="end center">
            <div flex>
                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                <cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-api-error-service>
                <!--<div ng-if="$ctrl.saveErrorMessage" class="errorInfo">{{$ctrl.saveErrorMessage.Error}}</div>-->
            </div>

            <div>
                <md-button ng-click="$ctrl.cancel()" ng-disabled="$ctrl.saving"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
                <md-button type="submit" class="md-primary" ng-disabled="$ctrl.saving "><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
            </div>

        </md-card-actions>
    </md-card>
</form>
