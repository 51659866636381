<script src="user_card_controller.js"></script>
<md-card ng-if="!$ctrl.asForm" class="user-">
    <md-card-header ng-click="$ctrl.showMy()">
        <md-card-avatar>
            <img ng-src="{{$ctrl.data.Picture}}" class="md-user-avatar"/>
        </md-card-avatar>

        <md-card-header-text>
            <span class="md-headline">{{$ctrl.data.DisplayName}} </span>
            <span class="md-subhead"><span ng-if="$ctrl.data.Email">Email: {{$ctrl.data.Email}}, </span>{{$ctrl.data.Login}}</span>
        </md-card-header-text>
        <md-button  class="showByParentHover md-icon-button" ng-click="$ctrl.editAction()"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
    </md-card-header>
    <md-card-content class="user-card-content" ng-if="$ctrl.stateParams.cu">
        <div ng-cloak flex>
            <md-tabs md-dynamic-height md-border-bottom md-selected="selectedIndex" >
                <md-tab md-on-select="user_tab = 'serv'">
                    <md-tab-label>{{'SETITNGS_COMMON_TITLE_SERVICES'|translate}}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content><cmp-settings-connection-list id="settings_userServices"></cmp-settings-connection-list></md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab md-on-select="user_tab = 'serv_acc'">
                    <md-tab-label>{{'SETITNGS_COMMON_TITLE_SERVICE_ACCOUNTS'|translate}}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content><cmp-wsa-card-list ws-id="$ctrl.data.Workspace" id="settings_userWsServices"></cmp-wsa-card-list></md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab md-on-select="user_tab = 'billing'" ng-if="$ctrl.GlobalSettings.billing_enabled" >
                    <md-tab-label>{{'SETTITNGS_COMMON_TITLE_BILLING'|translate}}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content>
                            <cmp-billing-ws
                                    ws-id="$ctrl.data.Workspace"
                                    ng-if="user_tab == 'billing'"
                            ></cmp-billing-ws>
                          <cmp-invoice-ws ws-id="$ctrl.data.Workspace"></cmp-invoice-ws>
                        </md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab md-on-select="user_tab = 'token'">
                    <md-tab-label>{{'SETTINGS_TOKEN_TITLE' | translate }}</md-tab-label>
                    <md-tab-body class="md-padding">
                        <md-content>
                            <cmp-user-token ng-if="user_tab == 'token'"></cmp-user-token>
                        </md-content>
                    </md-tab-body>
                </md-tab>
                <md-tab md-on-select="user_tab = 'delete'">
                  <md-tab-label>{{'SETTINGS_DELETE_USER'|translate}}</md-tab-label>
                  <md-tab-body class="md-padding">
                    <md-content layout="column">
                      <md-button class="md-warn" ng-click="$ctrl.deleteAccount()" layout-align="center center">
                        Delete Account
                      </md-button>
                    </md-content>
                  </md-tab-body>
                </md-tab>

            </md-tabs>
        </div>
    </md-card-content>
    <md-content ui-view=""></md-content>
</md-card>


<md-card ng-if="$ctrl.asForm && !$ctrl.setPassword" md-theme="default" >
    <md-card-title>
        <!--<md-card-title-text>-->

            <!--<span class="md-headline">-->
                <!--<md-input-container class="flex">-->
                    <!--<input name="DisplayName" required ng-model="$ctrl.data.DisplayName"  focus-me="shouldBeOpen">-->
                    <!--<div ng-messages="owner_item.DisplayName.$error.required"><div ng-message="required">This is required!</div></div>-->
                <!--</md-input-container>-->
            <!--</span>-->
            <!--<span class="md-subhead flex layout-row layout-align-start-center">-->
                <!--<md-input-container class="flex">-->
                    <!--<label>Identification</label>-->
                    <!--<input ng-model="$ctrl.data.Login" ng-disabled="!$ctrl.loginEditing">-->
                <!--</md-input-container>-->
                <!--<md-icon md-svg-icon="core:edit" ng-if="!$ctrl.loginEditing" ng-click="$ctrl.editLogin()"></md-icon>-->
                <!--<md-icon md-svg-icon="core:check" ng-if="$ctrl.loginEditing" ng-click="$ctrl.saveLogin()"></md-icon>-->
                <!--<md-icon md-svg-icon="core:close" ng-if="$ctrl.loginEditing" ng-click="$ctrl.cancelEditLogin()"></md-icon>-->
            <!--</span>-->
            <!--<span class="md-subhead">-->
                <!--<md-input-container class="flex">-->
                    <!--<label>Organization</label>-->
                    <!--<input ng-model="$ctrl.data.User.Organization">-->
                <!--</md-input-container>-->
            <!--</span>-->
            <!--<span class="md-subhead">-->
                <!--<md-input-container class="flex">-->
                    <!--<label>Phone</label>-->
                    <!--<input ng-model="$ctrl.data.User.Phone">-->
                <!--</md-input-container>-->
            <!--</span>-->

        <!--</md-card-title-text>-->
        <!--<md-card-title-media>-->
            <!--<div class="md-media-lg card-media"></div>-->
        <!--</md-card-title-media>-->

    </md-card-title>


    <form name="cuForm" ng-submit="$ctrl.save(cuForm)">
        <md-card-content layout="column">
            <div flex layout="row">
                <md-input-container flex="50">
                    <label>{{"COMMON_NAME"|translate}}</label>
                    <input name="DisplayName" required ng-model="$ctrl.updData.DisplayName" required minlength="2" focus-me="shouldBeOpen">
                    <help-hint hint="user.displayname"></help-hint>
                    <div ng-messages="cuForm.DisplayName.$error">
                        <div ng-message="required">Name should be filled</div>
                        <div ng-message="minlength">Name is too short</div>
                    </div>
                </md-input-container>
                <div flex="50" class="layout-row layout-align-start-center">
                    <md-input-container flex>
                        <label>{{"COMMON_IDENTIFICATION"|translate}}</label>
                        <input ng-model="$ctrl.updData.Login" ng-disabled="!$ctrl.loginEditing">
                        <help-hint hint="user.login"></help-hint>
                        <div ng-messages="cuForm.Name.$error">
                            <div ng-message="errorname">{{$ctrl.nameErrorMessage}}</div>
                        </div>
                    </md-input-container>
                    <md-button class="md-icon-button" ng-hide="$ctrl.loginEditing" ng-click="$ctrl.editLogin()"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
                    <md-button class="md-icon-button" ng-hide="!$ctrl.loginEditing" ng-click="$ctrl.saveLogin()"><md-icon md-svg-icon="core:check"></md-icon></md-button>
                    <md-button class="md-icon-button" ng-hide="!$ctrl.loginEditing" ng-click="$ctrl.cancelEditLogin()"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                </div>
            </div>
            <div flex layout="row">
                <md-input-container flex="50">
                    <label>{{"COMMON_ORGANIZATION"|translate}}</label>
                    <input type="text" name="Organization" ng-model="$ctrl.updData.Organization">
                    <help-hint hint="user.organization"></help-hint>
                </md-input-container>
                <md-input-container flex="50">
                    <label>{{"COMMON_PHONE"|translate}}</label>
                    <input type="text" name="Phone" ng-model="$ctrl.updData.Phone">
                    <help-hint hint="user.phone"></help-hint>
                </md-input-container>
            </div>
            <div flex layout="row" layout-align="start center">
                <div flex="50" layout="column">
                    <div ng-if="$ctrl.editEmail && !$ctrl.editEmailComplite" flex layout-align="start center" >
                        <md-input-container flex>
                            <label>Email</label>
                            <input type="email" ng-model="$ctrl.dataEmail.Email" name="newEmail" required/>
                            <div ng-messages="cuForm.newEmail.$error">
                                <div ng-message="required" class="message slide-left">You did not enter a field</div>
                            </div>
                        </md-input-container>
                        <md-button ng-click="$ctrl.sendEmail(cuForm)" class="md-icon-button"><md-icon md-svg-icon="core:check"></md-icon></md-button>
                        <md-button ng-click="$ctrl.editEmail = false" class="md-icon-button"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                    </div>
                    <cmp-error-toast ng-if="$ctrl.editEmailError" error="$ctrl.editEmailError"></cmp-error-toast>
                    <div ng-if="$ctrl.editEmail && $ctrl.editEmailComplite">
                        <md-input-container> Please confirm your new email!</md-input-container>
                    </div>
                    <div ng-if="!$ctrl.editEmail" flex layout="row" layout-align="start center">
                        <md-input-container flex>
                            <label>Email</label>
                            <input type="text" ng-disabled="true" ng-model="$ctrl.email">
                        </md-input-container>
                        <md-button ng-click="$ctrl.editEmail = true" class="md-icon-button"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
                    </div>
                </div>
                <div flex="50">
                    <md-input-container><a href="" ng-click="$ctrl.setPassword = true">
                        {{ $ctrl.data.HasPassword ? "USER_PASS_CHANGE" : "USER_PASS_SET" | translate }}
                        </a>
                        <!--<span ng-if="!$ctrl.data.HasPassword">Set password</span>-->
                        <!--<span ng-if="$ctrl.data.HasPassword">Change password</span></a>-->
                    </md-input-container>
                </div>
            </div>
            <div layout="row" layout-align="center center">
                <img ng-src="{{$ctrl.data.Picture}}?25" class="md-avatar" ng-if="$ctrl.data.Picture" style="height: 40px"/>
                <div style="width:2em"></div>
                <div class="flex">
                    <lf-ng-md-file-input lf-files="$ctrl.file" flex lf-filesize="1MB" name="picture" lf-api="$ctrl.lfApi"></lf-ng-md-file-input>
                    <div ng-messages="cuForm.picture.$error" >
                        <div ng-message="filesize" translate>FORM_ERROR_FILESIZE</div>
                    </div>
                </div>
                <md-button ng-click="$ctrl.uploadFile(cuForm)" ng-disabled="!$ctrl.file.length">upload</md-button>
            </div>
        </md-card-content>


        <div flex>
            <md-progress-linear ng-if="$ctrl.isLoading"></md-progress-linear>
            <cmp-api-error-service ng-if="$ctrl.uploadError" error="$ctrl.uploadError" ></cmp-api-error-service>
        </div>
        <md-card-actions layout="row" layout-align="end center" class="layout-align-end-center layout-row">
            <md-button type="submit"><md-icon md-svg-icon="core:check"></md-icon> {{"COMMON_SAVE"|translate}}</md-button>
            <md-button type="button" ng-click="$ctrl.viewAction()"><md-icon md-svg-icon="core:close"></md-icon> {{"COMMON_CANCEL"|translate}}</md-button>
        </md-card-actions>
    </form>
</md-card>
<cmp-user-password ng-if="$ctrl.setPassword" set-password="$ctrl.setPassword"></cmp-user-password>
