<layout-page-content-list class="menuLevel layoutMenu" >
    <h3><a ui-sref="setting"><md-icon md-svg-icon="core:settings"></md-icon><span class="label">Settings</span></a></h3>
    <md-divider></md-divider>
    <div class="blockMenu">
        <a ui-sref="setting({wsId: 'my'})" ui-sref-active="current" class="blockMenuItem" >
            <img ng-src="{{$ctrl.CU.Picture}}" class="icon" alt="" ng-if="$ctrl.CU.Picture"/>
            <md-icon md-svg-icon="core:user"  ng-if="!$ctrl.CU.Picture"></md-icon>
            <span class="label">{{$ctrl.CU.DisplayName}}</span>
        </a>

        <a ui-sref="setting({ wsId : org.Workspace })" ui-sref-active="current" ng-repeat="org in $ctrl.orgList | orderBy:'DisplayName' " class="blockMenuItem">
            <!--<img ng-src="{{org.Picture}}" class="icon" alt="" ng-if="org.Picture"/>-->
            <div org-icon data="org" ng-if="org.Picture"></div>
            <md-icon md-svg-icon="core:org" alt="" ng-if="!org.Picture"></md-icon>
            <span class="label">{{org.DisplayName}}</span>
            <md-icon md-svg-icon="core:alert" class="md-warn" alt="Billing issue" ng-if="org.billingFailed" title="Billing issue"></md-icon>
        </a>

        <a ui-sref="setting({wsId: 'new'})" ui-sref-active="current" class="blockMenuItem" >
            <md-icon md-svg-icon="core:plus" class="md-avatar" ></md-icon>
            <span class="label">New organization</span>
        </a>

    </div>

</layout-page-content-list>

<layout-page-content-item flex layout="column" layout-align="start" ui-view=""></layout-page-content-item>


<!--<div class="contentPageTitle">-->
    <!--<div class="main_width_greed inner">-->
        <!--<div class="title flex layout-row layout-align-start-center">-->
            <!--<span class="item">{{"SETITNGS_COMMON_TITLE_PAGA"|translate}}</span>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->

<!--<div class="main_width_greed">-->
    <!--<div class="state_settings flex layout-row layout-align-space-between-stretch">-->
        <!--<md-content class="state_settings_menu">-->
            <!--<md-list class="leftMenu">-->
                <!--<md-list-item>-->
                    <!--<a ui-sref="setting({wsId: 'my'})" ui-sref-active="active" class="flex layout-row layout-align-start-center" >-->
                        <!--<img ng-src="{{$ctrl.CU.Picture}}" class="md-avatar" alt="" ng-if="$ctrl.CU.Picture"/>-->
                        <!--<md-icon md-svg-icon="core:user" class="md-avatar" alt="" ng-if="!$ctrl.CU.Picture"></md-icon>-->
                        <!--<span class="flex">{{$ctrl.CU.DisplayName}}</span>-->
                    <!--</a>-->
                <!--</md-list-item>-->
                <!--<md-list-item ng-repeat="org in $ctrl.orgList | orderBy:'DisplayName' ">-->
                    <!--<a ui-sref="setting({wsId : org.ID})" ui-sref-active="active" class="flex layout-row layout-align-start-center">-->
                        <!--<img ng-src="{{org.Picture}}" class="md-avatar" alt="" ng-if="org.Picture"/>-->
                        <!--<md-icon md-svg-icon="core:org" class="md-avatar" alt="" ng-if="!org.Picture"></md-icon>-->
                        <!--<span class="flex">{{org.DisplayName}}</span>-->
                        <!--<md-icon md-svg-icon="core:alert" class="md-warn" alt="Billing issue" ng-if="org.billingFailed" title="Billing issue"></md-icon>-->
                    <!--</a>-->
                <!--</md-list-item>-->
                <!--<md-list-item>-->
                    <!--<a ui-sref="setting({wsId: 'new'})" ui-sref-active="active" class="flex layout-row layout-align-start-center" >-->
                        <!--<md-icon md-svg-icon="core:plus" class="md-avatar" ></md-icon>-->
                        <!--<span class="flex">New organization</span>-->
                    <!--</a>-->
                <!--</md-list-item>-->
                <!--&lt;!&ndash;<cmp-organizations-menu-list></cmp-organizations-menu-list>&ndash;&gt;-->
            <!--</md-list>-->
        <!--</md-content>-->
        <!--<md-content flex ui-view="" style="background-color: transparent!important;"></md-content>-->
    <!--</div>-->
<!--</div>-->
