<md-card class="catalogCardSmall">
    <div class="catalogCardSmall-content">
        <a ui-sref="{{$ctrl.link}}" class="media" style='background: url("{{$ctrl.pic}}") center center no-repeat; background-size: contain;'></a>
        <!--<div class="media" style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'></div>-->
        <div class="content" flex>
            <a class="contentTitle" ui-sref="{{$ctrl.link}}" >
            <!--<a class="contentTitle" ui-sref="{{$ctrl.itemState}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name})" >-->
                <h5>
                    <md-icon ng-if="!$ctrl.data.Published" md-svg-icon="core:lock" ng-class="md-avatar-icon" aria-hidden="true">
                        <md-tooltip>{{'CHART_NOT_PUBLISHED'|translate}}</md-tooltip>
                    </md-icon>{{$ctrl.data.DisplayName}}
                </h5>

            </a>
            <div class="md-subhead" ng-if="$ctrl.multy">
                <a ui-sref="catalog.list({Interface: $ctrl.data.Interface})" >
                    <md-icon md-svg-icon="core:{{$ctrl.data.Interface}}"  ></md-icon>{{$ctrl.data.Interface | translate}}
                    <!--<div flex></div>-->
                </a>
            </div>
           <div class="md-subhead" ng-if="!$ctrl.isWs">
                <a ui-sref="wsCatalog.list({wsId : $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface })" class="chartWS">
                    <md-icon md-svg-icon="core:group" ng-class="md-avatar-icon" aria-hidden="true"></md-icon>{{$ctrl.data.WorkspaceDisplayName}}
                </a>
            </div>
            <p ng-if="$ctrl.data.Description"  class="chartDescription">{{$ctrl.data.Description}}</p>
        </div>
        <div class="action" layout="row" layout-align="space-between">
            <cmp-catalog-rating
                    layout="row" layout-align="space-between stretch"
                    data="$ctrl.data"
                    resource="$ctrl.resource"
                    resource-params="{wsId: $ctrl.data.WorkspaceName, Name: $ctrl.data.Name, Interface:$ctrl.data.Interface}">
            </cmp-catalog-rating>

            <!--<md-button layout="column" layout-align="center center" ui-sref="{{$ctrl.itemState + '.tab'}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name, tab: 'comments'})" >-->
            <md-button layout="column" layout-align="center center" ui-sref="{{$ctrl.itemState + '.tab'}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name, tab: 'comments'})" >
                    <span><md-icon md-svg-icon="core:comments"></md-icon>{{$ctrl.data.Comments}}</span>
                <md-tooltip>comments</md-tooltip>
            </md-button>
        </div>
    </div>
</md-card>
