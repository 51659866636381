<md-list-item class="paddingListItemTabContent md-3-line"
              ui-sref="wsProject({projectName: $ctrl.data.Name})">
  <md-icon md-svg-icon="core:project" class="md-avatar-icon"></md-icon>
  <div class="md-list-item-text flex">
    <h4>{{$ctrl.data.DisplayName}}</h4>
    <p class="description" ng-if="$ctrl.data.Description">{{ $ctrl.data.Description }}</p>
  </div>
  <md-button href="{{$ctrl.data.RepositoryURL}}" target="_blank" class="link md-secondary">
    <md-icon md-svg-icon="core:link"></md-icon>
    {{$ctrl.data.RepositoryURL}}
  </md-button>

  <div layout="row" layout-align="end center">
    <md-menu-bar class="padding-zero layout-row layout-align-end-center"
                 ng-if="$ctrl.permissions.can('project.manage')">
        <div class="md-secondary" ng-include="'kuberlab/projects/card/project_card_item_menu.html'"></div>
    </md-menu-bar>
  </div>
</md-list-item>
