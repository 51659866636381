<div layout="row" layout-align="start" class="md-padding blockImages" ng-show="!$ctrl.webrtcStatus">
    <div flex class="requestImg">
        <div class="onImage" ng-if="!$ctrl.config[$ctrl.paramImageName]"></div>
        <div class="hasImage" ng-if="$ctrl.config[$ctrl.paramImageName]" style='background: url("{{$ctrl.config[$ctrl.paramImageName]}}") center center no-repeat;" '></div>
    </div>
    <div flex class="responceImg layout-column layout-align-center-center" >
        <md-button class="md-raised md-primary" style="width: 200px" ng-if='$ctrl.canUploda && !this.sending' ng-click="$ctrl.send()" ng-disabled=" $ctrl.autoRequest && !$ctrl.config[$ctrl.paramImageName]">Apply</md-button>
        <cmp-error-toast ng-if="$ctrl.savingError" error="$ctrl.savingError"></cmp-error-toast>
        <!--<div class="hasImage" ng-if="$ctrl.response.image" style='background: url("data:image/{{$ctrl.response.encoding ? $ctrl.response.encoding : $ctrl.default_encode }};base64,{{$ctrl.response.image}}") center center no-repeat;" ' ng-click="$ctrl.parent.showDialog($ctrl.response.image, 'image')"></div>-->
        <img class="hasImage bg-transparent" ng-if="$ctrl.response.image"
             src='data:image/{{$ctrl.response.encoding ? $ctrl.response.encoding : $ctrl.default_encode }};base64,{{$ctrl.response.image}}'
             style='max-height: 100%; max-width: 100%; width: auto; height: auto;' ng-click="$ctrl.parent.showDialog($ctrl.response.image, 'image')"></img>
        <img src="/img/load.gif" ng-if="$ctrl.sending"  />

        <md-button  ng-click="$ctrl.send()" ng-if="$ctrl.response.image" class="update-button md-icon-button"><md-icon md-svg-icon="core:reload"></md-icon></md-button>
    </div>
</div>
<div ng-if="$ctrl.hasWebrtc && $ctrl.workWebrtc" >
    <cmp-webrtc-stream auto-start="true" flex link="$ctrl.linkStream" params="$ctrl.config" on-change="$ctrl.webrtcEvent($event)"> </cmp-webrtc-stream>
</div>

<div>
    <div ng-if="$ctrl.response.captionBuf" ng-repeat="item in $ctrl.response.captionBuf" class="responceCaption">{{item}}</div>
    <cmp-form-constructor options="$ctrl.params" ng-model="$ctrl.config" api="api" class="md-padding" action="$ctrl.startWebrtc($event)"></cmp-form-constructor>
    <please-wait ng-if="$ctrl.hasWebrtc && $ctrl.workWebrtc" norogress="true">Video stream.</please-wait>
</div>

<div ng-if="$ctrl.response.imageBufData" class="flex md-padding imageBufData">
    <div layout="row" layout-align="start" class="title">
        <h4><a href="" ng-click="$ctrl.dsHide = !$ctrl.dsHide"><md-icon md-svg-src="core:{{$ctrl.dsHide ? 'menu-down' : 'menu-right'}}" ></md-icon> Description result</a></h4>
    </div>
    <md-content ng-if="$ctrl.dsHide" class="md-padding">
        <cmp-serving-parameters-table data="$ctrl.response.imageBufData" meta="$ctrl.response.metaData" parent="$ctrl.parent"></cmp-serving-parameters-table>
    </md-content>
</div>

<!--{{$ctrl.config}}-->
