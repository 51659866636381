<div flex layout="row" layout-align="start center">
    <div flex></div>
    <cmp-template-select type="'serving'" ng-model="$ctrl.data" is-model="$ctrl.isModel" model-spec="$ctrl.modelSpec"></cmp-template-select>
</div>

<div class="layout-row layout-align-start-start layout-wrap">
    <md-input-container flex="100">
        <label translate>MLAPP_CMD</label>
        <textarea ng-model="$ctrl.data.command" ></textarea>
    </md-input-container>
    <md-input-container flex>
        <label translate>MLAPP_WORK_DIR</label>
        <input type="text" ng-model="$ctrl.data.workDir">
    </md-input-container>
    <md-input-container flex >
        <label>Node allocator</label>
        <md-select ng-model="$ctrl.data.nodes" ng-disabled="!$ctrl.allocatednodes.length">
            <md-option ng-value="undefined">default</md-option>
            <md-option ng-value="item" ng-repeat="item in $ctrl.allocatednodes">{{item}}</md-option>
        </md-select>
    </md-input-container>
</div>

<div class="task-resource-env cmp-block-compact" ng-if="$ctrl.isModel">
    <h5 class="cmp-block-compact-title" >{{"MLAPP_RESOURCES" | translate}}</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <md-input-container class="flex-50" >
            <label>CPU (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.requests.cpu" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>CPU Limit (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.limits.cpu" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>Memory (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.requests.memory" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Memory Limit (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.limits.memory" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>GPU</label>
            <input type="number" ng-model="$ctrl.data.accelerators.gpu" min="0"/>
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Replicas</label>
            <input type="number" ng-model="$ctrl.data.replicas" min="0" />
        </md-input-container>
    </div>
</div>

<div class="task-resource-env cmp-block-compact" ng-if="!$ctrl.isModel">
    <h5 class="cmp-block-compact-title" >{{"MLAPP_RESOURCES" | translate}}</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <md-input-container class="flex-50" >
            <label>CPU (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.requests.cpu" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>CPU Limit (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.limits.cpu" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>Memory (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.requests.memory" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Memory Limit (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.limits.memory" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>GPU</label>
            <input type="number" ng-model="$ctrl.data.resources.accelerators.gpu" min="0"/>
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Replicas</label>
            <input type="number" ng-model="$ctrl.data.replicas" min="0" />
        </md-input-container>
    </div>
</div>

<div class="task-resource-env cmp-block-compact" ng-if="!$ctrl.isModel">
    <h5 class="cmp-block-compact-title" >{{"MLAPP_AUTOSCALE" | translate}}</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">

        <md-input-container class="flex-50" >
            <md-checkbox ng-model="$ctrl.data.autoscale.enabled">enabled</md-checkbox>
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>target average utilization</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.data.autoscale.target_average_utilization" ng-required="$ctrl.data.autoscale.enabled" ng-disabled="!$ctrl.data.autoscale.enabled">
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>min replicas</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.data.autoscale.min_replicas" ng-required="$ctrl.data.autoscale.enabled" ng-disabled="!$ctrl.data.autoscale.enabled">
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>max replicas</label>
            <input type="number" step="1" min="0" ng-model="$ctrl.data.autoscale.max_replicas" ng-required="$ctrl.data.autoscale.enabled" ng-disabled="!$ctrl.data.autoscale.enabled">
        </md-input-container>

    </div>
</div>

<div class="task-images cmp-block-compact" ng-if="$ctrl.data.images ">
    <h5 class="cmp-block-compact-title">Images</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <div  class="flex layout-row layout-align-start">
            <cmp-select ng-model="$ctrl.data.images.cpu" options="$ctrl.imgList.cpu"  ng-required="true" md-floating-label="cpu" flex label="'cpu'"></cmp-select>
            <div class="help-block error" ng-show="$ctrl.form.mage_cpu.$error.required">{{"FORM_ERROR_REQUIRED" | translate}}</div>
        </div>
        <div  class="flex layout-row layout-align-start">
            <cmp-select ng-model="$ctrl.data.images.gpu" options="$ctrl.imgList.gpu" md-floating-label="gpu" flex label="'gpu'"></cmp-select>
            <div class="help-block error" ng-show="$ctrl.form.mage_gpu.$error.required">{{"FORM_ERROR_REQUIRED" | translate}}</div>
        </div>
    </div>
</div>

<div class="cmp-block-compact">
    <h5 class="cmp-block-compact-title" translate>MLAPP_ENV_ARGS</h5>
    <div class="cmp-block-compact-content">
        <cmp-autolist
                data="$ctrl.data.env"
                class="flex"
                labels="['name', 'value']"
                pattern="{name: {
                            pattern: '^[A-Z][_A-Z0-9]{0,61}[A-Z0-9]$',
                            message: 'Valid name must be 63 characters or less and must begin with an upper case alpha [A-Z] and end with an upper case alphanumeric character ([A-Z0-9]) with underline (_) and upper case alphanumerics between'}}"
                form="$ctrl.form"
        ></cmp-autolist>
    </div>
</div>

<div class="cmp-block-compact">
    <h5 class="cmp-block-compact-title" translate>COMMON_PORTS</h5>
    <div class="cmp-block-compact-content">
        <cmp-autolist
                data="$ctrl.data.ports"
                class="flex"
                labels="['name', 'protocol', 'port', 'targetPort']"
                attr="{
                    type: {
                        port: 'number',
                        targetPort: 'number'
                    },
                    label: {
                        targetPort: 'target port'
                    }
                }"
                form="$ctrl.form"
        ></cmp-autolist>
    </div>
</div>

<div class="task-images cmp-block-compact" ng-if="$ctrl.data.taskName" >
    <h5 class="cmp-block-compact-title">Mount config</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap" flex>
        <div class="layout-row layout-align-start-start layout-wrap" flex>
            <md-input-container flex>
                <label translate>Default mount path</label>
                <input type="text" ng-model="$ctrl.data.default_mount_path">
            </md-input-container>
            <md-input-container flex>
                <md-checkbox ng-model="$ctrl.data.default_volume_mapping">Default volume mapping</md-checkbox>
            </md-input-container>
        </div>
    </div>
</div>


<div class="cmp-block-compact" ng-if="!$ctrl.data.taskName || ($ctrl.data.taskName && !$ctrl.data.default_volume_mapping) ">
    <h5 class="cmp-block-compact-title" translate>Volumes</h5>
    <div class="cmp-block-compact-content" flex>
        <cmp-serving-volume-list ng-if="!$ctrl.data.taskName" list="$ctrl.data.sources" data="$ctrl.data" serving="$ctrl.serving" workspace-name="$ctrl.workspaceName"></cmp-serving-volume-list>
        <cmp-applications-task-volumes
                flex
                ng-if="$ctrl.data.taskName"
                volumes="$ctrl.data.volumes"
                application="$ctrl.application"
                link-app="{application: $ctrl.serving.ApplicationName, task: $ctrl.serving.Task, build: $ctrl.serving.Build}"
                workspace-name="$ctrl.workspaceName"
        ></cmp-applications-task-volumes>
    </div>
</div>

<cmp-serving-form-spec ng-if="$ctrl.data.spec" data="$ctrl.data.spec" flex="100"></cmp-serving-form-spec>

