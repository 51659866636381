<md-divider></md-divider>
<div id="tabItem_{{$ctrl.data.name}}" ng-click="$ctrl.clickJob()" class="layout-row layout-align-start-center jobDesc cmp-clickable">
    <div class="icon-tree-point">
        <md-icon md-svg-icon="core:minus-box" ng-if="$ctrl.current"></md-icon>
        <md-icon md-svg-icon="core:plus-box" ng-if="!$ctrl.current"></md-icon>
    </div>
    <div class="flex layout-row layout-align-space-between-center">
        <h4>{{$ctrl.data.name}}</h4>
        <div>
            <md-icon md-svg-icon="core:calendar"></md-icon>
            <span class="ts">{{ $ctrl.data.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
            <span class="ts" ng-if="$ctrl.data.stop_time"> - {{ $ctrl.data.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
        </div>
    </div>
    <div class="status {{$ctrl.data.status}} flex-15" style="text-align: right">{{$ctrl.data.status}}</div>
</div>
<div class="fullInfo margin-left" ng-if="$ctrl.current">
    <md-progress-linear ng-if="$ctrl.uploadPods" ></md-progress-linear>
    <cmp-api-error-service error="$ctrl.uploadError" ng-if="$ctrl.uploadError"></cmp-api-error-service>
    <div>
        <cmp-mlapps-logs-job-pod ng-repeat="pod in $ctrl.Pods"
                     pod="pod"
                     mlapp="$ctrl.mlapp"
                     job="$ctrl.data"
                     resource="$ctrl.resource"></cmp-mlapps-logs-job-pod>
    </div>
</div>
