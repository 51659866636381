<div ng-if="$ctrl.versions && $ctrl.versions.length">
    <div layout="row" layout-align="start center">
        <md-input-container flex="15">
            <label translate>COMMON_VERSION</label>
            <md-select ng-model="$ctrl.version">
                <md-option ng-repeat="v in $ctrl.versions" ng-value="v">{{v.Version}} </md-option>
            </md-select>
        </md-input-container>
        <div ng-if="$ctrl.version.SizeBytes">
            Size: <span ng-bind-html="$ctrl.CatalogService.inferenceVersionSize($ctrl.version)"></span>
        </div>
        <!--<div class="flex layout-align-end-center layout-row" style="width: 100%; ">-->
            <!--<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>-->
            <!--<a layout="row"-->
                <!--class="md-button md-raised"-->
                <!--style="margin-left: 0.5em"-->
                <!--ng-disabled="isDownloading"-->
                <!--ng-click="$ctrl.download()">-->
                <!--<md-icon md-svg-icon="core:link"></md-icon>-->
                <!--<span style="margin: 0 10px">download</span>-->
                <!--<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>-->
            <!--</a>-->
        <!--</div>-->

         <div class="flex layout-align-end-center layout-row" style="width: 100%; ">
            <!--<cmp-api-error-service ng-if="downloadError" error="downloadError" format="'dialog'"></cmp-api-error-service>-->
            <!--<a layout="row"-->
               <!--href="{{$ctrl.version.DownloadURL}}"-->
               <!--traget="_blank"-->
                <!--class="md-button md-raised"-->
                <!--style="margin-left: 0.5em"-->
                <!--ng-disabled="isDownloading"-->
                <!--ng-click="$ctrl.download()">-->
            <a layout="row"
               href="{{$ctrl.version.DownloadURL}}"
               target="_blank"
                class="md-button md-raised"
                style="margin-left: 0.5em"
               download
            >
                <md-icon md-svg-icon="core:link"></md-icon>
                <span style="margin: 0 10px">download</span>
                <!--<md-progress-circular ng-if="isDownloading" md-diameter="20" md-mode="indeterminate"></md-progress-circular>-->
            </a>
        </div>

        <md-menu class="md-secondary" md-position-mode="target-right target">
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.canManage">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <md-menu-item>
                    <md-button aria-label="icon" ng-click="$ctrl.delete()">
                        <md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
</div>
<div>
Form upload inference use <a href="https://github.com/kuberlab/pluk/releases/latest" target="_blank">kinference</a>
</div>
