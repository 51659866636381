<md-card class="chartCardSmall">
    <div class="md-media-sm card-media" layout style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>
        <!--<img src="{{$ctrl.data.Picture}}" >-->
    </div>
    <!--<md-card-title>-->
        <!--<div class="md-media-sm card-media" layout style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>-->
            <!--&lt;!&ndash;<img src="{{$ctrl.data.Picture}}" >&ndash;&gt;-->
        <!--</div>-->
        <!--&lt;!&ndash;<md-card-title-media>&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="md-media-sm card-media" layout style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>&ndash;&gt;-->
                <!--&lt;!&ndash;&lt;!&ndash;<img src="{{$ctrl.data.Picture}}" >&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
        <!--&lt;!&ndash;</md-card-title-media>&ndash;&gt;-->
        <!--&lt;!&ndash;{{$ctrl.itemState}}&ndash;&gt;-->
        <!--&lt;!&ndash;<md-card-title-text>&ndash;&gt;-->
            <!--&lt;!&ndash;<a class="md-headline chartTitle" ui-sref="{{$ctrl.itemState}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name})" >&ndash;&gt;-->
              <!--&lt;!&ndash;{{$ctrl.data.DisplayName}}&ndash;&gt;-->
              <!--&lt;!&ndash;<md-icon ng-if="!$ctrl.data.Published" md-svg-icon="core:lock" ng-class="md-avatar-icon" aria-hidden="true">&ndash;&gt;-->
                <!--&lt;!&ndash;<md-tooltip>{{'CHART_NOT_PUBLISHED'|translate}}</md-tooltip>&ndash;&gt;-->
              <!--&lt;!&ndash;</md-icon>&ndash;&gt;-->
            <!--&lt;!&ndash;</a>&ndash;&gt;-->
            <!--&lt;!&ndash;<span class="md-subhead" ng-if="$ctrl.isMlapp(item)">(is mlapp)</span>&ndash;&gt;-->
            <!--&lt;!&ndash;&lt;!&ndash;<span class="md-subhead chartVersion">latest version: {{$ctrl.data.Version}}</span>&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;<span class="md-subhead" ng-if="!$ctrl.isWs">&ndash;&gt;-->
                <!--&lt;!&ndash;<a ui-sref="wsCatalog.list({wsId : $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface })" class="chartWS">&ndash;&gt;-->
                    <!--&lt;!&ndash;<md-icon md-svg-icon="core:group" ng-class="md-avatar-icon" aria-hidden="true"></md-icon>{{$ctrl.data.WorkspaceDisplayName}}&ndash;&gt;-->
                <!--&lt;!&ndash;</a>&ndash;&gt;-->
            <!--&lt;!&ndash;</span>&ndash;&gt;-->
            <!--&lt;!&ndash;<p ng-if="$ctrl.data.Description"  class="chartDescription">{{$ctrl.data.Description}}</p>&ndash;&gt;-->
        <!--&lt;!&ndash;</md-card-title-text>&ndash;&gt;-->

    <!--</md-card-title>-->
    <md-card-content flex>
        <a class="md-headline chartTitle" ui-sref="{{$ctrl.itemState}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name})" >
            {{$ctrl.data.DisplayName}}
            <md-icon ng-if="!$ctrl.data.Published" md-svg-icon="core:lock" ng-class="md-avatar-icon" aria-hidden="true">
                <md-tooltip>{{'CHART_NOT_PUBLISHED'|translate}}</md-tooltip>
            </md-icon>
        </a>
        <span class="md-subhead" ng-if="$ctrl.isMlapp(item)">(is mlapp)</span>
        <!--<span class="md-subhead chartVersion">latest version: {{$ctrl.data.Version}}</span>-->
        <div class="md-subhead" ng-if="!$ctrl.isWs">
            <a ui-sref="wsCatalog.list({wsId : $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface })" class="chartWS">
                <md-icon md-svg-icon="core:group" ng-class="md-avatar-icon" aria-hidden="true"></md-icon>{{$ctrl.data.WorkspaceDisplayName}}
            </a>
        </div>
        <p ng-if="$ctrl.data.Description"  class="chartDescription">{{$ctrl.data.Description}}</p>
    </md-card-content>

    <md-card-actions layout="row" layout-align="space-between stretch">
        <cmp-catalog-rating
                flex="50"
                layout="row" layout-align="space-between stretch"
                data="$ctrl.data"
                resource="$ctrl.resource"
                resource-params="{wsId: $ctrl.data.WorkspaceName, Name: $ctrl.data.Name}">
        </cmp-catalog-rating>

        <md-button flex="50" layout="column" layout-align="center center" ui-sref="{{$ctrl.itemState + '.tab'}}({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name, tab: 'comments'})" >
                <span>
                <md-icon md-svg-icon="core:comments"></md-icon>
                    {{$ctrl.data.Comments}}
                </span>
            <md-tooltip>comments</md-tooltip>
        </md-button>
    </md-card-actions>
</md-card>
