<md-dialog>
    <form ng-cloak name="saveModel" ng-submit="$ctrl.load()">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Commit</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" ></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="md-padding"   >

                <!--{-->
                <!--"AuthorEmail":"iljapoletaev@gmail.com",-->
                <!--"AuthorName":"Илья",-->
                <!--"ID":"37f9bb34767074e8edbf741ca5546693ab4cd95d",-->
                <!--"Message":"Execute 2018-01-31 16:45:55",-->
                <!--"Parents":["72a927144330a39c1ac84b530a6f75cf2c0f47c8"],-->
                <!--"When":"2018-01-31T13:46:07Z",-->
                <!--"branch":"3test3",-->
                <!--"build":"12",-->
                <!--"completed":true,-->
                <!--"start_time":"2018-01-31T13:46:07.785969Z",-->
                <!--"status":"Succeeded",-->
                <!--"stop_time":"2018-01-31T13:46:13.733967Z",-->
                <!--"task_name":"prepare-data"-->
                <!--}-->

                <div ng-if="commit.build">
                    <h4>Execute from {{commit.When | date}} ({{commit.AuthorName}})</h4>
                    <div>experiment: {{commit.branch}}</div>
                    <div>{{commit.task_name}}:{{commit.build}} status: {{commit.status}}</div>
                    <p>{{commit.Message}}</p>
                </div>
                <div ng-if="!commit.build">
                    <h4>Save from {{commit.When | date}} ({{commit.AuthorName}})</h4>
                    <p>{{commit.Message}}</p>
                </div>

                <!--{{commit}}-->
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
                <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
            </div>
            <md-button ng-click="cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button ng-click="load(commit)">
                {{"PROJECT_CHECKOUT" | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
