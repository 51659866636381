<form class="ws-padding-content flex task-filter">
    <div class="flex layout-row layout-align-space-between-start">
        <cmp-pagenate page="$ctrl.rParams.page" list="$ctrl.list" limit="$ctrl.rParams.limit" limits="['5','10','15']"></cmp-pagenate>
        <md-button ng-if="!$ctrl.asFilter" ng-click="$ctrl.asFilter=true">
            <md-icon md-svg-icon="core:filter"></md-icon>
            {{"COMMON_FILTER"|translate}}
        </md-button>


        <div ng-if="$ctrl.asFilter" class="layout-row">
            <md-button ng-click="$ctrl.clearFilter()"><md-icon md-svg-icon="core:filter-remove"></md-icon> {{"COMMON_CLEAR"|translate}}</md-button>
            <md-button ng-click="$ctrl.addFilter()" class="md-raised md-primary"><md-icon md-svg-icon="core:filter"></md-icon> {{"COMMON_FIND"|translate}}</md-button>
        </div>
    </div>

    <div ng-if="$ctrl.asFilter" class="flex">
        <div layout-gt-xs="row" layout-align="start start">
            <div flex="20">
                <md-input-container flex>
                    <label>{{"COMMON_CLUSTER"|translate}}</label>
                    <md-select ng-model="$ctrl.filterParams.cluster" flex>
                        <md-option ng-value="null">{{"COMMON_ALL"|translate}}</md-option>
                        <md-option ng-repeat="cluster in $ctrl.clusters" ng-value="cluster.Name" >
                        {{cluster.Name}}
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
            <div flex="20">
                <md-input-container flex>
                    <label>{{"COMMON_TYPE"|translate}}</label>
                    <md-select ng-model="$ctrl.filterParams.type" flex>
                        <md-option ng-value="null">{{"COMMON_ALL"|translate}}</md-option>
                        <md-option ng-repeat="type in $ctrl.types" ng-value="type.Name" >
                        {{type.Name}}
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
            <div flex="20">
                <md-input-container flex>
                    <label>{{"COMMON_STATUS"|translate}}</label>
                    <md-select ng-model="$ctrl.filterParams.status" flex>
                        <md-option ng-value="null">{{"COMMON_ALL"|translate}}</md-option>
                        <md-option ng-value="'Completed'">Completed</md-option>
                        <md-option ng-value="'Failed'">Failed</md-option>
                    </md-select>
                </md-input-container>
            </div>
            <md-datepicker ng-model="$ctrl.filterParams.from" md-max-date="$ctrl.to" md-placeholder="From date" class="flex flex-20"></md-datepicker>
            <md-datepicker ng-model="$ctrl.filterParams.to" md-min-date="$ctrl.from" md-placeholder="To date" class="flex flex-20"></md-datepicker>
          </div>
    </div>
</form>

<md-progress-linear ng-if="$ctrl.isLoading" md-mode="indeterminate"></md-progress-linear>

<cmp-error-toast ng-if="$ctrl.loadError" error="$ctrl.loadError"></cmp-error-toast>
<md-list ng-if="!$ctrl.loadError">
<div class="titleList layout-row layout-align-space-between-center">
    <div style="width: 54px"></div>
    <b class="flex-40" >{{"COMMON_NAME"|translate}}</b>
    <b flex>{{"PROJECT_TASK_TITLE_TIME"|translate}}</b>
    <b class="flex-20" style="text-align: right">{{"COMMON_STATUS"|translate}}</b>
</div>
<cmp-project-task ng-repeat="item in $ctrl.list" data="item" id="tabItem_{{item.ID}}"></cmp-project-task>
</md-list>
