<md-list-item flex ng-click="$ctrl.goItem($event)" class="paddingListItemTabContent">
    <md-icon md-svg-icon="core:cluster" ng-class="md-avatar-icon"></md-icon>
    <h4 class="flex-40">{{$ctrl.data.Name}}</h4>
    <div flex style="line-height: 1.2em; font-size: 90%" >
        <div><md-icon md-svg-icon="{{$ctrl.AuthService.getIcon($ctrl.data.Provider)}}" ></md-icon>&nbsp{{$ctrl.data.Account}}</div>
        <div><md-icon md-svg-icon="core:ip" ></md-icon>&nbsp<span ng-if="$ctrl.data.MasterIP && $ctrl.data.MasterIP!= ''">{{$ctrl.data.MasterIP}}</span><span ng-if="!$ctrl.data.MasterIP || $ctrl.data.MasterIP == ''">no master addr</span></div>
    </div>


    <md-menu-bar class="padding-zero layout-row layout-align-end-center" >
        <md-button ui-sref="wsProject.tab({tab: 'tasks'})"
                   ng-if="$ctrl.data.CurrentTask"
                   class="layout-row layout-align-end-center" >
            <md-progress-circular md-diameter="16px" style="margin-right: 4px;"></md-progress-circular>
            {{$ctrl.data.Status}}
            <md-tooltip>Has active task</md-tooltip></a>
        </md-button>
        <md-button ng-disabled="true" ng-if="!$ctrl.data.CurrentTask" class="clusterStatus status {{$ctrl.data.Status.toLowerCase()}}"
                   style="text-align: right;
                            padding-left: 20px;
                            padding: 0;
                            margin: 0;">
            {{$ctrl.data.Status}}
            <md-tooltip>Status of cluster</md-tooltip>
        </md-button>
        <md-menu class="" md-position-mode="target-right target">
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.permissions.can('project.manage')">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <div ng-if="$ctrl.permissions.can('project.manage')">
                    <md-menu-item>
                        <md-button ng-click="$ctrl.installChart($event)"><md-icon md-svg-icon="core:application"></md-icon> install chart</md-button>
                    </md-menu-item>
                    <md-divider></md-divider>
                </div>
                <md-menu-item>
                    <md-button ng-click="$ctrl.share()"><md-icon md-svg-icon="core:share"></md-icon> Share</md-button>
                </md-menu-item>
                <div ng-if="$ctrl.permissions.can('project.manage')">
                    <md-menu-item >
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">Actions</md-button>
                            <md-menu-content width="3">
                                <md-menu-item >
                                    <md-button ng-click="$ctrl.action('start', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:play"></md-icon> {{"COMMON_START" | translate}}</md-button>
                                </md-menu-item>
                                <md-menu-item >
                                    <md-button ng-click="$ctrl.action('stop', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP" | translate}}</md-button>
                                </md-menu-item>
                                <md-menu-item>
                                    <md-button ng-click="$ctrl.rebase()"><md-icon md-svg-icon="core:rebase"></md-icon> {{"COMMON_REBASE" | translate}}</md-button>
                                </md-menu-item>
                            </md-menu-content>
                    </md-menu-item>
                    <md-divider></md-divider>
                </div>
                <md-menu-item>
                    <md-button  class="md-button"
                        ng-href="{{$ctrl.clusterService.baseApiPath}}{{$ctrl.data.Name}}/kube-config"
                        target="_self"
                        download="config"
                    ><md-icon md-svg-icon="core:link"></md-icon> config</md-button>
                </md-menu-item>
                <md-menu-item>
                    <md-button target="_blank"
                       class="md-button"
                       ng-href="/api/v1/kl-proxy/{{$ctrl.$state.params.wsId}}/{{$ctrl.$state.params.projectName}}/{{$ctrl.data.Name}}/grafana/dashboard/db/kubernetes-pod-resources"
                    ><md-icon md-svg-icon="core:metric"></md-icon> grafana</md-button>
                </md-menu-item>
                <md-menu-item>
                    <md-button target="_blank"
                       class="md-button"
                       ng-href="/api/v1/kl-proxy/{{$ctrl.$state.params.wsId}}/{{$ctrl.$state.params.projectName}}/{{$ctrl.data.Name}}/admin/"
                    ><md-icon md-svg-icon="core:metric"></md-icon> kubeadmin</md-button>
                </md-menu-item>
                <md-menu-item>
                    <a target="_blank"
                       class="md-button md-ink-ripple"
                       ng-href="/api/v1/proxy/namespaces/kube-system/services/kibana-logging:5601/{{$ctrl.$state.params.wsId}}/{{$ctrl.$state.params.projectName}}/{{$ctrl.data.Name}}/{{$ctrl.$state.params.envName}}/"
                    ><md-icon md-svg-icon="core:metric"></md-icon> kibana</a>
                </md-menu-item>
                <div ng-if="$ctrl.permissions.can('project.manage')">
                    <md-divider></md-divider>
                    <md-menu-item >
                        <md-button ng-click="$ctrl.action('delete', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE" | translate}}</md-button>
                    </md-menu-item>
                </div>
            </md-menu-content>
        </md-menu>
    </md-menu-bar>
    <cmp-api-error-service ng-if="$ctrl.actionError" error="$ctrl.actionError" format="'dialog'"></cmp-api-error-service>
</md-list-item>
