<cmp-webrtc-stream flex link="$ctrl.linkStream" params="$ctrl.config" on-error="$ctrl.webrtcError($event)"> </cmp-webrtc-stream>

<div ng-if="$ctrl.response.captionBuf" ng-repeat="item in $ctrl.response.captionBuf" class="responceCaption">{{item}}</div>

<cmp-form-constructor options="$ctrl.params" ng-model="$ctrl.config" api="api" class="md-padding"></cmp-form-constructor>

<div ng-if="$ctrl.response.imageBufData" class="flex md-padding imageBufData">
    <div layout="row" layout-align="start" class="title">
        <h4><a href="" ng-click="$ctrl.dsHide = !$ctrl.dsHide"><md-icon md-svg-src="core:{{$ctrl.dsHide ? 'menu-down' : 'menu-right'}}" ></md-icon> Description result</a></h4>
    </div>
    <md-content ng-if="$ctrl.dsHide" class="md-padding">
        <cmp-serving-parameters-table data="$ctrl.response.imageBufData" meta="$ctrl.response.metaData" parent="$ctrl.parent"></cmp-serving-parameters-table>
    </md-content>
</div>

<!--{{$ctrl.config}}-->
