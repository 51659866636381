<home-card-welcom-content>
    <h2><cmp-logo></cmp-logo> ERROR</h2>
    <div class="content" >
        <span translate="aaa">test1</span>
        <span translate="'COMMON_OK'">test2</span>
        <md-button translate="aaa" translate-default="$ctrl.test">test3</md-button>
        <md-button translate="COMMON_OK" translate-default="$ctrl.test">test4</md-button>
        <!--<md-button translate="'COMMON_OK'" translate-default="test4"></md-button>-->
        <!--<md-button translate="COMMON_OK">test4</md-button>-->
        <!--{{$ctrl.$state.params.message}}-->
    </div>
</home-card-welcom-content>
