<div layout="row" class="applicationMetrics">
    <section class="md-content leftMenu" flex="25">
        <h4>Components</h4>
        <ul style="list-style: none; padding-left: 1em;" >
            <li ng-if="$ctrl.tasks.length" ng-click="$ctrl.taskListShow = !$ctrl.taskListShow " class="cmp-clickable" ><h4>tasks ({{$ctrl.tasks.length}})
                <md-icon md-svg-icon="core:chevron-down" ng-if="!$ctrl.taskListShow"></md-icon>
                <md-icon md-svg-icon="core:chevron-up" ng-if="$ctrl.taskListShow"></md-icon>
            </h4></li>
            <ul style="    list-style: none; padding-left: 1em;" ng-show="$ctrl.taskListShow">
                <li ng-repeat="el in $ctrl.tasks">
                    <md-checkbox ng-model="$ctrl.mElement[el.task_name + '-' + el.build]" ng-change="$ctrl.setMetricElement(el, 'taskGraph')" ng-disabled="$ctrl.loading" >{{el.task_name}}-{{el.build}}</md-checkbox>
                </li>
            </ul>
            <li ng-repeat="el in $ctrl.staticElements">
                <md-checkbox ng-model="$ctrl.mElement[el.name]" ng-change="$ctrl.setMetricElement(el, 'applicationGraph')" ng-disabled="$ctrl.loading">{{el.name}}</md-checkbox>
            </li>
        </ul>

        <hr/>
        <h4>Metrics</h4>
        <ul>
            <li ng-repeat="item in $ctrl.Metrics" >
                <md-checkbox ng-model="item.active"  ng-disabled="$ctrl.loading" ng-change="$ctrl.changeMetricsList()">{{item.title | translate}}</md-checkbox>
            </li>
        </ul>
    </section>
    <section class="md-content md-padding" flex>
        <div layout="row" layout-align="start center">
            <div flex></div>

            <md-input-container ng-if="$ctrl.currentAction == 'taskGraph'">
                <md-checkbox ng-model="$ctrl.details" style="margin: 0;" ng-change="$ctrl.updateMetrics()" ng-disabled="$ctrl.loading" >Details</md-checkbox>
            </md-input-container>

            <md-menu ng-if="$ctrl.currentAction == 'applicationGraph'" ng-disabled="$ctrl.loading" >
                <md-button aria-label="Open phone interactions menu" ng-click="$mdMenu.open()">
                    {{$ctrl.timeIntervanTitle()}}
                </md-button>
                <md-menu-content width="4">
                    <md-menu-item ng-repeat="item in $ctrl.timeIntervals">
                        <md-button ng-click="$ctrl.setTimeInterval({start: null, end: null, interval: item.value})">
                            {{item.title}}
                        </md-button>
                </md-menu-content>
            </md-menu>

            <md-menu ng-if="$ctrl.currentAction == 'taskGraph'" ng-disabled="$ctrl.loading" >
                <md-button aria-label="Open phone interactions menu" ng-click="$mdMenu.open()">
                    {{$ctrl.intervanTitle()}}
                </md-button>
                <md-menu-content width="4">
                    <md-menu-item ng-repeat="item in $ctrl.intervals">
                        <md-button ng-click="$ctrl.setInterval(item.value)">
                            {{item.title}}
                        </md-button>
                </md-menu-content>
            </md-menu>
            <md-button ng-click="$ctrl.updateMetrics()" class="md-raised" ng-disabled="$ctrl.loading" ><md-icon md-svg-icon="core:reload"></md-icon> update</md-button>
        </div>
        <cmp-error-toast ng-if="$ctrl.apiError" error="$ctrl.apiError"></cmp-error-toast>
        <!--<nvd3-pie-chart chart-id="chart1" data='[-->
      <!--{-->
        <!--"label": "One",-->
        <!--"value" : 29.765957771107-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Two",-->
        <!--"value" : 0-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Three",-->
        <!--"value" : 32.807804682612-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Four",-->
        <!--"value" : 196.45946739256-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Five",-->
        <!--"value" : 0.19434030906893-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Six",-->
        <!--"value" : 98.079782601442-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Seven",-->
        <!--"value" : 13.925743130903-->
        <!--} ,-->
        <!--{-->
        <!--"label": "Eight",-->
        <!--"value" : 5.1387322875705-->
        <!--}-->
        <!--]'-->
                        <!--donut="true"-->
                        <!--half="true"-->
                        <!--width="300" height="300" duration="1000" label-type="percent" responsive="true" style="display: block"></nvd3-pie-chart>-->


        <div ng-repeat=" item in $ctrl.Metrics " ng-if="item.active" class="metricItem" >
            <h4 translate>{{item.title}}</h4>
            <!--<nvd3-line-graph-->
                    <!--chart-id="{{item.name}}"-->
                    <!--data="$ctrl.d3Metrics.metrics[item.name].series"-->
                    <!--responsive="true"-->
                    <!--height="350"-->
                    <!--duration="2000"-->
                    <!--guide="true"-->
                    <!--ylabel="{{$ctrl.d3Metrics.metrics[item.name].unit}}"-->
                    <!--yformat=".02f"--
                    <!--update-count="$ctrl.d3Metrics.metrics[item.name].count"-->
                    <!--controller="$ctrl.d3Metrics.metrics[item.name].controller"-->
                    <!--style="display: block">-->
            <!--</nvd3-line-graph>-->
            <div class="loading" flex layout="column" layout-align="center" ng-if="$ctrl.loading">
              <md-progress-circular style="margin:auto"></md-progress-circular>
            </div>

            <nvd3 options='item.options' data="$ctrl.d3Metrics.metrics[item.name].series" api="item.api" flex on-ready="$ctrl.nvd3Callback" id="{{item.title}}" style="height:450px" ></nvd3>
            <!--{{$ctrl.d3Metrics.metrics[item.name].series}}-->
            <!--{{$ctrl.nvd3Opt}}-->
        </div>
        <!--{{$ctrl.d3Data}}-->
        <!--<div ng-if="item.active" ng-repeat="item in $ctrl.Metrics" class="metricItem">-->
            <!--<div><b translate >{{item.title}}</b> {{item.unit}}</div>-->
            <!--<canvas-->
                    <!--id="line" class="chart chart-line"-->
                    <!--chart-data="item.data"-->
                    <!--chart-labels="$ctrl.ChartLabels"-->
                    <!--chart-series="$ctrl.ChartSeries"-->
                    <!--chart-options="$ctrl.ChartOptions"-->
                    <!--chart-click="onClick">-->
            <!--</canvas>-->
            <!--<div class="loading" flex layout="column" layout-align="center" ng-if="$ctrl.loading">-->
                <!--<md-progress-circular style="margin:auto"></md-progress-circular>-->
            <!--</div>-->
        <!--</div>-->
    </section>
</div>

