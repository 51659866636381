    <div class="item ">
        <div class="form layout-column">
            <md-input-container>
                <label>Namespace</label>
                <input type="text" ng-model="$ctrl.data.Namespace" required  />
            </md-input-container>
        </div>
        <div class="info"><p>Namespaces let you partition resources into logically named groups. </p></div>
    </div>



    <div class="item ">
        <div class="form layout-column">
            <md-input-container">
                <label>Service</label>
                <md-select ng-model="$ctrl.data.Service" required ng-change="$ctrl.changeService()">
                    <md-option ng-repeat="i in $ctrl.listServices" ng-value="i">{{i}}</md-option>
                </md-select>
            </md-input-container>
            <cmp-wizard-deploy-ports ng-if="$ctrl.data.Ports" data="$ctrl.data.Ports"></cmp-wizard-deploy-ports>
        </div>
        <div class="info"><p>Optionally, an internal or external Service can be defined to map an incoming Port to a target Port seen by the container.</p></div>
    </div>

    <div class="item">
        <div class="form">
            <h5>Labels</h5>
            <cmp-wizard-deploy-labels data="$ctrl.data.Labels"></cmp-wizard-deploy-labels>
        </div>
        <div class="info"><p>The specified labels will be applied to the created Replication Controller, Service (if any) and Pods. Common labels include release, environment, tier, partition and track.</p></div>
    </div>





    <div class="item">
        <md-input-container class="form">
            <label>Image</label>
            <input type="text" ng-model="$ctrl.data.Image.Name">
        </md-input-container>
        <div class="info"><p>Image link</p></div>
    </div>


    <div class="item">
        <md-input-container class="form">
            <label>Tag</label>
            <input type="text" ng-model="$ctrl.data.Image.Tag">
        </md-input-container>
        <div class="info"><p>Image tag</p></div>
    </div>

    <div class="item">
        <md-input-container class="form">
            <label>Replicas</label>
            <input type="number" ng-model="$ctrl.data.Replicas">
        </md-input-container>
        <div class="info"><p>A Deployment will be created to maintain the desired number of pods across your cluster.</p></div>
    </div>

    <div class="item">
        <div class="form layout-row">
            <md-input-container flex="50">
                <label>Cpu Requests</label>
                <input type="text" ng-model="$ctrl.data.CpuRequests">
            </md-input-container>
            <md-input-container flex="50">
                <label>Cpu Limits</label>
                <input type="text" ng-model="$ctrl.data.CpuLimits">
            </md-input-container>
        </div>
        <div class="info"><p>CPU config</p></div>
    </div>

    <div class="item">
        <div class="form layout-row" >
            <md-input-container flex="50">
                <label>Memory Requests</label>
                <input type="text" ng-model="$ctrl.data.MemoryRequests">
            </md-input-container>
            <md-input-container flex="50">
                <label>Memory Limits</label>
                <input type="text" ng-model="$ctrl.data.MemoryLimits">
            </md-input-container>
        </div>
        <div class="info"><p>Memory config</p></div>
    </div>

    <div class="item ">
        <div class="form layout-column">
            <md-input-container>
                <label>Run command</label>
                <input type="text" ng-model="$ctrl.data.Command"   />
            </md-input-container>
            <md-input-container>
                <label>Run command arguments</label>
                <input type="text" ng-model="$ctrl.data.CommandArgumanrs"   />
            </md-input-container>
        </div>
        <div class="info"><p>By default, your containers run the selected image's default entrypoint command. You can use the command options to override the default.</p></div>
    </div>

    <div class="item">
        <div class="form">
            <md-checkbox ng-model="$ctrl.data.Privileged" aria-label="Run as privileged">
                Run as privileged
            </md-checkbox>
        </div>
        <div class="info"><p>Processes in privileged containers are equivalent to processes running as root on the host.</p></div>
    </div>

    <div class="item">
        <div class="form">
            <h5>Environment variables</h5>
            <cmp-wizard-deploy-variables data="$ctrl.data.EnvironmentVariables"></cmp-wizard-deploy-variables>
        </div>
        <div class="info"><p>The specified labels will be applied to the created Replication Controller, Service (if any) and Pods. Common labels include release, environment, tier, partition and track.</p></div>
    </div>

