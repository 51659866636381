<!--{{$ctrl.data}}-->
<md-list-item class="md-3-line layout-align-start-center" ng-if="!$ctrl.asForm">
    <md-icon md-svg-icon="core:{{$ctrl.getType()}}" class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text flex">
        <h4>{{$ctrl.data.name}}</h4>
        <p><cmp-storage-view data="$ctrl.data"></cmp-storage-view>
        <br/>
            Mount path:{{$ctrl.data.mountPath}}
        </p>
    </div>
    <!--<div class="flex-10 " style="margin:auto">{{$ctrl.data.mountPath}}</div>-->
    <div class="flex-10 layout-row layout-align-center-center" style="margin:auto">
        <md-checkbox ng-model="$ctrl.data.isLibDir" style="margin: auto" ng-disabled="true"></md-checkbox>
    </div>
    <div class="flex-10 layout-row layout-align-center-center" style="margin:auto">
        <md-checkbox ng-model="$ctrl.data.isTrainLogDir" style="margin: auto" ng-disabled="true"></md-checkbox>
    </div>
    <md-menu class="md-secondary" md-position-mode="target-right target">
        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
            <md-icon md-svg-icon="core:short-menu"></md-icon>
        </md-button>
        <md-menu-content width="3">
            <md-menu-item ng-if="!$ctrl.envList.Error">
                <md-button aria-label="icon" ng-click="$ctrl.edit()">
                    <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT"|translate}}
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button aria-label="icon" ng-click="$ctrl.delete()">
                    <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-list-item>

<div ng-if="$ctrl.asForm">
    <form name='add' ng-submit="$ctrl.save(add, $ctrl.data)" class="flex">
        <md-card class="flex">
            <md-card-content>
                <cmp-storage
                        data="$ctrl.data"
                        types="$ctrl.types"
                        cluster-storages="$ctrl.clusterStorageList"
                    ></cmp-storage>
                <md-checkbox ng-model="$ctrl.data.isTrainLogDir" ng-disabled="$ctrl.getType() == 'gitRepo' || $ctrl.isTrainLogDir">Train Log Dir</md-checkbox>
                <md-checkbox ng-model="$ctrl.data.isLibDir" >Library Dir</md-checkbox>
            </md-card-content>
            <md-card-actions ng-if="!$ctrl.isTrainLogDir" class="layout-row layout-align-start-center">
                <div class="flex">
                    <md-progress-linear ng-if="$ctrl.saving" class="flex"></md-progress-linear>
                    <!--<cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage" class="flex"></cmp-api-error-service>-->
                </div>
                <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                <md-button ng-if="$ctrl.isNewElement" type="submite" class="md-primary">Add</md-button>
                <md-button ng-if="!$ctrl.isNewElement" type="submite" class="md-primary">save</md-button>
            </md-card-actions>
        </md-card>
    </form>
</div>
