    <div layout="row" layout-align="start center" >
        <div class="name flex" layout="row" layout-align="start center" >
            <md-button class="md-icon-button" ng-click="$ctrl.close()"><md-icon md-svg-icon="core:back"></md-icon></md-button>
            <item-status-icon data="{Health: $ctrl.data.status}" class="widthAuto"></item-status-icon>
            <h3 style="margin: 0;">{{$ctrl.data.task_name}}:{{$ctrl.data.build}}<span ng-if="$ctrl.data.exec_info.name">&nbsp;&nbsp;-&nbsp;&nbsp;{{$ctrl.data.exec_info.name}}</span> <span ng-if="$ctrl.data.serve" class="status {{$ctrl.data.serve.status}}" > ({{"COMMON_SERVING" | translate}}<span ng-if="!$ctrl.data.serving">:direct</span>)</span></span></h3>
        </div>
        <div ng-include="'kuberlab/applications/jobs/application_job_menu.html'"></div>
    </div>



    <md-content flex >
        <div ng-if="$ctrl.data.status == 'Running'" class="applicationMetricShort layout-row layout-align-center " style="background-color: #eee; padding-bottom:10px: " ng-include="'kuberlab/applications/jobs/application_job_status.html'"></div>

        <div class="cmp-block-compact">
            <h5 flex class="cmp-block-compact-title">Info</h5>
            <div class="cmp-block-compact-content">
                <div class="wordTime" >
                    <div class="layout-row layout-align-start-center">
                        <md-icon md-svg-icon="core:calendar" class="tsIcon"></md-icon>
                        <div class=" flex layout-column layout-align-start-start">
                            <span class="ts">{{ $ctrl.data.start_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                            <span class="ts" ng-if="$ctrl.data.stop_time">{{ $ctrl.data.stop_time | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                        </div>
                    </div>
                </div>
                <div style="padding-bottom: 1em;">
                    <div style="font-size: 90%" >{{"COMMON_EXPERIMENT" | translate }}: {{$ctrl.data.branch}}, {{"COMMON_AUTHOR" | translate }}: {{$ctrl.data.author}}</div>
                    <div ng-if="$ctrl.data.comment">{{$ctrl.data.comment}}</div>
                </div>
                <div ng-include="'kuberlab/applications/jobs/application_job_info.html'"></div>
            </div>
        </div>

        <div class="cmp-block-compact">
            <h5 flex class="cmp-block-compact-title">Logs</h5>
            <div class="cmp-block-compact-content">
                <cmp-applications-logs-job  flex layout="row" application="$ctrl.appController.data" data="$ctrl.data"></cmp-applications-logs-job>
            </div>
        </div>

        <div class="cmp-block-compact">
            <h5 flex class="cmp-block-compact-title">Metrics</h5>
            <div class="cmp-block-compact-content">
                <cmp-applocation-job-mertincs active="true" app-controller="$ctrl.appController" task="$ctrl.data"></cmp-applocation-job-mertincs>
            </div>
        </div>

        <div class="cmp-block-compact" ng-if="$ctrl.data.serve">
            <h5 flex class="cmp-block-compact-title">Serving</h5>
            <div class="cmp-block-compact-content">
                <div class="md-padding" layout="row" layout-align="start center" ng-if="$ctrl.data.serving">
                    <cmp-serving-icon data="$ctrl.data.serving"></cmp-serving-icon>
                    <div>
                        <h4 style="margin: 0">{{$ctrl.data.serving.Task}}:{{$ctrl.data.serving.Build}} <span style="font-weight: normal" ng-if="$ctrl.data.UserDisplayName">( {{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.serving.UserDisplayName}} )</span></h4>
                        <div><b>Address:</b> {{$ctrl.data.serve.inner_address}}</div>
                        <div><a ui-sref="wsServing.tab({servingName: $ctrl.data.serving.Name, wsId: $ctrl.data.serving.WorkspaceName})">serving page</a></div>
                    </div>
                    <div flex></div>
                    <md-button
                            ng-if="$ctrl.permissions.can('application.manage')"
                            class="md-raised md-primary"
                            ng-click="$ctrl.listController.action('stop_serving', {task: $ctrl.data.task_name, build: $ctrl.data.serve.build})"
                            ng-disabled="!$ctrl.data.serving.Status == 'Running' || $ctrl.loading"
                    ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
                </div>
                <div class="md-padding" layout="row" layout-align="start center" ng-if="!$ctrl.data.serving && $ctrl.data.serve">
                    <cmp-serving-icon data="$ctrl.data.serve"></cmp-serving-icon>
                    <div>
                        <h4 style="margin: 0">{{$ctrl.data.serve.serving}}:{{$ctrl.data.serve.build}} </h4>
                        <div><b>Address:</b> {{$ctrl.data.serve.inner_address}}</div>
                    </div>
                    <div flex></div>

                    <md-menu class="menu" md-position-mode="target-right target">
                        <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                            <md-icon md-svg-icon="core:short-menu"></md-icon>
                        </md-button>
                        <md-menu-content width="3">
                            <md-menu-item >
                                <md-button
                                        ng-if="$ctrl.permissions.can('application.manage')"
                                        class="md-raised md-primary"
                                        ng-click="$ctrl.action('stop_serving', {task: $ctrl.data.serve.serving, build: $ctrl.data.serve.build})"
                                        ng-disabled="!$ctrl.data.serve.status == 'Running' || $ctrl.loading"
                                ><md-icon md-svg-icon="core:stop"></md-icon> {{ "COMMON_STOP" | translate}}</md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
                <cmp-serving-logs request-data="{servingName: $ctrl.data.serving.Name, wsId: $ctrl.data.serving.WorkspaceName}"></cmp-serving-logs>
            </div>
        </div>
    </md-content>
