<label translate>COMMON_NAME</label>
<input
        type="text"
        name="Name"
        ng-model="$ctrl.data.Name"
        required
        ng-model-options='{ debounce: 1000 }'
        ng-pattern="$ctrl.validate.idPattern"
        ng-disabled="fieldName.disabled"
>
<div ng-messages="form.Name.$error">
    <div ng-message="required" translate>FORM_ERROR_REQUIRED</div>
    <div ng-message="pattern" translate>FORM_ERROR_NAME_PATTERN</div>
</div>
