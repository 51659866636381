<layout-page-content-list class="menuLevel layoutMenu" >
    <!--<h3><a ui-sref="workspace"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{$ctrl.WS.DisplayName}}</span></a></h3>-->
    <layout-ws></layout-ws>
    <cmp-serving-list-short flex></cmp-serving-list-short>
</layout-page-content-list>


<layout-page-content-item flex layout="column" layout-align="start">

    <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
        <layout-button-submenu></layout-button-submenu>
        <layout-back></layout-back>
        <!--<div ng-include="'kuberlab/serving/card/serving-item-status.html'"></div>-->

        <item-status-icon data="$ctrl.data" class="widthAuto" ></item-status-icon>

        <h3>{{$ctrl.ServingService.Name($ctrl.data)}}
            <!--{{$ctrl.data.DisplayName || $ctrl.data.Name}}-->
        </h3>
        <div flex></div>
        <md-menu-bar  class="padding-zero layout-row layout-align-end-center">
            <md-menu>
                <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" >
                    <md-icon md-svg-icon="core:info"></md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item ng-if="$ctrl.data.Name">
                        <span>{{$ctrl.data.Name}}</span>
                    </md-menu-item>

                    <md-menu-item ng-if="$ctrl.data.UserLogin">
                        <span >{{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.UserLogin}}</span>
                    </md-menu-item>

                    <md-menu-item>
                        <span>cluster: <cmp-application-cluster-link class="flex" data="$ctrl.data" style="display: inline"></cmp-application-cluster-link></span>
                    </md-menu-item>
                    <!---->
                    <!--<md-menu-item style="font-weight: normal" ng-if="$ctrl.data.UserDisplayName">-->
                        <!--<span>{{'COMMON_AUTHOR' | translate}}: {{$ctrl.data.UserDisplayName}}</span>-->
                    <!--</md-menu-item>-->
                    <!--<md-menu-item ng-include="'kuberlab/serving/card/serving_link_model.html'"></md-menu-item>-->
                    <!--<md-menu-item>-->
                        <!--<span>address: {{$ctrl.data.InnerAddress}}</span>-->
                    <!--</md-menu-item>-->
                </md-menu-content>
            </md-menu>
            <div class="md-secondary" ng-include="'kuberlab/serving/card/serving_menu.html'"></div>
        </md-menu-bar>
    </layout-page-content-title>
    <div  flex class="" layout="column" layout-align="start">
        <md-tabs md-border-bottom md-dynamic-height  >
            <md-tab ng-repeat="tab in $ctrl.pageTabs"
                    md-active="$ctrl.$state.params.tab == tab.title"
                    ui-sref="wsServing.tab({tab: tab.title})"
            >
                <md-tab-label>
                    {{tab.title}}
                </md-tab-label>
            </md-tab>
        </md-tabs>
        <md-content flex class="fullInfo md-padding" ng-repeat="item in $ctrl.pageTabs" ng-show="item.title == $ctrl.$state.params.tab" bind-html-compile="item.content"></md-content>
    </div>

</layout-page-content-item>

