<md-card class="chartCardFull catalogCardItem">

    <md-card-content class="padding-content layout-row layout-align-space-between-start" >
        <div class="md-media-xl card-media" layout="column" style='background: url("{{$ctrl.data.Picture}}") center center no-repeat; background-size: contain;'>
            <img src="{{$ctrl.data.Picture}}" class="md-media-xl card-media" >
            <md-button class="md-icon-button edit" aria-label="Add service" ng-if="$ctrl.canManage" ng-click=" $ctrl._editPic = !!!$ctrl._editPic" >
                <md-icon md-svg-icon="core:edit"></md-icon>
            </md-button>

            <form class="uploadFile" ng-if="$ctrl._editPic" name="Picture" ng-submit="$ctrl.uploadFile(Picture, fileP, 'picture', 'picture', lfApiP)" layout="column">
                <div layout="row" layout-align="center center">
                    <lf-ng-md-file-input
                            lf-files="fileP"
                            flex progress
                            ng-disabled="$ctrl.isLoading.picture"
                            lf-browse-label=" "
                            lf-remove-label=" "
                            required
                            lf-api="lfApiP"></lf-ng-md-file-input>

                </div>
                <div layout="row" layout-align="start center">
                    <md-button flex="50" type="submite" ng-disabled="$ctrl.isLoading.picture || !fileP || !fileP.length">
                        <span ng-show="!$ctrl.isLoading.picture" >{{"COMMON_UPLOAD" | translate}}</span>
                        <div flex linear ng-if="$ctrl.isLoading.picture">
                            {{$ctrl.loadingProgres}}%
                            <md-progress-linear ng-if="$ctrl.isLoading.picture" flex></md-progress-linear>
                        </div>
                    </md-button>
                    <md-button flex="50" ng-click="$ctrl.addFile = false">{{"COMMON_CANCEL" | translate}}</md-button>
                </div>

                <cmp-error-toast ng-if="$ctrl.loadingError.picture" error="$ctrl.loadingError.Picture"></cmp-error-toast>
            </form>
        </div>
        <!--<img src="{{$ctrl.data.Picture}}" class="md-media-xl card-media" alt="$ctrl.data.Name">-->
        <md-card-actions layout="column" layout-align="start start" flex class="chartInfo">
            <div flex layout="row" layout-align="space-between center" style="width: 100%">
                <div flex></div>
                <div layout="row" layout-align="end center">
                    <md-card-actions layout="row" layout-align="end center">
                        <cmp-catalog-rating
                                data="$ctrl.data"
                                resource="$ctrl.resource"
                                resource-params="{wsId: item.WorkspaceName, Name: $ctrl.data.Name}">
                        </cmp-catalog-rating>
                        <md-button>
                            <md-icon md-svg-icon="core:comments"></md-icon>
                            {{$ctrl.data.Comments}}
                            <md-tooltip>comments</md-tooltip>
                        </md-button>
                    </md-card-actions>


                    <md-menu class="md-secondary" md-position-mode="target-right target">
                        <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.canManage">
                            <md-icon md-svg-icon="core:short-menu"></md-icon>
                        </md-button>
                        <md-menu-content width="3">
                            <!--<md-menu-item>-->
                                <!--<md-button aria-label="icon" ng-click="$ctrl.actionForm()">-->
                                    <!--<md-icon md-svg-icon="core:edit"></md-icon>{{ "COMMON_EDIT"  | translate}}-->
                                <!--</md-button>-->
                            <!--</md-menu-item>-->
                            <md-menu-item>
                                <md-button aria-label="icon" ng-click="$ctrl.publish()">
                                    <md-icon md-svg-icon="{{$ctrl.data.Published ? 'core:unpublish' : 'core:publish' }}"></md-icon>{{ $ctrl.data.Published ? "COMMON_UNPUBLISH" : "COMMON_PUBLISH"  | translate}}
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'MLMODEL_DELETE_DIALOG_MESSAGE', 'MLMODEL_DELETE_DIALOG_TITLE')">
                                    <md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}
                                </md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
            <div ng-if="!$ctrl.asForm" flex layout="column" layout-align="start start">

                <div class="md-headline">{{$ctrl.data.DisplayName}}</div>
                <div ng-if="$ctrl.data.Published" class="published">Published</div>
                <div ng-if="!$ctrl.data.Published" class="not published">Not published</div>
                <div class="broken" ng-if="$ctrl.data.Broken">Broken</div>
                <p ng-if="$ctrl.data.Description">{{$ctrl.data.Description}}</p>
                <div>
                    <span ng-if="$ctrl.data.Keywords && $ctrl.data.Keywords.length > 0"><strong>Keywords:</strong> <span ng-repeat="k in $ctrl.data.Keywords" class="keyword"><a ng-click="$ctrl.$state.go($ctrl.stateCatalog, {search:k})">{{k}}</a></span></span>
                </div>
                <cmp-api-error-service ng-if="$ctrl.deleteError" error="$ctrl.deleteError" format="'dialog'"></cmp-api-error-service>
                <!--<div layout="row" layout-align="start start"  ng-if="$ctrl.Service.Interface == 'mlmodel' || $ctrl.Service.Interface == 'dataset'">-->
                    <!--<div flex></div>-->
                    <!--<md-button ng-click="$ctrl.fork()">{{"COMMON_FORK" | translate }} 1</md-button>-->

                <!--</div>-->
            </div>
            <div ng-if="$ctrl.asForm" ng-include="'kuberlab/catalog/base/card/card_new.html'"></div>
        </md-card-actions>
    </md-card-content>

    <md-tabs md-border-bottom md-dynamic-height  >
        <md-tab ng-repeat="tab in $ctrl.pageTabs"
                md-active="$ctrl.$state.params.tab == tab.title"
                ng-click="$ctrl.goTab(tab.title)">
            <md-tab-label>
                {{tab.title}}
            </md-tab-label>
        </md-tab>
    </md-tabs>
    <md-content class="fullInfo padding-content" ui-view="tabContent"></md-content>

</md-card>



