<div class="task-images cmp-block-compact" >
    <h5 class="cmp-block-compact-title">Source</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <div class="layout-row layout-align-start" flex="100">
            <cmp-storage-git data="$ctrl.data.source"  flex="100" ></cmp-storage-git>
        </div>
    </div>
</div>

<div class="layout-row layout-align-start-start layout-wrap">
    <md-input-container flex="100">
        <label translate>MLAPP_CMD</label>
        <textarea ng-model="$ctrl.data.command" ></textarea>
    </md-input-container>
</div>

<div class="task-resource-env cmp-block-compact">
    <h5 class="cmp-block-compact-title" >{{"MLAPP_RESOURCES" | translate}}</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <md-input-container class="flex-50" >
            <label>CPU (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.requests.cpu" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>CPU Limit (<span>m</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.limits.cpu" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>Memory (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.requests.memory" />
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Memory Limit (<span>Mi,Gi</span>)</label>
            <input type="text" ng-model="$ctrl.data.resources.limits.memory" />
        </md-input-container>

        <md-input-container class="flex-50" >
            <label>GPU</label>
            <input type="number" ng-model="$ctrl.data.resources.accelerators.gpu" min="0"/>
        </md-input-container>
        <md-input-container class="flex-50" >
            <label>Replicas</label>
            <input type="number" ng-model="$ctrl.data.replicas" min="0" />
        </md-input-container>
    </div>
</div>

<div class="task-images cmp-block-compact" >
    <h5 class="cmp-block-compact-title">Images</h5>
    <div class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">
        <div class="flex layout-row layout-align-start">
            <md-input-container flex>
                <label>CPU</label>
                <input type="text" ng-model="$ctrl.data.images.cpu" name="image_cpu" required>
            </md-input-container>
            <md-input-container flex>
                <label>GPU</label>
                <input type="text" ng-model="$ctrl.data.images.gpu" name="image_gpu" >
            </md-input-container>
            <!--<div class="help-block error" ng-show="$ctrl.form['image_' + key ].$error.required">{{"FORM_ERROR_REQUIRED" | translate}}</div>-->
        </div>
    </div>
</div>

<div class="cmp-block-compact">
    <h5 class="cmp-block-compact-title" translate>MLAPP_ENV_ARGS</h5>
    <div class="cmp-block-compact-content">
        <cmp-autolist
                data="$ctrl.data.env"
                class="flex"
                labels="['name', 'value']"
                pattern="{name: {
                            pattern: '^[A-Z][_A-Z0-9]{0,61}[A-Z0-9]$',
                            message: 'Valid name must be 63 characters or less and must begin with an upper case alpha [A-Z] and end with an upper case alphanumeric character ([A-Z0-9]) with underline (_) and upper case alphanumerics between'}}"
                form="$ctrl.form"
        ></cmp-autolist>
    </div>
</div>

<div class="cmp-block-compact">
    <h5 class="cmp-block-compact-title" translate>COMMON_PORTS</h5>
    <div class="cmp-block-compact-content">
        <cmp-autolist
                data="$ctrl.data.ports"
                class="flex"
                labels="['name', 'protocol', 'port', 'targetPort']"
                attr="{
                    type: {
                        port: 'number',
                        targetPort: 'number'
                    },
                    label: {
                        targetPort: 'target port'
                    }
                }"
                form="$ctrl.form"
        ></cmp-autolist>
    </div>
</div>

<cmp-mlmodel-serving-spec data="$ctrl.data.spec" flex="100"></cmp-mlmodel-serving-spec>





<!--<div class="task-ports cmp-block-compact" ng-if="$ctrl.data.ports && $ctrl.data.ports.length">-->
    <!--<h5 class="cmp-block-compact-title">Ports</h5>-->
    <!--<ml-list class="cmp-block-compact-content layout-row layout-align-start-start layout-wrap">-->
        <!--<md-list-item ng-repeat="port in $ctrl.data.ports">-->
            <!--<md-input-container  class="flex-25">-->
                <!--<label>name</label>-->
                <!--<input type="text" ng-model="port.name">-->
            <!--</md-input-container>-->
            <!--<md-input-container  class="flex-25">-->
                <!--<label>protocol</label>-->
                <!--<input type="text" ng-model="port.protocol">-->
            <!--</md-input-container>-->
            <!--<md-input-container  class="flex-25">-->
                <!--<label>port</label>-->
                <!--<input type="text" ng-model="port.port">-->
            <!--</md-input-container>-->
            <!--<md-input-container  class="flex-25">-->
                <!--<label>TargetPort</label>-->
                <!--<input type="text" ng-model="port.TargetPort">-->
            <!--</md-input-container>-->
        <!--</md-list-item>-->
    <!--</ml-list>-->
<!--</div>-->