<form name="saveConfig" ng-submit="$ctrl.saveConfig(saveOptions)" flex layout="column" layout-align="start">
    <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.template"></div>
    <div layout="row" layout-align="start" flex>
        <div flex layout="row" layout-align="start"  class="nd-padding" style="margin-left:0; margin-right: 0">
            <md-progress-liner ng-if="$ctrl.saving"></md-progress-liner>
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        </div>
        <!--<md-button class="md-primary" ng-click="$ctrl.cancelEdit()">cancel</md-button>-->
        <md-button class="md-primary" type="submit">Save</md-button>
    </div>
</form>
