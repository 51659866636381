
<cmp-api-error-service ng-if="$ctrl.errorJobs" error="$ctrl.errorJobs"></cmp-api-error-service>
<!--<div class="layout-row layout-align-start-center">-->
    <!--<h2 class="flex" translate>{{'MLAPPS_METRICS_TITLE'}}</h2>-->
    <!--<div class="flex-20 layout-row" ng-include="'kuberlab/mlapps/card/mlapps_jobs_filter.html'"></div>-->
<!--</div>-->
<div class="layout-row layout-align-start-start md-padding">
    <md-list class="flex-20">
        <h4>{{'MLAPPS_JOBS_TITLE' | translate}}</h4>
        <md-list-item ng-repeat=" item in $ctrl.Jobs  ">
            <md-checkbox ng-model="$ctrl.mJobs[item.name]" ng-change="$ctrl.getGraphs()"></md-checkbox>
            <span>{{item.name}} - {{item.status}}</span>
        </md-list-item>
    </md-list>
    <div class="flex md-padding" layout="column" layout-align="center none" >

        <div class="layout-row layout-align-start-center">
            <md-input-container class="flex">
                <label>Metrics</label>
                <md-select ng-model="$ctrl.useMetrics" ng-change="$ctrl.changeMetricsList()" multiple >
                    <md-option ng-value="item.name" ng-repeat="item in $ctrl.Metrics" >{{item.title | translate}}</md-option>
                </md-select>
            </md-input-container>
            <md-checkbox ng-model="$ctrl.iteration" ng-change="$ctrl.getGraphs()" class="flex-20">by itetation</md-checkbox>
        </div>


        <div ng-if="item.active" ng-repeat="item in $ctrl.Metrics" class="md-padding">
            <h3 ><span translate >{{item.title}}</span> {{item.Measure}}</h3>
            <md-progress-linear ng-if="$ctrl.updateGraphs"></md-progress-linear>
            <canvas
                    id="line" class="chart chart-line"
                    chart-data="item.data"
                    chart-labels="item.labels"
                    chart-series="item.series"
                    chart-options="item.options"
                    chart-click="onClick">
            </canvas>
            <!--{{item.labels}}-->
        </div>
    </div>
</div>
