<div ng-if="!$ctrl.asForm" layout="row" layout-align="start center">
    <md-icon md-svg-icon="core:persistentStorage" class="md-avatar-icon"></md-icon>
    <div class="md-list-item-text flex" layout="row" layout-align="space-between center">
        <div flex >
            <div flex layout="row" layout-align="start center" ><h4>{{$ctrl.data.Name}} ({{$ctrl.data.SizeGb}} GB)</h4> <div style="padding: 0 2em"> (from cluster {{$ctrl.data.ClusterType}}/{{$ctrl.data.SharedClusterName ? $ctrl.data.SharedClusterName : $ctrl.data.GlobalClusterName}})</div></div>
            <p ng-if="$ctrl.data.Description">Cluster{{}}{{$ctrl.data.Description}}</p>
        </div>
        <div>
            {{$ctrl.data.Status}}
        </div>
    </div>
    <md-menu-bar class="padding-zero layout-row layout-align-end-center md-secondary">
        <md-menu  md-position-mode="target-right target">
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
            </md-button>
            <md-menu-content width="3">
                <md-menu-item>
                    <md-button aria-label="icon"  ng-click="$ctrl.actionForm()"><md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT" | translate}}</md-button>
                </md-menu-item>
                <md-menu-item >
                    <md-button aria-label="icon"  ng-click="$ctrl.deleteConfirm()"><md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE" | translate}}</md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-menu-bar>
</div>

<md-card ng-if="$ctrl.asForm" >
    <md-card-content ng-include="'kuberlab/storage/persistent/form.html'"></md-card-content>
</md-card>
