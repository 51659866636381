<div class="billing-invoices" layout="column" ng-if="$ctrl.invoicesList.length && $ctrl.plans">

  <div class="layout-margin">
    <h3>Invoices</h3>

    <!--<table class="table active-table " ng-table="$ctrl.tableData">-->
      <!--<thead>-->
        <!--<th>Interval</th>-->
        <!--<th>Amount</th>-->
        <!--<th>Status</th>-->
        <!--&lt;!&ndash;<th></th>&ndash;&gt;-->
      <!--</thead>-->
      <!--<tbody>-->
        <!--<tr ng-repeat="invoice in $ctrl.invoicesList" ng-click="$ctrl.usage(invoice)">-->
          <!--<td>{{invoice.Start | date: 'dd/MM/yyyy HH:mm'}} - {{invoice.End | date: 'dd/MM/yyyy HH:mm'}}</td>-->
          <!--<td>{{invoice.Amount}} {{invoice.Currency | uppercase}}</td>-->
          <!--<td>-->
            <!--<span class="status {{invoice.Status}}">{{invoice.Status}}</span>-->
            <!--<md-tooltip ng-if="invoice.PaidTime">{{invoice.PaidTime | date: 'dd/MM/yyyy HH:mm'}}</md-tooltip>-->
          <!--</td>-->
        <!--</tr>-->
      <!--</tbody>-->
    <!--</table>-->

    <table  class="table active-table " ng-table="$ctrl.tableData">
        <tr ng-repeat="invoice in $data" ng-click="$ctrl.usage(invoice)">
          <td title="'Interval'">{{invoice.Start | date: 'dd/MM/yyyy HH:mm'}} - {{invoice.End | date: 'dd/MM/yyyy HH:mm'}}</td>
          <td title="'Amount'">{{invoice.Amount}} {{invoice.Currency | uppercase}}</td>
          <td title="'Status'">
            <span class="status {{invoice.Status}}">{{invoice.Status}}</span>
            <md-tooltip ng-if="invoice.PaidTime">{{invoice.PaidTime | date: 'dd/MM/yyyy HH:mm'}}</md-tooltip>
          </td>
        </tr>
    </table>
  </div>
</div>
