<div ng-switch="$ctrl.data.Type">
    <div ng-switch-when="app">
        from project: <a ui-sref="wsApplication.tab({
            wsId: $ctrl.data.WorkspaceName,
            applicationName: $ctrl.data.ApplicationName,
            tab: 'jobs',
            tabItem: 'job_' + $ctrl.data.Task + '_' + $ctrl.data.Build})">{{$ctrl.data.ApplicationName}}/{{$ctrl.data.Task}}:{{$ctrl.data.Build}}</a>
    </div>
    <!--<div ng-switch-when="model" >-->
        <!--model: <a ui-sref="catalog_new.item.tab({Interface: 'mlmodel', wsId: $ctrl.data.ApplicationModelWorkspaceName, item: $ctrl.data.ApplicationModelName, tab: 'versions', tabItem: $ctrl.data.Version})">{{$ctrl.data.ApplicationModel.DisplayName}} {{$ctrl.data.Version}}</a>-->
    <!--</div>-->
</div>

