<md-menu class="md-secondary" md-position-mode="target-right target">
    <md-button class="md-icon-button" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="!$ctrl.listController.canManage">
        <md-icon md-svg-icon="core:short-menu"></md-icon>
    </md-button>
    <md-menu-content width="3">
        <md-menu-item ng-if="$ctrl.canManage" >
            <md-button ng-click="$ctrl.listController.createNew($ctrl.data)" ><md-icon md-svg-icon="core:copy"></md-icon>Сlone version</md-button>
        </md-menu-item>
        <md-menu-item ng-if="$ctrl.data.Editing">
            <md-button ng-click="$ctrl.commit()"  ><md-icon md-svg-icon="core:commit"></md-icon>{{"COMMON_COMMIT" | translate}}</md-button>
        </md-menu-item>
        <md-menu-item>
            <md-button ng-click="$ctrl.listController.delete($ctrl.data)">
                <md-icon md-svg-icon="core:delete"></md-icon>{{"COMMON_DELETE" | translate}}
            </md-button>
        </md-menu-item>
        <md-menu-item ng-if="$ctrl.data.DownloadURL">
            <md-button href="{{$ctrl.data.DownloadURL}}" target="_blank" download >
                <md-icon md-svg-icon="core:link"></md-icon>{{"COMMON_DOWNLOAD" | translate}} (<span ng-bind-html="$ctrl.listController.CatalogService.datasetVersionSize($ctrl.data)"></span>)
            </md-button>
        </md-menu-item>
    </md-menu-content>
</md-menu>
