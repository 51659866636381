<form ng-submit="$ctrl.send(simplyForm)" name="simplyForm" flex layout="column" layout-align="start">
    <div layout="row" layout-align="start" class="topControll">
        <div flex>
            <md-progress-liner ng-if="$ctrl.saving"></md-progress-liner>
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        </div>
        <md-button class="md-primary" type="submit" ng-disabled='$ctrl.saving' >build</md-button>
    </div>
    <md-content class="simplyFormContent md-padding"  layout="column" layout-align="start" flex>
        <cmp-form-constructor
                ng-model="$ctrl.data.data"
                api="$ctrl.apiForm"
                options="$ctrl.options"
                cluster-id="$ctrl.appController.data.ClusterID"
                workspace="$ctrl.appController.data.WorkspaceName"
                app-volumes="$ctrl.appController.data.Configuration.spec.volumes"
        ></cmp-form-constructor >

        <cmp-applications-task-volumes-revision
                ng-if="$ctrl.options.length"
                default-volume-mapping="true"
                task="$ctrl.data.volumes"
                application="$ctrl.appController.data" ></cmp-applications-task-volumes-revision>

        <div ng-if="!$ctrl.options.length"><p style="text-align: center;">Form not configured</p></div>
    </md-content>
    <div layout="row" layout-align="start">
        <div flex>
            <md-progress-liner ng-if="$ctrl.saving"></md-progress-liner>
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        </div>
        <!--<md-button class="md-primary" ng-click="$ctrl.edit()">Edit</md-button>-->
        <md-button class="md-primary" type="submit" ng-disabled='$ctrl.saving' >build</md-button>
    </div>


    <!--<div ng-if="$ctrl.responce">-->
        <!--<h4>result task</h4>-->
        <!--<cmp-applications-simply-task app-controller="$ctrl.appController" list="$ctrl.jobs"></cmp-applications-simply-task>-->
        <!--&lt;!&ndash;<cmp-application-job-item data="$ctrl.responce"></cmp-application-job-item>&ndash;&gt;-->
    <!--</div>-->
</form>


<!--<form ng-if="$ctrl.asEdit" name="saveConfig" ng-submit="$ctrl.saveConfig(saveOptions)" flex layout="column" layout-align="start">-->
    <!--<div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.template"></div>-->
    <!--<div layout="row" layout-align="start" flex>-->
        <!--<div flex layout="row" layout-align="start"  class="nd-padding" style="margin-left:0; margin-right: 0">-->
            <!--<md-progress-liner ng-if="$ctrl.saving"></md-progress-liner>-->
            <!--<cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>-->
        <!--</div>-->
        <!--&lt;!&ndash;<md-button class="md-primary" ng-click="$ctrl.cancelEdit()">cancel</md-button>&ndash;&gt;-->
        <!--<md-button class="md-primary" type="submit">Save</md-button>-->
    <!--</div>-->
<!--</form>-->
