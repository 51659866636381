<md-dialog aria-label="Mango (Fruit)">
    <form ng-cloak name="saveModel" ng-submit="$ctrl.save(saveModel)">
        <md-toolbar>
            <div class="md-toolbar-tools">
                <h2>Save model</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>

        <md-dialog-content>
            <div class="layout-row layout-align-start-top layout-wrap md-padding"   >
                <md-input-container flex="50">
                    <label>Catalog model</label>
                    <md-select ng-model="data.ID" ng-change="setModel(data.ID)">
                        <md-option ng-repeat="item in mlmodels" ng-value="item.ID">{{item.DisplayName}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex="50">
                    <label>Catalog model version</label>
                    <md-select ng-model="version" ng-change="setVersion(item)">
                        <md-option ng-repeat="item in versions" ng-value="item.Version">{{item.Version}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="33">
                    <label translate>COMMON_DISPLAYNAME</label>
                    <input type="text" ng-model="data.DisplayName" required ng-disabled="!asNew">
                </md-input-container >
                <md-input-container flex="33">
                    <label translate>COMMON_IDENT</label>
                    <input type="text"
                           name="Name"
                           ng-model="data.Name"
                           ng-pattern="validate.namePattern"
                           ng-disabled="!asNew"
                    >
                    <div ng-messages="saveModel.Name.$error">
                        <div ng-message="pattern" translate>FORM_ERROR_NAME_PATTERN</div>
                    </div>
                </md-input-container >

                <md-input-container flex="33">
                    <label>Version</label>
                    <input type="text" ng-model="data.Version" required ng-disabled="version != newVersionName">
                </md-input-container>

                <md-input-container flex="100">
                    <label translate>COMMON_DESCRIPTION</label>
                    <textarea ng-model="data.Description" ng-disabled="!asNew"></textarea>
                </md-input-container>

                <md-input-container flex="50">
                    <label translate>COMMON_KEYWORDS</label>
                    <input type="text" ng-model="Keywords" ng-disabled="!asNew" >
                </md-input-container>

                <md-input-container flex="50">
                    <md-checkbox ng-model="data.Published" ng-disabled="!asNew" >{{ "COMMON_PUBLISHED" | translate}}</md-checkbox>
                </md-input-container>

                <md-input-container flex="50">
                    <label translate>COMMON_VOLUME</label>
                    <md-select ng-model="data.Volume" required>
                        <md-option ng-repeat="item in $ctrl.application.Configuration.spec.volumes" ng-value="item.name">
                            <md-icon md-svg-icon="core:{{$ctrl.StorageService.getType(item)}}" class="md-avatar-icon"></md-icon>
                            {{item.name}}
                        </md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="50">
                    <label translate>COMMON_PATH</label>
                    <input type="text" ng-model="data.Path" required>
                </md-input-container>

                <cmp-serving-form-config data="data.Configuration.serving" serving="data.Configuration.serving" flex="100"></cmp-serving-form-config>
                <cmp-serving-form-spec data="data.Configuration.servingSpec" flex="100"></cmp-serving-form-spec>

                <!--<cmp-mlmodel-serving-form data="data.Configuration.serving"></cmp-mlmodel-serving-form>-->
                <!--<cmp-mlmodel-serving-spec data="data.Configuration.servingSpec" flex></cmp-mlmodel-serving-spec>-->

            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
                <md-progress-linear ng-if="loading"></md-progress-linear>
                <cmp-error-toast ng-if="error" error="error"></cmp-error-toast>
            </div>
            <md-button ng-click="cancel()">
                {{"COMMON_CANCEL" | translate}}
            </md-button>
            <md-button type="submit">
                {{"COMMON_SAVE" | translate}}
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
