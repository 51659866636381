<!--{{$ctrl.workspaceName}}-->
<md-list class="flex" style="position: relative;">
    <div class="titleList layout-row layout-align-space-between-center ng-scope">
        <!--<div style="width: 54px"></div>-->
        <b class="flex">Volume</b>
        <!--<b class="flex-10" style="text-align: left">Mount</b>-->
        <b class="flex-10" style="text-align: center">Lib</b>
        <!--<b class="flex-10" style="text-align: center">Output</b>-->
        <!--<div style="width: 48px;"></div>-->
    </div>
    <div ng-repeat="item in $ctrl.appController.data.Configuration.spec.volumes">

        <cmp-applications-volumes-item
                data="item",
                application="$ctrl.application"
                list-controller="$ctrl"
                workspace-name="$ctrl.workspaceName"
        ></cmp-applications-volumes-item>
        <md-divider></md-divider>
    </div>
    <cmp-applications-volumes-item
            ng-if="$ctrl.Source"
            data="$ctrl.Source"
            application="$ctrl.application"
            list-controller="$ctrl"
            is-new-element="true"
            workspace-name="$ctrl.workspaceName"
    ></cmp-applications-volumes-item>
    <div class="layout-row layout-align-end-center">
        <md-button class="md-raised md-primary" ng-if="!$ctrl.Source" ng-click="$ctrl.getNewSource()">
            <md-icon md-svg-icon="core:plus"></md-icon>
            {{"COMMON_ADD" | translate}}
        </md-button>
    </div>
    <!--<div ng-if="$ctrl.saving" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.3);" layout="row" layout-align="center center">-->
        <!--<div style="color: #fff; font-size: 1.5em; ">{{$ctrl.action}}</div>-->
    <!--</div>-->
</md-list>

<cmp-api-error-service ng-if="$ctrl.updateError" error="$ctrl.updateError" format="'dialog'"></cmp-api-error-service>
